import React from 'react'
import PropTypes from 'prop-types'

// const regEx = `/\[icon\s?(?:name=")?([^\]]*)\"\]/g`



class Text extends React.Component {
    constructor(props) {
        super(props)
        this.state = { expanded: false }
        this.expand = this.expand.bind(this)
    }

    expand() {
        this.setState({ expanded: true })
    }

    render() {

        const { apiElement } = this.props
        const { expanded } = this.state

        const shortcodesApplied = apiElement.text.replace(
            /\[icon_([^\]]*)\]/g,
            (matchedString, name) => {
                // const iconName = name !== '' ?  name : 'homeopathy'

                return `<div class="icon shortcode icon_${name} active" ></div>`
            }
        )

        const shortcodesAppliedMore = apiElement.text_more && apiElement.text_more.replace(
            /\[icon_([^\]]*)\]/g,
            (matchedString, name) => {
                // const iconName = name !== '' ?  name : 'homeopathy'

                return `<div class="icon shortcode icon_${name} active" ></div>`
            }
        )

        return (
            <div className={`text_block align_${apiElement.text_align} ${apiElement.force_no_columns ? 'force_no_columns' : ''} justify`} >
            {apiElement.anchor_name !== '' && <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />}
                        {apiElement.title !== '' && <h2>{apiElement.title}</h2>}
                        <div className="text"
                            dangerouslySetInnerHTML={{
                            __html: shortcodesApplied || null
                            }}/>
                    
                        {apiElement.more_button && !expanded && <div className="center"><button className="more_button" type='button' onClick={this.expand}>{apiElement.text_more_button}</button></div>}
                        {apiElement.more_button && 
                        <div className={`text more_text ${expanded ? 'visible' : ''}`}
                            dangerouslySetInnerHTML={{
                            __html: shortcodesAppliedMore || null
                            }}/>}

            </div>
        )
    }
}

Text.defaultProps = {
    apiElement: {}
}
Text.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default Text