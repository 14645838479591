import React from 'react'
// import $ from 'jquery'
import { Link, withRouter } from 'react-router-dom'

import Settings from '../../Settings'
import urlFormat from '../../utils/urlFormat'

import SvgMindMapDesktop from './SvgMindMapDesktop'
import SvgMindMapMobile from './SvgMindMapMobile'

const up = text => (text ? text.toUpperCase() : '')

class WorkshopMindmap extends React.Component {
    constructor(props) {
        super(props)
        this.goToScreen = this.goToScreen.bind(this)
        this.state = { activeScreen: 'main' }
    }

    goToScreen(screen) {
        // const { history, url } = this.props
        // const link = `${url}single/${urlFormat(page)}`
        this.setState({ activeScreen: screen })
        // console.log(screen)
    }

    render() {
        const { apiElement, url } = this.props
        const { goToScreen } = this
        const { activeScreen } = this.state
        // console.log(apiElement)
        return (
            <div className="workshop_mindmap">
                {apiElement.anchor_name !== '' && (
                    <div
                        className="anchor"
                        id={`${apiElement.anchor_name}_anchor`}
                    />
                )}
                {apiElement.title !== '' && <h1>{apiElement.title}</h1>}
                <div className="dekstop">
                    <SvgMindMapDesktop apiElement={apiElement} url={url} />
                </div>
                <div className="mobile">
                    <SvgMindMapMobile
                        screen={activeScreen}
                        apiElement={apiElement}
                        goToScreen={goToScreen}
                        url={url}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(WorkshopMindmap)
