import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Validator from 'validator'

import { setUserData } from '../../actions/userActions'
import Spinner from '../../utils/Spinner'
import _t from '../../utils/translate'

class WorkshopForm extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        const { user } = this.props

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        console.log(user)

        this.state = {
            loading: false,
            success: false,
            data: {
                first_name: '',
                last_name: '',
                city: '',
                phone: '',
                email: '',
                more_information: '',
                agb: false,
                newsletter: true,
                ...user
            },
            errors: {}
        }
    }

    onChange = e => {
        // const setUserData = this.props
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        // console.log(target)
        // console.log(name)
        // console.log(value)
        // console.log(this.props.setUserData({ asdf: 'adsf' }))

        this.setState({
            data: { ...this.state.data, [name]: value },
            errors: { ...this.state.errors, [name]: false }
        })

        if (
            name !== 'newsletter' &&
            name !== 'agb' &&
            name !== 'more_information'
        )
            this.props.setUserData({
                [name]: value
            })
    }

    onSubmit = e => {
        const { showMessage } = this
        const { submit } = this.props
        const { data } = this.state
        const errors = this.validate(data)

        // console.log('adfsdasfadfsfads')

        e.preventDefault()

        this.setState({ errors })
        if (Object.keys(errors).length !== 0) {
            showMessage(errors)
        }
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            submit(data).then(res => {
                // console.log(res)
                this.setState({ loading: false, success: true })
                return res
            })
            //         .catch(err => {
            //             // console.error(err.response.data.code)
            //             console.error(err)
            //             showMessage(
            //                 err.response &&
            //                 err.response.data &&
            //                 err.response.data.message ?
            //                 _t(err.response.data.message) :
            //                 _t('There was a problem logging you in.'),
            //                 'error'
            //             )
            //             this.setState({
            //                 errors: { global: 'There is a problem.' } || {},
            //                 loading: false
            //             })
            //         })
        }
    }

    validate = data => {
        const errors = {}
        if (!data.first_name)
            errors.first_name = _t(
                `Bitte geben Sie Ihren Vornamen an.`,
                'account'
            )
        if (!data.last_name)
            errors.last_name = _t(
                `Bitte geben Sie Ihren Nachnamen an.`,
                'account'
            )
        if (!data.city)
            errors.city = _t(`Bitte geben Sie Ihren Wohnort an.`, 'account')
        if (!data.phone)
            errors.phone = _t(
                `Bitte geben Sie Ihre Telefonnummer an.`,
                'account'
            )
        if (!Validator.isEmail(data.email))
            errors.email = _t(
                'Bitte korrigieren Sie Ihre Email Adresse.',
                'account'
            )
        if (!data.email)
            errors.email = _t(
                `Bitte geben Sie Ihre Email Adresse an.`,
                'account'
            )
        if (!data.agb)
            errors.agb = _t(
                `Sie müssen die Datenschutzerklärung akzeptieren.`,
                'account'
            )

        return errors
    }

    showMessage = message => {
        console.error('message: ')
        console.error(message)
    }

    render() {
        const { wsOptions } = this.props

        if (wsOptions.length < 1) {
            return <span>Options not found!</span>
        }
        const { data, errors, loading, success } = this.state
        const { onChange, onSubmit } = this
        return (
            <div
                className={`form_holder background_color ${
                    loading ? 'loading' : ''
                } ${success ? 'success' : ''}`}
            >
                <form onSubmit={onSubmit}>
                    <div
                        className={`left field ${
                            errors.first_name ? 'error' : ''
                        }`}
                    >
                        <span className="error_msg">{errors.first_name}</span>
                        <span className="required">
                            <input
                                className="white"
                                name="first_name"
                                value={data.first_name}
                                placeholder={`${wsOptions.text_first_name}*`}
                                onChange={onChange}
                                type="text"
                            />
                        </span>
                    </div>
                    <div
                        className={`right field ${
                            errors.last_name ? 'error' : ''
                        }`}
                    >
                        <span className="error_msg">{errors.last_name}</span>
                        <span className="required">
                            <input
                                className="white"
                                name="last_name"
                                value={data.last_name}
                                placeholder={`${wsOptions.text_last_name}*`}
                                onChange={onChange}
                                type="text"
                            />
                        </span>
                    </div>
                    <div className={`field ${errors.city ? 'error' : ''}`}>
                        <span className="error_msg">{errors.city}</span>
                        <span className="required">
                            <input
                                className="white"
                                name="city"
                                value={data.city}
                                placeholder={`${wsOptions.text_city}*`}
                                onChange={onChange}
                                type="text"
                            />
                        </span>
                    </div>
                    <div className={`field ${errors.phone ? 'error' : ''}`}>
                        <span className="error_msg">{errors.phone}</span>
                        <span className="required">
                            <input
                                className="white"
                                name="phone"
                                value={data.phone}
                                placeholder={`${wsOptions.text_phone}*`}
                                onChange={onChange}
                                type="text"
                            />
                        </span>
                    </div>
                    <div className={`field ${errors.email ? 'error' : ''}`}>
                        <span className="error_msg">{errors.email}</span>
                        <span className="required">
                            <input
                                className="white"
                                name="email"
                                value={data.email}
                                placeholder={`${wsOptions.text_email}*`}
                                onChange={onChange}
                                type="email"
                            />
                        </span>
                    </div>
                    <div
                        className={`field ${
                            errors.more_information ? 'error' : ''
                        }`}
                    >
                        <span className="error_msg">
                            {errors.more_information}
                        </span>
                        <textarea
                            className="white"
                            name="more_information"
                            value={data.more_information}
                            style={{}}
                            onChange={onChange}
                            placeholder={wsOptions.text_more_information}
                        />
                    </div>
                    <div className={`field ${errors.agb ? 'error' : ''}`}>
                        <span className="error_msg">{errors.agb}</span>
                        <input
                            className="white"
                            type="checkbox"
                            id="agb"
                            name="agb"
                            value="agb_read"
                            checked={data.agb}
                            onChange={onChange}
                        />
                        <label htmlFor="agb">
                            {wsOptions.text_agb_checkbox} (
                            <Link to={wsOptions.agb_link} target="_blank">
                                {wsOptions.agb_link_text}
                            </Link>
                            )*
                        </label>
                    </div>
                    <input
                        className="white"
                        type="checkbox"
                        id="newsletter"
                        name="newsletter"
                        value="newsletter"
                        checked={data.newsletter}
                        onChange={onChange}
                    />
                    <label htmlFor="newsletter">
                        {wsOptions.text_newsletter_checkbox}
                    </label>
                    <button className="white" type="submit">
                        {wsOptions.text_submit}
                    </button>
                    <div className="form_overlay"></div>
                </form>
                <div className="loading_holder">
                    <Spinner
                        status={`${loading ? 'loading' : ''}${
                            success ? 'success' : ''
                        }`}
                    />
                    <div className="messages">
                        <span className="loading_message">
                            {wsOptions.text_loading}
                        </span>
                        <span className="success_message">
                            {wsOptions.text_success}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps, { setUserData })(WorkshopForm)
