import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Settings from '../Settings'
import BlocksRenderer from './BlocksRenderer'
import Footer from './Footer'

const SinglePageRenderer = props => {
	const { page, loading, paddingTop, pageId, title } = props

	let yoastMeta = {}

	const tmp = page?.yoast_meta?.map(entry => {
		let identifier = null
		if (entry?.name) identifier = entry.name.replace(/[^0-9a-z]/g, '')
		if (entry?.property)
			identifier = entry.property.replace(/[^0-9a-z]/g, '')
		yoastMeta[identifier] = entry.content
	})

	let titlex = title
	if (page?.title?.rendered) titlex = page.title.rendered
	if (yoastMeta?.ogtitle) titlex = yoastMeta.ogtitle

	// if (page?.title?.rendered ) title = page?.title?.rendered

	console.log(yoastMeta)

	return (
		<div
			className="page_content"
			style={{
				position: 'relative',
				paddingBottom: '50px'
			}}
		>
			<Helmet>
				<title>{titlex}</title>
				<meta name="description" content={yoastMeta?.description} />
			</Helmet>

			<BlocksRenderer page={page} loading={loading} />
			<Footer />
		</div>
	)
}

SinglePageRenderer.defaultProps = {
	page: { acf: {} },
	paddingTop: 140,
	title: 'SN'
}

SinglePageRenderer.propTypes = {
	page: PropTypes.shape({ acf: PropTypes.object }), // eslint-disable-line
	loading: PropTypes.bool.isRequired,
	paddingTop: PropTypes.number,
	pageId: PropTypes.string.isRequired,
	title: PropTypes.string
}

const mapStateToProps = state => ({
	loading: state.pages.loading
})

export default connect(mapStateToProps)(SinglePageRenderer)
