import React from 'react'
import { Link } from 'react-router-dom'

import urlFormat from '../../utils/urlFormat'

const up = text => text ? text.toUpperCase() : ''

const SvgMindMapMobile = ({ apiElement, goToScreen, screen, url }) => (
    <React.Fragment>
        <MainScreen active={screen === 'main'} apiElement={apiElement} goToScreen={goToScreen} url={url}/>
        <Rollenarbeit  active={screen === 'rollenarbeit'} apiElement={apiElement} goToScreen={goToScreen} url={url}/>
        <Kuenstlerpersoenlichkeit  active={screen === 'kuenstlerpersoenlichkeit'} apiElement={apiElement} goToScreen={goToScreen} url={url}/>
        <Kameraworkshops  active={screen === 'kameraworkshops'} apiElement={apiElement} goToScreen={goToScreen} url={url}/>
    </React.Fragment>
)

const MainScreen = ({ apiElement, url, goToScreen, active }) => (

    <svg className={`main_screen ${active ? 'active' : ''}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345 569">



   <Link to={`${url}single/${apiElement.meet_sourcetuning_original_title !== '' ? urlFormat(apiElement.meet_sourcetuning_original_title) : urlFormat(apiElement.meet_sourcetuning_text)}`}>
   <g id="Gruppe_36" data-name="Gruppe 36">
        <g id="MEET_SOURCETUNING" data-name="MEET SOURCETUNING" className="cls-1"><text className="cls-2" transform="translate(72.11 93.38)">{up(apiElement.meet_sourcetuning_text) || ''}</text></g>
        <rect id="Rechteck_16" data-name="Rechteck 16" className="cls-3" x="59.39" y="66.51" width="218.54" height="42.73" />
    </g>
    <g id="Gruppe_38" data-name="Gruppe 38">
        <circle id="Ellipse_5" data-name="Ellipse 5" className="cls-4" cx="274.95" cy="71.4" r="17.8" />
        <g id="Gruppe_37" data-name="Gruppe 37">
            <path id="Pfad_7" data-name="Pfad 7" className="cls-5" d="M267.67,72.28l.63,1-2.58,1.64.79,1.24,2.24-1.42.58.91-2.24,1.42,1.4,2.21-1.18.75-3.39-5.34Z" />
            <path id="Pfad_8" data-name="Pfad 8" className="cls-5" d="M271.23,70a2,2,0,0,1,.72-.3,1.76,1.76,0,0,1,.7,0,1.64,1.64,0,0,1,.61.27,1.76,1.76,0,0,1,.47.5,1.9,1.9,0,0,1,.32.94,1.48,1.48,0,0,1-.31.94h0a1.08,1.08,0,0,1,.48,0,1.21,1.21,0,0,1,.42.15,1.58,1.58,0,0,1,.37.29,3.81,3.81,0,0,1,.31.37l.2.29.25.33a2.7,2.7,0,0,0,.28.3.92.92,0,0,0,.3.2l-1.18.75a2.45,2.45,0,0,1-.51-.53q-.25-.34-.49-.63a1.26,1.26,0,0,0-.65-.45,1,1,0,0,0-.76.21l-1.18.75,1.33,2.09-1.18.75-3.4-5.34Zm1.12,2.68a1.05,1.05,0,0,0,.49-.56.88.88,0,0,0-.17-.71.85.85,0,0,0-.56-.44,1.07,1.07,0,0,0-.72.21L270.1,72l1,1.5Z" />
            <path id="Pfad_9" data-name="Pfad 9" className="cls-5" d="M277.75,65.88l.63,1-2.82,1.79.73,1.15,2.59-1.65.58.91-2.59,1.65.83,1.31,2.88-1.83.63,1-4.06,2.58-3.4-5.34Z" />
            <path id="Pfad_10" data-name="Pfad 10" className="cls-5" d="M282.6,62.8l.63,1-2.82,1.79.73,1.15,2.59-1.65.58.91-2.59,1.65.83,1.31,2.88-1.83.63,1L282,70.68l-3.4-5.34Z" />
        </g>
    </g>
    </Link>


    <Link to={`${url}single/${apiElement.boost_your_character_original_title !== '' ? urlFormat(apiElement.boost_your_character_original_title) : urlFormat(apiElement.boost_your_character_text)}`}>
    
    <g id="Gruppe_40" data-name="Gruppe 40">
        <g id="BOOST_YOUR_CHARACTER" data-name="BOOST YOUR CHARACTER" className="cls-1"><text className="cls-2" transform="translate(61.87 192.55)">{up(apiElement.boost_your_character_text) || ''}</text></g>
        <rect id="Rechteck_17" data-name="Rechteck 17" className="cls-3" x="49.15" y="165.67" width="239.02" height="42.73" />
    </g>

    </Link>
    

    <path id="Pfad_14" data-name="Pfad 14" className="cls-6" d="M167.08,120.3v34.94" />
    <rect onClick={()=>{goToScreen('rollenarbeit')}} id="Rechteck_19" data-name="Rechteck 19" className="cls-4" x="20.7" y="271.8" width="154.45" height="42.73" /><text onClick={()=>{goToScreen('rollenarbeit')}} className="cls-7" transform="translate(33.42 298.68)">{up(apiElement.rollenarbeit_text) || ''}</text>
    <rect onClick={()=>{goToScreen('kuenstlerpersoenlichkeit')}} id="Rechteck_21" data-name="Rechteck 21" className="cls-4" x="93.28" y="359.8" width="231.03" height="42.73" /><text  onClick={()=>{goToScreen('kuenstlerpersoenlichkeit')}} className="cls-7" transform="translate(106 386.68)">{up(apiElement.kuenstlerpersoenlichkeit_text) || ''}</text>
    <line id="Linie_18" data-name="Linie 18" className="cls-8" x1="146.13" y1="217.76" x2="97.65" y2="261.88" />
    <line id="Linie_19" data-name="Linie 19" className="cls-8" x1="181.01" y1="220.52" x2="214.65" y2="342.88" />
    <path id="Pfad_12" data-name="Pfad 12" className="cls-4" d="M216.48,349.11,204.32,333.4l8.41,2.2,6.07-6.21Z" />
    <path id="Pfad_12-2" data-name="Pfad 12-2" className="cls-4" d="M93.63,265.89l8.42-18.25,2,8.57,8.41,2.55Z" /><text className="cls-9" transform="translate(33.41 499.55)">{up(apiElement.einzelcoaching_text) || ''}</text>
    <rect id="Rechteck_18" data-name="Rechteck 18" className="cls-10" x="20.69" y="472.67" width="239.02" height="42.73" />
    <line id="Linie_19-2" data-name="Linie 19-2" className="cls-11" x1="83.99" y1="457.21" x2="48.27" y2="335.41" />
    <path id="Pfad_12-3" data-name="Pfad 12-3" className="cls-12" d="M45.8,327.86l12.43,15.5-8.45-2-6,6.32Z" />
    <path id="Pfad_13" data-name="Pfad 13" className="cls-13" d="M166.2,455.87l11.63-32.92" />
    <path id="Pfad_12-4" data-name="Pfad 12-4" className="cls-12" d="M179.59,418.49l.72,19.85-5.55-6.68-8.56,1.51Z" />


</svg>



)

const Rollenarbeit = ({ apiElement, url, goToScreen, active }) => (

    <svg className={`rollenarbeit_screen ${active ? 'active' : ''}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345 569">
<rect id="Rechteck_19" data-name="Rechteck 19" className="cls-1" x="105.37" y="293" width="135.45" height="42.73" /><text className="cls-2" transform="translate(118.09 319.88)">{up(apiElement.rollenarbeit_text) || ''}</text>
    <g  onClick={()=>{goToScreen('kameraworkshops')}} >
    <rect id="Rechteck_19-2" data-name="Rechteck 19" className="cls-1" x="16.43" y="118" width="180.13" height="42.73" />
    <text className="cls-2" transform="translate(29.15 144.88)">{up(apiElement.kameraworkshops_text) || ''}</text>
    </g>

    <Link to={`${url}single/${apiElement.character_class_original_title !== '' ? urlFormat(apiElement.character_class_original_title) : urlFormat(apiElement.character_class_text)}`}>
    <text className="cls-4" transform="translate(169.5 222.22)">{up(apiElement.character_class_text) || ''}</text>
    <rect id="Rechteck_24" data-name="Rechteck 24" className="cls-5" x="156.78" y="195.34" width="166.59" height="42.73" />
    </Link>

    
    <Link to={`${url}single/${apiElement.muehelose_reise_original_title !== '' ? urlFormat(apiElement.muehelose_reise_original_title) : urlFormat(apiElement.muehelose_reise_text)}`}>
    <text className="cls-4" transform="translate(29.15 410.96)">{up(apiElement.muehelose_reise_text_line1) || ''}</text><text className="cls-4" transform="translate(29.15 426.96)">{up(apiElement.muehelose_reise_text_line2) || ''}</text>
    <rect id="Rechteck_28" data-name="Rechteck 28" className="cls-5" x="16.43" y="392.09" width="178.88" height="42.73" />
    </Link>

    <Link to={`${url}single/${apiElement.shakespeare_animalisch_original_title !== '' ? urlFormat(apiElement.shakespeare_animalisch_original_title) : urlFormat(apiElement.shakespeare_animalisch_text)}`}>
    <text className="cls-4" transform="translate(202.2 468.58)">{up(apiElement.shakespeare_animalisch_text_line1) || ''}</text>
    <text className="cls-4" transform="translate(202.2 484.58)">{up(apiElement.shakespeare_animalisch_text_line2) || ''}</text>
    <rect id="Rechteck_27" data-name="Rechteck 27" className="cls-5" x="189.48" y="450.08" width="133.89" height="42.73" />
    </Link>
    <line id="Linie_20" data-name="Linie 20" className="cls-6" x1="101.87" y1="173.08" x2="139.87" y2="279.08" />
    <line id="Linie_21" data-name="Linie 21" className="cls-6" x1="235.87" y1="246.08" x2="213.87" y2="282.08" />
    <line id="Linie_22" data-name="Linie 22" className="cls-6" x1="213.87" y1="348.08" x2="254.87" y2="439.08" />
    <line id="Linie_23" data-name="Linie 23" className="cls-6" x1="133.87" y1="348.08" x2="111.87" y2="384.08" />
    <g  onClick={()=>{goToScreen('main')}}id="Gruppe_79" data-name="Gruppe 79">
        <rect id="Rechteck_20" data-name="Rechteck 20" className="cls-1" x="16.43" y="13.63" width="158.45" height="42.73" />
        <g id="ALLE_WORKSHOPS-2" data-name="ALLE WORKSHOPS-2" className="cls-7"><text className="cls-2" transform="translate(29.15 40.51)">{up(apiElement.all_workshops_text) || ''}</text></g>
    </g>


</svg>



)


const Kuenstlerpersoenlichkeit = ({ apiElement, url, goToScreen, active }) => (

    <svg className={`kuenstlerpersoenlichkeit_screen ${active ? 'active' : ''}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345 569">
    <g  onClick={()=>{goToScreen('main')}}  id="Gruppe_79" data-name="Gruppe 79">
        <rect id="Rechteck_20" data-name="Rechteck 20" className="cls-1" x="16.43" y="13.63" width="158.45" height="42.73" />
        <g id="ALLE_WORKSHOPS-2" data-name="ALLE WORKSHOPS-2" className="cls-2"><text className="cls-3" transform="translate(29.15 40.51)">{up(apiElement.all_workshops_text) || ''}</text></g>
    </g>
    <line id="Linie_20" data-name="Linie 20" className="cls-4" x1="216.53" y1="168.03" x2="192.53" y2="239.03" />
    <line id="Linie_23" data-name="Linie 23" className="cls-4" x1="149.53" y1="308.03" x2="130.53" y2="353.03" />
    <g id="Gruppe_67" data-name="Gruppe 67">
        <rect id="Rechteck_21" data-name="Rechteck 21" className="cls-1" x="51.16" y="253.95" width="231.03" height="42.73" />
        <g id="KÜNSTLERPERSÖNLICHKEIT" className="cls-2"><text className="cls-3" transform="translate(63.88 280.83)">{up(apiElement.kuenstlerpersoenlichkeit_text) || ''}</text></g>
    </g>
    <Link to={`${url}single/${apiElement.interviewtraining_original_title !== '' ? urlFormat(apiElement.interviewtraining_original_title) : urlFormat(apiElement.interviewtraining_text)}`}>
    <g id="Gruppe_80" data-name="Gruppe 80">
        <g id="INTERVIEWTRAINING" className="cls-2"><text className="cls-5" transform="translate(141.48 141.12)">{up(apiElement.interviewtraining_text) || ''}</text></g>
        <rect id="Rechteck_25" data-name="Rechteck 25" className="cls-7" x="128.76" y="114.24" width="175.28" height="42.73" />
    </g>
    </Link>

    <Link to={`${url}single/${apiElement.helden_und_loser_original_title !== '' ? urlFormat(apiElement.helden_und_loser_original_title) : urlFormat(apiElement.helden_und_loser_text)}`}>
    <g id="Gruppe_81" data-name="Gruppe 81">
        <g id="HELDEN_UND_LOSER" data-name="HELDEN UND LOSER" className="cls-2"><text className="cls-5" transform="translate(53.68 393.53)">{up(apiElement.helden_und_loser_text) || ''}</text></g>
        <rect id="Rechteck_22" data-name="Rechteck 22" className="cls-7" x="40.96" y="366.03" width="175.59" height="42.73" />
    </g>
    </Link>

    <Link to={`${url}single/${apiElement.helden_und_loser_masterclass_original_title !== '' ? urlFormat(apiElement.helden_und_loser_masterclass_original_title) : urlFormat(apiElement.helden_und_loser_masterclass_text)}`}>
    <g id="Gruppe_82" data-name="Gruppe 82">
        <g id="HELDEN_UND_LOSER_MASTERCLASS" data-name="HELDEN UND LOSER MASTERCLASS" className="cls-2"><text className="cls-5" transform="translate(53.8 474.53)">{up(apiElement.helden_und_loser_masterclass_text_line1) || ''}</text><text className="cls-5" transform="translate(53.8 490.53)">{up(apiElement.helden_und_loser_masterclass_text_line2) || ''}</text></g>
        <rect id="Rechteck_26" data-name="Rechteck 26" className="cls-7" x="41.08" y="456.03" width="178.35" height="42.73" />
    </g>
    </Link>
    <line id="Linie_24" data-name="Linie 24" className="cls-4" x1="130.74" y1="419.91" x2="127.98" y2="443.77" />
    <g id="Gruppe_69" data-name="Gruppe 69">
        <path id="Pfad_12" data-name="Pfad 12" className="cls-1" d="M127.54,448.76,122,429.46l7.09,5.2,8-3.58Z" />
    </g>


</svg>



)



const Kameraworkshops = ({ apiElement, url, goToScreen, active }) => (


    <svg className={`kameraworkshops_screen ${active ? 'active' : ''}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 345 569">
    
    <Link to={`${url}single/${apiElement.konkret_und_geheimnisvoll_original_title !== '' ? urlFormat(apiElement.konkret_und_geheimnisvoll_original_title) : urlFormat(apiElement.konkret_und_geheimnisvoll_text)}`}>
    <g id="Gruppe_82" data-name="Gruppe 82">
        <g id="HELDEN_UND_LOSER_MASTERCLASS" data-name="HELDEN UND LOSER MASTERCLASS" className="cls-1"><text className="cls-2" transform="translate(155.84 164.53)">{up(apiElement.konkret_und_geheimnisvoll_text_line1) || ''}</text><text className="cls-2" transform="translate(155.84 180.53)">{up(apiElement.konkret_und_geheimnisvoll_text_line2) || ''}</text></g>
        <rect id="Rechteck_26" data-name="Rechteck 26" className="cls-3" x="143.12" y="146.03" width="154.92" height="42.73" />
    </g>
    </Link>

    <Link to={`${url}single/${apiElement.die_zweite_haut_original_title !== '' ? urlFormat(apiElement.die_zweite_haut_original_title) : urlFormat(apiElement.die_zweite_haut_text)}`}>
    <g id="HELDEN_UND_LOSER_MASTERCLASS-2" data-name="HELDEN UND LOSER MASTERCLASS" className="cls-1"><text className="cls-2" transform="translate(58.84 441.03)">{up(apiElement.die_zweite_haut_text) || ''}</text></g>
    <rect id="Rechteck_26-2" data-name="Rechteck 26" className="cls-4" x="46.12" y="415.03" width="178.35" height="42.73" />
    </Link>
    <rect id="Rechteck_19" data-name="Rechteck 19" className="cls-5" x="78.43" y="292" width="180.13" height="42.73" />
    <text className="cls-6" transform="translate(91.15 318.88)">{up(apiElement.kameraworkshops_text) || ''}</text>
    <line id="Linie_21" data-name="Linie 21" className="cls-8" x1="219.04" y1="202" x2="185.04" y2="277" />
    <line id="Linie_21-2" data-name="Linie 21" className="cls-8" x1="152.04" y1="352" x2="132.04" y2="401" />
    <g onClick={()=>{goToScreen('main')}}>
        <rect id="Rechteck_20" data-name="Rechteck 20" className="cls-5" x="16.43" y="13.63" width="158.45" height="42.73" />
        <g id="ALLE_WORKSHOPS-2" data-name="ALLE WORKSHOPS-2" className="cls-1"><text className="cls-6" transform="translate(29.15 40.51)">{up(apiElement.all_workshops_text) || ''}</text></g>
    </g>


</svg>



)




const SvgMindMapDesktop = ({ apiElement, goToLink, url }) => (<svg version="1.1" id="Layer_1" x="0px" y="0px"
     viewBox="0 0 1887 1081">
<g>

    <g>
        <g>
            <line className="st9" x1="895.9" y1="355.7" x2="729" y2="573.1"/>
            <g>
                <polygon className="st2" points="717.4,588.2 725,560.2 729.9,572 742.5,573.6"/>
            </g>
        </g>
    </g>
    <g>
        <g>
            <line className="st9" x1="1044.9" y1="355.7" x2="1344.6" y2="577.4"/>
            <g>
                <polygon className="st2" points="1359.9,588.7 1331.8,581.6 1343.4,576.5 1344.8,563.9"/>
            </g>
        </g>
    </g>
    <g>
        <g>
            <line className="st9" x1="1241.9" y1="866.7" x2="1241.9" y2="902.6"/>
            <g>
                <polygon className="st2" points="1241.9,921.7 1230.9,894.8 1241.9,901.2 1252.9,894.8"/>
            </g>
        </g>
    </g>
    <line className="st9" x1="959.9" y1="182.2" x2="959.9" y2="237.2"/>
    <line className="st9" x1="1354.9" y1="697.7" x2="1241.9" y2="771.7"/>
    <line className="st9" x1="1525.9" y1="700.7" x2="1633.9" y2="758.7"/>
    <line className="st9" x1="774.9" y1="737.7" x2="703.9" y2="694.7"/>
    <line className="st9" x1="535.9" y1="886.7" x2="558.9" y2="697.7"/>
    <line className="st9" x1="267.9" y1="715.7" x2="424.9" y2="655.7"/>
    <line className="st9" x1="579.6" y1="577.4" x2="593.6" y2="495.1"/>
    <line className="st9" x1="434.7" y1="606.8" x2="402.2" y2="588.2"/>



    <g>
        <g>
            <line className="st0" x1="1282.9" y1="320.7" x2="778" y2="606.8"/>
            <g>
                <polygon className="st1" points="761.4,616.2 779.4,593.4 779.2,606.1 790.2,612.5"/>
            </g>
        </g>
    </g>
    <g>
        <g>
            <line className="st0" x1="1354.9" y1="346.7" x2="1367.7" y2="550.7"/>
            <g>
                <polygon className="st1" points="1368.9,569.7 1356.3,543.6 1367.6,549.3 1378.2,542.2"/>
            </g>
        </g>
    </g>

    <Link to={`${url}single/${apiElement.meet_sourcetuning_original_title !== '' ? urlFormat(apiElement.meet_sourcetuning_original_title) : urlFormat(apiElement.meet_sourcetuning_text)}`}>
    <g>
        <g>
            <rect x="790.4" y="97.5" className="st6" width="344" height="67.3"/>
            <text transform="matrix(1 0 0 1 810.3989 130.7798)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.meet_sourcetuning_text) || ''}</tspan><tspan x="0" y="17.4" className="st2 st3 st5">{apiElement.meet_sourcetuning_subtitle || ''}</tspan></text>
        </g>
        <g>

                <ellipse transform="matrix(0.8229 -0.5682 0.5682 0.8229 140.2548 660.483)" className="st2" cx="1129.7" cy="105.2" rx="28" ry="28"/>
            <g>
                <path className="st7" d="M1118.2,106.6l1,1.6l-4.1,2.6l1.2,1.9l3.5-2.2l0.9,1.4l-3.5,2.2l2.2,3.5l-1.8,1.2l-5.3-8.4L1118.2,106.6z"
                    />
                <path className="st7" d="M1123.9,103.1c0.4-0.2,0.8-0.4,1.1-0.5c0.4-0.1,0.7-0.1,1.1,0c0.4,0.1,0.7,0.2,1,0.4
                    c0.3,0.2,0.5,0.5,0.7,0.8c0.3,0.5,0.5,1,0.5,1.5c0,0.5-0.1,1-0.5,1.5l0,0c0.3-0.1,0.5-0.1,0.7-0.1c0.2,0,0.5,0.1,0.7,0.2
                    c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.4,0.5
                    c0.2,0.1,0.3,0.2,0.5,0.3l-1.8,1.2c-0.3-0.2-0.5-0.5-0.8-0.8c-0.3-0.4-0.5-0.7-0.8-1c-0.3-0.4-0.7-0.6-1-0.7
                    c-0.3-0.1-0.7,0-1.2,0.3l-1.8,1.2l2.1,3.3l-1.8,1.2l-5.3-8.4L1123.9,103.1z M1125.6,107.3c0.4-0.3,0.7-0.6,0.8-0.9
                    c0.1-0.3,0-0.7-0.3-1.1c-0.3-0.4-0.6-0.6-0.9-0.7c-0.3,0-0.7,0.1-1.1,0.3l-2,1.3l1.5,2.4L1125.6,107.3z"/>
                <path className="st7" d="M1134.1,96.5l1,1.6l-4.4,2.8l1.1,1.8l4.1-2.6l0.9,1.4l-4.1,2.6l1.3,2.1l4.5-2.9l1,1.6l-6.4,4.1l-5.3-8.4
                    L1134.1,96.5z"/>
                <path className="st7" d="M1141.7,91.7l1,1.6l-4.4,2.8l1.1,1.8l4.1-2.6l0.9,1.4l-4.1,2.6l1.3,2.1l4.5-2.9l1,1.6l-6.4,4.1l-5.3-8.4
                    L1141.7,91.7z"/>
            </g>
        </g>
    </g>
    </Link>
    <Link to={`${url}single/${apiElement.boost_your_character_original_title !== '' ? urlFormat(apiElement.boost_your_character_original_title) : urlFormat(apiElement.boost_your_character_text)}`}>
    <g>
        <g>
            <rect x="774.3" y="253.6" className="st6" width="376.3" height="67.3"/>
            <text transform="matrix(1 0 0 1 794.2837 286.8799)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.boost_your_character_text) || ''}</tspan><tspan x="0" y="17.4" className="st2 st3 st5">{apiElement.boost_your_character_subtitle || ''}</tspan></text>
        </g>
    </g>
    </Link>
    <g>
        <g className="button">
            <rect x="1309.4" y="253.6" className="st8" width="376.3" height="67.3"/>
            <text transform="matrix(1 0 0 1 1329.4126 286.8799)"><tspan x="0" y="0" className="st1 st3 st4">{up(apiElement.einzelcoaching_text) || ''}</tspan><tspan x="0" y="17.4" className="st1 st3 st5">{apiElement.einzelcoaching_subtitle || ''}</tspan></text>
        </g>
    </g>
    <g>
        <rect x="451.6" y="612.7" className="st2" width="243.1" height="67.3"/>
        <text transform="matrix(1 0 0 1 471.5864 655)"><tspan x="0" y="0" className="st7 st3 st4">{up(apiElement.rollenarbeit_text) || ''}</tspan><tspan x="0" y="17.4" className="st7 st3 st5">{apiElement.rollenarbeit_subtitle || ''}</tspan></text>
    </g>
    <Link to={`${url}single/${apiElement.muehelose_reise_original_title !== '' ? urlFormat(apiElement.muehelose_reise_original_title) : urlFormat(apiElement.muehelose_reise_text)}`}>
    <g className="hidden">
        <rect x="191.6" y="96.7" className="st2 link solid" width="243.1" height="67.3"/>
        <text transform="matrix(1 0 0 1 211.5864 130)"><tspan x="0" y="0" className="st7 st3 st4">{up(apiElement.nachwuchs_text) || ''}</tspan><tspan x="0" y="17.4" className="st7 st3 st5">{apiElement.nachwuchs_subtitle || ''}</tspan></text>
    </g>
    </Link>
    <g>
        <rect x="1160.8" y="612.7" className="st2" width="388.9" height="67.3"/>
        <text transform="matrix(1 0 0 1 1180.8042 655)"><tspan x="0" y="0" className="st7 st3 st4">{up(apiElement.kuenstlerpersoenlichkeit_text) || ''}</tspan><tspan x="0" y="17.4" className="st7 st3 st5">{apiElement.kuenstlerpersoenlichkeit_subtitle || ''}</tspan></text>
    </g>
    <Link to={`${url}single/${apiElement.helden_und_loser_original_title !== '' ? urlFormat(apiElement.helden_und_loser_original_title) : urlFormat(apiElement.helden_und_loser_text)}`}>
    <g className="button">
        <rect x="1078.6" y="781.6" className="st6" width="326.8" height="67.3"/>
        <text transform="matrix(1 0 0 1 1098.6304 814.8892)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.helden_und_loser_text) || ''}</tspan><tspan x="0" y="17.4" className="st2 st3 st5">{apiElement.helden_und_loser_subtitle || ''}</tspan></text>
    </g>
    </Link>


    <Link to={`${url}single/${apiElement.konkret_und_geheimnisvoll_original_title !== '' ? urlFormat(apiElement.konkret_und_geheimnisvoll_original_title) : urlFormat(apiElement.konkret_und_geheimnisvoll_text)}`}>
<g>
    <rect x="300" y="415" className="st6" width="450" height="67.3"></rect>
    <text transform="matrix(1 0 0 1 320 448.3392)" className="st2 st3 st4">{up(apiElement.konkret_und_geheimnisvoll_text) || ''}</text>
    <text transform="matrix(1 0 0 1 321 465.7392)" className="st2 st3 st5">{apiElement.konkret_und_geheimnisvoll_subtitle || ''}</text>
</g>
    </Link>
    <Link to={`${url}single/${apiElement.die_zweite_haut_original_title !== '' ? urlFormat(apiElement.die_zweite_haut_original_title) : urlFormat(apiElement.die_zweite_haut_text)}`}>
<g>
    <rect x="58.9" y="517" className="st6" width="326.8" height="67.3"/>
    <text transform="matrix(1 0 0 1 78.9312 550.3392)" className="st2 st3 st4">{up(apiElement.die_zweite_haut_text) || ''}</text>
    <text transform="matrix(1 0 0 1 78.9312 567.7392)" className="st2 st3 st5">{apiElement.die_zweite_haut_subtitle || ''}</text>
</g>
    </Link>



    <Link to={`${url}single/${apiElement.character_class_original_title !== '' ? urlFormat(apiElement.character_class_original_title) : urlFormat(apiElement.character_class_text)}`}>
    <g>
        <rect x="104.6" y="737.6" className="st6" width="326.8" height="67.3"/>
        <text transform="matrix(1 0 0 1 124.6304 770.8892)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.character_class_text) || ''}</tspan><tspan x="0" y="17.4" className="st2 st3 st5">{apiElement.character_class_subtitle || ''}</tspan></text>
    </g>
    </Link>
    <Link to={`${url}single/${apiElement.interviewtraining_original_title !== '' ? urlFormat(apiElement.interviewtraining_original_title) : urlFormat(apiElement.interviewtraining_text)}`}>
    <g>
        <rect x="1486" y="781.6" className="st6" width="296.4" height="67.3"/>
        <text transform="matrix(1 0 0 1 1506.0305 814.8892)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.interviewtraining_text) || ''}</tspan><tspan x="0" y="17.4" className="st2 st3 st5">{apiElement.interviewtraining_subtitle || ''}</tspan></text>
    </g>
    </Link>
    <Link to={`${url}single/${apiElement.helden_und_loser_masterclass_original_title !== '' ? urlFormat(apiElement.helden_und_loser_masterclass_original_title) : urlFormat(apiElement.helden_und_loser_masterclass_text)}`}>
    <g>
        <rect x="995.4" y="936.5" className="st6" width="493.3" height="67.3"/>
        <text transform="matrix(1 0 0 1 1015.3867 969.7783)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.helden_und_loser_masterclass_text) || ''}</tspan><tspan x="-0.3" y="17.4" className="st2 st3 st5">{apiElement.helden_und_loser_masterclass_subtitle || ''}</tspan></text>
    </g>
    </Link>
    <Link to={`${url}single/${apiElement.shakespeare_animalisch_original_title !== '' ? urlFormat(apiElement.shakespeare_animalisch_original_title) : urlFormat(apiElement.shakespeare_animalisch_text)}`}>
    <g>
        <rect x="579.6" y="758.5" className="st6" width="389.8" height="67.3"/>
        <text transform="matrix(1 0 0 1 599.6304 791.7783)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.shakespeare_animalisch_text) || ''}</tspan><tspan x="0" y="17.4" className="st2 st3 st5">{apiElement.shakespeare_animalisch_subtitle || ''}</tspan></text>
    </g>
    </Link>

    <Link to={`${url}single/${apiElement.muehelose_reise_original_title !== '' ? urlFormat(apiElement.muehelose_reise_original_title): urlFormat(apiElement.muehelose_reise_text)}`}>
    <g className="link">
        <rect x="297.6" y="902.5" className="st6" width="476.8" height="67.3"/>
        <text transform="matrix(1 0 0 1 317.6304 935.7783)"><tspan x="0" y="0" className="st2 st3 st4">{up(apiElement.muehelose_reise_text) || ''}</tspan><tspan x="0" y="17.4" className="st2 st3 st5">{apiElement.muehelose_reise_subtitle || ''}</tspan></text>
    </g>
    </Link>

</g>
</svg>)

export default SvgMindMapMobile