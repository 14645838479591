import React from 'react'

const Spinner = ({ status = 'loading', color = 'white' }) => {
	return (
		<div
			className={`circle-loader color_${color} ${
				status === 'success' ? 'load-complete' : ''
			}`}
		>
			<div
				className={`checkmark ${status === 'success' ? 'draw' : ''}`}
			></div>
		</div>
	)
}

export default Spinner
