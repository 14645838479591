import parseDate from './parseDate'

const formatDate = (rawDate) => {

    const date = parseDate(rawDate)

    // console.log(date)

    return `${date.day}.${date.month}.${date.year}${date.hour!== '' ? ` ${date.hour}`:''}${date.minute!== '' ? `:${date.minute}` :''}`
}

export default formatDate