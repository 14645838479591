import { PAGES_LOADED } from '../actions/types'

const initialState = {
    data: {},
    loading: true
}

export default function(state = initialState, action) {
    switch (action.type) {
        case PAGES_LOADED:
            return {
                ...action.payload,
                loading: false
            }
        default:
            return state
    }
}