import React from 'react'
import { connect } from 'react-redux'

const LoadingScreen = ({ ui, workshops, pages, options }) => {
    const loading = (workshops.loading || pages.loading || options.loading)

    return <div className={`loading_screen ${!loading ? 'hidden' : ''}`}><div className="loading_holder">
    <div className="logo"/>
    <span>{ui.error ? `Error: ${ui.errorMessage}` : 'Loading...'}</span></div></div>

}

const mapStateToProps = (state) => ({
    ui: state.ui,
    workshops: state.workshops,
    options: state.options,
    pages: state.pages
})

export default connect(mapStateToProps)(LoadingScreen)