import { combineReducers } from 'redux'
import pagesReducer from './pagesReducer'
import workshopsReducer from './workshopsReducer'
import browserLanguagesReducer from './browserLanguagesReducer'
import uiReducer from './uiReducer'
import optionsReducer from './optionsReducer'
import userReducer from './userReducer'

const rootReducer = combineReducers({
    pages: pagesReducer,
    workshops: workshopsReducer,
    browserLanugages: browserLanguagesReducer,
    ui: uiReducer,
    options: optionsReducer,
    user: userReducer,
})

export default rootReducer