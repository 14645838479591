import React from 'react'
import PropTypes from 'prop-types'

import Reveal from 'react-reveal/Reveal'
import shortid from 'shortid'

import Settings from '../Settings'

import Text from './blocks/Text'
import HeaderImage from './blocks/HeaderImage'
import Image from './blocks/Image'
import LandingBanner from './blocks/LandingBanner'
import TextWithReference from './blocks/TextWithReference'
import Banner from './blocks/Banner'
import NewsDisplay from './blocks/NewsDisplay'
import PeopleDisplay from './blocks/PeopleDisplay'
import WorkshopCalendar from './blocks/WorkshopCalendar'
import SingleEvent from './blocks/SingleEvent'
import References from './blocks/References'
import Video from './blocks/Video'
import WorkshopMindmap from './blocks/WorkshopMindmap'
import ContactForm from './blocks/ContactForm'

const BlocksRenderer = ({ loading, page }) => {
	// console.log(page)
	return (
		<React.Fragment>
			{!loading &&
				'acf' in page &&
				'blocks' in page.acf &&
				page.acf.blocks.length > 0 &&
				page.acf.blocks.map((element, n) => {
					// Display all layout names for debug:
					// return <p>{element.acf_fc_layout}</p>
					switch (element.acf_fc_layout) {
						case 'text':
							if (element.disable_fade_in)
								return (
									<Text
										key={shortid.generate()}
										apiElement={element}
									/>
								)
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<Text apiElement={element} />
								</Reveal>
							)

						case 'header_image':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<HeaderImage apiElement={element} />
								</Reveal>
							)

						case 'image':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<Image apiElement={element} />
								</Reveal>
							)
						case 'landing_banner':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<LandingBanner apiElement={element} />
								</Reveal>
							)
						case 'banner':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<Banner apiElement={element} />
								</Reveal>
							)
						case 'text_with_reference':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<TextWithReference
										key={shortid.generate()}
										apiElement={element}
									/>
								</Reveal>
							)

						case 'news_display':
							return (
								<NewsDisplay
									key={shortid.generate()}
									apiElement={element}
									url={page.acf.url}
								/>
							)

						case 'people_display':
							return (
								<PeopleDisplay
									key={shortid.generate()}
									apiElement={element}
								/>
							)
						case 'workshop_calendar':
							return (
								<WorkshopCalendar
									apiElement={element}
									key={shortid.generate()}
									url={page.acf.url}
								/>
							)
						case 'references':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<References apiElement={element} />
								</Reveal>
							)
						case 'video':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<Video apiElement={element} />
								</Reveal>
							)
						case 'workshop_mindmap':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<WorkshopMindmap
										apiElement={element}
										url={page.acf.url}
									/>
								</Reveal>
							)
						case 'contact_form':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<ContactForm apiElement={element} />
								</Reveal>
							)
						case 'single_event':
							return (
								<Reveal
									effect="custom_fade_in"
									key={shortid.generate()}
								>
									<SingleEvent
										apiElement={element}
										url={page.acf.url}
									/>
								</Reveal>
							)

						default:
							return (
								<div key={shortid.generate()}>
									<p
										style={{
											textAlign: 'center'
										}}
										className="red"
										key={shortid.generate()}
									>
										Block <b>'{element.acf_fc_layout}'</b>{' '}
										not found!
									</p>

									{Settings.debugBlocks && (
										<pre>
											{JSON.stringify(element, null, 2)}
										</pre>
									)}
								</div>
							)
					}
				})}
			{(!loading &&
				'acf' in page &&
				'blocks' in page.acf &&
				page.acf.blocks.length > 0) || <h1>no content</h1>}
		</React.Fragment>
	)
}

BlocksRenderer.defaultProps = {
	page: {}
}

BlocksRenderer.propTypes = {
	loading: PropTypes.bool.isRequired,
	page: PropTypes.object // eslint-disable-line
}

export default BlocksRenderer
