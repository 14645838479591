import React from 'react'
import PropTypes from 'prop-types'


const slideTime = 50000;

const SingleReference = ({ reference, active }) =>
    <div className={`single_reference ${active ? 'active' : ''}`}>
    <div className="left">
        <div className="image" style={{background: `url('${reference.hasOwnProperty(
                                                                'image'
                                                            ) &&
                                                                reference.image.hasOwnProperty('sizes') &&
                                                                reference.image.sizes.large}')`, 
                                                                backgroundPosition: 'center', 
                                                                backgroundSize: 'cover'}}/>
    </div>
    <div className="right">
    <p className={`quote ${reference.quote.length < 150 ? 'short' : ''}`}>
    {reference.quote}
    </p>

    <p className={`quotee ${reference.quote.length < 150 ? 'short' : ''}`}>
    {reference.quotee}
    </p>
    </div>
    </div>




class References extends React.Component {
    constructor(props) {
        super(props)
        this.nextSlide = this.nextSlide.bind(this)
        this.goToSlide = this.goToSlide.bind(this)
        this.state = { currentPosition: 1, intervalId: null }
    }


    componentDidMount() {
        if (this.props.apiElement.references.length > 0) {
            var intervalId = setInterval(this.nextSlide, slideTime);
            this.setState({ intervalId: intervalId });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    nextSlide() {
        const { apiElement } = this.props
        if (this.state.currentPosition < apiElement.references.length)
            this.setState(oldState => ({ currentPosition: oldState.currentPosition + 1 }))
        else this.setState({ currentPosition: 1 })
        // console.log(`showing slide ${this.state.currentPosition} of ${apiElement.references.length}`)
    }

    goToSlide(slide) {
        clearInterval(this.state.intervalId);
        var intervalId = setInterval(this.nextSlide, slideTime);
        this.setState({ currentPosition: slide, intervalId })

    }



    render() {
        const { apiElement } = this.props
        const { nextSlide } = this
        const { currentPosition } = this.state
        return (<div className='references_block'>
            {apiElement.anchor_name !== '' && <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />}
            <div className="height_ghost_wrapper">
                <div className = "height_ghost" > 
                <div className="single_reference_holder">{
    apiElement.references.length > 0 && apiElement.references.map((reference, i) =>
        <SingleReference key={`reference_${reference.quotee}`} reference={reference} active={currentPosition === i+1}/>
    )
}
<div className="clearfix"/> </div>
            </div>
            </div>
            {apiElement.references.length > 0 && apiElement.references.map((reference,i) =>
                <SingleReference key={`reference_${reference.quotee}`} reference={reference} active={currentPosition === i+1}/>
                )
            }

            {apiElement.references.length > 1 && <div className="button_holder">
            {apiElement.references.length > 0 && apiElement.references.map((reference,i) =>
                <button key={`reference_${reference.quotee}_button`} className={`navigation ${currentPosition === i+1 ? 'active' : ''}`} onClick={()=>this.goToSlide(i+1)} />
                )
            }
            </div>
        } </div>)
    }
}

Text.defaultProps = {
    apiElement: {}
}
Text.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default References