import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import parseDate from '../../utils/parseDate'
import urlFormat from '../../utils/urlFormat'
import Settings from '../../Settings'
import { stubString } from 'lodash'
import { setLanguage } from '../../actions/uiActions'

const { siteBaseUrl, workshopBaseUrl } = Settings

const SingleCalendarEntry = ({ event, urlbase, language, setLanguage }) => {
  const setLanguageHandler = () => {
    if (language !== event.language)
      console.log(`change Language to ${event.language}`)
    setLanguage(event.language)
  }

  const startDate = parseDate(event.start_date)
  const endDate = parseDate(event.end_date)

  // const eventUrl = `${url}/~cal~${urlFormat(eventTitle)}_${event.start_date}`
  const eventUrl = `${workshopBaseUrl[event.language]}/single/${urlFormat(
    event.title
  )}_${event.start_date}`
  // const eventUrl = eventUrlTmp.split(`/${language}/`).join(`/${event.language}/`)

  // const eventUrl = `${url}${urlFormat(event.title)}`
  if (event.is_external && event.link.includes(siteBaseUrl))
    return (
      <Link
        to={event.link}
        className={`single_calender_entry ${event?.color ? event.color : ''}`}
      >
        <div className="left">
          <span className="day">
            {startDate.day}
            {startDate.day !== endDate.day && `-${endDate.day}`}
          </span>
        </div>
        <div className="right">
          <span className="title hover hover-1">
            {event.language}_{event.title}
          </span>
          <br />
          <span className="sub_title">
            {event.link}
            {/* {siteBaseUrl} */}
            {/* {event.subtitle} */}
            {/* {event.location && `, ${event.location}`} */}
          </span>
        </div>
        <div className="clearfix" />
      </Link>
    )

  if (event.is_external && !event.link.includes(siteBaseUrl))
    return (
      <a
        href={event.link}
        rel="noopener noreferrer"
        target="_blank"
        className={`single_calender_entry ${event?.color ? event.color : ''}`}
      >
        <div className="left">
          <span className="day">
            {startDate.day}
            {startDate.day !== endDate.day && `-${endDate.day}`}
          </span>
        </div>
        <div className="right">
          <span className="title hover hover-1">{event.title}</span>
          <br />
          <span className="sub_title">
            {event.subtitle}
            {event.location && `, ${event.location}`}
          </span>
        </div>
        <div className="clearfix" />
      </a>
    )
  return (
    <Link
      onClick={setLanguageHandler}
      to={eventUrl}
      className={`single_calender_entry${event?.color ? event.color : ' '}${
        event.language === 'en' ? 'blue' : ' '
      }`}
    >
      <div className="left">
        <span className="day">
          {startDate.day}
          {startDate.day !== endDate.day && `-${endDate.day}`}
        </span>
      </div>
      <div className="right">
        <span className="title hover hover-1">{event.title}</span>
        <br />
        <span className="sub_title">
          {event.subtitle}
          {event.location && `, ${event.location}`}
        </span>
      </div>
      <div className="clearfix" />
    </Link>
  )
}

export default connect(null, { setLanguage })(SingleCalendarEntry)
