import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import WebFont from 'webfontloader'
import reportWebVitals from './reportWebVitals'
// import Analytics from 'react-router-ga'
import ReactGA from 'react-ga'
// import $ from 'jquery'

import Settings from './Settings'

// import setAuthorizationHeader from './utils/setAuthorizationHeader'
// import { store } from './store/store'
// import { userLoggedIn, userDataLoaded } from './actions/userActions'
// import { pagesLoaded } from './actions/pagesActions'
// import { cartLoaded } from './actions/cartActions'

import * as serviceWorker from './serviceWorker'
import App from './App'

import LogRocket from 'logrocket'
// import Quickmetrics from 'quickmetrics'

if (!window.location.href.includes('localhost')) {
  console.log('Not local testing. Start Logging.')
  LogRocket.init('e1gon3/rothcoaching')
} else {
  console.log("Local testing. Don't start logging.")
}

ReactGA.initialize('UA-59124434-3', {
  gaOptions: {
    // userId: 123
  },
})

// const qm = new Quickmetrics({
//   apiKey: 's6SzcP8sGIjWJ6123PGB4g',
//   maxBatchTime: 10, // max and default set to 60 seconds
//   maxBatchSize: 1000, // max and default set to 1000 events per batch
// })

// function sendToAnalytics(metric) {
//     const body = JSON.stringify(metric);
//     const url = 'https://example.com/analytics';

//     // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
//     if (navigator.sendBeacon) {
//       navigator.sendBeacon(url, body);
//     } else {
//       fetch(url, { body, method: 'POST', keepalive: true });
//     }
//   }

reportWebVitals(({ name, id, value }) => {
  const url = `https://qckm.io?m=${name}&v=${value}&k=92-wxInXUXH95Cj7Su7JXg`
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url)
  } else {
    fetch(url, { method: 'POST', keepalive: true })
  }
  //   if (vitals.name === 'LCP' || vitals.name === 'FID' || vitals.name === 'CLS') {
  // qm.event(vitals.name, vitals.value)
  //   console.log(vitals.name, vitals.value)
  //   }
})

WebFont.load({
  google: {
    families: [
      'IBM Plex Sans:extra-light,light,regular',
      'PT Sans',
      'sans-serif',
    ],
  },
})

/* eslint-disable */
// $.fn.scrollGuard = function() {
//     return this.on('wheel', function(e) {
//         var $this = $(this)
//         if (e.originalEvent.deltaY < 0) {
//             /* scrolling up */
//             return $this.scrollTop() > 0
//         } else {
//             /* scrolling down */
//             return (
//                 $this.scrollTop() + $this.innerHeight() < $this[0].scrollHeight
//             )
//         }
//     })
// }

/* eslint-enable */

// if (localStorage.snJWT && localStorage.snUser) {
//  const payload = decode(localStorage.snJWT)
//  console.log(payload)
//  const userAuth = { token: localStorage.snJWT }
//  const userData = JSON.parse(localStorage.snUser)
//  setAuthorizationHeader(localStorage.snJWT)
//  ReactGA.set({ userId: userData.login })
//  store.dispatch(userLoggedIn(userAuth))
//  store.dispatch(userDataLoaded(userData))
// }

// if (localStorage.snPages) {
//  const pages = JSON.parse(localStorage.snPages)
//  store.dispatch(pagesLoaded(pages))
// }

// if (localStorage.snCart) {
//  const cart = JSON.parse(localStorage.snCart)
//  store.dispatch(cartLoaded(cart))
// }

ReactDOM.render(
  <BrowserRouter basename={Settings.basename}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
// <Analytics id="UA-140713645-1" debug>
// </Analytics>

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
