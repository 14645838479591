import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Validator from 'validator'

import {
    setUserData,
    submitContactFormRequest
} from '../../actions/userActions'
import Spinner from '../../utils/Spinner'
import _t from '../../utils/translate'

class ContactForm extends React.Component {
    static propTypes = {}

    constructor(props) {
        super(props)
        const { user } = this.props

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        console.log(user)

        this.state = {
            loading: false,
            success: false,
            data: {
                first_name: '',
                last_name: '',
                city: '',
                phone: '',
                email: '',
                message: '',
                agb: false,
                newsletter: true,
                ...user
            },
            errors: {}
        }
    }

    onChange = e => {
        // const setUserData = this.props
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        // console.log(target)
        // console.log(name)
        // console.log(value)
        // console.log(this.props.setUserData({ asdf: 'adsf' }))

        this.setState({
            data: { ...this.state.data, [name]: value },
            errors: { ...this.state.errors, [name]: false }
        })

        if (name !== 'newsletter' && name !== 'agb' && name !== 'message')
            this.props.setUserData({
                [name]: value
            })
    }

    onSubmit = e => {
        const { showMessage } = this
        const { submitContactFormRequest, language } = this.props
        const { data } = this.state
        const errors = this.validate(data)

        // console.log('adfsdasfadfsfads')

        e.preventDefault()

        this.setState({ errors })
        if (Object.keys(errors).length !== 0) {
            showMessage(errors)
        }
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            // submit(data)
            submitContactFormRequest({ ...data, language }).then(res => {
                // console.log(res)
                this.setState({ loading: false, success: true })
                return res
            })
        }
    }

    validate = data => {
        const errors = {}
        if (!data.first_name)
            errors.first_name = _t(
                `Bitte geben Sie Ihren Vornamen an.`,
                'account'
            )
        if (!data.last_name)
            errors.last_name = _t(
                `Bitte geben Sie Ihren Nachnamen an.`,
                'account'
            )
        if (!data.city)
            errors.city = _t(`Bitte geben Sie Ihren Wohnort an.`, 'account')
        // if (!data.phone)
        //     errors.phone = _t(
        //         `Bitte geben Sie Ihre Telefonnummer an.`,
        //         'account'
        //     )
        if (!Validator.isEmail(data.email))
            errors.email = _t(
                'Bitte korrigieren Sie Ihre Email Adresse.',
                'account'
            )
        if (!data.email)
            errors.email = _t(
                `Bitte geben Sie Ihre Email Adresse an.`,
                'account'
            )
        if (!data.agb)
            errors.agb = _t(
                `Sie müssen die Datenschutzerklärung akzeptieren.`,
                'account'
            )

        return errors
    }

    showMessage = message => {
        console.error('message: ')
        console.error(message)
    }

    render() {
        if (this.props.options.loading) {
            return null
        }

        const {
            language,
            apiElement: { is_background_color, anchor_name }
        } = this.props
        const contactFormOptions = this.props.options.data[language]
            .contact_form

        console.log(language)
        console.log(this.props.options.data[language])

        if (!contactFormOptions || contactFormOptions.length < 1) {
            return <span>Options not found!</span>
        }
        const { data, errors, loading, success } = this.state
        const { onChange, onSubmit } = this
        return (
            <div
                className={`contact_form_wrapper ${
                    is_background_color
                        ? 'background_color'
                        : 'background_white'
                }`}
            >
                <div className="contact_form" id={`${anchor_name}_anchor`}>
                    <div className="text">
                        {contactFormOptions.title?.length > 0 && (
                            <h1>{contactFormOptions.title}</h1>
                        )}
                        <p>{contactFormOptions.text}</p>
                    </div>
                    <div className="contact">
                        <div
                            className="image"
                            style={{
                                backgroundImage: `url('${
                                    contactFormOptions.hasOwnProperty(
                                        'image_contact'
                                    ) &&
                                    contactFormOptions.image_contact.hasOwnProperty(
                                        'sizes'
                                    ) &&
                                    contactFormOptions.image_contact.sizes.large
                                }')`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        />
                        <h2 className="name">
                            {contactFormOptions.name_contact}
                        </h2>
                        <a
                            href={`mailto:${contactFormOptions.email_address_contact}?subject=Anfrage`}
                            className="email"
                        >
                            {contactFormOptions.email_address_contact}
                        </a>
                    </div>
                    <div className="form">
                        <div
                            className={`form_holder ${
                                loading ? 'loading' : ''
                            } ${success ? 'success' : ''}`}
                        >
                            <form onSubmit={onSubmit}>
                                <div
                                    className={`left field ${
                                        errors.first_name ? 'error' : ''
                                    }`}
                                >
                                    <span className="required">
                                        <input
                                            className={
                                                is_background_color
                                                    ? 'white'
                                                    : ''
                                            }
                                            name="first_name"
                                            value={data.first_name}
                                            placeholder={`${contactFormOptions.text_first_name}*`}
                                            onChange={onChange}
                                            type="text"
                                        />
                                    </span>
                                    <span className="error_msg">
                                        {errors.first_name}
                                    </span>
                                </div>
                                <div
                                    className={`right field ${
                                        errors.last_name ? 'error' : ''
                                    }`}
                                >
                                    <span className="required">
                                        <input
                                            className={
                                                is_background_color
                                                    ? 'white'
                                                    : ''
                                            }
                                            name="last_name"
                                            value={data.last_name}
                                            placeholder={`${contactFormOptions.text_last_name}*`}
                                            onChange={onChange}
                                            type="text"
                                        />
                                    </span>
                                    <span className="error_msg">
                                        {errors.last_name}
                                    </span>
                                </div>
                                <div
                                    className={`field ${
                                        errors.email ? 'error' : ''
                                    }`}
                                >
                                    <span className="required">
                                        <input
                                            className={
                                                is_background_color
                                                    ? 'white'
                                                    : ''
                                            }
                                            name="email"
                                            value={data.email}
                                            placeholder={`${contactFormOptions.text_email}*`}
                                            onChange={onChange}
                                            type="email"
                                        />
                                    </span>
                                    <span className="error_msg">
                                        {errors.email}
                                    </span>
                                </div>
                                <div
                                    className={`field ${
                                        errors.city ? 'error' : ''
                                    }`}
                                >
                                    <span className="required">
                                        <input
                                            className={
                                                is_background_color
                                                    ? 'white'
                                                    : ''
                                            }
                                            name="city"
                                            value={data.city}
                                            placeholder={`${contactFormOptions.text_city}*`}
                                            onChange={onChange}
                                            type="text"
                                        />
                                    </span>
                                    <span className="error_msg">
                                        {errors.city}
                                    </span>
                                </div>
                                <div
                                    className={`field ${
                                        errors.phone ? 'error' : ''
                                    }`}
                                >
                                    <span className="required">
                                        <input
                                            className={
                                                is_background_color
                                                    ? 'white'
                                                    : ''
                                            }
                                            name="phone"
                                            value={data.phone}
                                            placeholder={`${contactFormOptions.text_phone}`}
                                            onChange={onChange}
                                            type="text"
                                        />
                                    </span>
                                    <span className="error_msg">
                                        {errors.phone}
                                    </span>
                                </div>
                                <div
                                    className={`field ${
                                        errors.message ? 'error' : ''
                                    }`}
                                >
                                    <textarea
                                        className={
                                            is_background_color ? 'white' : ''
                                        }
                                        name="message"
                                        value={data.message}
                                        style={{}}
                                        onChange={onChange}
                                        rows={5}
                                        placeholder={
                                            contactFormOptions.text_message
                                        }
                                    />
                                    <span className="error_msg">
                                        {errors.message}
                                    </span>
                                </div>
                                <div
                                    className={`field ${
                                        errors.agb ? 'error' : ''
                                    } `}
                                >
                                    <span className="error_msg">
                                        {errors.agb}
                                    </span>
                                    <input
                                        type="checkbox"
                                        id="agb"
                                        name="agb"
                                        className={
                                            is_background_color ? 'white' : ''
                                        }
                                        value="agb_read"
                                        checked={data.agb}
                                        onChange={onChange}
                                    />
                                    <label
                                        htmlFor="agb"
                                        className={
                                            is_background_color ? 'white' : ''
                                        }
                                    >
                                        {contactFormOptions.text_agb_checkbox} (
                                        <Link
                                            to={contactFormOptions.agb_link}
                                            target="_blank"
                                        >
                                            {contactFormOptions.agb_link_text}
                                        </Link>
                                        )*
                                    </label>
                                </div>
                                <input
                                    type="checkbox"
                                    id="newsletter"
                                    name="newsletter"
                                    value="newsletter"
                                    checked={data.newsletter}
                                    onChange={onChange}
                                    className={
                                        is_background_color ? 'white' : ''
                                    }
                                />
                                <label
                                    htmlFor="newsletter"
                                    className={
                                        is_background_color ? 'white' : ''
                                    }
                                >
                                    {
                                        contactFormOptions.text_newsletter_checkbox
                                    }
                                </label>
                                <button
                                    className={
                                        is_background_color ? 'white' : ''
                                    }
                                    type="submit"
                                >
                                    {contactFormOptions.text_submit}
                                </button>
                                <div className="form_overlay"></div>
                            </form>
                            <div className="loading_holder">
                                <Spinner
                                    status={`${loading ? 'loading' : ''}${
                                        success ? 'success' : ''
                                    }`}
                                    color="primary"
                                />
                                <div className="messages">
                                    <span className="loading_message">
                                        {contactFormOptions.text_loading}
                                    </span>
                                    <span className="success_message">
                                        {contactFormOptions.text_success}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    options: state.options,
    language: state.ui.language
})

ContactForm.propTypes = {
    lanugage: PropTypes.string,
    apiElement: PropTypes.shape({
        anchor_name: PropTypes.string,
        is_background_color: PropTypes.bool.isRequired
    })
}

export default connect(mapStateToProps, {
    setUserData,
    submitContactFormRequest
})(ContactForm)
