import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import _t from '../utils/translate'
import Settings from '../Settings'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.handleNewsletter = this.handleNewsletter.bind(this)
  }

  handleNewsletter() {
    console.log('newsletter')
  }

  render() {
    const { handleNewsletter } = this
    const { options, language } = this.props
    return (
      <div className="footer_wrapper">
        <div className="footer mobile">
          <div className="logo" />
          <div className="link_holder">
            {Settings.mobileMenu[language].map((menuItem) => (
              <NavLink key={`mobile_menu_${menuItem.url}`} to={menuItem.url}>
                {menuItem.title}
              </NavLink>
            ))}
          </div>
          <Link
            className="imprint"
            to={
              !options.loading
                ? options.data[language].footer.impressum_link
                : ''
            }
          >
            {!options.loading && options.data[language].footer.impressum_text}
          </Link>
        </div>
        <div className="footer desktop">
          <div className="logo_holder"></div>
          <div className="flex">
            <div className="info_left">
              <div className="logo" />
              <div className="footer_text">
                {false && (
                  <button onClick={handleNewsletter}>
                    {!options.loading
                      ? options.data[language].footer.newsletter_text
                      : ''}
                  </button>
                )}
                <Link className="button solid" to={'#newsletter'}>
                  {!options.loading
                    ? options.data[language].footer.newsletter_text
                    : ''}
                </Link>
                <br />
                <Link
                  className="button solid contact"
                  to={
                    !options.loading
                      ? options.data[language].footer.contact_link
                      : ''
                  }
                >
                  {!options.loading
                    ? options.data[language].footer.contact_text
                    : ''}
                </Link>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      (!options.loading &&
                        options.data[language].footer.info_left) ||
                      null,
                  }}
                />
                <Link
                  to={
                    !options.loading
                      ? options.data[language].footer.impressum_link
                      : ''
                  }
                >
                  {!options.loading &&
                    options.data[language].footer.impressum_text}
                </Link>
              </div>
            </div>
            {!options.loading &&
              options.data[language].footer &&
              options.data[language].footer.links.length > 0 &&
              options.data[language].footer.links.map((page) => (
                <div
                  className="link_block"
                  key={`footer_menu_${page.page_link}`}
                >
                  <NavLink className="main" to={page.page_link}>
                    {page.page}
                  </NavLink>
                  {page.sublinks.length &&
                    page.sublinks.map((sublink, i) => (
                      <NavLink
                        key={`footer_submenu_${sublink.link}_${i}`}
                        to={sublink.link}
                      >
                        {sublink.text}
                      </NavLink>
                    ))}
                </div>
              ))}
          </div>
        </div>

        {!options.loading && Settings.debugBlocks && (
          <pre>{JSON.stringify(options.data[language].footer, null, 2)}</pre>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.ui.language,
  options: state.options,
})

export default connect(mapStateToProps)(Footer)
