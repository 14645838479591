import { store } from '../store/store'
import { LOAD_PAGES, PAGES_LOADED, SET_LANGUAGE, SET_ERROR } from './types'
import { api } from '../api'
import Settings from '../Settings'
import { setError } from './uiActions'

// export const pagesLoaded = pages => ({ type: LOAD_PAGES, payload: pages })
export const pagesLoaded = pages => ({ type: PAGES_LOADED, payload: pages })
function handleErrors(responses) {
    // console.log(response)

    responses.map(response => {
        if (response.status !== 200) {
            throw Error(JSON.stringify(response))
        } else return response
    })
    return responses
}

const repeat = () => {
    window.setTimeout(() => {
        // loadPages()

        repeat();
    }, 1000)
}

export const loadPages = () => {
    // console.log(Settings.supportedLanguages)
    setError('err.message')
    let promises = []
    Settings.supportedLanguages.map(lang => {
        promises.push(api.pages.getPagesLang(lang))
    })
    Promise.all(promises)
        .then(res => handleErrors(res))
        .then(res => {
            let allLanguagesPages = {}
            res.map((singleRes, x) => {
                allLanguagesPages[Settings.supportedLanguages[x]] = singleRes.data
                return null
            })
            // console.log(allLanguagesPages)
            store.dispatch(pagesLoaded({ data: { ...allLanguagesPages } }))
        })
        .catch(function(err) {
            // console.error(`Failed to load pages: ${err.message}`); // some coding error in handling happened
            store.dispatch({ type: SET_ERROR, payload: err.message })

            window.setTimeout(() => {

                loadPages();
                // console.log('do it again');

            }, 3000)
        });
}


// export const loadLanguage = (language, pageJustLoaded = false) => dispatch => {
//     if (store.getState().ui.language !== language || pageJustLoaded) {
//         let languageToLoad = language
//         if (pageJustLoaded) {
//             languageToLoad = store.getState().ui.language ?
//                 store.getState().ui.language :
//                 'en'
//         }
//         dispatch({ type: SET_LANGUAGE, payload: languageToLoad })
//         api.pages
//             .getPagesLang(languageToLoad)
//             .then(res => handleErrors(res))
//             .then(res => res.data)
//             .then(res => {
//                 return res.reduce((obj, item) => {
//                     obj[item.slug] = item // eslint-disable-line
//                     return obj
//                 }, {})
//             })
//             .then(pages =>
//                 dispatch(
//                     langLoaded({ data: { ...pages }, language: languageToLoad })
//                 )
//             )
//             .catch(err => {
//                 console.log(err)
//             })
//     }
// }

export const test = 'test'