import _ from 'lodash'
import Settings from '../Settings'

import { store } from '../store/store'

const LOG_ON_SERVER = false

const translation = {
    en: {
        general: [
            ['test', 'Test'],
            ['test', 'Test'],
            ['test', 'Test'],
            ['test', 'Test']
        ],
        account: [
            ['Bitte geben Sie Ihren Namen an.', 'Please specify your name.'],
            ['Bitte geben Sie Ihren Wohnort an.', 'Please specify your city of residence.'],
            ['Bitte geben Sie Ihre Telefonnummer an.', 'Please specify your phone number.'],
            ['Bitte korrigieren Sie Ihre Email Adresse.', 'Please make sure your email address is correct.'],
            ['Bitte geben Sie Ihre Email Adresse an.', 'Please specify your email address.'],
            ['Sie müssen die Datenschutzerklärung akzeptieren.', 'You have to accept the privacy policy.'],
        ],
        months: [
            ['01', 'January'],
            ['02', 'February'],
            ['03', 'March'],
            ['04', 'April'],
            ['05', 'May'],
            ['06', 'June'],
            ['07', 'July'],
            ['08', 'August'],
            ['09', 'September'],
            ['10', 'October'],
            ['11', 'November'],
            ['12', 'December'],
        ]
    },
    de: {
        general: [
            ['test', 'Test'],
            ['test', 'Test'],
            ['test', 'Test'],
            ['test', 'Test']
        ],
        menu: [
            ['test', 'Test'],
            ['test', 'Test'],
            ['test', 'Test'],
            ['test', 'Test']
        ],
        months: [
            ['01', 'Januar'],
            ['02', 'Februar'],
            ['03', 'März'],
            ['04', 'April'],
            ['05', 'Mai'],
            ['06', 'Juni'],
            ['07', 'Juli'],
            ['08', 'August'],
            ['09', 'September'],
            ['10', 'Oktober'],
            ['11', 'November'],
            ['12', 'Dezember'],
        ]
    },
}

const sendToServer = (input, namespace) => {
    fetch(Settings.translationLog, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify({
            string: input,
            namespace,
            uid: `${input}---${namespace}`
        }), // data can be `string` or {object}!
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch(res => console.log('asdf'))
}

const translate = (input, namespace = 'general') => {
    // const language = 'fr'
    const { language } = store.getState().ui
    // let searchIn
    // if (namespace) searchIn = translation[language][namespace]
    const searchIn = translation[language][namespace]

    const find = _.find(searchIn, x => x[0] === input)
    if (find) {
        return find[1]
    }
    if (LOG_ON_SERVER) sendToServer(input, namespace)
    return input
}

export default translate