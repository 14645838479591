import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax';

import Settings from '../../Settings'

const LandingBanner = ({ apiElement }) => {
    // console.log(apiElement)
    const imageUrl = apiElement.hasOwnProperty(
            'image'
        ) &&
        apiElement.image.hasOwnProperty('sizes') &&
        apiElement.image.sizes.large


    return (
        <div className="landing_banner header_image">
            {apiElement.anchor_name !== '' && <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />}
                <div className="image_wrapper">
                    <Parallax y={[0, Settings.parallaxEnd]} className='header_image_parallax'>
                        <div className="image_holder" style={{backgroundImage: `url(${imageUrl})`, backgroundPosition: apiElement.image_position || 'center'}}/>
                    </Parallax>
                </div>
            

                <div className="button_holder hidden_on_mobile">
                    <div className="quote_holder">
                        { apiElement.quote !== '' &&
                        <React.Fragment>
                            <p className="quote">{apiElement.quote}</p>
                            {apiElement.quotee !== '' && <p className="quotee">- {apiElement.quotee}</p>}
                        </React.Fragment>
                        }
                    </div>
                    {apiElement.button_1_text !== '' && <Link to={apiElement.button_1_link}>{apiElement.button_1_text}</Link>}
                    {apiElement.button_2_text !== '' && <React.Fragment><br/><Link to={apiElement.button_2_link}>{apiElement.button_2_text}</Link></React.Fragment>}
                </div>
                    
        {apiElement.quote !== '' &&
            <div className="quote_holder_mobile">
                <p className="quote">{apiElement.quote}</p>
                {apiElement.quotee !== '' && <p className="quotee">- {apiElement.quotee}</p>}

                {apiElement.button_1_text !== '' && <Link to={apiElement.button_1_link}>{apiElement.button_1_text}</Link>}
                {apiElement.button_2_text !== '' && <React.Fragment><br/><Link to={apiElement.button_2_link}>{apiElement.button_2_text}</Link></React.Fragment>}
            </div>
        }



        </div>

    )
}

LandingBanner.defaultProps = {
    apiElement: {}
}
LandingBanner.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default LandingBanner