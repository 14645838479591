import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Helmet } from 'react-helmet'
import { PersistGate } from 'redux-persist/integration/react'
import { ParallaxProvider } from 'react-scroll-parallax'

import { store, persistor } from './store/store'

import AppWrapper from './AppWrapper'
import LandingPage from './components/pages/LandingPage'
import LoadingScreen from './components/pages/LoadingScreen'
import Newsletter from './components/Newsletter'
// import MainMenu from './components/MainMenu'
import MobileMenu from './components/MobileMenu'
import Menu from './components/Menu'
import ContactButtonMenu from './components/ContactButtonMenu'
import Message from './components/Message'
import PageLoad from './components/PageLoad'
import GeneratedRoutes from './components/GeneratedRoutes'

import './css/_index.scss'
// import 'include-media/dist/_include-media.scss'
// import 'semantic-ui-css/semantic.min.css'

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})

// const persistState = false

const App = () => (
  <ParallaxProvider>
    <Provider store={store}>
      <PageLoad>
        <PersistGate loading={null} persistor={persistor}>
          <AppWrapper>
            <Helmet>
              <meta charSet="utf-8" />
              <title>rothcoaching</title>
            </Helmet>
            <div className="app_content">
              <Route
                path="/"
                exact
                component={() => (
                  <>
                    <Helmet>
                      <meta name="robots" content="noindex, follow" />
                    </Helmet>
                    {<Redirect status={301} to="/de" />}
                  </>
                )}
              />
              <GeneratedRoutes />
              <Menu />
              <MobileMenu />
              <ContactButtonMenu />
            </div>
            <Message />
            <LoadingScreen />
            <Newsletter />
          </AppWrapper>
        </PersistGate>
      </PageLoad>
    </Provider>
  </ParallaxProvider>
)

export default App
