export const LOAD_PAGES = 'LOAD_PAGES'
export const PAGES_LOADED = 'PAGES_LOADED'

export const LOAD_BROWSER_LANGUAGES = 'LOAD_BROWSER_LANGUAGES'

export const HIDE_POPUPS = 'HIDE_POPUPS'

export const SET_LOADING = 'SET_LOADING'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_ALL_POPUPS = 'HIDE_ALL_POPUPS'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const HIDE_MESSAGE = 'HIDE_MESSAGE'

export const SHOW_MENU = 'SHOW_MENU'
export const HIDE_MENU = 'HIDE_MENU'

export const SET_ERROR = 'SET_ERROR'

export const WORKSHOPS_LOADED = 'WORKSHOPS_LOADED'

export const OPTIONS_LOADED = 'OPTIONS_LOADED'

export const SHOW_NEWSLETTER_POPUP = 'SHOW_NEWSLETTER_POPUP'
export const HIDE_NEWSLETTER_POPUP = 'HIDE_NEWSLETTER_POPUP'

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'

export const WORKSHOP_SIGNUP = 'WORKSHOP_SIGNUP'