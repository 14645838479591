import React from 'react'
import PropTypes from 'prop-types'
import { Parallax } from 'react-scroll-parallax'

import Settings from '../../Settings'

const Image = ({ apiElement }) => {
  console.log({ image: apiElement })

  const imageUrl = apiElement?.image

  return (
    <React.Fragment>
      {apiElement.anchor_name !== '' && (
        <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />
      )}
      <Parallax
        y={[Settings.parallaxStart, Settings.parallaxEnd]}
        className={`image ${
          apiElement.hidden_on_mobile ? 'hidden_on_mobile' : ''
        }`}
      >
        <div
          className="image_holder"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: apiElement.image_position || 'center',
          }}
        />
      </Parallax>
    </React.Fragment>
  )
}

Image.defaultProps = {
  apiElement: {},
}
Image.propTypes = {
  apiElement: PropTypes.object, // eslint-disable-line
}

export default Image
