const parseDate = (date) => {

    const year = date.substr(0, 4)
    const month = date.substr(4, 2)
    const day = date.substr(6, 2)
    const hour = date.substr(8, 2)
    const minute = date.substr(10, 2)

    return { year, month, day, hour, minute }
}

export default parseDate