const Settings = {
  basename: '/',
  siteBaseUrl: 'https://rothcoaching.com',
  supportedLanguages: ['de', 'en'],
  SiteName: 'Rothcoaching',
  // defaultLanguage: 'de',
  placeholder: '',
  // apiBase: 'https://one-squared.com/sn/wp_server/0.3.0/wp-json',
  // apiBase: 'https://api.one-squared.com/rothcoaching_dev/wp-json',
  apiBase: 'https://rothcoaching.com/backend/wp-json',
  cachedApiBase: 'https://cache.one-squared.com/',
  useCache: true,
  GoogleAnalyticsId: 'UA-140713645-1',
  translationLog:
    // 'https://api.one-squared.com/sn/translations/public/_/items/sn_translations_log'
    '',
  menu: {
    de: [
      { title: 'News', url: '/de/news' },
      { title: 'About', url: '/de/about' },
      { title: 'Workshops', url: '/de/schauspielworkshops' },
      { title: 'Einzelcoaching', url: '/de/einzelcoaching' },
      { title: 'Produktionsbetreuung', url: '/de/produktionsbetreuung' },
      { title: 'Coachausbildung', url: '/de/coachausbildung' },
      { title: 'EN', url: '/en/' },
    ],
    en: [
      { title: 'News', url: '/en/news' },
      { title: 'About', url: '/en/about' },
      { title: 'Workshops', url: '/en/workshops' },
      { title: 'Coaching', url: '/en/coaching' },
      { title: 'Productions', url: '/en/productions' },
      { title: 'Become a Coach', url: '/en/become_a_coach' },
      { title: 'DE', url: '/de/' },
    ],
  },
  mobileMenu: {
    de: [
      { title: 'Start', url: '/de/' },
      { title: 'News', url: '/de/news' },
      { title: 'About', url: '/de/about' },
      { title: 'Workshops', url: '/de/schauspielworkshops' },
      { title: 'Einzelcoaching', url: '/de/einzelcoaching' },
      { title: 'Produktionsbetreuung', url: '/de/produktionsbetreuung' },
      { title: 'Coachausbildung', url: '/de/coachausbildung' },
      { title: 'English', url: '/en/' },
      { title: 'Newsletter', url: '#newsletter' },
    ],
    en: [
      { title: 'Start', url: '/en/' },
      { title: 'News', url: '/en/news' },
      { title: 'About', url: '/en/about' },
      { title: 'Workshops', url: '/en/workshops' },
      { title: 'Coaching', url: '/en/coaching' },
      { title: 'Productions', url: '/en/productions' },
      { title: 'Become a Coach', url: '/en/become_a_coach' },
      { title: 'Deutsch', url: '/de/' },
      { title: 'Newsletter', url: '#newsletter' },
    ],
  },
  debugBlocks: false,
  // debugBlocks: true,
  parallaxFactor: 0.2,
  // parallaxFactor: .05,

  parallaxFactorLess: 0.1,
  slideShowSpeed: 2000,
  parallaxStart: -20,
  parallaxEnd: 20,
  workshopBaseUrl: { de: '/de/schauspielworkshops', en: '/en/workshops' },
}

export default Settings
