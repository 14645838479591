import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import SinglePageRenderer from './SinglePageRenderer'

const GeneratedRoutes = ({ pages }) => {
  if (!pages.loading) {
    return (
      <React.Fragment>
        {pages.data.de.map((page, i) => (
          <Route
            // path={page.acf.exact ? page.acf.url : `${page.acf.url}:subpage?`}
            path={page.link
              .split('https://backend.rothcoaching.com')
              .join('')
              .split('landing/')
              .join('')}
            key={`de_${i}_${page.acf.url}`}
            exact
            component={() => (
              <SinglePageRenderer page={page} pageId={`page_${page.id}`} />
            )}
          />
        ))}
        {/* {pages.data.en.map((page, i) => (
          <Route
            path={page.acf.exact ? page.acf.url : `${page.acf.url}:subpage?`}
            key={`en_${i}_${page.acf.url}`}
            exact
            component={() => (
              <SinglePageRenderer page={page} pageId={`page_${page.id}`} />
            )}
          />
        ))} */}
      </React.Fragment>
    )
  }
  return null
}

const mapStateToProps = (state) => ({
  pages: state.pages,
})

export default connect(mapStateToProps)(GeneratedRoutes)
