import _ from 'lodash'

import urlFormat from '../utils/urlFormat'

import { WORKSHOPS_LOADED } from '../actions/types'

const initialState = {
  data: {},
  calendar: {},
  loading: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case WORKSHOPS_LOADED:
      let workshopCalendar = {}
      let workshopData = {}
      const languages = Object.keys(action.payload.data)
      languages.map((lang) => {
        // console.log(action.payload.data[lang])

        // const lang = 'de'

        action.payload.data[lang].map((workshop) => {
          if (!workshopCalendar.hasOwnProperty(lang)) {
            workshopCalendar[lang] = []
          }
          if (!workshopData.hasOwnProperty(lang)) {
            workshopData[lang] = []
          }

          const workshopImage = _.find(workshop.acf.gallery, {
            acf_fc_layout: 'image',
          })
            ? _.find(workshop.acf.gallery, { acf_fc_layout: 'image' }).image
            : null
          const workshopImageNew = workshop.acf.titelbild
            ? workshop.acf.titelbild.image
            : workshopImage

          const ImagePosition = workshop.acf.titelbild
            ? workshop.acf.titelbild.position
            : 'top'

          // console.log(workshop.acf.titelbild)
          workshopData[lang].push({
            ...workshop,
            url: urlFormat(workshop.acf.title),
            image: workshopImageNew,
            image_position: ImagePosition,
          })

          workshop.acf.calendar.length > 0 &&
            workshop.acf.calendar.map((event) => {
              // console.log(event)

              const title =
                event.title !== '' ? event.title : workshop.acf.title
              const subtitle =
                event.subtitle !== '' ? event.subtitle : workshop.acf.subtitle
              const description =
                event.description !== ''
                  ? event.description
                  : workshop.acf.description
              // const image = event.image ? event.image : (_.find(workshop.acf.gallery, { acf_fc_layout: 'image' }) ? _.find(workshop.acf.gallery, { acf_fc_layout: 'image' }).image : null)
              const image = event.image ? event.image : workshopImageNew
              const bookable_online = workshop?.acf?.bookable_online
              const show_contact_form = workshop?.acf?.show_contact_form
              const is_free = workshop?.acf?.is_free
              const is_external = workshop?.acf?.intern__extern
              const color = workshop?.acf?.color
              const link = event?.link
              const showInAllLanguages = workshop?.acf?.show_in_all_languages

              workshopCalendar[lang].push({
                workshop_id: workshop.id,
                ...event,
                title,
                subtitle,
                description,
                image,
                bookable_online,
                show_contact_form,
                is_free,
                calUrl: `${urlFormat(title)}_${event.start_date}`,
                image_position:
                  workshop.acf.titelbild && workshop.acf.titelbild.position,
                is_external,
                link,
                color,
                showInAllLanguages,
                language: lang,
              })
            })
          workshopCalendar[lang] = _.sortBy(
            workshopCalendar[lang],
            'start_date'
          )
        })
      })

      // console.log(workshopCalendar)

      return {
        data: workshopData,
        calendar: workshopCalendar,
        loading: false,
      }
    default:
      return state
  }
}
