import { UPDATE_USER_DATA } from '../actions/types'

const initialState = {
    name: '',
    city: '',
    phone: '',
    email: ''
}

export default function(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}