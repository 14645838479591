import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ParallaxBanner } from 'react-scroll-parallax'
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'

import Settings from '../../Settings'
import formatDate from '../../utils/formatDate'
import urlFormat from '../../utils/urlFormat'

const PeopleDisplay = props => {
    // console.log(props)
    const { apiElement, url } = props
    let match = { params: {} }
    const sortedPosts = _.reverse(_.sortBy(apiElement.posts, 'date'))
    // const sortedPosts = apiElement.posts
    return (
        <div className="people_display">
            {apiElement.anchor_name !== '' && (
                <div
                    className="anchor"
                    id={`${apiElement.anchor_name}_anchor`}
                />
            )}
            <h1>{apiElement.title}</h1>
            <div className="people">
                {apiElement.people.length > 0 &&
                    apiElement.people.map((person, i) => (
                        <Reveal
                            effect="custom_fade_in"
                            key={`single_person_${urlFormat(
                                person.name
                            )}_${i}_reveal`}
                            className="single_person"
                        >
                            <div
                                key={`single_person_${urlFormat(
                                    person.name
                                )}_${i}`}
                                className="person"
                            >
                                <div className="image">
                                    <ParallaxBanner
                                        className="parallax_holder"
                                        layers={[
                                            {
                                                image:
                                                    person.hasOwnProperty(
                                                        'image'
                                                    ) &&
                                                    person.image.hasOwnProperty(
                                                        'sizes'
                                                    ) &&
                                                    person.image.sizes.large,
                                                amount:
                                                    Settings.parallaxFactorLess
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="text align_center">
                                    <h2 className="align_center">
                                        {person.name}
                                    </h2>
                                    {person.role !== '' && (
                                        <span className="role">
                                            {person.role}
                                        </span>
                                    )}
                                    {person.email !== '' && (
                                        <a
                                            href={`mailto:${person.email}`}
                                            className="email"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    person.email.replace(
                                                        '@',
                                                        '&#8203;@'
                                                    ) || null
                                            }}
                                        />
                                    )}
                                    {person.phone !== '' && (
                                        <a
                                            href={`tel:${person.phone}`}
                                            className="phone"
                                        >
                                            {person.phone}
                                        </a>
                                    )}
                                    {person.website !== '' && (
                                        <a
                                            href={`http://${person.website}`}
                                            className="website"
                                        >
                                            {person.website}
                                        </a>
                                    )}
                                    {person.text !== '' && (
                                        <p
                                            className="justify"
                                            dangerouslySetInnerHTML={{
                                                __html: person.text || null
                                            }}
                                        ></p>
                                    )}
                                </div>
                            </div>
                        </Reveal>
                    ))}
            </div>

            {Settings.debugBlocks && (
                <pre>{JSON.stringify(apiElement, null, 2)}</pre>
            )}
        </div>
    )
}

PeopleDisplay.defaultProps = {
    apiElement: {}
}
PeopleDisplay.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default PeopleDisplay
