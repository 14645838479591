import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

class ContactButtonMenu extends React.Component {
    render() {
        const { language, options } = this.props
        // const { changeLanguage } = this

        if (options.loading) return null

        return (
            <Link
                className="button contact_button_menu"
                to={
                    !options.loading
                        ? options.data[language].footer.contact_link
                        : ''
                }
            >
                {!options.loading
                    ? options.data[language].footer.contact_text
                    : ''}
            </Link>
        )
    }
}

const mapStateToProps = state => ({
    language: state.ui.language,
    options: state.options
})

export default connect(mapStateToProps)(ContactButtonMenu)
