import React from 'react'
import { Link } from 'react-router-dom'

import urlFormat from '../../utils/urlFormat'

const up = (text) => (text ? text.toUpperCase() : '')

const SvgMindMapDesktop = ({ apiElement, goToLink, url }) => (
  <svg
    id="eb1c72fb-5979-4b18-8619-0a14354d1c54"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1695.64 1054.92"
  >
    <Link to={`${url}single/meet_sourcetuning___online`}>
      <g id="meet-sourcetuning">
        <g>
          <rect
            class="e8181e97-3015-49de-82f8-4bd3baa60643"
            x="655.12"
            y="20.32"
            width="344.03"
            height="67.26"
          />
          <g>
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M745,111.59l4.09,12h0l3.88-12h5.42V129.1h-3.61V116.69h-.05l-4.29,12.41h-3l-4.29-12.28h-.05V129.1h-3.6V111.59Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M774.92,111.59v3.24h-9.25v3.75h8.48v3h-8.48v4.3h9.44v3.23H761.82V111.59Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M790.81,111.59v3.24h-9.25v3.75h8.49v3h-8.49v4.3H791v3.23H777.71V111.59Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M792.23,114.83v-3.24h14.34v3.24h-5.24V129.1h-3.85V114.83Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M818.35,124.79a2.71,2.71,0,0,0,.84,1,3.7,3.7,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4,4,0,0,0,1.11-.33,2.47,2.47,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.65,3.65,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.61,4.61,0,0,1,.57-2.34,5.3,5.3,0,0,1,1.51-1.65,6.38,6.38,0,0,1,2.11-1,8.71,8.71,0,0,1,2.36-.32,11.34,11.34,0,0,1,2.63.3,6.76,6.76,0,0,1,2.25,1,4.93,4.93,0,0,1,1.55,1.75,5.3,5.3,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.2,4.2,0,0,0-.93.1,2.4,2.4,0,0,0-.84.34,2,2,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.58,1.58,0,0,0,.19.84,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54c.68.18,1.56.41,2.66.68.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.38,5.38,0,0,1-.47,2.24,4.82,4.82,0,0,1-1.38,1.77,6.81,6.81,0,0,1-2.28,1.17,10.75,10.75,0,0,1-3.16.42,10.86,10.86,0,0,1-2.8-.36,7.12,7.12,0,0,1-2.41-1.12,5.52,5.52,0,0,1-1.65-1.93,5.76,5.76,0,0,1-.59-2.8H818A3.14,3.14,0,0,0,818.35,124.79Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M831.15,116.78a8.59,8.59,0,0,1,1.69-2.93,7.69,7.69,0,0,1,2.71-2,9.58,9.58,0,0,1,7.24,0,7.65,7.65,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,10.73,10.73,0,0,1,.59,3.64,10.37,10.37,0,0,1-.59,3.57,8.46,8.46,0,0,1-1.69,2.88,7.7,7.7,0,0,1-2.7,1.93,9.71,9.71,0,0,1-7.24,0,7.74,7.74,0,0,1-2.71-1.93,8.46,8.46,0,0,1-1.69-2.88,10.37,10.37,0,0,1-.59-3.57A10.73,10.73,0,0,1,831.15,116.78Zm3.52,5.79a5.64,5.64,0,0,0,.82,1.87,4,4,0,0,0,1.47,1.34,5.11,5.11,0,0,0,4.41,0,4,4,0,0,0,1.47-1.34,5.87,5.87,0,0,0,.83-1.87,9,9,0,0,0,.25-2.15,9.65,9.65,0,0,0-.25-2.23,6.1,6.1,0,0,0-.83-1.93,4,4,0,0,0-1.47-1.34,5,5,0,0,0-4.41,0,4,4,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.14,9.14,0,0,0-.26,2.23A8.55,8.55,0,0,0,834.67,122.57Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M863.27,127.77a9.63,9.63,0,0,1-11,0c-1.3-1.15-1.95-2.91-1.95-5.3V111.59h3.85v10.89a7.22,7.22,0,0,0,.13,1.4,2.71,2.71,0,0,0,1.6,2.07,4.46,4.46,0,0,0,1.88.33,3.57,3.57,0,0,0,2.84-.92,4.33,4.33,0,0,0,.79-2.88V111.59h3.85v10.89A6.64,6.64,0,0,1,863.27,127.77Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M878,111.59a5.72,5.72,0,0,1,2.12.38,4.87,4.87,0,0,1,1.62,1,4.54,4.54,0,0,1,1,1.53,4.83,4.83,0,0,1,.36,1.87,5.32,5.32,0,0,1-.65,2.68,4.08,4.08,0,0,1-2.13,1.71v0a2.92,2.92,0,0,1,1.18.6,3.23,3.23,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,12.52,12.52,0,0,1,.19,1.33c0,.28,0,.6,0,1s0,.76.09,1.15a7.68,7.68,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89H879.8a6.88,6.88,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V111.59Zm-1.37,7.92a2.91,2.91,0,0,0,2-.58,2.47,2.47,0,0,0,.67-1.92,2.35,2.35,0,0,0-.67-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M897.51,116.26a3.93,3.93,0,0,0-.86-1,4.11,4.11,0,0,0-2.53-.89,4.46,4.46,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,6.08,6.08,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.85,5.85,0,0,0,.82,1.87,4.2,4.2,0,0,0,1.47,1.34,4.46,4.46,0,0,0,2.21.5,3.56,3.56,0,0,0,2.76-1.08,4.93,4.93,0,0,0,1.21-2.84h3.73a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.76,6.76,0,0,1-2.35,1.42,8.57,8.57,0,0,1-3,.5,9.13,9.13,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93A8.8,8.8,0,0,1,886.1,124a10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.93,8.93,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.79,8.79,0,0,1,3.62-.71,9.08,9.08,0,0,1,2.74.41,7.57,7.57,0,0,1,2.3,1.22,6.54,6.54,0,0,1,1.68,2,7,7,0,0,1,.83,2.7H898A3.43,3.43,0,0,0,897.51,116.26Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M917.54,111.59v3.24H908.3v3.75h8.48v3H908.3v4.3h9.44v3.23H904.45V111.59Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M919,114.83v-3.24h14.35v3.24h-5.25V129.1h-3.85V114.83Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M948.2,127.77a9.63,9.63,0,0,1-11,0c-1.3-1.15-2-2.91-2-5.3V111.59h3.85v10.89a8.1,8.1,0,0,0,.12,1.4,2.79,2.79,0,0,0,.51,1.21,2.82,2.82,0,0,0,1.1.86,4.41,4.41,0,0,0,1.87.33,3.6,3.6,0,0,0,2.85-.92,4.39,4.39,0,0,0,.78-2.88V111.59h3.85v10.89A6.67,6.67,0,0,1,948.2,127.77Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M957.32,111.59l7.31,11.75h0V111.59h3.61V129.1h-3.85l-7.28-11.72h0V129.1h-3.61V111.59Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M975.51,111.59V129.1h-3.85V111.59Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M982.72,111.59,990,123.34h.05V111.59h3.61V129.1h-3.85l-7.29-11.72h0V129.1h-3.6V111.59Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M1007.44,129a6.44,6.44,0,0,1-2.52.53,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93A8.46,8.46,0,0,1,996.9,124a10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.76,8.76,0,0,1,3.62-.71,8.47,8.47,0,0,1,2.61.4,7.51,7.51,0,0,1,2.28,1.19,6.43,6.43,0,0,1,1.68,1.94,6.59,6.59,0,0,1,.81,2.65h-3.68a3.71,3.71,0,0,0-1.32-2.21,3.88,3.88,0,0,0-2.38-.74,4.43,4.43,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.2,4.2,0,0,0,1.47,1.34,4.43,4.43,0,0,0,2.21.5,4.15,4.15,0,0,0,3-1,4.2,4.2,0,0,0,1.22-2.8h-3.88v-2.87h7.36v9.46h-2.45l-.4-2A5.69,5.69,0,0,1,1007.44,129Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M738.78,139.2a5.2,5.2,0,0,1,1-1.73,4.58,4.58,0,0,1,1.6-1.16,5.66,5.66,0,0,1,4.28,0,4.73,4.73,0,0,1,1.59,1.16,5.2,5.2,0,0,1,1,1.73,6.51,6.51,0,0,1,.35,2.15,6.25,6.25,0,0,1-.35,2.1,5.06,5.06,0,0,1-1,1.7,4.68,4.68,0,0,1-1.59,1.14,5.79,5.79,0,0,1-4.28,0,4.53,4.53,0,0,1-1.6-1.14,5.06,5.06,0,0,1-1-1.7,6.25,6.25,0,0,1-.35-2.1A6.51,6.51,0,0,1,738.78,139.2Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.65,2.65,0,0,0,4.35,0,3.46,3.46,0,0,0,.48-1.11,5.3,5.3,0,0,0,.15-1.26,5.74,5.74,0,0,0-.15-1.32,3.63,3.63,0,0,0-.48-1.14,2.65,2.65,0,0,0-4.35,0,3.63,3.63,0,0,0-.48,1.14,5.74,5.74,0,0,0-.15,1.32A5.3,5.3,0,0,0,740.86,142.61Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M751.88,139v1h0a2.39,2.39,0,0,1,1-.95,2.87,2.87,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.57,5.57,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.69,2.69,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2.05V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M760.62,136.14v10.33h-2V136.14Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M762.3,137.83v-1.69h2.06v1.69Zm2.06,1.16v7.48H762.3V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M767.93,139v1h0a2.44,2.44,0,0,1,1-.95,2.87,2.87,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.57,5.57,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.59,2.59,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M776.76,144.68a1.83,1.83,0,0,0,1.33.44,1.8,1.8,0,0,0,1.07-.31,1.26,1.26,0,0,0,.55-.65h1.81a3.45,3.45,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.19,3.19,0,0,1-1.2-.81,3.4,3.4,0,0,1-.76-1.24,4.76,4.76,0,0,1,0-3.16,3.89,3.89,0,0,1,.78-1.25,3.78,3.78,0,0,1,1.21-.83,3.94,3.94,0,0,1,1.56-.3,3.6,3.6,0,0,1,1.66.36,3.34,3.34,0,0,1,1.17,1,4.16,4.16,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67h-5.4A2.1,2.1,0,0,0,776.76,144.68Zm2.32-3.94a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.35A2.39,2.39,0,0,0,779.08,140.74Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M787.21,141.64v1.76h-4.36v-1.76Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M791.22,146.47l-3.34-10.33h2.33l2.3,7.26h0l2.33-7.26h2.34l-3.43,10.33Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M797.12,141.11a3.65,3.65,0,0,1,.79-1.25,3.48,3.48,0,0,1,1.21-.79,4.21,4.21,0,0,1,1.59-.28,4.3,4.3,0,0,1,1.6.28,3.57,3.57,0,0,1,1.23.79,3.79,3.79,0,0,1,.78,1.25,4.68,4.68,0,0,1,.27,1.63,4.63,4.63,0,0,1-.27,1.62,3.84,3.84,0,0,1-.78,1.24,3.4,3.4,0,0,1-1.23.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.32,3.32,0,0,1-1.21-.78,3.71,3.71,0,0,1-.79-1.24,4.63,4.63,0,0,1-.27-1.62A4.68,4.68,0,0,1,797.12,141.11Zm1.87,2.5a2.38,2.38,0,0,0,.3.77,1.5,1.5,0,0,0,.55.54,2,2,0,0,0,1.75,0,1.45,1.45,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.67,1.67,0,0,0-2.3.55,2.24,2.24,0,0,0-.3.76,4.41,4.41,0,0,0-.09.89A4.14,4.14,0,0,0,799,143.61Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M807.88,139v1.39h0a2.29,2.29,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,2.85,2.85,0,0,1,.68-.33,2.35,2.35,0,0,1,.75-.11,1.5,1.5,0,0,1,.45.07v1.91l-.34-.05a2.75,2.75,0,0,0-.4,0,2.27,2.27,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M815.66,139v1.37h-1.5v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.3,0v1.59a4.51,4.51,0,0,1-.58.06h-.62a6.37,6.37,0,0,1-.9-.06,2.08,2.08,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.19-.91v-4.42h-1.24V139h1.24v-2.24h2.06V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M818.6,139v1.39h0a2.88,2.88,0,0,1,.39-.65,2.73,2.73,0,0,1,.57-.5,2.85,2.85,0,0,1,.68-.33,2.35,2.35,0,0,1,.75-.11,1.6,1.6,0,0,1,.45.07v1.91a2.66,2.66,0,0,0-.35-.05,2.61,2.61,0,0,0-.39,0,2.32,2.32,0,0,0-1,.18,1.59,1.59,0,0,0-.62.52,2.12,2.12,0,0,0-.35.76,4.59,4.59,0,0,0-.1.94v3.37h-2.05V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M822.19,141.29a2.43,2.43,0,0,1,.36-1.2,2.58,2.58,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.43,6.43,0,0,1,1.24-.12,7.37,7.37,0,0,1,1.14.08,3.27,3.27,0,0,1,1.06.31,2,2,0,0,1,.78.64,1.83,1.83,0,0,1,.3,1.09v3.9a7.66,7.66,0,0,0,.06,1,1.9,1.9,0,0,0,.2.69h-2.08a2,2,0,0,1-.09-.35,2.35,2.35,0,0,1-.05-.37,2.85,2.85,0,0,1-1.16.71,4.87,4.87,0,0,1-1.36.2,3.56,3.56,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.61,2.61,0,0,1,0-2.05,2.06,2.06,0,0,1,.58-.66,3,3,0,0,1,.81-.37c.31-.08.61-.14.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.41,1.41,0,0,0,.55-.25.54.54,0,0,0,.19-.49,1.09,1.09,0,0,0-.11-.52.73.73,0,0,0-.29-.31,1.1,1.1,0,0,0-.42-.14,2.78,2.78,0,0,0-.51,0,1.59,1.59,0,0,0-1,.26,1.16,1.16,0,0,0-.4.87Zm4.75,1.52a.88.88,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.9,3.9,0,0,0-.46.12,1.57,1.57,0,0,0-.39.19.9.9,0,0,0-.26.32.93.93,0,0,0-.11.47,1,1,0,0,0,.11.47.75.75,0,0,0,.27.29.92.92,0,0,0,.41.15,2.42,2.42,0,0,0,.47.05,1.85,1.85,0,0,0,.94-.2,1.38,1.38,0,0,0,.5-.49,1.6,1.6,0,0,0,.19-.57,3,3,0,0,0,0-.46Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M837.66,147a2.6,2.6,0,0,1-.53,1.08A3.28,3.28,0,0,1,836,149a5.14,5.14,0,0,1-2.06.34,4.82,4.82,0,0,1-1.12-.13,3.86,3.86,0,0,1-1-.43A2.33,2.33,0,0,1,831,148a2.11,2.11,0,0,1-.36-1.09h2a1.17,1.17,0,0,0,.57.8,2,2,0,0,0,1,.23,1.5,1.5,0,0,0,1.26-.52,2.09,2.09,0,0,0,.39-1.32v-1h0a2.11,2.11,0,0,1-.95.87,3,3,0,0,1-1.3.28,3.19,3.19,0,0,1-1.44-.29,2.75,2.75,0,0,1-1-.81,3.5,3.5,0,0,1-.57-1.2,5.36,5.36,0,0,1-.19-1.46,4.7,4.7,0,0,1,.21-1.4,3.69,3.69,0,0,1,.62-1.18,2.79,2.79,0,0,1,1-.82,3,3,0,0,1,1.38-.3,2.92,2.92,0,0,1,1.29.27,2.26,2.26,0,0,1,.94.93h0v-1h1.95v7A5.18,5.18,0,0,1,837.66,147Zm-2.82-2.49a1.54,1.54,0,0,0,.55-.46,2,2,0,0,0,.33-.66,2.81,2.81,0,0,0,.11-.77,4.17,4.17,0,0,0-.09-.88,2.28,2.28,0,0,0-.29-.73,1.56,1.56,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.49,1.49,0,0,0-1.26.65,2.12,2.12,0,0,0-.31.68,3.44,3.44,0,0,0-.09.8,4.26,4.26,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.61,1.61,0,0,0,.52.52,1.41,1.41,0,0,0,.79.21A1.59,1.59,0,0,0,834.84,144.53Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M845.45,139v1h0a3,3,0,0,1,1-.9,2.64,2.64,0,0,1,1.31-.31,3,3,0,0,1,1.29.27,1.81,1.81,0,0,1,.89,1,3,3,0,0,1,.91-.87,2.4,2.4,0,0,1,1.35-.37,3.9,3.9,0,0,1,1.1.14,2.27,2.27,0,0,1,.87.46,2.1,2.1,0,0,1,.56.84,3.24,3.24,0,0,1,.2,1.23v5h-2.05v-4.24c0-.25,0-.49,0-.71a1.51,1.51,0,0,0-.16-.58,1,1,0,0,0-.38-.39,1.48,1.48,0,0,0-.69-.14,1.32,1.32,0,0,0-.7.16,1.18,1.18,0,0,0-.42.44,1.69,1.69,0,0,0-.2.61,3.82,3.82,0,0,0-.06.68v4.17h-2v-4.2c0-.22,0-.44,0-.65a2,2,0,0,0-.13-.61.91.91,0,0,0-.36-.44,1.38,1.38,0,0,0-.74-.16,1.69,1.69,0,0,0-.39.06,1.23,1.23,0,0,0-.46.25,1.33,1.33,0,0,0-.38.52,2.05,2.05,0,0,0-.16.89v4.34h-2.06V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M856.62,137.83v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M864.41,139v1.37H862.9v3.71a1,1,0,0,0,.18.69,1,1,0,0,0,.69.18l.34,0,.3,0v1.59a4.71,4.71,0,0,1-.58.06h-.62a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.24,1.24,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42H859.6V139h1.25v-2.24h2V139Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M876.77,146.11a5,5,0,0,1-2.5.59,5.24,5.24,0,0,1-2.14-.41,4.63,4.63,0,0,1-1.6-1.14,5.23,5.23,0,0,1-1-1.7,6.25,6.25,0,0,1-.34-2.1,6.51,6.51,0,0,1,.34-2.15,5.38,5.38,0,0,1,1-1.73,4.68,4.68,0,0,1,1.6-1.16,5.66,5.66,0,0,1,4.28,0,4.54,4.54,0,0,1,1.59,1.16,5.2,5.2,0,0,1,1,1.73,6.51,6.51,0,0,1,.35,2.15,6.25,6.25,0,0,1-.35,2.1,5.27,5.27,0,0,1-1,1.7l1.29,1.18-1.06,1.1Zm-.33-2.39a3.24,3.24,0,0,0,.46-1,4.74,4.74,0,0,0,.17-1.4,5.74,5.74,0,0,0-.15-1.32,3.63,3.63,0,0,0-.48-1.14,2.45,2.45,0,0,0-.87-.79,3,3,0,0,0-2.61,0,2.51,2.51,0,0,0-.86.79,3.43,3.43,0,0,0-.49,1.14,5.74,5.74,0,0,0-.15,1.32,5.3,5.3,0,0,0,.15,1.26,3.27,3.27,0,0,0,.49,1.11,2.51,2.51,0,0,0,.86.79,2.64,2.64,0,0,0,1.31.3,3.82,3.82,0,0,0,.55,0,1.27,1.27,0,0,0,.36-.12l-1-.88,1.06-1.13Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M887.9,142v1.55H885v2.88h-1.55v-2.88h-2.88V142h2.88v-2.88H885V142Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M894.73,136.14l3.87,10.33h-2.36l-.78-2.3h-3.87l-.81,2.3h-2.29l3.91-10.33Zm.13,6.34-1.3-3.8h0l-1.35,3.8Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M901.65,144.24v2.23a2.27,2.27,0,0,1-.18.91,2.2,2.2,0,0,1-.49.71,2.59,2.59,0,0,1-.71.5,3.33,3.33,0,0,1-.88.27v-1a1.07,1.07,0,0,0,.41-.18,1.26,1.26,0,0,0,.34-.31,1.2,1.2,0,0,0,.22-.4,1.22,1.22,0,0,0,.06-.46h-1v-2.23Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M912.42,138.05h-5.11v-1.91h8.13v1.79l-5.54,6.63h5.69v1.91h-8.71v-1.79Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M916.82,139.2a5,5,0,0,1,1-1.73,4.49,4.49,0,0,1,1.6-1.16,5.64,5.64,0,0,1,4.27,0,4.68,4.68,0,0,1,1.6,1.16,5.18,5.18,0,0,1,1,1.73,6.26,6.26,0,0,1,.35,2.15,6,6,0,0,1-.35,2.1,5,5,0,0,1-1,1.7,4.63,4.63,0,0,1-1.6,1.14,5.76,5.76,0,0,1-4.27,0,4.45,4.45,0,0,1-1.6-1.14,4.9,4.9,0,0,1-1-1.7,6.25,6.25,0,0,1-.35-2.1A6.51,6.51,0,0,1,916.82,139.2Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.45,2.45,0,0,0,.87.79,3,3,0,0,0,2.6,0,2.45,2.45,0,0,0,.87-.79,3.48,3.48,0,0,0,.49-1.11,5.3,5.3,0,0,0,.15-1.26,5.74,5.74,0,0,0-.15-1.32,3.64,3.64,0,0,0-.49-1.14,2.45,2.45,0,0,0-.87-.79,3,3,0,0,0-2.6,0,2.45,2.45,0,0,0-.87.79,3.63,3.63,0,0,0-.48,1.14,5.24,5.24,0,0,0-.16,1.32A4.84,4.84,0,0,0,918.9,142.61Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M928.08,139.2a5,5,0,0,1,1-1.73,4.49,4.49,0,0,1,1.6-1.16,5.64,5.64,0,0,1,4.27,0,4.54,4.54,0,0,1,1.59,1.16,5,5,0,0,1,1,1.73,6.26,6.26,0,0,1,.35,2.15,6,6,0,0,1-.35,2.1,4.9,4.9,0,0,1-1,1.7,4.5,4.5,0,0,1-1.59,1.14,5.76,5.76,0,0,1-4.27,0,4.45,4.45,0,0,1-1.6-1.14,4.9,4.9,0,0,1-1-1.7,6.25,6.25,0,0,1-.35-2.1A6.51,6.51,0,0,1,928.08,139.2Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.45,2.45,0,0,0,.87.79,2.63,2.63,0,0,0,1.3.3,2.6,2.6,0,0,0,1.3-.3,2.45,2.45,0,0,0,.87-.79,3.48,3.48,0,0,0,.49-1.11,5.3,5.3,0,0,0,.15-1.26,5.74,5.74,0,0,0-.15-1.32,3.64,3.64,0,0,0-.49-1.14,2.45,2.45,0,0,0-.87-.79,2.6,2.6,0,0,0-1.3-.3,2.63,2.63,0,0,0-1.3.3,2.45,2.45,0,0,0-.87.79,3.63,3.63,0,0,0-.48,1.14,5.24,5.24,0,0,0-.16,1.32A4.84,4.84,0,0,0,930.16,142.61Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
              d="M942.64,136.14l2.42,7.1h0l2.29-7.1h3.2v10.33h-2.13v-7.32h0l-2.53,7.32h-1.75l-2.53-7.25h0v7.25h-2.13V136.14Z"
              transform="translate(-62.75 -75.53)"
            />
          </g>
        </g>
        <g>
          <circle
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            cx="994.45"
            cy="28.03"
            r="28.03"
          />
          <g>
            <path
              class="ab339c66-f4d7-4802-89ff-1b5f30cec564"
              d="M1045.73,104.94l1,1.56-4.07,2.58,1.24,1.94,3.52-2.24.91,1.44-3.52,2.24,2.21,3.47-1.85,1.18-5.34-8.41Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ab339c66-f4d7-4802-89ff-1b5f30cec564"
              d="M1051.34,101.38a3.13,3.13,0,0,1,1.13-.47,2.9,2.9,0,0,1,1.1,0,2.58,2.58,0,0,1,1,.42,2.8,2.8,0,0,1,.74.79,3.06,3.06,0,0,1,.51,1.49,2.35,2.35,0,0,1-.5,1.47l0,0a1.74,1.74,0,0,1,.74-.07,1.85,1.85,0,0,1,.66.23,2.74,2.74,0,0,1,.58.45,6.55,6.55,0,0,1,.49.58l.32.46c.13.17.26.35.4.52a4.06,4.06,0,0,0,.43.48,1.66,1.66,0,0,0,.47.31l-1.85,1.17a3.72,3.72,0,0,1-.79-.83c-.26-.36-.52-.69-.78-1a2,2,0,0,0-1-.71,1.66,1.66,0,0,0-1.19.33l-1.85,1.18,2.08,3.28-1.84,1.18-5.35-8.41Zm1.75,4.22a1.62,1.62,0,0,0,.78-.89,1.41,1.41,0,0,0-.27-1.12,1.32,1.32,0,0,0-.88-.69,1.73,1.73,0,0,0-1.13.33l-2,1.29,1.51,2.37Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ab339c66-f4d7-4802-89ff-1b5f30cec564"
              d="M1061.6,94.86l1,1.55-4.44,2.83,1.15,1.8,4.07-2.59.92,1.44-4.08,2.58,1.31,2.07,4.54-2.89,1,1.56-6.38,4.06-5.34-8.41Z"
              transform="translate(-62.75 -75.53)"
            />
            <path
              class="ab339c66-f4d7-4802-89ff-1b5f30cec564"
              d="M1069.23,90l1,1.55-4.44,2.83,1.14,1.8L1071,93.6l.91,1.44-4.08,2.58,1.31,2.07,4.54-2.89,1,1.56-6.39,4.06L1062.94,94Z"
              transform="translate(-62.75 -75.53)"
            />
          </g>
        </g>
      </g>
    </Link>
    <Link
      to={`${url}single/${
        apiElement.boost_your_character_original_title !== ''
          ? urlFormat(apiElement.boost_your_character_original_title)
          : urlFormat(apiElement.boost_your_character_text)
      }`}
    >
      <g id="boost-your-character">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="639"
          y="171.4"
          width="376.26"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M731.7,262.67a11.31,11.31,0,0,1,2.28.22,4.89,4.89,0,0,1,1.77.73,3.36,3.36,0,0,1,1.14,1.33,4.73,4.73,0,0,1,.4,2.06,3.84,3.84,0,0,1-.6,2.21,4.36,4.36,0,0,1-1.78,1.45,4.19,4.19,0,0,1,2.42,1.63,4.85,4.85,0,0,1,.8,2.81,4.75,4.75,0,0,1-.52,2.29,4.48,4.48,0,0,1-1.38,1.58,6.24,6.24,0,0,1-2,.91,9.21,9.21,0,0,1-2.29.29h-8.49V262.67Zm-.49,7.09a2.73,2.73,0,0,0,1.69-.49,1.87,1.87,0,0,0,.67-1.6,2,2,0,0,0-.23-1,1.55,1.55,0,0,0-.58-.61,2.53,2.53,0,0,0-.85-.31,5.36,5.36,0,0,0-1-.09h-3.61v4.1Zm.22,7.43a5.34,5.34,0,0,0,1.08-.11,2.64,2.64,0,0,0,.91-.37,1.76,1.76,0,0,0,.62-.7,2.34,2.34,0,0,0,.24-1.13,2.28,2.28,0,0,0-.76-1.92,3.28,3.28,0,0,0-2-.58h-4.2v4.81Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M740.55,267.86a8.78,8.78,0,0,1,1.7-2.93,7.48,7.48,0,0,1,2.71-2,9.46,9.46,0,0,1,7.24,0,7.61,7.61,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,11,11,0,0,1,.59,3.64,10.65,10.65,0,0,1-.59,3.57A8.46,8.46,0,0,1,754.9,278a7.79,7.79,0,0,1-2.7,1.93,9.84,9.84,0,0,1-7.24,0,7.65,7.65,0,0,1-2.71-1.93,8.65,8.65,0,0,1-1.7-2.88,10.65,10.65,0,0,1-.58-3.57A11,11,0,0,1,740.55,267.86Zm3.52,5.79a6.08,6.08,0,0,0,.82,1.87,4.24,4.24,0,0,0,1.48,1.34,5.11,5.11,0,0,0,4.41,0,4.21,4.21,0,0,0,1.47-1.34,5.64,5.64,0,0,0,.82-1.87,8.55,8.55,0,0,0,.26-2.15,9.14,9.14,0,0,0-.26-2.23,5.86,5.86,0,0,0-.82-1.93,4.12,4.12,0,0,0-1.47-1.34,5,5,0,0,0-4.41,0,4.15,4.15,0,0,0-1.48,1.34,6.32,6.32,0,0,0-.82,1.93,9.65,9.65,0,0,0-.25,2.23A9,9,0,0,0,744.07,273.65Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M759.63,267.86a8.78,8.78,0,0,1,1.7-2.93,7.48,7.48,0,0,1,2.71-2,9.46,9.46,0,0,1,7.24,0,7.7,7.7,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,11,11,0,0,1,.59,3.64,10.65,10.65,0,0,1-.59,3.57A8.46,8.46,0,0,1,774,278a7.89,7.89,0,0,1-2.7,1.93,9.84,9.84,0,0,1-7.24,0,7.65,7.65,0,0,1-2.71-1.93,8.65,8.65,0,0,1-1.7-2.88,10.65,10.65,0,0,1-.59-3.57A11,11,0,0,1,759.63,267.86Zm3.52,5.79a5.85,5.85,0,0,0,.82,1.87,4.24,4.24,0,0,0,1.48,1.34,5.11,5.11,0,0,0,4.41,0,4.21,4.21,0,0,0,1.47-1.34,5.64,5.64,0,0,0,.82-1.87,9,9,0,0,0,.26-2.15,9.65,9.65,0,0,0-.26-2.23,5.86,5.86,0,0,0-.82-1.93,4.12,4.12,0,0,0-1.47-1.34,5,5,0,0,0-4.41,0,4.15,4.15,0,0,0-1.48,1.34,6.08,6.08,0,0,0-.82,1.93,9.65,9.65,0,0,0-.25,2.23A9,9,0,0,0,763.15,273.65Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M781.83,275.87a2.79,2.79,0,0,0,.84,1,3.7,3.7,0,0,0,1.24.56,6,6,0,0,0,1.47.19,7.41,7.41,0,0,0,1.11-.09,4,4,0,0,0,1.1-.33,2.47,2.47,0,0,0,.86-.67,1.7,1.7,0,0,0,.34-1.1,1.55,1.55,0,0,0-.45-1.15,3.54,3.54,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6A6.57,6.57,0,0,1,780,271a4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2.05,4.51,4.51,0,0,1,.58-2.34,5.29,5.29,0,0,1,1.5-1.66,6.92,6.92,0,0,1,2.11-1,9.12,9.12,0,0,1,2.36-.32,11.34,11.34,0,0,1,2.63.31,6.85,6.85,0,0,1,2.25,1,5.05,5.05,0,0,1,1.55,1.76,5.3,5.3,0,0,1,.58,2.59H788.2a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.71,6.71,0,0,0-1.31-.12,4.91,4.91,0,0,0-.94.09,2.41,2.41,0,0,0-.84.35,2.16,2.16,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.61,1.61,0,0,0,.19.84,1.76,1.76,0,0,0,.78.59,9.6,9.6,0,0,0,1.59.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.08,5.08,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.34,5.34,0,0,1-.47,2.23,4.85,4.85,0,0,1-1.38,1.78,6.81,6.81,0,0,1-2.28,1.17,11.09,11.09,0,0,1-3.15.41,10.87,10.87,0,0,1-2.81-.35A7.12,7.12,0,0,1,780,279.1a5.52,5.52,0,0,1-1.65-1.93,5.76,5.76,0,0,1-.59-2.8h3.73A3.14,3.14,0,0,0,781.83,275.87Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M793.43,265.91v-3.24h14.34v3.24h-5.25v14.27h-3.85V265.91Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M814.71,262.67H819l4.09,6.92,4.07-6.92h4.3L825,273.46v6.72h-3.85v-6.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M832.79,267.86a8.59,8.59,0,0,1,1.69-2.93,7.56,7.56,0,0,1,2.71-2,9.46,9.46,0,0,1,7.24,0,7.53,7.53,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,10.73,10.73,0,0,1,.59,3.64,10.41,10.41,0,0,1-.59,3.57,8.46,8.46,0,0,1-1.69,2.88,7.7,7.7,0,0,1-2.7,1.93,9.84,9.84,0,0,1-7.24,0,7.74,7.74,0,0,1-2.71-1.93,8.46,8.46,0,0,1-1.69-2.88,10.41,10.41,0,0,1-.59-3.57A10.73,10.73,0,0,1,832.79,267.86Zm3.51,5.79a5.87,5.87,0,0,0,.83,1.87,4.13,4.13,0,0,0,1.47,1.34,5.11,5.11,0,0,0,4.41,0,4.13,4.13,0,0,0,1.47-1.34,5.64,5.64,0,0,0,.82-1.87,8.55,8.55,0,0,0,.26-2.15,9.14,9.14,0,0,0-.26-2.23,5.86,5.86,0,0,0-.82-1.93A4,4,0,0,0,843,266a5,5,0,0,0-4.41,0,4,4,0,0,0-1.47,1.34,6.1,6.1,0,0,0-.83,1.93,9.65,9.65,0,0,0-.25,2.23A9,9,0,0,0,836.3,273.65Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M864.91,278.84a9.65,9.65,0,0,1-11,0c-1.3-1.15-1.95-2.91-1.95-5.3V262.67h3.85v10.89a7.3,7.3,0,0,0,.13,1.4,2.79,2.79,0,0,0,.51,1.21,2.88,2.88,0,0,0,1.09.86,4.46,4.46,0,0,0,1.88.33,3.61,3.61,0,0,0,2.84-.92,4.33,4.33,0,0,0,.79-2.88V262.67h3.85v10.89Q866.9,277.12,864.91,278.84Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M879.65,262.67a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.83,4.83,0,0,1,.36,1.87,5.25,5.25,0,0,1-.66,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a2.92,2.92,0,0,1,1.18.6,3.23,3.23,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6.05,1s0,.76.09,1.15a7.93,7.93,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.13-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V262.67Zm-1.37,7.92a2.86,2.86,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M906,267.34a3.73,3.73,0,0,0-.86-1,4,4,0,0,0-1.16-.66,4.13,4.13,0,0,0-1.36-.23,4.46,4.46,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.3,4.3,0,0,0,1.47,1.34,4.57,4.57,0,0,0,2.21.5,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.21-2.84h3.73a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.85,6.85,0,0,1-2.35,1.42,8.57,8.57,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.69,7.83,7.83,0,0,1-2.71-1.93,8.62,8.62,0,0,1-1.69-2.88,10.65,10.65,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.65,7.65,0,0,1,2.71-2,9.3,9.3,0,0,1,6.35-.3,7.3,7.3,0,0,1,2.31,1.22,6.54,6.54,0,0,1,1.68,2,7,7,0,0,1,.83,2.7H906.4A3.09,3.09,0,0,0,906,267.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M916.75,262.67v6.72h7.09v-6.72h3.85v17.51h-3.85v-7.55h-7.09v7.55H912.9V262.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M939.8,262.67l6.55,17.51h-4l-1.33-3.9h-6.55l-1.37,3.9h-3.87l6.62-17.51Zm.23,10.74L937.82,267h-.05l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M957.31,262.67a5.73,5.73,0,0,1,2.13.38,5.07,5.07,0,0,1,1.61,1,4.53,4.53,0,0,1,1,1.54,4.83,4.83,0,0,1,.36,1.87,5.34,5.34,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a3,3,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.69,4.69,0,0,1,.43,1.21,12.13,12.13,0,0,1,.18,1.33c0,.28,0,.6,0,1s0,.76.08,1.15a7.93,7.93,0,0,0,.2,1.12,2.36,2.36,0,0,0,.41.89H959.1a6.88,6.88,0,0,1-.39-2c-.05-.77-.12-1.51-.22-2.21a3.47,3.47,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85V262.67Zm-1.37,7.92a2.89,2.89,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M974.31,262.67l6.55,17.51h-4l-1.32-3.9H969l-1.38,3.9h-3.87l6.62-17.51Zm.22,10.74L972.32,267h-.05L970,273.41Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M993.61,267.34a3.93,3.93,0,0,0-.86-1,4,4,0,0,0-1.16-.66,4.19,4.19,0,0,0-1.37-.23,4.42,4.42,0,0,0-2.2.51,4,4,0,0,0-1.47,1.34,6.1,6.1,0,0,0-.83,1.93,9.65,9.65,0,0,0-.25,2.23,9,9,0,0,0,.25,2.15,5.87,5.87,0,0,0,.83,1.87,4.13,4.13,0,0,0,1.47,1.34,4.53,4.53,0,0,0,2.2.5,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.22-2.84h3.72a8.77,8.77,0,0,1-.76,3,7,7,0,0,1-1.61,2.25,6.8,6.8,0,0,1-2.36,1.42,8.57,8.57,0,0,1-3,.49,9,9,0,0,1-3.61-.69A7.74,7.74,0,0,1,983.9,278a8.46,8.46,0,0,1-1.69-2.88,10.41,10.41,0,0,1-.59-3.57,10.73,10.73,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.56,7.56,0,0,1,2.71-2,9.3,9.3,0,0,1,6.35-.3,7.15,7.15,0,0,1,2.3,1.22,6.54,6.54,0,0,1,1.68,2,7.13,7.13,0,0,1,.84,2.7h-3.73A3.25,3.25,0,0,0,993.61,267.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M999.18,265.91v-3.24h14.34v3.24h-5.25v14.27h-3.85V265.91Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1028.63,262.67v3.24h-9.25v3.75h8.49v3h-8.49v4.29h9.44v3.24h-13.29V262.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1040.87,262.67a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.61,1,4.53,4.53,0,0,1,1,1.54,4.83,4.83,0,0,1,.36,1.87,5.34,5.34,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a3,3,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.69,4.69,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s0,.76.08,1.15a9.67,9.67,0,0,0,.2,1.12,2.7,2.7,0,0,0,.4.89h-3.84a6.54,6.54,0,0,1-.4-2c0-.77-.12-1.51-.22-2.21a3.47,3.47,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85V262.67Zm-1.38,7.92a2.89,2.89,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M724.5,295a1.81,1.81,0,0,0,.5.59,2.17,2.17,0,0,0,.74.33,3.43,3.43,0,0,0,.86.11,5,5,0,0,0,.65,0,2.11,2.11,0,0,0,.66-.2,1.38,1.38,0,0,0,.5-.39,1,1,0,0,0,.21-.65.91.91,0,0,0-.27-.68,2.08,2.08,0,0,0-.7-.43,6.82,6.82,0,0,0-1-.31c-.37-.08-.74-.18-1.11-.29a8.25,8.25,0,0,1-1.13-.35,3.92,3.92,0,0,1-1-.54,2.5,2.5,0,0,1-.7-.84,2.47,2.47,0,0,1-.27-1.2,2.8,2.8,0,0,1,.34-1.39,3.18,3.18,0,0,1,.89-1,3.87,3.87,0,0,1,1.25-.58,5.12,5.12,0,0,1,1.39-.19,6.52,6.52,0,0,1,1.55.18,3.92,3.92,0,0,1,1.33.59,3,3,0,0,1,.92,1,3.26,3.26,0,0,1,.34,1.53h-2.2a1.85,1.85,0,0,0-.2-.77,1.36,1.36,0,0,0-.44-.48,2,2,0,0,0-.63-.24,3.67,3.67,0,0,0-.77-.07,2.5,2.5,0,0,0-.55.05,1.4,1.4,0,0,0-.5.21,1.16,1.16,0,0,0-.37.36.94.94,0,0,0-.15.55.91.91,0,0,0,.12.49,1,1,0,0,0,.46.35,6.66,6.66,0,0,0,.94.32l1.57.4c.19,0,.46.11.8.21a3.66,3.66,0,0,1,1,.49,3,3,0,0,1,.88.89,2.56,2.56,0,0,1,.37,1.42,3.25,3.25,0,0,1-.28,1.32,3,3,0,0,1-.82,1.05,4.13,4.13,0,0,1-1.34.69,7,7,0,0,1-3.52,0,4.12,4.12,0,0,1-1.42-.66,3.18,3.18,0,0,1-1.32-2.79h2.2A1.94,1.94,0,0,0,724.5,295Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M732,292.19a3.49,3.49,0,0,1,.78-1.25,3.43,3.43,0,0,1,1.21-.79,4.56,4.56,0,0,1,3.2,0,3.37,3.37,0,0,1,2,2,4.86,4.86,0,0,1,0,3.25,3.4,3.4,0,0,1-.78,1.23,3.34,3.34,0,0,1-1.22.79,4.71,4.71,0,0,1-3.2,0,3.3,3.3,0,0,1-1.21-.79,3.4,3.4,0,0,1-.78-1.23,4.86,4.86,0,0,1,0-3.25Zm1.86,2.5a2.38,2.38,0,0,0,.3.77,1.61,1.61,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.67,1.67,0,0,0,.87-.2,1.56,1.56,0,0,0,.57-.54,2.16,2.16,0,0,0,.29-.77,4.21,4.21,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2,2,0,0,0-.29-.76,1.58,1.58,0,0,0-.57-.55,1.67,1.67,0,0,0-.87-.21,1.58,1.58,0,0,0-1.43.76,2.24,2.24,0,0,0-.3.76,5.26,5.26,0,0,0-.08.89A5,5,0,0,0,733.85,294.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M745.85,297.55v-1h0a2.39,2.39,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.32,2.32,0,0,1-.43-1,6.3,6.3,0,0,1-.12-1.27v-4.6h2.05v4.22a2.63,2.63,0,0,0,.29,1.38,1.12,1.12,0,0,0,1,.46,1.45,1.45,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M751.32,290.07v1.39h0a2.45,2.45,0,0,1,1-1.15A2.85,2.85,0,0,1,753,290a2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35-.05a2.56,2.56,0,0,0-.39,0,2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M758.31,291.41a1.44,1.44,0,0,0-.82.23,1.77,1.77,0,0,0-.54.58,2.44,2.44,0,0,0-.3.78,3.92,3.92,0,0,0,0,1.67,2.65,2.65,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.48,1.48,0,0,0,.81.21,1.5,1.5,0,0,0,1.14-.41,2,2,0,0,0,.5-1.11h2a3.4,3.4,0,0,1-1.16,2.28,3.74,3.74,0,0,1-2.44.78,4.32,4.32,0,0,1-1.55-.28,3.64,3.64,0,0,1-1.19-.79,3.68,3.68,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,5,5,0,0,1,.25-1.62,3.77,3.77,0,0,1,.75-1.29,3.33,3.33,0,0,1,1.2-.84,4.1,4.1,0,0,1,1.62-.31,4.55,4.55,0,0,1,1.28.18,3.21,3.21,0,0,1,1.1.53,2.78,2.78,0,0,1,.79.88,2.86,2.86,0,0,1,.35,1.25h-2A1.38,1.38,0,0,0,758.31,291.41Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M765.25,295.75a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66H770a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.57,4.57,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.51,3.51,0,0,1,1.17,1,4.09,4.09,0,0,1,.65,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,765.25,295.75Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.26,2.26,0,0,0,767.57,291.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M775.47,290.07v1.37H774v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.63a6.48,6.48,0,0,1-.9-.06,2.08,2.08,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.19-.91v-4.42h-1.24v-1.37h1.24v-2.25H774v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M781.51,297.55v-1h0a2.39,2.39,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.46,2.46,0,0,1-.43-1,6.3,6.3,0,0,1-.12-1.27v-4.6h2v4.22a2.63,2.63,0,0,0,.29,1.38,1.12,1.12,0,0,0,1,.46,1.44,1.44,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M787,290.07v1h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92H785v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M793.67,288.91v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M799.3,290.07v1h.05a2.3,2.3,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.54,3.54,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.58,2.58,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M813,298.1a2.54,2.54,0,0,1-.53,1.08,3.15,3.15,0,0,1-1.17.84,4.91,4.91,0,0,1-2,.35,4.43,4.43,0,0,1-1.13-.14,3.66,3.66,0,0,1-1-.42,2.65,2.65,0,0,1-.78-.75A2,2,0,0,1,806,298h2a1.13,1.13,0,0,0,.56.8,2,2,0,0,0,1,.23,1.52,1.52,0,0,0,1.27-.52,2.09,2.09,0,0,0,.38-1.32v-1h0a2,2,0,0,1-.95.88,3,3,0,0,1-1.29.28,3.41,3.41,0,0,1-1.45-.29,2.84,2.84,0,0,1-1-.81,3.47,3.47,0,0,1-.57-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.57,3.57,0,0,1,.61-1.18,2.88,2.88,0,0,1,1-.82,3,3,0,0,1,1.38-.31,3,3,0,0,1,1.3.28,2.19,2.19,0,0,1,.93.93h0v-1h2v7A5.18,5.18,0,0,1,813,298.1Zm-2.81-2.49a1.76,1.76,0,0,0,.55-.46,2.24,2.24,0,0,0,.32-.66,2.88,2.88,0,0,0,.11-.78,4.07,4.07,0,0,0-.09-.87,1.9,1.9,0,0,0-.29-.73,1.52,1.52,0,0,0-.53-.51,1.74,1.74,0,0,0-.84-.19,1.49,1.49,0,0,0-.75.18,1.55,1.55,0,0,0-.52.47,1.83,1.83,0,0,0-.3.68,3.44,3.44,0,0,0-.1.8,4.26,4.26,0,0,0,.08.8,2.3,2.3,0,0,0,.28.72,1.58,1.58,0,0,0,.51.52,1.44,1.44,0,0,0,.8.2A1.62,1.62,0,0,0,810.19,295.61Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M825.14,297.47a3.76,3.76,0,0,1-1.49.31,5.41,5.41,0,0,1-2.14-.41,4.76,4.76,0,0,1-1.6-1.14,5.23,5.23,0,0,1-1-1.7,6.25,6.25,0,0,1-.34-2.1,6.46,6.46,0,0,1,.34-2.15,5.38,5.38,0,0,1,1-1.73,4.55,4.55,0,0,1,1.6-1.16,5.24,5.24,0,0,1,2.14-.42,5,5,0,0,1,1.54.24,4.4,4.4,0,0,1,1.34.7,4.07,4.07,0,0,1,1,1.14,3.8,3.8,0,0,1,.47,1.57h-2.17a2.15,2.15,0,0,0-.78-1.31,2.26,2.26,0,0,0-1.4-.43,2.64,2.64,0,0,0-1.31.3,2.51,2.51,0,0,0-.86.79,3.34,3.34,0,0,0-.49,1.14,5.68,5.68,0,0,0-.15,1.32,5.3,5.3,0,0,0,.15,1.26,3.27,3.27,0,0,0,.49,1.11,2.51,2.51,0,0,0,.86.79,2.64,2.64,0,0,0,1.31.29,2.22,2.22,0,0,0,2.46-2.22h-2.29V292h4.34v5.59h-1.45l-.23-1.17A3.39,3.39,0,0,1,825.14,297.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M831.74,290.07v1.39h0a2.16,2.16,0,0,1,.39-.65,2.42,2.42,0,0,1,.56-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.34-.05-.4,0a2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M840.48,297.55v-1h0a2.42,2.42,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.46,3.46,0,0,1-1.35-.22,2,2,0,0,1-.84-.61,2.45,2.45,0,0,1-.42-1,5.63,5.63,0,0,1-.13-1.27v-4.6h2.06v4.22a2.53,2.53,0,0,0,.29,1.38,1.1,1.1,0,0,0,1,.46,1.43,1.43,0,0,0,1.21-.5,2.77,2.77,0,0,0,.38-1.64v-3.92h2v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M846,290.07v1h0a2.33,2.33,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1A6.12,6.12,0,0,1,851,293v4.6H849v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M857.89,296.59a2.11,2.11,0,0,1-.95.88,3.11,3.11,0,0,1-1.32.27,3.19,3.19,0,0,1-1.48-.33,3,3,0,0,1-1-.88,4.12,4.12,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.12,3.12,0,0,1,1-.86,3,3,0,0,1,1.44-.33,2.92,2.92,0,0,1,1.27.29,2.13,2.13,0,0,1,.95.83h0v-3.76h2v10.33h-2v-1Zm-.09-3.69a2,2,0,0,0-.29-.76,1.61,1.61,0,0,0-.55-.52,1.54,1.54,0,0,0-.84-.21,1.62,1.62,0,0,0-.85.21,1.58,1.58,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,4.33,4.33,0,0,0-.1.89,3.7,3.7,0,0,0,.11.87,2.13,2.13,0,0,0,.32.77,1.76,1.76,0,0,0,.57.55,1.5,1.5,0,0,0,.81.21,1.6,1.6,0,0,0,.85-.2,1.47,1.47,0,0,0,.54-.54,2.21,2.21,0,0,0,.29-.78,4.33,4.33,0,0,0,.09-.89A4.26,4.26,0,0,0,857.8,292.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M863.53,287.22v10.33h-2V287.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M865.07,292.37a2.43,2.43,0,0,1,.36-1.2,2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,9,9,0,0,1,1.14.08,3.85,3.85,0,0,1,1.06.31,2.23,2.23,0,0,1,.78.65,1.81,1.81,0,0,1,.3,1.09v3.89a7.43,7.43,0,0,0,.06,1,1.9,1.9,0,0,0,.2.7h-2.08c0-.12-.07-.23-.1-.35s0-.25-.05-.37a2.82,2.82,0,0,1-1.15.71,4.88,4.88,0,0,1-1.37.2,3.88,3.88,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.78,1.78,0,0,1-.55-.69,2.38,2.38,0,0,1-.19-1,2.15,2.15,0,0,1,.22-1.05,1.78,1.78,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.58.58,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.84.84,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.82,2.82,0,0,0-.52,0,1.58,1.58,0,0,0-1,.26,1.16,1.16,0,0,0-.41.87Zm4.75,1.52a1,1,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19,1,1,0,0,0-.27.31,1.14,1.14,0,0,0-.1.48,1,1,0,0,0,.1.46.74.74,0,0,0,.28.3,1.19,1.19,0,0,0,.4.15,2.61,2.61,0,0,0,.48.05,1.75,1.75,0,0,0,.94-.21,1.26,1.26,0,0,0,.49-.48,1.45,1.45,0,0,0,.2-.57c0-.2,0-.35,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M880.53,298.1a2.43,2.43,0,0,1-.53,1.08,3.15,3.15,0,0,1-1.17.84,4.88,4.88,0,0,1-2.05.35,4.35,4.35,0,0,1-1.12-.14,3.61,3.61,0,0,1-1-.42,2.65,2.65,0,0,1-.78-.75,2.14,2.14,0,0,1-.35-1.09h2a1.13,1.13,0,0,0,.56.8,2,2,0,0,0,1,.23,1.52,1.52,0,0,0,1.27-.52,2.09,2.09,0,0,0,.38-1.32v-1h0a2,2,0,0,1-.94.88,3.07,3.07,0,0,1-1.3.28A3.41,3.41,0,0,1,875,297a2.93,2.93,0,0,1-1-.81,3.47,3.47,0,0,1-.57-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.59,3.59,0,0,1,.62-1.18,2.85,2.85,0,0,1,1-.82,3,3,0,0,1,1.38-.31,2.93,2.93,0,0,1,1.3.28,2.19,2.19,0,0,1,.93.93h0v-1h2v7A4.6,4.6,0,0,1,880.53,298.1Zm-2.81-2.49a1.76,1.76,0,0,0,.55-.46,2.24,2.24,0,0,0,.32-.66,2.54,2.54,0,0,0,.11-.78,4.85,4.85,0,0,0-.08-.87,2.28,2.28,0,0,0-.29-.73,1.65,1.65,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.42,1.42,0,0,0-.74.18,1.49,1.49,0,0,0-.53.47,1.83,1.83,0,0,0-.3.68,3.44,3.44,0,0,0-.09.8,3.56,3.56,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.52,1.52,0,0,0,.52.52,1.39,1.39,0,0,0,.79.2A1.59,1.59,0,0,0,877.72,295.61Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M884.51,295.75a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.51,4.51,0,0,1,.28-1.57A3.58,3.58,0,0,1,883,291a3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.13,5.13,0,0,1,.15,1.67H884A2.05,2.05,0,0,0,884.51,295.75Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.26,2.26,0,0,0,886.83,291.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M892.57,290.07v1h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.75,2.75,0,0,0-.38,1.65v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M901.57,295.32v2.23a2.19,2.19,0,0,1-.18.9,2.14,2.14,0,0,1-.49.72,2.59,2.59,0,0,1-.71.5,3.33,3.33,0,0,1-.88.27v-1a1.07,1.07,0,0,0,.41-.18,1.27,1.27,0,0,0,.34-.31,1.2,1.2,0,0,0,.22-.4,1.22,1.22,0,0,0,.06-.46h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M909.73,287.22v8.42h5v1.91h-7.31V287.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M918.32,287.22v10.33H916V287.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M922.56,297.55l-3.35-10.33h2.33l2.3,7.26h0l2.33-7.26h2.35l-3.43,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M937.16,287.22v1.91H931.7v2.21h5v1.77h-5v2.53h5.58v1.91h-7.85V287.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M942.66,292.19a3.49,3.49,0,0,1,.78-1.25,3.39,3.39,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.39,3.39,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.4,3.4,0,0,1-.78,1.23,3.26,3.26,0,0,1-1.22.79,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.26,3.26,0,0,1-1.22-.79,3.4,3.4,0,0,1-.78-1.23,4.86,4.86,0,0,1,0-3.25Zm1.87,2.5a2.16,2.16,0,0,0,.29.77,1.61,1.61,0,0,0,.56.54,2,2,0,0,0,1.74,0,1.56,1.56,0,0,0,.57-.54,2.38,2.38,0,0,0,.3-.77,5,5,0,0,0,.08-.87,5.26,5.26,0,0,0-.08-.89,2.24,2.24,0,0,0-.3-.76,1.58,1.58,0,0,0-.57-.55,1.69,1.69,0,0,0-2.3.55,2,2,0,0,0-.29.76,4.41,4.41,0,0,0-.09.89A4.21,4.21,0,0,0,944.53,294.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M956.77,296.59a2.11,2.11,0,0,1-.95.88,3.11,3.11,0,0,1-1.32.27,3.19,3.19,0,0,1-1.48-.33,3,3,0,0,1-1-.88,4.12,4.12,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.12,3.12,0,0,1,1-.86,3,3,0,0,1,1.45-.33,2.91,2.91,0,0,1,1.26.29,2.13,2.13,0,0,1,.95.83h0v-3.76h2v10.33h-2v-1Zm-.09-3.69a2,2,0,0,0-.29-.76,1.61,1.61,0,0,0-.55-.52,1.53,1.53,0,0,0-.83-.21,1.64,1.64,0,0,0-.86.21,1.58,1.58,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,4.33,4.33,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.32,2.32,0,0,0,.32.77,1.76,1.76,0,0,0,.57.55,1.51,1.51,0,0,0,.82.21,1.56,1.56,0,0,0,.84-.2,1.58,1.58,0,0,0,.55-.54,2.41,2.41,0,0,0,.28-.78,4.33,4.33,0,0,0,.09-.89A4.26,4.26,0,0,0,956.68,292.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M962.5,295.75a2.12,2.12,0,0,0,2.41.14,1.33,1.33,0,0,0,.55-.66h1.8a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.6,3.6,0,0,1,2-2.08,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67H962A2,2,0,0,0,962.5,295.75Zm2.33-3.93a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,964.83,291.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M970.56,290.07v1.39h0a2.45,2.45,0,0,1,1-1.15,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35-.05a2.56,2.56,0,0,0-.39,0,2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M983.36,289.13h-5.11v-1.91h8.13V289l-5.54,6.63h5.69v1.91h-8.71v-1.8Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M987.76,290.28a5,5,0,0,1,1-1.73,4.38,4.38,0,0,1,1.6-1.16,5.64,5.64,0,0,1,4.27,0,4.55,4.55,0,0,1,1.6,1.16,5.18,5.18,0,0,1,1,1.73,6.22,6.22,0,0,1,.35,2.15,6,6,0,0,1-.35,2.1,5,5,0,0,1-1,1.7,4.76,4.76,0,0,1-1.6,1.14,5.76,5.76,0,0,1-4.27,0,4.57,4.57,0,0,1-1.6-1.14,4.9,4.9,0,0,1-1-1.7,6.25,6.25,0,0,1-.35-2.1A6.46,6.46,0,0,1,987.76,290.28Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.45,2.45,0,0,0,.87.79,3.06,3.06,0,0,0,2.6,0,2.45,2.45,0,0,0,.87-.79,3.48,3.48,0,0,0,.49-1.11,5.3,5.3,0,0,0,.15-1.26,5.68,5.68,0,0,0-.15-1.32,3.55,3.55,0,0,0-.49-1.14,2.45,2.45,0,0,0-.87-.79,2.6,2.6,0,0,0-1.3-.3,2.63,2.63,0,0,0-1.3.3,2.45,2.45,0,0,0-.87.79,3.53,3.53,0,0,0-.48,1.14,5.19,5.19,0,0,0-.16,1.32A4.84,4.84,0,0,0,989.84,293.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M999,290.28a5,5,0,0,1,1-1.73,4.38,4.38,0,0,1,1.6-1.16,5.64,5.64,0,0,1,4.27,0,4.55,4.55,0,0,1,1.6,1.16,5.18,5.18,0,0,1,1,1.73,6.22,6.22,0,0,1,.35,2.15,6,6,0,0,1-.35,2.1,5,5,0,0,1-1,1.7,4.76,4.76,0,0,1-1.6,1.14,5.76,5.76,0,0,1-4.27,0,4.57,4.57,0,0,1-1.6-1.14,4.9,4.9,0,0,1-1-1.7,6.25,6.25,0,0,1-.35-2.1A6.46,6.46,0,0,1,999,290.28Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.45,2.45,0,0,0,.87.79,3.06,3.06,0,0,0,2.6,0,2.45,2.45,0,0,0,.87-.79,3.48,3.48,0,0,0,.49-1.11,5.3,5.3,0,0,0,.15-1.26,5.68,5.68,0,0,0-.15-1.32,3.55,3.55,0,0,0-.49-1.14,2.45,2.45,0,0,0-.87-.79,2.6,2.6,0,0,0-1.3-.3,2.63,2.63,0,0,0-1.3.3,2.45,2.45,0,0,0-.87.79,3.53,3.53,0,0,0-.48,1.14,5.19,5.19,0,0,0-.16,1.32A4.84,4.84,0,0,0,1001.1,293.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1013.58,287.22l2.42,7.1h0l2.28-7.1h3.2v10.33h-2.13v-7.32h0l-2.53,7.32h-1.75l-2.53-7.25h0v7.25h-2.13V287.22Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    <Link to={`${url}single/experience_your_script____online`}>
      {/* experience your script */}
      <g id="experience-your-script">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="1"
          y="306.4"
          width="437.04"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M98.56,397.67v3.24H89.31v3.75H97.8v3H89.31v4.29h9.44v3.24H85.46V397.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M100,397.67h4.47l3.43,5.59,3.56-5.59h4.21L110.06,406l6.11,9.15h-4.59l-3.83-6.06-3.9,6.06H99.54l6.1-9.17Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M125.61,397.67a7.26,7.26,0,0,1,2.79.48,5.35,5.35,0,0,1,1.88,1.26,4.92,4.92,0,0,1,1.05,1.79,6.72,6.72,0,0,1,0,4.16,4.8,4.8,0,0,1-1.05,1.8,5.1,5.1,0,0,1-1.88,1.26,7.26,7.26,0,0,1-2.79.48h-4v6.28h-3.85V397.67Zm-1.06,8.24a7.63,7.63,0,0,0,1.28-.1,2.89,2.89,0,0,0,1.08-.38,2,2,0,0,0,.74-.79,2.81,2.81,0,0,0,.29-1.35,2.76,2.76,0,0,0-.29-1.35,2,2,0,0,0-.74-.8,3.08,3.08,0,0,0-1.08-.38,8.85,8.85,0,0,0-1.28-.1h-3v5.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M147.16,397.67v3.24h-9.24v3.75h8.48v3h-8.48v4.29h9.44v3.24H134.07V397.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M159.4,397.67a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.82,4.82,0,0,1,.35,1.87,5.24,5.24,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s.05.76.09,1.15a7.93,7.93,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.88,6.88,0,0,1-.39-2c-.05-.77-.13-1.51-.22-2.21a3.48,3.48,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84H150V397.67ZM158,405.59a2.86,2.86,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M171.51,397.67v17.51h-3.85V397.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M188,397.67v3.24h-9.24v3.75h8.48v3h-8.48v4.29h9.44v3.24H174.9V397.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M194.61,397.67l7.31,11.75H202V397.67h3.61v17.51h-3.85l-7.29-11.72h-.05v11.72h-3.6V397.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M220.19,402.34a3.73,3.73,0,0,0-.86-1,4,4,0,0,0-1.16-.66,4.13,4.13,0,0,0-1.36-.23,4.46,4.46,0,0,0-2.21.51,4.12,4.12,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.21,4.21,0,0,0,1.47,1.34,4.57,4.57,0,0,0,2.21.5,3.59,3.59,0,0,0,2.76-1.08,5,5,0,0,0,1.21-2.84h3.73a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.8,6.8,0,0,1-2.36,1.42,8.47,8.47,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.69,7.83,7.83,0,0,1-2.71-1.93,8.46,8.46,0,0,1-1.69-2.88,10.65,10.65,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.65,7.65,0,0,1,2.71-2,9.3,9.3,0,0,1,6.35-.3,7.3,7.3,0,0,1,2.31,1.22,6.54,6.54,0,0,1,1.68,2,7,7,0,0,1,.83,2.7h-3.73A3.09,3.09,0,0,0,220.19,402.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M240.23,397.67v3.24H231v3.75h8.49v3H231v4.29h9.44v3.24H227.13V397.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M248,397.67h4.32l4.09,6.92,4.07-6.92h4.3l-6.5,10.79v6.72h-3.85v-6.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M266,402.86a8.59,8.59,0,0,1,1.69-2.93,7.56,7.56,0,0,1,2.71-2,9.46,9.46,0,0,1,7.24,0,7.53,7.53,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,10.73,10.73,0,0,1,.59,3.64,10.41,10.41,0,0,1-.59,3.57,8.46,8.46,0,0,1-1.69,2.88,7.7,7.7,0,0,1-2.7,1.93,9.84,9.84,0,0,1-7.24,0,7.74,7.74,0,0,1-2.71-1.93,8.46,8.46,0,0,1-1.69-2.88,10.41,10.41,0,0,1-.59-3.57A10.73,10.73,0,0,1,266,402.86Zm3.51,5.79a5.87,5.87,0,0,0,.83,1.87,4.13,4.13,0,0,0,1.47,1.34,5.11,5.11,0,0,0,4.41,0,4.13,4.13,0,0,0,1.47-1.34,5.64,5.64,0,0,0,.82-1.87,8.55,8.55,0,0,0,.26-2.15,9.14,9.14,0,0,0-.26-2.23,5.86,5.86,0,0,0-.82-1.93,4,4,0,0,0-1.47-1.34,5,5,0,0,0-4.41,0,4,4,0,0,0-1.47,1.34,6.1,6.1,0,0,0-.83,1.93,9.65,9.65,0,0,0-.25,2.23A9,9,0,0,0,269.54,408.65Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M298.15,413.84a9.65,9.65,0,0,1-11,0c-1.3-1.15-1.95-2.91-1.95-5.3V397.67h3.85v10.89a7.3,7.3,0,0,0,.13,1.4,2.79,2.79,0,0,0,.51,1.21,2.88,2.88,0,0,0,1.09.86,4.46,4.46,0,0,0,1.88.33,3.61,3.61,0,0,0,2.84-.92,4.33,4.33,0,0,0,.79-2.88V397.67h3.85v10.89Q300.14,412.12,298.15,413.84Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M312.89,397.67a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.82,4.82,0,0,1,.35,1.87,5.24,5.24,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s.05.76.09,1.15a9.68,9.68,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.88,6.88,0,0,1-.39-2c-.05-.77-.13-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64H307.3v6.84h-3.85V397.67Zm-1.37,7.92a2.86,2.86,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58H307.3v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M330.91,410.87a2.93,2.93,0,0,0,.85,1,3.62,3.62,0,0,0,1.24.56,5.92,5.92,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4.08,4.08,0,0,0,1.11-.33,2.35,2.35,0,0,0,.85-.67,1.7,1.7,0,0,0,.35-1.1,1.52,1.52,0,0,0-.46-1.15,3.49,3.49,0,0,0-1.18-.73,12.22,12.22,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.4,6.4,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2.05,4.61,4.61,0,0,1,.57-2.34,5.45,5.45,0,0,1,1.51-1.66,6.7,6.7,0,0,1,2.11-1,9,9,0,0,1,2.35-.32,11.36,11.36,0,0,1,2.64.31,6.8,6.8,0,0,1,2.24,1,5,5,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3.07,3.07,0,0,0-1.07-.42,6.64,6.64,0,0,0-1.31-.12,4.88,4.88,0,0,0-.93.09,2.55,2.55,0,0,0-.85.35,2.12,2.12,0,0,0-.62.61,1.86,1.86,0,0,0-.05,1.77,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.68c.32.07.78.19,1.36.36a6.47,6.47,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.34,5.34,0,0,1-.47,2.23,4.77,4.77,0,0,1-1.39,1.78,6.63,6.63,0,0,1-2.28,1.17,11.89,11.89,0,0,1-6,.06,7.16,7.16,0,0,1-2.4-1.12,5.42,5.42,0,0,1-1.65-1.93,5.76,5.76,0,0,1-.59-2.8h3.72A3.26,3.26,0,0,0,330.91,410.87Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M355.12,402.34a4,4,0,0,0-2-1.62,4.09,4.09,0,0,0-1.36-.23,4.42,4.42,0,0,0-2.2.51,4.15,4.15,0,0,0-1.48,1.34,6.32,6.32,0,0,0-.82,1.93,9.65,9.65,0,0,0-.25,2.23,9,9,0,0,0,.25,2.15,6.08,6.08,0,0,0,.82,1.87,4.24,4.24,0,0,0,1.48,1.34,4.53,4.53,0,0,0,2.2.5,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.22-2.84h3.72a8.77,8.77,0,0,1-.76,3,7,7,0,0,1-1.62,2.25,6.76,6.76,0,0,1-2.35,1.42,8.57,8.57,0,0,1-3,.49,9,9,0,0,1-3.61-.69,7.65,7.65,0,0,1-2.71-1.93,8.65,8.65,0,0,1-1.7-2.88,10.65,10.65,0,0,1-.58-3.57,11,11,0,0,1,.58-3.64,8.78,8.78,0,0,1,1.7-2.93,7.48,7.48,0,0,1,2.71-2,9.3,9.3,0,0,1,6.35-.3,7.25,7.25,0,0,1,2.3,1.22,6.54,6.54,0,0,1,1.68,2,7.13,7.13,0,0,1,.84,2.7h-3.73A3.25,3.25,0,0,0,355.12,402.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M371.5,397.67a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.82,4.82,0,0,1,.35,1.87,5.24,5.24,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6.05,1s.05.76.09,1.15a7.93,7.93,0,0,0,.2,1.12,2.35,2.35,0,0,0,.4.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.13-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V397.67Zm-1.37,7.92a2.86,2.86,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M383.61,397.67v17.51h-3.85V397.67Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M394.89,397.67a7.28,7.28,0,0,1,2.8.48,5.35,5.35,0,0,1,1.88,1.26,4.92,4.92,0,0,1,1.05,1.79,6.72,6.72,0,0,1,0,4.16,4.8,4.8,0,0,1-1.05,1.8,5.1,5.1,0,0,1-1.88,1.26,7.28,7.28,0,0,1-2.8.48h-4v6.28H387V397.67Zm-1.05,8.24a7.43,7.43,0,0,0,1.27-.1,2.83,2.83,0,0,0,1.08-.38,2,2,0,0,0,.75-.79,2.82,2.82,0,0,0,.28-1.35,2.76,2.76,0,0,0-.28-1.35,2.14,2.14,0,0,0-.75-.8,3,3,0,0,0-1.08-.38,8.6,8.6,0,0,0-1.27-.1h-3v5.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M402,400.91v-3.24h14.35v3.24h-5.25v14.27h-3.85V400.91Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M422,411.41v3.77h-3.85v-3.77Zm-1.08-1.48h-1.74l-.93-7.55v-4.71h3.63v4.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M89.23,422.22a5.42,5.42,0,0,1,1.86.31,4.11,4.11,0,0,1,1.49,1,4.37,4.37,0,0,1,1,1.59,6.38,6.38,0,0,1,.36,2.24,7.28,7.28,0,0,1-.29,2.09,4.66,4.66,0,0,1-.88,1.65,4.09,4.09,0,0,1-1.46,1.09,4.89,4.89,0,0,1-2.06.4H84.77V422.22Zm-.16,8.42a2.83,2.83,0,0,0,1-.16,2.11,2.11,0,0,0,.83-.53,2.73,2.73,0,0,0,.58-1,4.4,4.4,0,0,0,.21-1.45,6.1,6.1,0,0,0-.15-1.41,2.83,2.83,0,0,0-.5-1.08,2.12,2.12,0,0,0-.92-.68,3.64,3.64,0,0,0-1.41-.24H87v6.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M97.23,425.07v1.39h0a2.67,2.67,0,0,1,.39-.65,2.42,2.42,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.46,2.46,0,0,1,.76-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35-.05-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M102.85,430.75a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.18.58,4.5,4.5,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.51,4.51,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.31,3.64,3.64,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,102.85,430.75Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.35,2.35,0,0,0,105.17,426.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M111,422.22v3.89h0a2.39,2.39,0,0,1,1-.95,2.69,2.69,0,0,1,1.19-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1A6.16,6.16,0,0,1,116,428v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.45,1.45,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92H109V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M119.59,422.22V426h0a2.18,2.18,0,0,1,1-.85,3.45,3.45,0,0,1,1.34-.27,2.71,2.71,0,0,1,1.11.24,2.8,2.8,0,0,1,1,.7,3.51,3.51,0,0,1,.72,1.23,5.22,5.22,0,0,1,.28,1.77,5.13,5.13,0,0,1-.28,1.77,3.51,3.51,0,0,1-.72,1.23,2.83,2.83,0,0,1-1,.71,2.87,2.87,0,0,1-1.11.23,4.07,4.07,0,0,1-1.48-.26,1.89,1.89,0,0,1-1-.89h0v1h-1.95V422.22Zm3.33,5.68a2.26,2.26,0,0,0-.32-.76,1.61,1.61,0,0,0-.54-.53,1.64,1.64,0,0,0-1.57,0,1.64,1.64,0,0,0-.55.53,2.26,2.26,0,0,0-.32.76,3.93,3.93,0,0,0-.1.92,3.8,3.8,0,0,0,.1.89,2.32,2.32,0,0,0,.32.77,1.64,1.64,0,0,0,.55.53,1.72,1.72,0,0,0,1.57,0,1.61,1.61,0,0,0,.54-.53,2.32,2.32,0,0,0,.32-.77,3.8,3.8,0,0,0,.1-.89A3.93,3.93,0,0,0,122.92,427.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M131.44,432.55v-1h0a2.39,2.39,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.46,2.46,0,0,1-.43-1,6.3,6.3,0,0,1-.12-1.27v-4.6h2.05v4.22a2.63,2.63,0,0,0,.29,1.38,1.12,1.12,0,0,0,1,.46,1.44,1.44,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M138.54,426.41a1.45,1.45,0,0,0-.83.23,1.77,1.77,0,0,0-.54.58,2.44,2.44,0,0,0-.3.78,4.4,4.4,0,0,0,0,1.67,2.65,2.65,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.74,1.74,0,0,0,1.95-.2,2,2,0,0,0,.5-1.11h2A3.36,3.36,0,0,1,141,432a3.72,3.72,0,0,1-2.44.78,4.22,4.22,0,0,1-1.54-.28,3.58,3.58,0,0,1-1.2-.79,3.68,3.68,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,5,5,0,0,1,.25-1.62,3.77,3.77,0,0,1,.75-1.29,3.42,3.42,0,0,1,1.2-.84,4.1,4.1,0,0,1,1.62-.31,4.55,4.55,0,0,1,1.28.18,3.21,3.21,0,0,1,1.1.53,2.78,2.78,0,0,1,.79.88,2.86,2.86,0,0,1,.35,1.25h-2A1.39,1.39,0,0,0,138.54,426.41Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M145.32,422.22v3.89h.05a2.34,2.34,0,0,1,1-.95,2.65,2.65,0,0,1,1.18-.3,3.54,3.54,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.58,2.58,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M153.7,432.55l-2.56-7.48h2.16l1.58,5.11h0l1.57-5.11h2L156,432.55Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M159.42,427.19a3.63,3.63,0,0,1,.78-1.25,3.47,3.47,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.3,3.3,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.4,3.4,0,0,1-.78,1.23,3.18,3.18,0,0,1-1.22.79,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.34,3.34,0,0,1-1.22-.79,3.54,3.54,0,0,1-.78-1.23,5,5,0,0,1,0-3.25Zm1.87,2.5a2.62,2.62,0,0,0,.29.77,1.61,1.61,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.71,1.71,0,0,0,.88-.2,1.61,1.61,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.21,4.21,0,0,0,.08-.87,4.4,4.4,0,0,0-.08-.89,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.71,1.71,0,0,0-.88-.21,1.58,1.58,0,0,0-1.43.76,2.45,2.45,0,0,0-.29.76,4.41,4.41,0,0,0-.09.89A4.21,4.21,0,0,0,161.29,429.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M170.17,425.07v1.39h0a2.45,2.45,0,0,1,1-1.15,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35-.05a2.56,2.56,0,0,0-.39,0,2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M175.91,422.22V426h0a2.18,2.18,0,0,1,1-.85,3.36,3.36,0,0,1,1.33-.27,2.94,2.94,0,0,1,2.11.94,3.84,3.84,0,0,1,.72,1.23,5.23,5.23,0,0,1,.27,1.77,5.15,5.15,0,0,1-.27,1.77,3.84,3.84,0,0,1-.72,1.23,2.83,2.83,0,0,1-1,.71,3,3,0,0,1-1.12.23,4,4,0,0,1-1.47-.26,1.91,1.91,0,0,1-1-.89h0v1h-2V422.22Zm3.33,5.68a2.49,2.49,0,0,0-.32-.76,1.74,1.74,0,0,0-.55-.53,1.49,1.49,0,0,0-.79-.2,1.56,1.56,0,0,0-.78.2,1.74,1.74,0,0,0-.55.53,2.25,2.25,0,0,0-.31.76,3.93,3.93,0,0,0-.11.92,3.8,3.8,0,0,0,.11.89,2.31,2.31,0,0,0,.31.77,1.74,1.74,0,0,0,.55.53,1.55,1.55,0,0,0,.78.19,1.49,1.49,0,0,0,.79-.19,1.74,1.74,0,0,0,.55-.53,2.55,2.55,0,0,0,.32-.77,4.41,4.41,0,0,0,.1-.89A4.55,4.55,0,0,0,179.24,427.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M184.89,430.75a1.86,1.86,0,0,0,1.34.45,1.82,1.82,0,0,0,1.07-.31,1.28,1.28,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.9,3.9,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.42,5.42,0,0,1,.15,1.67h-5.4A2,2,0,0,0,184.89,430.75Zm2.33-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,187.22,426.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M193,425.07v1.39h0a2.62,2.62,0,0,1,1-1.15,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35-.05a2.56,2.56,0,0,0-.39,0,2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37H191v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M198.57,430.75a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.23,1.23,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.5,4.5,0,0,1,.27-1.57,3.75,3.75,0,0,1,.79-1.25,3.63,3.63,0,0,1,1.2-.83,4,4,0,0,1,1.56-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,198.57,430.75Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.74,1.74,0,0,0-.81.17,1.6,1.6,0,0,0-.53.41,1.53,1.53,0,0,0-.27.52,2.84,2.84,0,0,0-.1.49h3.35A2.35,2.35,0,0,0,200.89,426.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M204.74,423.91v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M212.52,425.07v1.37H211v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.3,0v1.59a4.51,4.51,0,0,1-.58.06h-.62a6.37,6.37,0,0,1-.9-.06,2.08,2.08,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.19-.91v-4.42h-1.24v-1.37H209v-2.25H211v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M218.57,432.55v-1h0a2.39,2.39,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.32,2.32,0,0,1-.43-1,6.3,6.3,0,0,1-.12-1.27v-4.6h2.05v4.22a2.63,2.63,0,0,0,.29,1.38,1.11,1.11,0,0,0,1,.46,1.45,1.45,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M224,425.07v1h.05a2.36,2.36,0,0,1,1-.95,2.92,2.92,0,0,1,1.27-.3,3.5,3.5,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.69,2.69,0,0,0-.28-1.38,1.14,1.14,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M237.74,433.1a2.54,2.54,0,0,1-.53,1.08A3.15,3.15,0,0,1,236,435a4.88,4.88,0,0,1-2,.35,4.35,4.35,0,0,1-1.12-.14,3.61,3.61,0,0,1-1.05-.42,2.65,2.65,0,0,1-.78-.75,2.14,2.14,0,0,1-.35-1.09h2a1.13,1.13,0,0,0,.56.8,2,2,0,0,0,1,.23,1.52,1.52,0,0,0,1.27-.52,2.09,2.09,0,0,0,.38-1.32v-1h0a2,2,0,0,1-.94.88,3.11,3.11,0,0,1-1.3.28,3.41,3.41,0,0,1-1.45-.29,2.84,2.84,0,0,1-1-.81,3.47,3.47,0,0,1-.57-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.59,3.59,0,0,1,.62-1.18,2.85,2.85,0,0,1,1-.82,3,3,0,0,1,1.38-.31,2.93,2.93,0,0,1,1.3.28,2.19,2.19,0,0,1,.93.93h0v-1h2v7A4.6,4.6,0,0,1,237.74,433.1Zm-2.81-2.49a1.76,1.76,0,0,0,.55-.46,2.24,2.24,0,0,0,.32-.66,2.54,2.54,0,0,0,.11-.78,4.85,4.85,0,0,0-.08-.87,2.28,2.28,0,0,0-.29-.73,1.65,1.65,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.42,1.42,0,0,0-.74.18,1.49,1.49,0,0,0-.53.47,1.83,1.83,0,0,0-.3.68,3.44,3.44,0,0,0-.09.8,3.56,3.56,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.58,1.58,0,0,0,.51.52,1.44,1.44,0,0,0,.8.2A1.59,1.59,0,0,0,234.93,430.61Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M245.27,430.69a1.2,1.2,0,0,0,.35.39,1.5,1.5,0,0,0,.5.22,2.33,2.33,0,0,0,.59.08,2.58,2.58,0,0,0,.46-.05,2,2,0,0,0,.43-.16,1,1,0,0,0,.32-.29.74.74,0,0,0,.14-.46c0-.31-.21-.54-.62-.69a12.17,12.17,0,0,0-1.71-.47,8.1,8.1,0,0,1-.88-.23,3.16,3.16,0,0,1-.76-.37,1.8,1.8,0,0,1-.54-.56,1.67,1.67,0,0,1-.2-.83,2.33,2.33,0,0,1,.28-1.19,2.1,2.1,0,0,1,.75-.73,3.23,3.23,0,0,1,1-.38,6.67,6.67,0,0,1,1.19-.11,5.76,5.76,0,0,1,1.18.12,3.28,3.28,0,0,1,1,.39,2.24,2.24,0,0,1,.74.73,2.48,2.48,0,0,1,.36,1.15h-2a.9.9,0,0,0-.44-.8,2.06,2.06,0,0,0-1-.21l-.38,0a2.22,2.22,0,0,0-.37.09.83.83,0,0,0-.28.21.62.62,0,0,0-.11.37.53.53,0,0,0,.2.45,1.56,1.56,0,0,0,.53.29,7.16,7.16,0,0,0,.74.19l.86.19q.45.1.87.24a2.86,2.86,0,0,1,.76.39,2,2,0,0,1,.54.59,1.82,1.82,0,0,1,.2.88,2.34,2.34,0,0,1-.3,1.23,2.22,2.22,0,0,1-.77.81,3.18,3.18,0,0,1-1.09.42,5.86,5.86,0,0,1-1.26.13,6.76,6.76,0,0,1-1.27-.13,3.39,3.39,0,0,1-1.11-.44,2.52,2.52,0,0,1-.79-.8,2.49,2.49,0,0,1-.34-1.25h1.95A1.15,1.15,0,0,0,245.27,430.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M251.37,423.91v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M257,425.07v1h0a2.33,2.33,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.12,6.12,0,0,1,.13,1.27v4.6H260v-4.23a2.68,2.68,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M265.58,425.07v1h.05a2.3,2.3,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.54,3.54,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.45,2.45,0,0,1,.42,1,5.51,5.51,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.58,2.58,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M274.32,422.22v10.33h-2.05V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M276,423.91v-1.69h2.06v1.69Zm2.06,1.16v7.48H276v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M283.25,426.41a1.44,1.44,0,0,0-.82.23,1.77,1.77,0,0,0-.54.58,2.44,2.44,0,0,0-.3.78,3.92,3.92,0,0,0,0,1.67,2.65,2.65,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.48,1.48,0,0,0,.81.21,1.5,1.5,0,0,0,1.14-.41,2,2,0,0,0,.5-1.11h2a3.4,3.4,0,0,1-1.16,2.28,3.74,3.74,0,0,1-2.44.78,4.32,4.32,0,0,1-1.55-.28,3.64,3.64,0,0,1-1.19-.79,3.68,3.68,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,5,5,0,0,1,.25-1.62,3.77,3.77,0,0,1,.75-1.29,3.25,3.25,0,0,1,1.2-.84,4.1,4.1,0,0,1,1.62-.31,4.55,4.55,0,0,1,1.28.18,3.21,3.21,0,0,1,1.1.53,2.78,2.78,0,0,1,.79.88,2.86,2.86,0,0,1,.35,1.25h-2A1.38,1.38,0,0,0,283.25,426.41Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M290,422.22v3.89h0a2.45,2.45,0,0,1,1-.95,2.69,2.69,0,0,1,1.19-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1A6.12,6.12,0,0,1,295,428v4.6H293v-4.23a2.68,2.68,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.83,2.83,0,0,0-.38,1.65v3.92H288V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M300.51,427.37a2.43,2.43,0,0,1,.36-1.2,2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.42,6.42,0,0,1,1.23-.13,9,9,0,0,1,1.15.08,3.88,3.88,0,0,1,1.05.31,2.06,2.06,0,0,1,.78.65,1.74,1.74,0,0,1,.31,1.09v3.89a7.43,7.43,0,0,0,.06,1,1.58,1.58,0,0,0,.2.7h-2.09l-.09-.35c0-.13,0-.25-.05-.37a2.79,2.79,0,0,1-1.16.71,4.76,4.76,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.38,2.38,0,0,1-.2-1,2.15,2.15,0,0,1,.23-1.05,1.78,1.78,0,0,1,.58-.66,2.51,2.51,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19l.91-.12a5.57,5.57,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.58.58,0,0,0,.18-.49,1,1,0,0,0-.11-.53.74.74,0,0,0-.28-.3,1.23,1.23,0,0,0-.42-.14,2.89,2.89,0,0,0-.52,0,1.58,1.58,0,0,0-.95.26,1.13,1.13,0,0,0-.41.87Zm4.74,1.52a.89.89,0,0,1-.32.18l-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19.89.89,0,0,0-.37.79.9.9,0,0,0,.1.46.74.74,0,0,0,.28.3,1.19,1.19,0,0,0,.4.15,2.52,2.52,0,0,0,.48.05,1.77,1.77,0,0,0,.94-.21,1.26,1.26,0,0,0,.49-.48,1.62,1.62,0,0,0,.2-.57c0-.2,0-.35,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M310.85,425.07v1h0a2.36,2.36,0,0,1,1-.95,2.92,2.92,0,0,1,1.27-.3,3.5,3.5,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.69,2.69,0,0,0-.28-1.38,1.14,1.14,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92H308.9v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M324.55,433.1a2.54,2.54,0,0,1-.53,1.08,3.15,3.15,0,0,1-1.17.84,4.88,4.88,0,0,1-2.05.35,4.35,4.35,0,0,1-1.12-.14,3.61,3.61,0,0,1-1.05-.42,2.65,2.65,0,0,1-.78-.75,2.14,2.14,0,0,1-.35-1.09h2a1.13,1.13,0,0,0,.56.8,2,2,0,0,0,1,.23,1.52,1.52,0,0,0,1.27-.52,2.09,2.09,0,0,0,.38-1.32v-1h0a2,2,0,0,1-.94.88,3.11,3.11,0,0,1-1.3.28A3.41,3.41,0,0,1,319,432a2.84,2.84,0,0,1-1-.81,3.47,3.47,0,0,1-.57-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.59,3.59,0,0,1,.62-1.18,2.85,2.85,0,0,1,1-.82,3,3,0,0,1,1.38-.31,2.93,2.93,0,0,1,1.3.28,2.19,2.19,0,0,1,.93.93h0v-1h2v7A4.6,4.6,0,0,1,324.55,433.1Zm-2.81-2.49a1.76,1.76,0,0,0,.55-.46,2.24,2.24,0,0,0,.32-.66,2.54,2.54,0,0,0,.11-.78,4.85,4.85,0,0,0-.08-.87,2.28,2.28,0,0,0-.29-.73,1.65,1.65,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.42,1.42,0,0,0-.74.18,1.49,1.49,0,0,0-.53.47,1.83,1.83,0,0,0-.3.68,3.44,3.44,0,0,0-.09.8,3.56,3.56,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.58,1.58,0,0,0,.51.52,1.44,1.44,0,0,0,.8.2A1.59,1.59,0,0,0,321.74,430.61Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M328.53,430.75a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.51,4.51,0,0,1,.28-1.57A3.58,3.58,0,0,1,327,426a3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.51,3.51,0,0,1,1.17,1,4.09,4.09,0,0,1,.65,1.42,5.13,5.13,0,0,1,.15,1.67H328A2.05,2.05,0,0,0,328.53,430.75Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.26,2.26,0,0,0,330.85,426.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M336.69,422.22v3.89h0a2.39,2.39,0,0,1,1-.95,2.69,2.69,0,0,1,1.19-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.75,2.75,0,0,0-.38,1.65v3.92h-2.06V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M345.42,430.75a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.51,4.51,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,345.42,430.75Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.26,2.26,0,0,0,347.74,426.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M353.48,425.07v1h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.05v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45A1.41,1.41,0,0,0,354,427a2.75,2.75,0,0,0-.38,1.65v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M362.48,430.32v2.23a2.19,2.19,0,0,1-.18.9,2.14,2.14,0,0,1-.49.72,2.59,2.59,0,0,1-.71.5,3.33,3.33,0,0,1-.88.27v-1a1.07,1.07,0,0,0,.41-.18,1.27,1.27,0,0,0,.34-.31,1.2,1.2,0,0,0,.22-.4,1.22,1.22,0,0,0,.06-.46h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M370.64,422.22v8.42h5v1.91h-7.31V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M379.23,422.22v10.33H377V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M383.47,432.55l-3.35-10.33h2.33l2.3,7.26h0l2.33-7.26h2.35L386,432.55Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M398.07,422.22v1.91h-5.46v2.21h5v1.77h-5v2.53h5.58v1.91h-7.85V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M403.57,427.19a3.49,3.49,0,0,1,.78-1.25,3.39,3.39,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.39,3.39,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.4,3.4,0,0,1-.78,1.23,3.26,3.26,0,0,1-1.22.79,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.26,3.26,0,0,1-1.22-.79,3.4,3.4,0,0,1-.78-1.23,4.86,4.86,0,0,1,0-3.25Zm1.87,2.5a2.37,2.37,0,0,0,.29.77,1.61,1.61,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.71,1.71,0,0,0,.88-.2,1.61,1.61,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,5,5,0,0,0,.08-.87,5.26,5.26,0,0,0-.08-.89,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.71,1.71,0,0,0-.88-.21,1.58,1.58,0,0,0-1.43.76,2.23,2.23,0,0,0-.29.76,4.41,4.41,0,0,0-.09.89A4.21,4.21,0,0,0,405.44,429.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M417.68,431.59a2.11,2.11,0,0,1-.95.88,3.11,3.11,0,0,1-1.32.27,3.19,3.19,0,0,1-1.48-.33,3,3,0,0,1-1.05-.88,4.12,4.12,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.12,3.12,0,0,1,1-.86,3,3,0,0,1,1.45-.33,2.91,2.91,0,0,1,1.26.29,2.13,2.13,0,0,1,.95.83h0v-3.76h2.05v10.33h-2v-1Zm-.09-3.69a2,2,0,0,0-.29-.76,1.61,1.61,0,0,0-.55-.52,1.53,1.53,0,0,0-.83-.21,1.64,1.64,0,0,0-.86.21,1.58,1.58,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,4.33,4.33,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.32,2.32,0,0,0,.32.77,1.76,1.76,0,0,0,.57.55,1.51,1.51,0,0,0,.82.21,1.56,1.56,0,0,0,.84-.2,1.58,1.58,0,0,0,.55-.54,2.41,2.41,0,0,0,.28-.78,4.33,4.33,0,0,0,.09-.89A4.26,4.26,0,0,0,417.59,427.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M423.41,430.75a2.12,2.12,0,0,0,2.41.14,1.33,1.33,0,0,0,.55-.66h1.8a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,4.11,4.11,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.39A2,2,0,0,0,423.41,430.75Zm2.33-3.93a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,425.74,426.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M431.47,425.07v1.39h0a2.45,2.45,0,0,1,1-1.15,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35-.05a2.56,2.56,0,0,0-.39,0,2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M444.27,424.13h-5.11v-1.91h8.13V424l-5.54,6.63h5.69v1.91h-8.71v-1.8Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M448.67,425.28a5,5,0,0,1,1-1.73,4.38,4.38,0,0,1,1.6-1.16,5.64,5.64,0,0,1,4.27,0,4.55,4.55,0,0,1,1.6,1.16,5.18,5.18,0,0,1,1,1.73,6.22,6.22,0,0,1,.35,2.15,6,6,0,0,1-.35,2.1,5,5,0,0,1-1,1.7,4.76,4.76,0,0,1-1.6,1.14,5.76,5.76,0,0,1-4.27,0,4.57,4.57,0,0,1-1.6-1.14,4.9,4.9,0,0,1-1-1.7,6.25,6.25,0,0,1-.35-2.1A6.46,6.46,0,0,1,448.67,425.28Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.45,2.45,0,0,0,.87.79,3.06,3.06,0,0,0,2.6,0,2.45,2.45,0,0,0,.87-.79,3.48,3.48,0,0,0,.49-1.11,5.3,5.3,0,0,0,.15-1.26,5.68,5.68,0,0,0-.15-1.32,3.55,3.55,0,0,0-.49-1.14,2.45,2.45,0,0,0-.87-.79,2.6,2.6,0,0,0-1.3-.3,2.63,2.63,0,0,0-1.3.3,2.45,2.45,0,0,0-.87.79,3.53,3.53,0,0,0-.48,1.14,5.19,5.19,0,0,0-.16,1.32A4.84,4.84,0,0,0,450.75,428.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M459.93,425.28a5,5,0,0,1,1-1.73,4.38,4.38,0,0,1,1.6-1.16,5.64,5.64,0,0,1,4.27,0,4.55,4.55,0,0,1,1.6,1.16,5.18,5.18,0,0,1,1,1.73,6.22,6.22,0,0,1,.35,2.15,6,6,0,0,1-.35,2.1,5,5,0,0,1-1,1.7,4.76,4.76,0,0,1-1.6,1.14,5.76,5.76,0,0,1-4.27,0,4.57,4.57,0,0,1-1.6-1.14,4.9,4.9,0,0,1-1-1.7,6.25,6.25,0,0,1-.35-2.1A6.46,6.46,0,0,1,459.93,425.28Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.45,2.45,0,0,0,.87.79,3.06,3.06,0,0,0,2.6,0,2.45,2.45,0,0,0,.87-.79,3.48,3.48,0,0,0,.49-1.11,5.3,5.3,0,0,0,.15-1.26,5.68,5.68,0,0,0-.15-1.32,3.55,3.55,0,0,0-.49-1.14,2.45,2.45,0,0,0-.87-.79,2.6,2.6,0,0,0-1.3-.3,2.63,2.63,0,0,0-1.3.3,2.45,2.45,0,0,0-.87.79,3.53,3.53,0,0,0-.48,1.14,5.19,5.19,0,0,0-.16,1.32A4.84,4.84,0,0,0,462,428.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M474.49,422.22l2.42,7.1h0l2.28-7.1h3.2v10.33h-2.13v-7.32h0l-2.53,7.32H476l-2.53-7.25h0v7.25h-2.13V422.22Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    <Link to="/de/einzelcoaching">
      {/* einzelcoaching */}
      <g id="einzelcoaching">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="116.13"
          y="171.29"
          width="376.26"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M213.69,272.56v3.23h-9.25v3.75h8.49v3h-8.49v4.29h9.44v3.24H200.59V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M220.33,272.56v17.51h-3.85V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M227.54,272.56l7.31,11.74h.05V272.56h3.6v17.51h-3.85l-7.28-11.73h-.05v11.73h-3.6V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M250.15,275.79H241.5v-3.23h13.78v3l-9.4,11.23h9.64v3.24H240.76V287Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M270.87,272.56v3.23h-9.24v3.75h8.48v3h-8.48v4.29h9.44v3.24H257.78V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M277.52,272.56v14.27h8.53v3.24H273.67V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M299.44,277.23a3.94,3.94,0,0,0-2-1.62,3.89,3.89,0,0,0-1.36-.23,4.46,4.46,0,0,0-2.21.5,4.14,4.14,0,0,0-1.47,1.35,5.72,5.72,0,0,0-.82,1.92,9.72,9.72,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.69,5.69,0,0,0,.82,1.88,4.27,4.27,0,0,0,1.47,1.33,4.46,4.46,0,0,0,2.21.51,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.21-2.85h3.73a8.89,8.89,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25A6.78,6.78,0,0,1,299,290a8.81,8.81,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93A8.43,8.43,0,0,1,288,285a10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.86,8.86,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.92,8.92,0,0,1,3.62-.71,8.72,8.72,0,0,1,2.73.42,7.28,7.28,0,0,1,2.31,1.21,6.54,6.54,0,0,1,1.68,2,7.06,7.06,0,0,1,.83,2.7h-3.73A3,3,0,0,0,299.44,277.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M306.21,277.74a8.86,8.86,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,7.83,7.83,0,0,1,2.7,2,8.86,8.86,0,0,1,1.69,2.93,11,11,0,0,1,.59,3.64,10.61,10.61,0,0,1-.59,3.57,8.43,8.43,0,0,1-1.69,2.88,7.89,7.89,0,0,1-2.7,1.93,9.74,9.74,0,0,1-7.25,0,7.83,7.83,0,0,1-2.71-1.93,8.43,8.43,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57A11,11,0,0,1,306.21,277.74Zm3.52,5.79a5.9,5.9,0,0,0,.82,1.88,4.27,4.27,0,0,0,1.47,1.33,5,5,0,0,0,4.42,0,4.27,4.27,0,0,0,1.47-1.33,5.69,5.69,0,0,0,.82-1.88,9,9,0,0,0,.26-2.15,9.72,9.72,0,0,0-.26-2.23,5.72,5.72,0,0,0-.82-1.92,4.14,4.14,0,0,0-1.47-1.35,5.13,5.13,0,0,0-4.42,0,4.14,4.14,0,0,0-1.47,1.35,5.93,5.93,0,0,0-.82,1.92,9.72,9.72,0,0,0-.26,2.23A9,9,0,0,0,309.73,283.53Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M334.19,272.56l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.74-2.2-6.43h0l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M353.49,277.23a3.94,3.94,0,0,0-2-1.62,3.89,3.89,0,0,0-1.36-.23,4.46,4.46,0,0,0-2.21.5,4.14,4.14,0,0,0-1.47,1.35,5.72,5.72,0,0,0-.82,1.92,9.72,9.72,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.69,5.69,0,0,0,.82,1.88,4.27,4.27,0,0,0,1.47,1.33,4.46,4.46,0,0,0,2.21.51,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.21-2.85h3.73a8.89,8.89,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.87,6.87,0,0,1-2.35,1.43,8.81,8.81,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93,8.43,8.43,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.86,8.86,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.92,8.92,0,0,1,3.62-.71,8.76,8.76,0,0,1,2.73.42,7.28,7.28,0,0,1,2.31,1.21,6.54,6.54,0,0,1,1.68,2,7.06,7.06,0,0,1,.83,2.7h-3.73A3,3,0,0,0,353.49,277.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M364.28,272.56v6.72h7.09v-6.72h3.85v17.51h-3.85v-7.56h-7.09v7.56h-3.85V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M382.45,272.56v17.51H378.6V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M389.66,272.56,397,284.3h0V272.56h3.61v17.51h-3.85l-7.29-11.73h-.05v11.73h-3.6V272.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M414.38,289.93a6.44,6.44,0,0,1-2.52.53,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93,8.27,8.27,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.69,8.69,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.92,8.92,0,0,1,3.62-.71,8.47,8.47,0,0,1,2.61.4,7.51,7.51,0,0,1,2.28,1.19,6.45,6.45,0,0,1,2.49,4.59h-3.68a3.71,3.71,0,0,0-1.32-2.21,3.87,3.87,0,0,0-2.38-.73,4.46,4.46,0,0,0-2.21.5,4.14,4.14,0,0,0-1.47,1.35,5.72,5.72,0,0,0-.82,1.92,9.72,9.72,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.69,5.69,0,0,0,.82,1.88,4.27,4.27,0,0,0,1.47,1.33,4.46,4.46,0,0,0,2.21.51,3.77,3.77,0,0,0,4.17-3.78h-3.88V280.6h7.36v9.47h-2.45l-.4-2A5.69,5.69,0,0,1,414.38,289.93Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    {/* masterclass helden und loser */}
    <g id="masterclass-helden-und-loser">
      <rect
        class="e8181e97-3015-49de-82f8-4bd3baa60643"
        x="1215.06"
        y="457.43"
        width="479.58"
        height="67.26"
      />
      <g>
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1304.94,548.7l4.09,12h0l3.88-12h5.42v17.51h-3.61V553.8h0l-4.29,12.41h-3l-4.29-12.28h-.05v12.28h-3.6V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1330.49,548.7l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.74L1328.5,553h0l-2.29,6.42Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1341.5,561.9a2.82,2.82,0,0,0,.85,1,3.62,3.62,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4.19,4.19,0,0,0,1.11-.33,2.44,2.44,0,0,0,.85-.67,1.7,1.7,0,0,0,.35-1.09,1.53,1.53,0,0,0-.46-1.16,3.54,3.54,0,0,0-1.19-.73,11.64,11.64,0,0,0-1.66-.52c-.62-.15-1.25-.31-1.89-.49a15.14,15.14,0,0,1-1.91-.6,6.4,6.4,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.46-2,4.61,4.61,0,0,1,.58-2.34,5.32,5.32,0,0,1,1.51-1.66,6.7,6.7,0,0,1,2.11-1,9,9,0,0,1,2.35-.31,11.36,11.36,0,0,1,2.64.3,6.8,6.8,0,0,1,2.24,1,5.08,5.08,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.58,2.59h-3.73a3.14,3.14,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.53,2.53,0,0,0-.85.34,2.12,2.12,0,0,0-.62.61,1.61,1.61,0,0,0-.25.93,1.58,1.58,0,0,0,.2.84,1.78,1.78,0,0,0,.77.59,9.94,9.94,0,0,0,1.59.54l2.67.68c.32.07.78.19,1.36.36a6.27,6.27,0,0,1,1.72.82,5.23,5.23,0,0,1,1.49,1.51,4.25,4.25,0,0,1,.62,2.41,5.41,5.41,0,0,1-.46,2.24,4.82,4.82,0,0,1-1.39,1.77,6.63,6.63,0,0,1-2.28,1.17,10.74,10.74,0,0,1-3.15.41,11,11,0,0,1-2.81-.35,7.07,7.07,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.87,5.87,0,0,1-.58-2.8h3.72A3.26,3.26,0,0,0,1341.5,561.9Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1353.1,551.94V548.7h14.35v3.24h-5.25v14.27h-3.85V551.94Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1382.55,548.7v3.24h-9.24v3.75h8.48v3h-8.48V563h9.44v3.24h-13.29V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1394.79,548.7a5.68,5.68,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.83,4.83,0,0,1,.36,1.87,5.32,5.32,0,0,1-.65,2.68,4.08,4.08,0,0,1-2.13,1.71V558a2.92,2.92,0,0,1,1.18.6,3.23,3.23,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,12.16,12.16,0,0,1,.19,1.33c0,.28,0,.6,0,1s0,.76.09,1.15a7.68,7.68,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V548.7Zm-1.37,7.92a2.91,2.91,0,0,0,2-.58,2.48,2.48,0,0,0,.67-1.92,2.35,2.35,0,0,0-.67-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1414.29,553.37a3.93,3.93,0,0,0-.86-1,4.11,4.11,0,0,0-2.53-.89,4.42,4.42,0,0,0-2.2.51,4.15,4.15,0,0,0-1.48,1.34,6.08,6.08,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.85,5.85,0,0,0,.82,1.87,4.24,4.24,0,0,0,1.48,1.34,4.53,4.53,0,0,0,2.2.5,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.22-2.84h3.72a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.76,6.76,0,0,1-2.35,1.42,8.57,8.57,0,0,1-3,.49,9,9,0,0,1-3.61-.69,7.65,7.65,0,0,1-2.71-1.93,8.65,8.65,0,0,1-1.7-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.78,8.78,0,0,1,1.7-2.93,7.48,7.48,0,0,1,2.71-2,9.46,9.46,0,0,1,6.35-.3,7.46,7.46,0,0,1,2.3,1.22,6.54,6.54,0,0,1,1.68,2,7.13,7.13,0,0,1,.84,2.7h-3.73A3.25,3.25,0,0,0,1414.29,553.37Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1425.08,548.7V563h8.53v3.24h-12.38V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1444.5,548.7l6.55,17.51h-4l-1.33-3.9h-6.54l-1.38,3.9h-3.87l6.62-17.51Zm.22,10.74-2.21-6.42h0l-2.28,6.42Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1455.51,561.9a2.72,2.72,0,0,0,.85,1,3.65,3.65,0,0,0,1.23.56,5.61,5.61,0,0,0,1.48.19,7.29,7.29,0,0,0,1.1-.09,4,4,0,0,0,1.1-.33,2.38,2.38,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.54,3.54,0,0,0-1.19-.73,11.4,11.4,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.88-.49a15.29,15.29,0,0,1-1.92-.6,6.51,6.51,0,0,1-1.66-.92,4.39,4.39,0,0,1-1.19-1.41,4.2,4.2,0,0,1-.46-2,4.51,4.51,0,0,1,.58-2.34,5.21,5.21,0,0,1,1.51-1.66,6.7,6.7,0,0,1,2.11-1,9,9,0,0,1,2.35-.31,11.49,11.49,0,0,1,2.64.3,6.8,6.8,0,0,1,2.24,1,5.18,5.18,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.57,2.59h-3.72a3.13,3.13,0,0,0-.34-1.3,2,2,0,0,0-.74-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.45,2.45,0,0,0-.85.34,2.29,2.29,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.49,1.49,0,0,0,.2.84,1.72,1.72,0,0,0,.77.59,9.6,9.6,0,0,0,1.59.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.23,5.23,0,0,1,1.49,1.51,4.33,4.33,0,0,1,.62,2.41,5.41,5.41,0,0,1-.46,2.24,5,5,0,0,1-1.39,1.77,6.63,6.63,0,0,1-2.28,1.17,10.74,10.74,0,0,1-3.15.41,10.93,10.93,0,0,1-2.81-.35,7,7,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.87,5.87,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,1455.51,561.9Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1471.42,561.9a2.93,2.93,0,0,0,.85,1,3.7,3.7,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4.08,4.08,0,0,0,1.11-.33,2.47,2.47,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.65,3.65,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.61,4.61,0,0,1,.57-2.34,5.45,5.45,0,0,1,1.51-1.66,6.81,6.81,0,0,1,2.11-1,9.09,9.09,0,0,1,2.35-.31,11.36,11.36,0,0,1,2.64.3,6.8,6.8,0,0,1,2.24,1,5,5,0,0,1,1.56,1.76,5.3,5.3,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.2,4.2,0,0,0-.93.1,2.4,2.4,0,0,0-.84.34,2,2,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.58,1.58,0,0,0,.19.84,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.42,5.42,0,0,1-.47,2.24,4.9,4.9,0,0,1-1.38,1.77,6.77,6.77,0,0,1-2.29,1.17,10.65,10.65,0,0,1-3.15.41,10.85,10.85,0,0,1-2.8-.35,7.12,7.12,0,0,1-2.41-1.12,5.52,5.52,0,0,1-1.65-1.93,5.76,5.76,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,1471.42,561.9Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1495.06,548.7v6.72h7.09V548.7H1506v17.51h-3.85v-7.55h-7.09v7.55h-3.85V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1522.48,548.7v3.24h-9.24v3.75h8.48v3h-8.48V563h9.44v3.24h-13.29V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1529.13,548.7V563h8.53v3.24h-12.38V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1547.37,548.7a9,9,0,0,1,3.15.54,6.79,6.79,0,0,1,2.53,1.62,7.49,7.49,0,0,1,1.67,2.7,10.9,10.9,0,0,1,.6,3.8,12.39,12.39,0,0,1-.49,3.53,7.76,7.76,0,0,1-1.49,2.8,7,7,0,0,1-2.47,1.85,8.41,8.41,0,0,1-3.5.67h-7.55V548.7ZM1547.1,563a4.91,4.91,0,0,0,1.62-.26,3.58,3.58,0,0,0,1.4-.9,4.43,4.43,0,0,0,1-1.63,7.06,7.06,0,0,0,.37-2.45,10.23,10.23,0,0,0-.26-2.39,4.62,4.62,0,0,0-.85-1.83,3.74,3.74,0,0,0-1.55-1.17,6.26,6.26,0,0,0-2.39-.4h-2.75v11Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1571.09,548.7v3.24h-9.25v3.75h8.49v3h-8.49V563h9.44v3.24H1558V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1577.71,548.7l7.31,11.75h0V548.7h3.61v17.51h-3.85l-7.28-11.72h0v11.72h-3.61V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1611.75,564.88a9.68,9.68,0,0,1-11,0c-1.3-1.15-2-2.91-2-5.3V548.7h3.85v10.89a8.1,8.1,0,0,0,.12,1.4,2.69,2.69,0,0,0,.52,1.21,2.81,2.81,0,0,0,1.09.86,4.41,4.41,0,0,0,1.87.33,3.63,3.63,0,0,0,2.85-.92,4.39,4.39,0,0,0,.78-2.88V548.7h3.85v10.89A6.67,6.67,0,0,1,1611.75,564.88Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1620.87,548.7l7.31,11.75h0V548.7h3.6v17.51H1628l-7.28-11.72h0v11.72H1617V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1642.77,548.7a9,9,0,0,1,3.15.54,7,7,0,0,1,2.53,1.62,7.47,7.47,0,0,1,1.66,2.7,10.9,10.9,0,0,1,.6,3.8,12,12,0,0,1-.49,3.53,7.74,7.74,0,0,1-1.48,2.8,7,7,0,0,1-2.48,1.85,8.36,8.36,0,0,1-3.49.67h-7.56V548.7ZM1642.5,563a5,5,0,0,0,1.62-.26,3.48,3.48,0,0,0,1.39-.9,4.59,4.59,0,0,0,1-1.63,7.32,7.32,0,0,0,.36-2.45,10.26,10.26,0,0,0-.25-2.39,4.91,4.91,0,0,0-.85-1.83,3.71,3.71,0,0,0-1.56-1.17,6.19,6.19,0,0,0-2.39-.4h-2.74v11Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1664.05,548.7V563h8.54v3.24H1660.2V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1674.57,553.89a8.78,8.78,0,0,1,1.7-2.93,7.56,7.56,0,0,1,2.71-2,9.58,9.58,0,0,1,7.24,0,7.61,7.61,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,11,11,0,0,1,.59,3.64,10.61,10.61,0,0,1-.59,3.57,8.46,8.46,0,0,1-1.69,2.88,7.79,7.79,0,0,1-2.7,1.93,9.84,9.84,0,0,1-7.24,0,7.74,7.74,0,0,1-2.71-1.93,8.65,8.65,0,0,1-1.7-2.88,10.61,10.61,0,0,1-.58-3.57A11,11,0,0,1,1674.57,553.89Zm3.52,5.79a5.87,5.87,0,0,0,.83,1.87,4.13,4.13,0,0,0,1.47,1.34,5.11,5.11,0,0,0,4.41,0,4.13,4.13,0,0,0,1.47-1.34,5.64,5.64,0,0,0,.82-1.87,8.55,8.55,0,0,0,.26-2.15,9.14,9.14,0,0,0-.26-2.23,5.86,5.86,0,0,0-.82-1.93,4,4,0,0,0-1.47-1.34,5,5,0,0,0-4.41,0,4,4,0,0,0-1.47,1.34,6.1,6.1,0,0,0-.83,1.93,9.65,9.65,0,0,0-.25,2.23A9,9,0,0,0,1678.09,559.68Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1696.77,561.9a2.79,2.79,0,0,0,.84,1,3.8,3.8,0,0,0,1.24.56,5.59,5.59,0,0,0,1.47.19,7.31,7.31,0,0,0,1.11-.09,4,4,0,0,0,1.1-.33,2.38,2.38,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.54,3.54,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6A6.57,6.57,0,0,1,1695,557a4.52,4.52,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.51,4.51,0,0,1,.58-2.34,5.29,5.29,0,0,1,1.5-1.66,6.92,6.92,0,0,1,2.11-1,9.11,9.11,0,0,1,2.36-.31,11.34,11.34,0,0,1,2.63.3,6.85,6.85,0,0,1,2.25,1,5.05,5.05,0,0,1,1.55,1.76,5.3,5.3,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.08,2.08,0,0,0-.74-.81,3.15,3.15,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.29,4.29,0,0,0-.94.1,2.4,2.4,0,0,0-.84.34,2.16,2.16,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.58,1.58,0,0,0,.19.84,1.82,1.82,0,0,0,.78.59,9.6,9.6,0,0,0,1.59.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.42,5.42,0,0,1-.47,2.24,4.9,4.9,0,0,1-1.38,1.77,6.63,6.63,0,0,1-2.28,1.17,10.74,10.74,0,0,1-3.15.41,10.93,10.93,0,0,1-2.81-.35,7,7,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.87,5.87,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,1696.77,561.9Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1722.84,548.7v3.24h-9.25v3.75h8.49v3h-8.49V563H1723v3.24h-13.29V548.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1735.07,548.7a5.68,5.68,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.53,4.53,0,0,1,1,1.54,4.83,4.83,0,0,1,.36,1.87,5.32,5.32,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71V558a3,3,0,0,1,1.17.6,3.26,3.26,0,0,1,.77,1,4.68,4.68,0,0,1,.42,1.21,10.14,10.14,0,0,1,.19,1.33c0,.28,0,.6,0,1s0,.76.08,1.15a7.68,7.68,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.47,3.47,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85V548.7Zm-1.37,7.92a3,3,0,0,0,2-.58,2.48,2.48,0,0,0,.66-1.92,2.34,2.34,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1303.28,573.25a5.34,5.34,0,0,1,1.86.31,4.16,4.16,0,0,1,1.49,1,4.48,4.48,0,0,1,1,1.59,6.65,6.65,0,0,1,.35,2.25,6.93,6.93,0,0,1-.29,2.08,4.49,4.49,0,0,1-.87,1.65,4.09,4.09,0,0,1-1.46,1.09,4.93,4.93,0,0,1-2.07.4h-4.45V573.25Zm-.16,8.42a2.85,2.85,0,0,0,1-.16,2.07,2.07,0,0,0,.82-.53,2.51,2.51,0,0,0,.58-1,4.14,4.14,0,0,0,.22-1.45,6.1,6.1,0,0,0-.15-1.41,2.83,2.83,0,0,0-.5-1.08,2.12,2.12,0,0,0-.92-.68,3.64,3.64,0,0,0-1.41-.24h-1.62v6.51Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1309.39,574.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1315.27,581.79a1.87,1.87,0,0,0,1.33.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66H1320a3.49,3.49,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.84,4.84,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.43,3.43,0,0,1,1.17,1,4,4,0,0,1,.65,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,1315.27,581.79Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.26,2.26,0,0,0,1317.59,577.85Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1327.89,573.25v4.28l4-4.28h2.83l-4,4.08,4.43,6.25h-2.85l-3.11-4.65-1.3,1.32v3.33h-2.27V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1340.91,583.58v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.46,2.46,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2.05v4.22a2.69,2.69,0,0,0,.29,1.39,1.13,1.13,0,0,0,1,.45,1.42,1.42,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64V576.1h2.06v7.48Zm-2.07-10.33v1.69h-1.95v-1.69Zm3,0v1.69h-2v-1.69Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1346.38,576.1v1h0a2.42,2.42,0,0,1,1-.95,2.92,2.92,0,0,1,1.27-.3,3.54,3.54,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.69,2.69,0,0,0-.28-1.38,1.14,1.14,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1354.74,581.72a1.12,1.12,0,0,0,.36.39,1.53,1.53,0,0,0,.5.23,2.71,2.71,0,0,0,.58.07,2.49,2.49,0,0,0,.46,0,1.82,1.82,0,0,0,.43-.16,1,1,0,0,0,.33-.29.81.81,0,0,0,.13-.46c0-.31-.21-.54-.62-.69a12.17,12.17,0,0,0-1.71-.47,8.75,8.75,0,0,1-.88-.23,3.35,3.35,0,0,1-.76-.37,1.67,1.67,0,0,1-.53-.56,1.55,1.55,0,0,1-.21-.83,2.25,2.25,0,0,1,.29-1.19,2,2,0,0,1,.74-.73,3.49,3.49,0,0,1,1-.38,6.75,6.75,0,0,1,1.19-.11,5.69,5.69,0,0,1,1.18.12,3.28,3.28,0,0,1,1,.39,2.37,2.37,0,0,1,.75.73,2.6,2.6,0,0,1,.35,1.15h-2a.9.9,0,0,0-.45-.8,2.06,2.06,0,0,0-1-.21l-.37,0a1.34,1.34,0,0,0-.37.1.58.58,0,0,0-.28.21.52.52,0,0,0-.12.36.56.56,0,0,0,.2.45,1.66,1.66,0,0,0,.53.29c.22.07.47.13.75.19l.85.19a8.84,8.84,0,0,1,.88.25,2.85,2.85,0,0,1,.76.38,1.86,1.86,0,0,1,.53.59,1.71,1.71,0,0,1,.2.88,2.5,2.5,0,0,1-.29,1.24,2.39,2.39,0,0,1-.78.8,3.4,3.4,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,6.78,6.78,0,0,1-1.28-.13,3.26,3.26,0,0,1-1.1-.44,2.45,2.45,0,0,1-.8-.8,2.49,2.49,0,0,1-.34-1.25h1.95A1.15,1.15,0,0,0,1354.74,581.72Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1364.9,576.1v1.37h-1.51v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.63a6.48,6.48,0,0,1-.9-.06,2.08,2.08,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.19-.91v-4.42h-1.24V576.1h1.24v-2.25h2.06v2.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1368,573.25v10.33h-2V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1371.81,581.79a1.89,1.89,0,0,0,1.33.44,1.83,1.83,0,0,0,1.08-.31,1.29,1.29,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.86,3.86,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.54,4.54,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.42,5.42,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,1371.81,581.79Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.66,2.66,0,0,0-.09.5h3.34A2.43,2.43,0,0,0,1374.14,577.85Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1379.88,576.1v1.39h0a2.88,2.88,0,0,1,.39-.65,3,3,0,0,1,.57-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35,0a2.55,2.55,0,0,0-.39,0,2.27,2.27,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1385.5,576.1v.95h0a2.25,2.25,0,0,1,1-.88,3,3,0,0,1,1.27-.28,3.25,3.25,0,0,1,1.52.34,3,3,0,0,1,1.06.88,3.63,3.63,0,0,1,.62,1.28,5.54,5.54,0,0,1,.2,1.53,5,5,0,0,1-.2,1.45,3.73,3.73,0,0,1-.61,1.23,3.15,3.15,0,0,1-1,.85,3.12,3.12,0,0,1-1.44.32,3,3,0,0,1-1.28-.28,2.52,2.52,0,0,1-1-.84h0v3.55h-2.06V576.1Zm2.67,5.93a1.55,1.55,0,0,0,.55-.53,2,2,0,0,0,.3-.76,4.31,4.31,0,0,0,.09-.88,3.71,3.71,0,0,0-.1-.88,2.24,2.24,0,0,0-.31-.78,1.8,1.8,0,0,0-.55-.55,1.59,1.59,0,0,0-.84-.21,1.56,1.56,0,0,0-.84.21,1.61,1.61,0,0,0-.55.55,2.24,2.24,0,0,0-.3.76,4.43,4.43,0,0,0-.09.9,3.71,3.71,0,0,0,.1.88,2.16,2.16,0,0,0,.3.76,1.58,1.58,0,0,0,1.4.73A1.54,1.54,0,0,0,1388.17,582Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1394.59,581.79a1.9,1.9,0,0,0,1.34.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.86,3.86,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.54,4.54,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.9,3.9,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.42,5.42,0,0,1,.15,1.67h-5.4A2.14,2.14,0,0,0,1394.59,581.79Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.41,1.8,1.8,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.66,2.66,0,0,0-.09.5h3.34A2.43,2.43,0,0,0,1396.92,577.85Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1402.66,576.1v1.39h0a2.59,2.59,0,0,1,.4-.65,2.67,2.67,0,0,1,.56-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35,0a2.55,2.55,0,0,0-.39,0,2.27,2.27,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1408.06,581.72a1.12,1.12,0,0,0,.36.39,1.53,1.53,0,0,0,.5.23,2.71,2.71,0,0,0,.58.07,2.49,2.49,0,0,0,.46,0,1.82,1.82,0,0,0,.43-.16,1,1,0,0,0,.33-.29.81.81,0,0,0,.13-.46c0-.31-.21-.54-.62-.69a12.17,12.17,0,0,0-1.71-.47,8.75,8.75,0,0,1-.88-.23,3.35,3.35,0,0,1-.76-.37,1.67,1.67,0,0,1-.53-.56,1.55,1.55,0,0,1-.21-.83,2.25,2.25,0,0,1,.29-1.19,2,2,0,0,1,.74-.73,3.49,3.49,0,0,1,1-.38,6.75,6.75,0,0,1,1.19-.11,5.69,5.69,0,0,1,1.18.12,3.28,3.28,0,0,1,1,.39,2.37,2.37,0,0,1,.75.73,2.6,2.6,0,0,1,.35,1.15h-2a.9.9,0,0,0-.45-.8,2.06,2.06,0,0,0-1-.21l-.37,0a1.34,1.34,0,0,0-.37.1.66.66,0,0,0-.29.21.57.57,0,0,0-.11.36.56.56,0,0,0,.2.45,1.66,1.66,0,0,0,.53.29c.22.07.47.13.75.19l.85.19a8,8,0,0,1,.87.25,2.58,2.58,0,0,1,.76.38,1.9,1.9,0,0,1,.54.59,1.82,1.82,0,0,1,.2.88,2.5,2.5,0,0,1-.29,1.24,2.39,2.39,0,0,1-.78.8,3.4,3.4,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,6.78,6.78,0,0,1-1.28-.13,3.26,3.26,0,0,1-1.1-.44,2.45,2.45,0,0,1-.8-.8,2.49,2.49,0,0,1-.34-1.25h2A1.15,1.15,0,0,0,1408.06,581.72Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1414.15,578.22a3.49,3.49,0,0,1,.78-1.25,3.58,3.58,0,0,1,1.21-.79,4.56,4.56,0,0,1,3.2,0,3.62,3.62,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.53,3.53,0,0,1-.78,1.24,3.45,3.45,0,0,1-1.22.78,4.71,4.71,0,0,1-3.2,0,3.41,3.41,0,0,1-1.21-.78,3.53,3.53,0,0,1-.78-1.24,4.86,4.86,0,0,1,0-3.25Zm3.08-5v1.69h-2v-1.69Zm-1.22,7.47a2.38,2.38,0,0,0,.3.77,1.53,1.53,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.67,1.67,0,0,0,.87-.2,1.48,1.48,0,0,0,.57-.54,2.16,2.16,0,0,0,.29-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2,2,0,0,0-.29-.76,1.58,1.58,0,0,0-.57-.55,1.67,1.67,0,0,0-.87-.21,1.58,1.58,0,0,0-1.43.76,2.24,2.24,0,0,0-.3.76,5.26,5.26,0,0,0-.08.89A4.94,4.94,0,0,0,1416,580.72Zm4.2-7.47v1.69h-2v-1.69Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1424.9,576.1v1h0a2.44,2.44,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1A5.51,5.51,0,0,1,1430,579v4.6h-2.06v-4.23a2.68,2.68,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.45,1.4,1.4,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1433.64,573.25v10.33h-2.05V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1435.32,574.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1442.57,577.44a1.42,1.42,0,0,0-.82.23,1.67,1.67,0,0,0-.54.58,2.44,2.44,0,0,0-.3.78,4.09,4.09,0,0,0-.09.85,3.88,3.88,0,0,0,.09.82,2.65,2.65,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.48,1.48,0,0,0,.81.21,1.5,1.5,0,0,0,1.14-.41,2,2,0,0,0,.5-1.11h2A3.4,3.4,0,0,1,1445,583a3.75,3.75,0,0,1-2.45.78,4.26,4.26,0,0,1-1.54-.28,3.4,3.4,0,0,1-1.19-.79,3.53,3.53,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,5,5,0,0,1,.25-1.62,3.77,3.77,0,0,1,.74-1.28,3.28,3.28,0,0,1,1.21-.85,4.1,4.1,0,0,1,1.62-.31,4.55,4.55,0,0,1,1.28.18,3.3,3.3,0,0,1,1.1.53,2.91,2.91,0,0,1,.79.88,2.73,2.73,0,0,1,.34,1.25h-2A1.38,1.38,0,0,0,1442.57,577.44Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1449.36,573.25v3.89h0a2.46,2.46,0,0,1,1-.95,2.69,2.69,0,0,1,1.19-.3,3.45,3.45,0,0,1,1.35.23,2.15,2.15,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.12,6.12,0,0,1,.13,1.27v4.6h-2.06v-4.23A2.68,2.68,0,0,0,1452,578a1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1458.13,573.25v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.73H1461l-2-3.34-.8.76v2.58h-2.05V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1466.4,581.79a1.85,1.85,0,0,0,1.33.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,3.92,3.92,0,0,1-2.18.58,4.5,4.5,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.68,4.68,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.31,3.64,3.64,0,0,1,1.67.37,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,1466.4,581.79Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.35,2.35,0,0,0,1468.72,577.85Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1472.56,574.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1480.35,576.1v1.37h-1.51v3.71a.71.71,0,0,0,.87.87l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.62a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.24,1.24,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42h-1.25V576.1h1.25v-2.25h2v2.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1487.41,573.25V577h0a2.18,2.18,0,0,1,1-.85,3.45,3.45,0,0,1,1.34-.27,2.74,2.74,0,0,1,1.11.24,2.71,2.71,0,0,1,1,.71,3.37,3.37,0,0,1,.72,1.22,5.22,5.22,0,0,1,.28,1.77,5.13,5.13,0,0,1-.28,1.77,3.51,3.51,0,0,1-.72,1.23,2.83,2.83,0,0,1-1,.71,2.9,2.9,0,0,1-1.11.23,4.07,4.07,0,0,1-1.48-.26,1.89,1.89,0,0,1-1-.89h0v1h-2V573.25Zm3.33,5.68a2.26,2.26,0,0,0-.32-.76,1.61,1.61,0,0,0-.54-.53,1.64,1.64,0,0,0-1.57,0,1.64,1.64,0,0,0-.55.53,2.26,2.26,0,0,0-.32.76,3.93,3.93,0,0,0-.1.92,3.74,3.74,0,0,0,.1.89,2.32,2.32,0,0,0,.32.77,1.64,1.64,0,0,0,.55.53,1.72,1.72,0,0,0,1.57,0,1.61,1.61,0,0,0,.54-.53,2.32,2.32,0,0,0,.32-.77,3.74,3.74,0,0,0,.1-.89A3.93,3.93,0,0,0,1490.74,578.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1496.4,581.79a1.87,1.87,0,0,0,1.33.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.7,3.7,0,0,1-.76-1.24,4.78,4.78,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.73,3.73,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.43,3.43,0,0,1,1.17,1,4.16,4.16,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67h-5.4A2.1,2.1,0,0,0,1496.4,581.79Zm2.32-3.94a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.35A2.35,2.35,0,0,0,1498.72,577.85Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1501.72,577.47V576.1H1503v-.58a2.23,2.23,0,0,1,.62-1.64,2.53,2.53,0,0,1,1.88-.63,5.06,5.06,0,0,1,.55,0l.53,0v1.54a6,6,0,0,0-.75-.05.79.79,0,0,0-.6.2,1,1,0,0,0-.18.66v.45h1.42v1.37H1505v6.11h-2v-6.11Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1509.28,576.1v1.39h0a2.88,2.88,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.46,2.46,0,0,1,.76-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35,0a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2.05V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1514.9,581.79a1.85,1.85,0,0,0,1.33.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,3.92,3.92,0,0,1-2.18.58,4.5,4.5,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.54,4.54,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.9,3.9,0,0,1,1.55-.31,3.64,3.64,0,0,1,1.67.37,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,1514.9,581.79Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.35,2.35,0,0,0,1517.22,577.85Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1521.06,574.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1526.94,581.79a1.87,1.87,0,0,0,1.33.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,3.91,3.91,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.84,4.84,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.43,3.43,0,0,1,1.17,1,4,4,0,0,1,.65,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,1526.94,581.79Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.26,2.26,0,0,0,1529.26,577.85Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1535,576.1v1h0a2.42,2.42,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92H1533V576.1Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1544,581.35v2.23a2.27,2.27,0,0,1-.18.91,2.2,2.2,0,0,1-.49.71,2.59,2.59,0,0,1-.71.5,3.33,3.33,0,0,1-.88.27v-1a1.12,1.12,0,0,0,.42-.18,1.43,1.43,0,0,0,.34-.31,1.39,1.39,0,0,0,.21-.4,1.22,1.22,0,0,0,.06-.46h-1v-2.23Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1552.16,573.25v8.42h5v1.91h-7.31V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1560.75,573.25v10.33h-2.28V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1565,583.58l-3.35-10.33H1564l2.31,7.26h0l2.33-7.26H1571l-3.43,10.33Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1579.59,573.25v1.91h-5.46v2.21h5v1.77h-5v2.53h5.58v1.91h-7.85V573.25Z"
          transform="translate(-62.75 -75.53)"
        />
      </g>
    </g>
    {/* der innere kompass */}
    <a href="https://rothcoaching-sources.com/de/workshops/single/der_innere_kompass">
      <g id="der-innere-kompass">
        <rect
          class="bc2d8202-7a69-4614-b4f2-859f61ae9564"
          x="1261.99"
          y="897.29"
          width="385.73"
          height="67.26"
        />
        <g>
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1354,988.57a9.22,9.22,0,0,1,3.15.53,6.87,6.87,0,0,1,2.53,1.62,7.47,7.47,0,0,1,1.66,2.7,10.7,10.7,0,0,1,.61,3.8,12.34,12.34,0,0,1-.49,3.53,7.92,7.92,0,0,1-1.49,2.8,7,7,0,0,1-2.48,1.85,8.36,8.36,0,0,1-3.49.68h-7.55V988.57Zm-.27,14.27a4.92,4.92,0,0,0,1.62-.27,3.58,3.58,0,0,0,1.4-.9,4.63,4.63,0,0,0,1-1.63,7.32,7.32,0,0,0,.36-2.45,10.32,10.32,0,0,0-.25-2.39,4.83,4.83,0,0,0-.85-1.83,3.77,3.77,0,0,0-1.56-1.16,6.17,6.17,0,0,0-2.39-.41h-2.74v11Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1377.71,988.57v3.23h-9.24v3.75H1377v3h-8.48v4.29h9.44v3.24h-13.29V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1390,988.57a5.49,5.49,0,0,1,2.12.38,4.85,4.85,0,0,1,1.62,1,4.54,4.54,0,0,1,1,1.53,4.88,4.88,0,0,1,.36,1.88,5.28,5.28,0,0,1-.65,2.67,4.15,4.15,0,0,1-2.13,1.72v0a3.07,3.07,0,0,1,1.18.61,3.19,3.19,0,0,1,.76.95,4.41,4.41,0,0,1,.43,1.22,12,12,0,0,1,.19,1.32c0,.28,0,.61,0,1s0,.76.09,1.15a7.44,7.44,0,0,0,.2,1.12,2.65,2.65,0,0,0,.4.9h-3.85a7,7,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.47,3.47,0,0,0-.84-2,2.88,2.88,0,0,0-2.08-.64h-3.85v6.85h-3.85V988.57Zm-1.37,7.92a2.91,2.91,0,0,0,2-.59,2.44,2.44,0,0,0,.67-1.91,2.35,2.35,0,0,0-.67-1.86,3,3,0,0,0-2-.57h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1408.88,988.57v17.51H1405V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1416.09,988.57l7.31,11.74h0V988.57h3.6v17.51h-3.85l-7.28-11.73h-.05v11.73h-3.6V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1434.26,988.57l7.31,11.74h0V988.57h3.61v17.51h-3.85l-7.29-11.73h0v11.73h-3.6V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1461.71,988.57v3.23h-9.25v3.75H1461v3h-8.49v4.29h9.44v3.24h-13.29V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1473.94,988.57a5.49,5.49,0,0,1,2.12.38,4.85,4.85,0,0,1,1.62,1,4.54,4.54,0,0,1,1,1.53,4.88,4.88,0,0,1,.36,1.88,5.28,5.28,0,0,1-.65,2.67,4.16,4.16,0,0,1-2.12,1.72v0a3.1,3.1,0,0,1,1.17.61,3.19,3.19,0,0,1,.76.95,4.41,4.41,0,0,1,.43,1.22,12,12,0,0,1,.19,1.32c0,.28,0,.61.05,1s0,.76.08,1.15a7.44,7.44,0,0,0,.2,1.12,2.65,2.65,0,0,0,.4.9h-3.85a7,7,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.53,3.53,0,0,0-.83-2,2.9,2.9,0,0,0-2.09-.64h-3.85v6.85h-3.85V988.57Zm-1.37,7.92a3,3,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.35,2.35,0,0,0-.66-1.86,3,3,0,0,0-2-.57h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1495.3,988.57v3.23h-9.24v3.75h8.48v3h-8.48v4.29h9.44v3.24h-13.29V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1508.76,988.57v7.25l6.85-7.25h4.8l-6.84,6.91,7.51,10.6h-4.84L1511,998.2l-2.21,2.23v5.65h-3.84V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1522.45,993.75a8.86,8.86,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,7.7,7.7,0,0,1,2.69,2,8.88,8.88,0,0,1,1.7,2.93,11,11,0,0,1,.59,3.64,10.61,10.61,0,0,1-.59,3.57,8.45,8.45,0,0,1-1.7,2.88,7.75,7.75,0,0,1-2.69,1.93,9.74,9.74,0,0,1-7.25,0,7.83,7.83,0,0,1-2.71-1.93,8.43,8.43,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57A11,11,0,0,1,1522.45,993.75Zm3.52,5.79a5.78,5.78,0,0,0,.82,1.88,4.27,4.27,0,0,0,1.47,1.33,5.11,5.11,0,0,0,4.41,0,4.22,4.22,0,0,0,1.48-1.33,6,6,0,0,0,.82-1.88,9,9,0,0,0,.26-2.15,9.65,9.65,0,0,0-.26-2.23,5.93,5.93,0,0,0-.82-1.92,4.09,4.09,0,0,0-1.48-1.35,5.11,5.11,0,0,0-4.41,0,4.14,4.14,0,0,0-1.47,1.35,5.72,5.72,0,0,0-.82,1.92,9.65,9.65,0,0,0-.26,2.23A9,9,0,0,0,1526,999.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1547.12,988.57l4.09,12h0l3.88-12h5.42v17.51H1557V993.67h0l-4.29,12.41h-3l-4.29-12.29h0v12.29h-3.6V988.57Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1571.84,988.57a7.24,7.24,0,0,1,2.79.47,5.16,5.16,0,0,1,1.88,1.27,4.84,4.84,0,0,1,1,1.79,6.5,6.5,0,0,1,0,4.15,4.92,4.92,0,0,1-1,1.81,5.26,5.26,0,0,1-1.88,1.26,7.23,7.23,0,0,1-2.79.48h-4v6.28h-3.85V988.57Zm-1.06,8.23a8.77,8.77,0,0,0,1.28-.09,3.21,3.21,0,0,0,1.08-.38,2.14,2.14,0,0,0,.75-.8,2.88,2.88,0,0,0,.28-1.35,2.94,2.94,0,0,0-.28-1.35,2,2,0,0,0-.75-.79,2.83,2.83,0,0,0-1.08-.38,7.55,7.55,0,0,0-1.28-.1h-3v5.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1587.21,988.57l6.55,17.51h-4l-1.33-3.9h-6.55l-1.37,3.9h-3.88l6.63-17.51Zm.23,10.74-2.21-6.43h0l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1598.23,1001.76a2.71,2.71,0,0,0,.84,1,3.72,3.72,0,0,0,1.24.57,5.92,5.92,0,0,0,1.47.18,7.29,7.29,0,0,0,1.1-.09,3.41,3.41,0,0,0,1.11-.33,2.36,2.36,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.52,1.52,0,0,0-.45-1.15,3.5,3.5,0,0,0-1.19-.74,11.79,11.79,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.28,6.28,0,0,1-1.67-.92,4.19,4.19,0,0,1-1.19-1.41,4.27,4.27,0,0,1-.45-2,4.67,4.67,0,0,1,.57-2.35,5.3,5.3,0,0,1,1.51-1.65,6.59,6.59,0,0,1,2.11-1,8.71,8.71,0,0,1,2.36-.32,10.82,10.82,0,0,1,2.63.31,6.54,6.54,0,0,1,2.25,1,5,5,0,0,1,1.55,1.75,5.33,5.33,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.25,2.25,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.2,4.2,0,0,0-.93.1,2.4,2.4,0,0,0-.84.34,2.16,2.16,0,0,0-.63.61,1.7,1.7,0,0,0-.24.94,1.57,1.57,0,0,0,.19.83,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.69c.33.06.78.18,1.36.35a6.63,6.63,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.29,4.29,0,0,1,.63,2.42,5.37,5.37,0,0,1-.47,2.23,4.93,4.93,0,0,1-1.38,1.78,7,7,0,0,1-2.28,1.16,10.75,10.75,0,0,1-3.16.42,10.86,10.86,0,0,1-2.8-.36,7.1,7.1,0,0,1-2.41-1.11,5.64,5.64,0,0,1-1.65-1.94,5.76,5.76,0,0,1-.59-2.8h3.73A3.14,3.14,0,0,0,1598.23,1001.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1614.14,1001.76a2.64,2.64,0,0,0,.85,1,3.63,3.63,0,0,0,1.24.57,5.92,5.92,0,0,0,1.47.18,7.29,7.29,0,0,0,1.1-.09,3.42,3.42,0,0,0,1.1-.33,2.28,2.28,0,0,0,.86-.67,1.7,1.7,0,0,0,.35-1.09,1.53,1.53,0,0,0-.46-1.15,3.4,3.4,0,0,0-1.19-.74,11.4,11.4,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.88-.49a15.83,15.83,0,0,1-1.92-.6,6.22,6.22,0,0,1-1.66-.92,4.15,4.15,0,0,1-1.65-3.45,4.68,4.68,0,0,1,.58-2.35,5.18,5.18,0,0,1,1.51-1.65,6.49,6.49,0,0,1,2.11-1,8.64,8.64,0,0,1,2.35-.32,10.85,10.85,0,0,1,2.64.31,6.41,6.41,0,0,1,2.24,1,5.15,5.15,0,0,1,1.56,1.75,5.44,5.44,0,0,1,.57,2.59h-3.72a3.35,3.35,0,0,0-.33-1.3,2.25,2.25,0,0,0-.75-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.53,2.53,0,0,0-.85.34,2.25,2.25,0,0,0-.62.61,1.63,1.63,0,0,0-.25.94,1.57,1.57,0,0,0,.2.83,1.78,1.78,0,0,0,.77.59,9.6,9.6,0,0,0,1.59.54l2.66.69c.33.06.79.18,1.37.35a6.58,6.58,0,0,1,1.72.82,5.23,5.23,0,0,1,1.49,1.51,4.37,4.37,0,0,1,.62,2.42,5.36,5.36,0,0,1-.46,2.23,5,5,0,0,1-1.39,1.78,6.79,6.79,0,0,1-2.28,1.16,10.73,10.73,0,0,1-3.15.42,10.93,10.93,0,0,1-2.81-.36,7,7,0,0,1-2.4-1.11,5.56,5.56,0,0,1-1.66-1.94,5.87,5.87,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,1614.14,1001.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1350.91,1013.11l3.86,10.33h-2.36l-.78-2.3h-3.87l-.81,2.3h-2.28l3.91-10.33Zm.13,6.34-1.31-3.79h0l-1.34,3.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1360.52,1023.44v-1h0a2.33,2.33,0,0,1-1,.94,2.9,2.9,0,0,1-1.27.29,3.52,3.52,0,0,1-1.36-.22,1.94,1.94,0,0,1-.83-.61,2.61,2.61,0,0,1-.43-1,6.16,6.16,0,0,1-.12-1.27V1016h2.06v4.23a2.69,2.69,0,0,0,.28,1.38,1.14,1.14,0,0,0,1,.45,1.43,1.43,0,0,0,1.22-.49,2.83,2.83,0,0,0,.37-1.65V1016h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1365.76,1021.58a1.12,1.12,0,0,0,.36.39,1.35,1.35,0,0,0,.5.23,2.2,2.2,0,0,0,.58.07,2.49,2.49,0,0,0,.46,0,1.47,1.47,0,0,0,.43-.16,1,1,0,0,0,.33-.29.8.8,0,0,0,.13-.45c0-.31-.21-.54-.62-.7a12.37,12.37,0,0,0-1.71-.46c-.3-.07-.59-.15-.88-.24a3,3,0,0,1-.76-.36,1.7,1.7,0,0,1-.53-.57,1.53,1.53,0,0,1-.21-.83,2.25,2.25,0,0,1,.29-1.19,2.07,2.07,0,0,1,.74-.73,3.22,3.22,0,0,1,1-.37,5.92,5.92,0,0,1,1.19-.11,6.47,6.47,0,0,1,1.18.11,3.28,3.28,0,0,1,1,.39,2.41,2.41,0,0,1,.75.74,2.52,2.52,0,0,1,.35,1.15h-2a.91.91,0,0,0-.45-.81,2.06,2.06,0,0,0-1-.21l-.37,0a1.34,1.34,0,0,0-.37.1.74.74,0,0,0-.28.21.53.53,0,0,0-.12.37.59.59,0,0,0,.2.45,1.65,1.65,0,0,0,.53.28,5.84,5.84,0,0,0,.75.19l.85.19a8.84,8.84,0,0,1,.88.25,2.85,2.85,0,0,1,.76.38,1.8,1.8,0,0,1,.53.6,1.65,1.65,0,0,1,.2.87,2.47,2.47,0,0,1-.29,1.24,2.39,2.39,0,0,1-.78.8,3.19,3.19,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,6,6,0,0,1-1.28-.13,3.46,3.46,0,0,1-1.1-.43,2.4,2.4,0,0,1-1.14-2.06h1.95A1.15,1.15,0,0,0,1365.76,1021.58Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1373.76,1016v1.39h0a2.12,2.12,0,0,1,.39-.64,2.47,2.47,0,0,1,.56-.51,3,3,0,0,1,.68-.33,2.74,2.74,0,0,1,.76-.11,1.48,1.48,0,0,1,.44.07v1.91l-.34,0-.39,0a2.13,2.13,0,0,0-1,.19,1.58,1.58,0,0,0-.63.51,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1377.49,1014.8v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1384.74,1017.31a1.44,1.44,0,0,0-.82.22,1.77,1.77,0,0,0-.54.58,2.63,2.63,0,0,0-.3.78,4.17,4.17,0,0,0-.09.85,3.88,3.88,0,0,0,.09.82,2.54,2.54,0,0,0,.28.76,1.58,1.58,0,0,0,.53.56,1.48,1.48,0,0,0,.81.22,1.51,1.51,0,0,0,1.14-.42,2,2,0,0,0,.5-1.1h2a3.4,3.4,0,0,1-1.16,2.27,3.75,3.75,0,0,1-2.45.78,4.09,4.09,0,0,1-1.54-.28,3.49,3.49,0,0,1-1.19-.79,3.68,3.68,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,4.9,4.9,0,0,1,.25-1.61,3.59,3.59,0,0,1,.75-1.29,3.27,3.27,0,0,1,1.2-.85,4.11,4.11,0,0,1,1.62-.3,4.94,4.94,0,0,1,1.28.17,3.4,3.4,0,0,1,1.1.53,2.73,2.73,0,0,1,1.14,2.13h-2A1.37,1.37,0,0,0,1384.74,1017.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1391.53,1013.11V1017h0a2.43,2.43,0,0,1,1-1,2.84,2.84,0,0,1,1.19-.29,3.46,3.46,0,0,1,1.35.22,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.63,5.63,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.69,2.69,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1402.17,1016v1.37h-1.51V1021a1,1,0,0,0,.18.7,1,1,0,0,0,.69.17l.34,0,.3,0v1.59a4.71,4.71,0,0,1-.58.06l-.62,0a6.59,6.59,0,0,1-.91-.07,2.21,2.21,0,0,1-.75-.25,1.33,1.33,0,0,1-.51-.54,1.84,1.84,0,0,1-.19-.91v-4.42h-1.25V1016h1.25v-2.24h2.05V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1408.22,1023.44v-1h0a2.3,2.3,0,0,1-1,.94,2.87,2.87,0,0,1-1.27.29,3.46,3.46,0,0,1-1.35-.22,2,2,0,0,1-.84-.61,2.45,2.45,0,0,1-.42-1,5.51,5.51,0,0,1-.13-1.27V1016h2.06v4.23a2.58,2.58,0,0,0,.29,1.38,1.12,1.12,0,0,0,1,.45,1.41,1.41,0,0,0,1.21-.49,2.83,2.83,0,0,0,.38-1.65V1016h2.05v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1413.69,1016v1h0a2.39,2.39,0,0,1,1-1,3,3,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.27,6.27,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.69,2.69,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1427.39,1024a2.48,2.48,0,0,1-.53,1.08,3.17,3.17,0,0,1-1.17.85,5.09,5.09,0,0,1-2.06.35,4.91,4.91,0,0,1-1.12-.14,3.56,3.56,0,0,1-1-.43,2.62,2.62,0,0,1-.78-.74,2.18,2.18,0,0,1-.36-1.1h2a1.19,1.19,0,0,0,.57.81,2,2,0,0,0,1,.22,1.46,1.46,0,0,0,1.26-.52,2,2,0,0,0,.39-1.32v-1h0a2,2,0,0,1-1,.87,3,3,0,0,1-1.29.29,3.23,3.23,0,0,1-1.45-.3,2.75,2.75,0,0,1-1-.81,3.34,3.34,0,0,1-.57-1.2,5.78,5.78,0,0,1-.18-1.46,4.59,4.59,0,0,1,.21-1.39,3.62,3.62,0,0,1,.61-1.19,3,3,0,0,1,1-.82,3,3,0,0,1,1.38-.3,3,3,0,0,1,1.3.27,2.25,2.25,0,0,1,.93.93h0v-1h2v7A5.09,5.09,0,0,1,1427.39,1024Zm-2.82-2.49a1.46,1.46,0,0,0,.55-.46,1.86,1.86,0,0,0,.33-.66,2.76,2.76,0,0,0,.11-.77,4.17,4.17,0,0,0-.09-.88,2.15,2.15,0,0,0-.29-.73,1.52,1.52,0,0,0-.53-.51,1.8,1.8,0,0,0-.84-.18,1.49,1.49,0,0,0-.75.17,1.56,1.56,0,0,0-.52.47,2,2,0,0,0-.3.68,3,3,0,0,0-.1.8,4.45,4.45,0,0,0,.08.81,2.34,2.34,0,0,0,.28.71,1.49,1.49,0,0,0,.51.52,1.42,1.42,0,0,0,.8.21A1.55,1.55,0,0,0,1424.57,1021.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1438.25,1023.44v-1h0a2.33,2.33,0,0,1-1,.94,2.92,2.92,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.32,2.32,0,0,1-.43-1,6.16,6.16,0,0,1-.12-1.27V1016h2v4.23a2.68,2.68,0,0,0,.29,1.38,1.13,1.13,0,0,0,1,.45,1.41,1.41,0,0,0,1.21-.49,2.83,2.83,0,0,0,.38-1.65V1016h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1443.72,1016v1h0a2.36,2.36,0,0,1,1-1,3,3,0,0,1,1.27-.29,3.55,3.55,0,0,1,1.36.22,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.3,6.3,0,0,1,.12,1.27v4.6h-2.06v-4.22a2.59,2.59,0,0,0-.29-1.39,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1455.66,1022.49a2,2,0,0,1-1,.87,3.11,3.11,0,0,1-1.32.27,3.32,3.32,0,0,1-1.48-.32,3.12,3.12,0,0,1-1.05-.89,4.12,4.12,0,0,1-.62-1.28,5.33,5.33,0,0,1-.21-1.5,5.05,5.05,0,0,1,.21-1.46,3.9,3.9,0,0,1,.62-1.24,3.24,3.24,0,0,1,1-.86,3.16,3.16,0,0,1,1.45-.32,2.9,2.9,0,0,1,1.26.28,2.26,2.26,0,0,1,1,.83h0v-3.76h2.06v10.33h-2v-1Zm-.08-3.7a2.19,2.19,0,0,0-.3-.75,1.61,1.61,0,0,0-.54-.53,1.58,1.58,0,0,0-.84-.2,1.64,1.64,0,0,0-.86.2,1.47,1.47,0,0,0-.55.53,2.43,2.43,0,0,0-.31.76,4.41,4.41,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.72,2.72,0,0,0,.32.78,1.87,1.87,0,0,0,.57.55,1.54,1.54,0,0,0,.82.21,1.56,1.56,0,0,0,.84-.21,1.5,1.5,0,0,0,.55-.54,2.62,2.62,0,0,0,.29-.77,5.29,5.29,0,0,0,.08-.9A5.08,5.08,0,0,0,1455.58,1018.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1465.17,1020.9a1.66,1.66,0,0,0,.5.58,2.12,2.12,0,0,0,.73.33,3.15,3.15,0,0,0,.87.11,3.77,3.77,0,0,0,.65,0,2.33,2.33,0,0,0,.65-.19,1.44,1.44,0,0,0,.51-.4,1,1,0,0,0,.2-.64.9.9,0,0,0-.27-.68,2,2,0,0,0-.7-.44,7.12,7.12,0,0,0-1-.3l-1.12-.29a11.4,11.4,0,0,1-1.13-.36,4,4,0,0,1-1-.54,2.56,2.56,0,0,1-.7-.83,2.52,2.52,0,0,1-.27-1.21,2.67,2.67,0,0,1,.34-1.38,3.09,3.09,0,0,1,.89-1,4,4,0,0,1,1.25-.58,5.48,5.48,0,0,1,1.39-.19,6.58,6.58,0,0,1,1.55.18,4.12,4.12,0,0,1,1.33.59,3,3,0,0,1,.91,1,3.08,3.08,0,0,1,.34,1.52h-2.2a1.75,1.75,0,0,0-.19-.77,1.25,1.25,0,0,0-.44-.47,1.93,1.93,0,0,0-.63-.25,3.84,3.84,0,0,0-.78-.07,2.64,2.64,0,0,0-.55.06,1.46,1.46,0,0,0-.5.2,1.37,1.37,0,0,0-.36.36,1,1,0,0,0-.15.55.89.89,0,0,0,.12.49,1,1,0,0,0,.45.35,5.92,5.92,0,0,0,.94.32l1.57.4c.2,0,.46.11.81.21a4.32,4.32,0,0,1,1,.49,3,3,0,0,1,.87.89,2.51,2.51,0,0,1,.37,1.43,3.17,3.17,0,0,1-.27,1.31,2.89,2.89,0,0,1-.82,1,3.9,3.9,0,0,1-1.35.69,7,7,0,0,1-3.51,0,3.93,3.93,0,0,1-1.42-.65,3.22,3.22,0,0,1-1-1.15,3.41,3.41,0,0,1-.35-1.65h2.2A2,2,0,0,0,1465.17,1020.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1472.67,1014.8v-1.69h2v1.69Zm2,1.16v7.48h-2V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1478.3,1016v1h0a2.39,2.39,0,0,1,1-1,3,3,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.3,6.3,0,0,1,.12,1.27v4.6h-2v-4.22a2.69,2.69,0,0,0-.29-1.39,1.13,1.13,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1486.88,1016v1h0a2.44,2.44,0,0,1,1-1,3,3,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.63,5.63,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.69,2.69,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1497.59,1014.8v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1503.22,1016v1h0a2.44,2.44,0,0,1,1-1,3,3,0,0,1,1.27-.29,3.55,3.55,0,0,1,1.36.22,2,2,0,0,1,.83.62,2.45,2.45,0,0,1,.42,1,5.63,5.63,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.59,2.59,0,0,0-.29-1.39,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1515.6,1021.58a1.23,1.23,0,0,0,.36.39,1.35,1.35,0,0,0,.5.23,2.25,2.25,0,0,0,.58.07,2.49,2.49,0,0,0,.46,0,1.47,1.47,0,0,0,.43-.16,1,1,0,0,0,.33-.29.73.73,0,0,0,.13-.45c0-.31-.21-.54-.62-.7a12.37,12.37,0,0,0-1.71-.46c-.3-.07-.59-.15-.88-.24a3.13,3.13,0,0,1-.76-.36,1.7,1.7,0,0,1-.53-.57,1.53,1.53,0,0,1-.2-.83,2.26,2.26,0,0,1,.28-1.19,2.15,2.15,0,0,1,.74-.73,3.19,3.19,0,0,1,1-.37,5.76,5.76,0,0,1,1.18-.11,6.47,6.47,0,0,1,1.18.11,3.28,3.28,0,0,1,1,.39,2.41,2.41,0,0,1,.75.74,2.52,2.52,0,0,1,.35,1.15h-2a.91.91,0,0,0-.45-.81,2.06,2.06,0,0,0-1-.21l-.37,0a1.34,1.34,0,0,0-.37.1.74.74,0,0,0-.28.21.53.53,0,0,0-.12.37.59.59,0,0,0,.2.45,1.65,1.65,0,0,0,.53.28,5.84,5.84,0,0,0,.75.19l.85.19a8.84,8.84,0,0,1,.88.25,2.85,2.85,0,0,1,.76.38,1.8,1.8,0,0,1,.53.6,1.65,1.65,0,0,1,.21.87,2.26,2.26,0,0,1-1.08,2,3.11,3.11,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,5.88,5.88,0,0,1-1.27-.13,3.43,3.43,0,0,1-1.11-.43,2.4,2.4,0,0,1-1.14-2.06h2A1.14,1.14,0,0,0,1515.6,1021.58Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1525.76,1016v1.37h-1.51V1021a.69.69,0,0,0,.87.87l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06l-.63,0a6.44,6.44,0,0,1-.9-.07,2.21,2.21,0,0,1-.75-.25,1.42,1.42,0,0,1-.52-.54,2,2,0,0,1-.18-.91v-4.42H1521V1016h1.25v-2.24h2V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1531.81,1023.44v-1h0a2.33,2.33,0,0,1-1,.94,2.9,2.9,0,0,1-1.27.29,3.52,3.52,0,0,1-1.36-.22,1.94,1.94,0,0,1-.83-.61,2.61,2.61,0,0,1-.43-1,6.16,6.16,0,0,1-.12-1.27V1016h2.06v4.23a2.69,2.69,0,0,0,.28,1.38,1.14,1.14,0,0,0,1,.45,1.43,1.43,0,0,0,1.22-.49,2.83,2.83,0,0,0,.38-1.65V1016h2v7.48Zm-2.07-10.33v1.69h-2v-1.69Zm3,0v1.69h-2v-1.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1537.28,1016v1.39h0a2.12,2.12,0,0,1,.39-.64,2.47,2.47,0,0,1,.56-.51,2.85,2.85,0,0,1,.68-.33,2.67,2.67,0,0,1,.75-.11,1.5,1.5,0,0,1,.45.07v1.91l-.35,0-.39,0a2.12,2.12,0,0,0-1,.19,1.58,1.58,0,0,0-.63.51,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1543,1016v1h0a3,3,0,0,1,1-.89,2.55,2.55,0,0,1,1.31-.32,3,3,0,0,1,1.29.27,1.85,1.85,0,0,1,.89,1,3,3,0,0,1,.91-.87,2.4,2.4,0,0,1,1.35-.37,4.25,4.25,0,0,1,1.1.14,2.16,2.16,0,0,1,.87.47,2.07,2.07,0,0,1,.56.83,3.25,3.25,0,0,1,.21,1.24v5h-2.06v-4.24c0-.25,0-.49,0-.71a1.51,1.51,0,0,0-.16-.58,1,1,0,0,0-.38-.39,1.48,1.48,0,0,0-.69-.14,1.32,1.32,0,0,0-.7.16,1.26,1.26,0,0,0-.42.44,1.69,1.69,0,0,0-.2.61,3.82,3.82,0,0,0,0,.68v4.17h-2.06v-4.19c0-.23,0-.45,0-.66a1.88,1.88,0,0,0-.13-.6.93.93,0,0,0-.36-.45,1.38,1.38,0,0,0-.74-.16,1.69,1.69,0,0,0-.39.06,1.39,1.39,0,0,0-.46.25,1.33,1.33,0,0,0-.38.52,2.05,2.05,0,0,0-.16.89v4.34H1541V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1554.12,1014.8v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1559.53,1021.58a1.2,1.2,0,0,0,.35.39,1.52,1.52,0,0,0,.5.23,2.32,2.32,0,0,0,.59.07,2.58,2.58,0,0,0,.46,0,1.59,1.59,0,0,0,.43-.16,1,1,0,0,0,.33-.29.8.8,0,0,0,.13-.45c0-.31-.21-.54-.62-.7a12.37,12.37,0,0,0-1.71-.46c-.3-.07-.6-.15-.88-.24a3,3,0,0,1-.76-.36,1.84,1.84,0,0,1-.54-.57,1.62,1.62,0,0,1-.2-.83,2.35,2.35,0,0,1,.28-1.19,2.18,2.18,0,0,1,.75-.73,3.14,3.14,0,0,1,1-.37,5.86,5.86,0,0,1,1.19-.11,6.56,6.56,0,0,1,1.18.11,3.28,3.28,0,0,1,1,.39,2.28,2.28,0,0,1,.74.74,2.41,2.41,0,0,1,.36,1.15h-2a.88.88,0,0,0-.44-.81,2.06,2.06,0,0,0-1-.21l-.38,0a1.56,1.56,0,0,0-.37.1.83.83,0,0,0-.28.21.58.58,0,0,0-.11.37.56.56,0,0,0,.2.45,1.55,1.55,0,0,0,.53.28,5.66,5.66,0,0,0,.74.19l.86.19a8,8,0,0,1,.87.25,2.58,2.58,0,0,1,.76.38,1.94,1.94,0,0,1,.54.6,1.75,1.75,0,0,1,.2.87,2.37,2.37,0,0,1-.3,1.24,2.27,2.27,0,0,1-.77.8,3.19,3.19,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,6,6,0,0,1-1.28-.13,3.62,3.62,0,0,1-1.11-.43,2.55,2.55,0,0,1-.79-.81,2.49,2.49,0,0,1-.34-1.25h2A1.15,1.15,0,0,0,1559.53,1021.58Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1569.15,1017.31a1.45,1.45,0,0,0-.83.22,1.77,1.77,0,0,0-.54.58,2.63,2.63,0,0,0-.3.78,4.14,4.14,0,0,0-.08.85,3.86,3.86,0,0,0,.08.82,2.54,2.54,0,0,0,.28.76,1.58,1.58,0,0,0,.53.56,1.69,1.69,0,0,0,2-.2,2,2,0,0,0,.5-1.1h2a3.36,3.36,0,0,1-1.16,2.27,3.72,3.72,0,0,1-2.44.78,4,4,0,0,1-1.54-.28,3.44,3.44,0,0,1-1.2-.79,3.51,3.51,0,0,1-.76-1.21,4.06,4.06,0,0,1-.28-1.54,4.9,4.9,0,0,1,.25-1.61,3.59,3.59,0,0,1,.75-1.29,3.45,3.45,0,0,1,1.2-.85,4.11,4.11,0,0,1,1.62-.3,4.88,4.88,0,0,1,1.28.17,3.4,3.4,0,0,1,1.1.53,2.77,2.77,0,0,1,.79.88,2.9,2.9,0,0,1,.35,1.25h-2A1.38,1.38,0,0,0,1569.15,1017.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1575.93,1013.11V1017h0a2.4,2.4,0,0,1,1-1,2.77,2.77,0,0,1,1.18-.29,3.55,3.55,0,0,1,1.36.22,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.3,6.3,0,0,1,.12,1.27v4.6h-2.06v-4.22a2.59,2.59,0,0,0-.29-1.39,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1584.66,1021.65a1.83,1.83,0,0,0,1.33.45,1.85,1.85,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.42,3.42,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.57,3.57,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.3,3.54,3.54,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,4.21,4.21,0,0,1,.66,1.42,5.36,5.36,0,0,1,.14,1.66h-5.39A2,2,0,0,0,1584.66,1021.65Zm2.32-3.94a1.42,1.42,0,0,0-1.12-.4,1.85,1.85,0,0,0-.82.16,1.55,1.55,0,0,0-.52.42,1.36,1.36,0,0,0-.27.52,2.44,2.44,0,0,0-.09.49h3.34A2.39,2.39,0,0,0,1587,1017.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1592.72,1016v1h0a2.42,2.42,0,0,1,1-1,3.05,3.05,0,0,1,1.27-.29,3.55,3.55,0,0,1,1.36.22,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.3,6.3,0,0,1,.12,1.27v4.6h-2.06v-4.22a2.59,2.59,0,0,0-.29-1.39,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1608.47,1015h-5.11v-1.91h8.14v1.79l-5.55,6.63h5.69v1.91h-8.71v-1.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1615,1021.65a1.81,1.81,0,0,0,1.33.45,1.9,1.9,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.43,3.43,0,0,1-1.34,1.92,3.86,3.86,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.9,3.9,0,0,1,1.55-.3,3.52,3.52,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.36,5.36,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,1615,1021.65Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.46,1.46,0,0,0-.52.42,1.55,1.55,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49h3.34A2.39,2.39,0,0,0,1617.28,1017.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1621.12,1014.8v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1628.91,1016v1.37h-1.51V1021a1,1,0,0,0,.18.7,1,1,0,0,0,.69.17l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06l-.62,0a6.47,6.47,0,0,1-.91-.07,2.21,2.21,0,0,1-.75-.25,1.42,1.42,0,0,1-.52-.54,2,2,0,0,1-.18-.91v-4.42h-1.25V1016h1.25v-2.24h2.05V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1632.09,1021.65a1.83,1.83,0,0,0,1.33.45,1.87,1.87,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.42,3.42,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.37,3.37,0,0,1-1.2-.8,3.53,3.53,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.27-1.56,3.79,3.79,0,0,1,.79-1.26,3.63,3.63,0,0,1,1.2-.83,3.94,3.94,0,0,1,1.56-.3,3.48,3.48,0,0,1,1.66.37,3.13,3.13,0,0,1,1.17,1,4.21,4.21,0,0,1,.66,1.42,5.36,5.36,0,0,1,.14,1.66h-5.4A2.08,2.08,0,0,0,1632.09,1021.65Zm2.32-3.94a1.43,1.43,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.55,1.55,0,0,0-.52.42,1.36,1.36,0,0,0-.27.52,1.94,1.94,0,0,0-.1.49h3.35A2.39,2.39,0,0,0,1634.41,1017.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1640.15,1016v1h0a2.36,2.36,0,0,1,1-1,3,3,0,0,1,1.27-.29,3.55,3.55,0,0,1,1.36.22,2.11,2.11,0,0,1,.83.62,2.45,2.45,0,0,1,.42,1,5.63,5.63,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.59,2.59,0,0,0-.29-1.39,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V1016Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1649.15,1021.21v2.23a2.27,2.27,0,0,1-.18.91,2.36,2.36,0,0,1-1.2,1.21,3.15,3.15,0,0,1-.87.27v-1a1.32,1.32,0,0,0,.41-.17,1.72,1.72,0,0,0,.34-.32,1.2,1.2,0,0,0,.22-.4,1.19,1.19,0,0,0,.05-.46h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1657.32,1013.11v8.42h5v1.91H1655v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1665.9,1013.11v10.33h-2.27v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1670.14,1023.44l-3.34-10.33h2.33l2.3,7.27h0l2.33-7.27h2.34l-3.43,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1684.74,1013.11V1015h-5.45v2.21h5V1019h-5v2.53h5.57v1.91H1677v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </a>
    {/* grenzen setzen */}
    <a href="https://rothcoaching-sources.com/de/workshops/single/grenzen_setzen__r_ume_teilen">
      <g id="grenzen-setzen">
        <rect
          class="bc2d8202-7a69-4614-b4f2-859f61ae9564"
          x="1287.43"
          y="986.66"
          width="334.83"
          height="67.26"
        />
        <g>
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1382.27,1095.31a6.51,6.51,0,0,1-2.53.52,8.92,8.92,0,0,1-3.62-.7,7.68,7.68,0,0,1-2.71-1.92,8.62,8.62,0,0,1-1.69-2.88,10.7,10.7,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.65,7.65,0,0,1,2.71-2,8.76,8.76,0,0,1,3.62-.72,8.48,8.48,0,0,1,2.61.41,7.3,7.3,0,0,1,2.28,1.19,6.43,6.43,0,0,1,1.68,1.94,6.54,6.54,0,0,1,.81,2.64h-3.68a3.69,3.69,0,0,0-1.32-2.2,3.88,3.88,0,0,0-2.38-.74,4.57,4.57,0,0,0-2.21.5,4.33,4.33,0,0,0-1.47,1.35,6.08,6.08,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.85,5.85,0,0,0,.82,1.87,4.3,4.3,0,0,0,1.47,1.34,4.57,4.57,0,0,0,2.21.5,4.17,4.17,0,0,0,3-1,4.24,4.24,0,0,0,1.21-2.81H1380V1086h7.36v9.47h-2.45l-.39-2A5.86,5.86,0,0,1,1382.27,1095.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1400,1077.93a5.72,5.72,0,0,1,2.12.38,4.85,4.85,0,0,1,1.62,1,4.58,4.58,0,0,1,1,1.54,5,5,0,0,1,.35,1.87,5.24,5.24,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v0a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.69,4.69,0,0,1,.43,1.21,9.89,9.89,0,0,1,.18,1.33c0,.27,0,.6.05,1s0,.76.09,1.15a7.93,7.93,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.61,6.61,0,0,1-.4-2c0-.76-.12-1.5-.22-2.2a3.47,3.47,0,0,0-.83-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85v-17.51Zm-1.38,7.92a2.87,2.87,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.21v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1421.31,1077.93v3.24h-9.25v3.75h8.49v3h-8.49v4.29h9.44v3.24h-13.29v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1427.93,1077.93l7.31,11.75h0v-11.75h3.6v17.51H1435l-7.28-11.72h0v11.72h-3.61v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1450.54,1081.17h-8.66v-3.24h13.78v3l-9.39,11.23h9.64v3.24h-14.76v-3Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1471.26,1077.93v3.24H1462v3.75h8.49v3H1462v4.29h9.45v3.24h-13.29v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1477.88,1077.93l7.31,11.75h0v-11.75h3.6v17.51H1485l-7.28-11.72h0v11.72h-3.6v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1502,1091.12a2.83,2.83,0,0,0,.84,1,3.8,3.8,0,0,0,1.24.56,6,6,0,0,0,1.47.19,7.41,7.41,0,0,0,1.11-.09,4,4,0,0,0,1.1-.33,2.39,2.39,0,0,0,.86-.68,1.67,1.67,0,0,0,.34-1.09,1.55,1.55,0,0,0-.45-1.15,3.72,3.72,0,0,0-1.19-.74,13.32,13.32,0,0,0-1.67-.51c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.52,4.52,0,0,1-1.19-1.41,4.34,4.34,0,0,1-.45-2,4.51,4.51,0,0,1,.58-2.34,5.1,5.1,0,0,1,1.51-1.66,6.75,6.75,0,0,1,2.1-1,9.12,9.12,0,0,1,2.36-.32,11.34,11.34,0,0,1,2.63.31,6.85,6.85,0,0,1,2.25,1,5.18,5.18,0,0,1,1.56,1.76,5.4,5.4,0,0,1,.57,2.58h-3.73a3.07,3.07,0,0,0-.33-1.3,2.12,2.12,0,0,0-.74-.8,3.15,3.15,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,5.07,5.07,0,0,0-.94.09,2.41,2.41,0,0,0-.84.35,2.29,2.29,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.61,1.61,0,0,0,.19.84,1.76,1.76,0,0,0,.78.59,10.8,10.8,0,0,0,1.59.53l2.66.69c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.08,5.08,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.34,5.34,0,0,1-.47,2.23,4.85,4.85,0,0,1-1.38,1.78,6.63,6.63,0,0,1-2.28,1.17,11.09,11.09,0,0,1-3.15.41,11.39,11.39,0,0,1-2.81-.35,7,7,0,0,1-2.4-1.12,5.39,5.39,0,0,1-1.66-1.94,5.69,5.69,0,0,1-.59-2.79h3.73A3.19,3.19,0,0,0,1502,1091.12Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1528.06,1077.93v3.24h-9.25v3.75h8.49v3h-8.49v4.29h9.44v3.24H1515v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1529.48,1081.17v-3.24h14.34v3.24h-5.24v14.27h-3.85v-14.27Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1554.1,1081.17h-8.66v-3.24h13.78v3l-9.39,11.23h9.64v3.24h-14.76v-3Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1574.82,1077.93v3.24h-9.24v3.75h8.48v3h-8.48v4.29H1575v3.24h-13.29v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1581.44,1077.93l7.31,11.75h0v-11.75h3.6v17.51h-3.85l-7.28-11.72h0v11.72h-3.6v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1373.47,1102.47v4.29l4-4.29h2.84l-4,4.09,4.43,6.25h-2.85l-3.12-4.65-1.3,1.32v3.33h-2.27v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1383.39,1105.33v1.39h0a2.16,2.16,0,0,1,.39-.65,2.72,2.72,0,0,1,.56-.51,2.82,2.82,0,0,1,.68-.32,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35,0-.39,0a2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1389,1111a1.83,1.83,0,0,0,1.33.45,1.78,1.78,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.29,3.13,3.13,0,0,1-1.2-.8,3.64,3.64,0,0,1-.76-1.24,4.59,4.59,0,0,1-.27-1.6,4.45,4.45,0,0,1,.27-1.56,3.75,3.75,0,0,1,.79-1.25,3.57,3.57,0,0,1,1.21-.83,4,4,0,0,1,3.22.06,3.38,3.38,0,0,1,1.16,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.39A2,2,0,0,0,1389,1111Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.84,1.84,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.05,2.05,0,0,0-.09.49h3.34A2.35,2.35,0,0,0,1391.32,1107.08Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1395,1107.63a2.36,2.36,0,0,1,.36-1.2,2.51,2.51,0,0,1,.82-.77,3.44,3.44,0,0,1,1.1-.41,6.44,6.44,0,0,1,1.24-.13,8.74,8.74,0,0,1,1.14.08,3.85,3.85,0,0,1,1.06.31,2.06,2.06,0,0,1,.78.65,1.81,1.81,0,0,1,.31,1.09v3.89a7.49,7.49,0,0,0,.05,1,1.76,1.76,0,0,0,.21.7H1400a2.11,2.11,0,0,1-.09-.36,2.2,2.2,0,0,1-.05-.37,2.65,2.65,0,0,1-1.16.71,4.54,4.54,0,0,1-1.36.21,4,4,0,0,1-1-.13,2.38,2.38,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.38,2.38,0,0,1-.2-1,2.15,2.15,0,0,1,.23-1,1.76,1.76,0,0,1,.57-.66,2.72,2.72,0,0,1,.81-.37,7.52,7.52,0,0,1,.92-.19l.92-.12a5.42,5.42,0,0,0,.79-.13,1.41,1.41,0,0,0,.55-.25.55.55,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.3,1.3,0,0,0-.42-.15,4.12,4.12,0,0,0-.51,0,1.52,1.52,0,0,0-1,.26,1.16,1.16,0,0,0-.4.87Zm4.75,1.52a1.29,1.29,0,0,1-.33.18l-.42.11-.47.07-.49.07-.45.12a1.64,1.64,0,0,0-.4.19.86.86,0,0,0-.26.31,1,1,0,0,0-.1.48.92.92,0,0,0,.1.46.72.72,0,0,0,.27.3,1.25,1.25,0,0,0,.41.15,2.44,2.44,0,0,0,.48.05,1.79,1.79,0,0,0,.94-.21,1.33,1.33,0,0,0,.49-.48,1.6,1.6,0,0,0,.19-.57,3.16,3.16,0,0,0,0-.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1407.53,1105.33v1.37H1406v3.71a1,1,0,0,0,.18.69,1,1,0,0,0,.69.17h.33l.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.62a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.24,1.24,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42h-1.25v-1.37H1404v-2.25h2v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1408.57,1104.17v-1.7h2v1.7Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1414.1,1112.81l-2.56-7.48h2.15l1.58,5.11h0l1.58-5.11h2l-2.54,7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1422,1111a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.29,3.13,3.13,0,0,1-1.2-.8,3.64,3.64,0,0,1-.76-1.24,4.59,4.59,0,0,1-.27-1.6,4.45,4.45,0,0,1,.27-1.56,3.75,3.75,0,0,1,.79-1.25,3.63,3.63,0,0,1,1.2-.83,3.78,3.78,0,0,1,1.56-.31,3.6,3.6,0,0,1,1.66.37,3.33,3.33,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.07,2.07,0,0,0,1422,1111Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.84,2.84,0,0,0-.1.49h3.35A2.35,2.35,0,0,0,1424.29,1107.08Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1439.59,1102.47v1.92h-5v2.38h4.33v1.77h-4.33v4.27h-2.27v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1442.64,1105.33v1.39h0a2.16,2.16,0,0,1,.39-.65,2.72,2.72,0,0,1,.56-.51,3,3,0,0,1,.68-.32,2.42,2.42,0,0,1,.76-.12,1.52,1.52,0,0,1,.44.08v1.91l-.34,0-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1448.25,1111a1.86,1.86,0,0,0,1.34.45,1.82,1.82,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66H1453a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.18.58,4.25,4.25,0,0,1-1.59-.29,3.21,3.21,0,0,1-1.2-.8,3.64,3.64,0,0,1-.76-1.24,4.59,4.59,0,0,1-.27-1.6,4.45,4.45,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.77,3.77,0,0,1,1.55-.31,3.64,3.64,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.12,2.12,0,0,0,1448.25,1111Zm2.33-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.35,2.35,0,0,0,1450.58,1107.08Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1454.42,1104.17v-1.7h2.06v1.7Zm2.06,1.16v7.48h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1460.05,1105.33v1.39h0a2.67,2.67,0,0,1,.39-.65,2.54,2.54,0,0,1,.57-.51,2.75,2.75,0,0,1,.67-.32,2.46,2.46,0,0,1,.76-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35,0-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1464,1106.43a2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,9,9,0,0,1,1.14.08,3.85,3.85,0,0,1,1.06.31,2.06,2.06,0,0,1,.78.65,1.81,1.81,0,0,1,.3,1.09v3.89a7.43,7.43,0,0,0,.06,1,2,2,0,0,0,.2.7h-2.08c0-.12-.07-.23-.1-.36s0-.24,0-.37a2.56,2.56,0,0,1-1.15.71,4.54,4.54,0,0,1-1.36.21,4,4,0,0,1-1-.13,2.38,2.38,0,0,1-.81-.41,1.87,1.87,0,0,1-.55-.69,2.38,2.38,0,0,1-.19-1,2.15,2.15,0,0,1,.22-1,1.78,1.78,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37,7.52,7.52,0,0,1,.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.58.58,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.3,1.3,0,0,0-.42-.15,4.12,4.12,0,0,0-.51,0,1.52,1.52,0,0,0-1,.26,1.13,1.13,0,0,0-.4.87h-2.06A2.36,2.36,0,0,1,1464,1106.43Zm2.59-4v1.7h-1.95v-1.7Zm1.47,6.86-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19,1,1,0,0,0-.27.31,1.14,1.14,0,0,0-.1.48,1,1,0,0,0,.1.46.74.74,0,0,0,.28.3,1.25,1.25,0,0,0,.41.15,2.42,2.42,0,0,0,.47.05,1.75,1.75,0,0,0,.94-.21,1.36,1.36,0,0,0,.5-.48,1.6,1.6,0,0,0,.19-.57,3.16,3.16,0,0,0,0-.47v-.76A1.29,1.29,0,0,1,1468.06,1109.33Zm1.51-6.86v1.7h-2v-1.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1477.1,1112.81v-1h0a2.36,2.36,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.66,3.66,0,0,1-1.35-.22,2.06,2.06,0,0,1-.84-.61,2.45,2.45,0,0,1-.42-1,6.27,6.27,0,0,1-.13-1.27v-4.6h2.06v4.22a2.63,2.63,0,0,0,.29,1.38,1.1,1.1,0,0,0,1,.46,1.42,1.42,0,0,0,1.21-.5,2.77,2.77,0,0,0,.38-1.64v-3.92h2v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1482.61,1105.33v1h0a2.89,2.89,0,0,1,1-.9,2.67,2.67,0,0,1,1.31-.32,3,3,0,0,1,1.3.28,1.85,1.85,0,0,1,.89,1,3.12,3.12,0,0,1,.9-.87,2.4,2.4,0,0,1,1.35-.38,3.85,3.85,0,0,1,1.1.15,2.14,2.14,0,0,1,.87.46,2.19,2.19,0,0,1,.57.83,3.54,3.54,0,0,1,.2,1.24v5h-2.06v-4.24c0-.25,0-.49,0-.71a1.65,1.65,0,0,0-.15-.58,1,1,0,0,0-.39-.39,1.36,1.36,0,0,0-.69-.15,1.32,1.32,0,0,0-.7.17,1.23,1.23,0,0,0-.42.43,1.75,1.75,0,0,0-.2.61,5.34,5.34,0,0,0,0,.69v4.17h-2.06v-4.2c0-.22,0-.44,0-.66a2,2,0,0,0-.12-.6,1,1,0,0,0-.36-.44,1.39,1.39,0,0,0-.75-.17,1.63,1.63,0,0,0-.38.07,1.27,1.27,0,0,0-.47.25,1.43,1.43,0,0,0-.38.52,2,2,0,0,0-.16.89v4.34h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1495.93,1111a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.29,3.05,3.05,0,0,1-1.2-.8,3.49,3.49,0,0,1-.76-1.24,4.59,4.59,0,0,1-.27-1.6,4.45,4.45,0,0,1,.27-1.56,3.89,3.89,0,0,1,.78-1.25,3.8,3.8,0,0,1,2.77-1.14,3.6,3.6,0,0,1,1.66.37,3.42,3.42,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.07,2.07,0,0,0,1495.93,1111Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.13,2.13,0,0,0-.1.49h3.35A2.35,2.35,0,0,0,1498.25,1107.08Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1506.12,1104.17v-1.7h2.05v1.7Zm2.05,1.16v7.48h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1511.79,1105.33v1h0a2.89,2.89,0,0,1,1-.9,2.67,2.67,0,0,1,1.31-.32,2.9,2.9,0,0,1,1.29.28,1.81,1.81,0,0,1,.89,1,3.3,3.3,0,0,1,.91-.87,2.4,2.4,0,0,1,1.35-.38,3.85,3.85,0,0,1,1.1.15,2.14,2.14,0,0,1,.87.46,2.07,2.07,0,0,1,.56.83,3.32,3.32,0,0,1,.21,1.24v5h-2.06v-4.24c0-.25,0-.49,0-.71a1.46,1.46,0,0,0-.16-.58,1,1,0,0,0-.38-.39,1.36,1.36,0,0,0-.69-.15,1.32,1.32,0,0,0-.7.17,1.23,1.23,0,0,0-.42.43,1.75,1.75,0,0,0-.2.61,4,4,0,0,0-.05.69v4.17h-2.06v-4.2c0-.22,0-.44,0-.66a1.74,1.74,0,0,0-.13-.6.91.91,0,0,0-.36-.44,1.37,1.37,0,0,0-.74-.17,1.8,1.8,0,0,0-.39.07,1.23,1.23,0,0,0-.46.25,1.33,1.33,0,0,0-.38.52,2,2,0,0,0-.16.89v4.34h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1532.3,1102.47l3.87,10.34h-2.36l-.78-2.3h-3.87l-.81,2.3h-2.29l3.91-10.34Zm.13,6.34-1.3-3.79h0l-1.35,3.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1539,1102.47v10.34h-2.06v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1542.69,1102.47v10.34h-2v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1548.42,1105.33v1.37h-1.5v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.17h.33l.3,0v1.59a4.54,4.54,0,0,1-.57.06h-.63a6.37,6.37,0,0,1-.9-.06,2.08,2.08,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.19-.91v-4.42h-1.24v-1.37h1.24v-2.25h2.06v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1549.32,1107.63a2.36,2.36,0,0,1,.36-1.2,2.58,2.58,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,8.86,8.86,0,0,1,1.14.08,3.85,3.85,0,0,1,1.06.31,2.06,2.06,0,0,1,.78.65,1.81,1.81,0,0,1,.3,1.09v3.89a7.43,7.43,0,0,0,.06,1,2,2,0,0,0,.2.7h-2.08a2.11,2.11,0,0,1-.09-.36,2.2,2.2,0,0,1,0-.37,2.65,2.65,0,0,1-1.16.71,4.54,4.54,0,0,1-1.36.21,4,4,0,0,1-1-.13,2.38,2.38,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.38,2.38,0,0,1-.2-1,2.15,2.15,0,0,1,.22-1,1.87,1.87,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37,7.52,7.52,0,0,1,.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.41,1.41,0,0,0,.55-.25.55.55,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.3,1.3,0,0,0-.42-.15,4.12,4.12,0,0,0-.51,0,1.52,1.52,0,0,0-1,.26,1.16,1.16,0,0,0-.4.87Zm4.75,1.52a1.29,1.29,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.31,3.31,0,0,0-.45.12,1.35,1.35,0,0,0-.4.19.86.86,0,0,0-.26.31,1,1,0,0,0-.11.48.92.92,0,0,0,.11.46.72.72,0,0,0,.27.3,1.25,1.25,0,0,0,.41.15,2.42,2.42,0,0,0,.47.05,1.75,1.75,0,0,0,.94-.21,1.29,1.29,0,0,0,.5-.48,1.6,1.6,0,0,0,.19-.57,3.16,3.16,0,0,0,0-.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1564.79,1113.36a2.66,2.66,0,0,1-.53,1.08,3.15,3.15,0,0,1-1.17.84,5,5,0,0,1-2.06.35,4.84,4.84,0,0,1-1.12-.14,3.55,3.55,0,0,1-1-.42,2.7,2.7,0,0,1-.79-.75,2.23,2.23,0,0,1-.35-1.09h2a1.2,1.2,0,0,0,.57.8,2,2,0,0,0,1,.23,1.5,1.5,0,0,0,1.26-.52,2.09,2.09,0,0,0,.39-1.32v-1h0a2.07,2.07,0,0,1-1,.88,3,3,0,0,1-1.3.28,3.19,3.19,0,0,1-1.44-.3,2.56,2.56,0,0,1-1-.81,3.34,3.34,0,0,1-.57-1.2,5.24,5.24,0,0,1-.19-1.45,4.7,4.7,0,0,1,.21-1.4,3.59,3.59,0,0,1,.62-1.18,2.88,2.88,0,0,1,1-.82,2.92,2.92,0,0,1,1.38-.31,2.79,2.79,0,0,1,1.29.28,2.21,2.21,0,0,1,.94.93h0v-1h2v7A5.18,5.18,0,0,1,1564.79,1113.36Zm-2.82-2.49a1.65,1.65,0,0,0,.55-.46,2.27,2.27,0,0,0,.33-.66,2.88,2.88,0,0,0,.11-.78,4.07,4.07,0,0,0-.09-.87,2.28,2.28,0,0,0-.29-.73,1.47,1.47,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.49,1.49,0,0,0-1.26.65,2,2,0,0,0-.31.68,3.44,3.44,0,0,0-.09.8,4.26,4.26,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.61,1.61,0,0,0,.52.52,1.41,1.41,0,0,0,.79.2A1.59,1.59,0,0,0,1562,1110.87Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1572.78,1111a2.12,2.12,0,0,0,2.41.14,1.37,1.37,0,0,0,.55-.66h1.8a3.45,3.45,0,0,1-1.33,1.93,3.94,3.94,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.29,3.21,3.21,0,0,1-1.2-.8,3.64,3.64,0,0,1-.76-1.24,4.59,4.59,0,0,1-.27-1.6,4.24,4.24,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.93,3.93,0,0,1,4.43-.77,3.38,3.38,0,0,1,1.16,1,4.11,4.11,0,0,1,.66,1.42,5.13,5.13,0,0,1,.14,1.67h-5.39A2,2,0,0,0,1572.78,1111Zm2.33-3.93a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,1575.11,1107.08Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1580.84,1105.33v1.39h0a2.67,2.67,0,0,1,.39-.65,3.06,3.06,0,0,1,.57-.51,2.82,2.82,0,0,1,.68-.32,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35,0-.39,0a2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1586.58,1102.47v3.9h0a2.34,2.34,0,0,1,1-.95,2.65,2.65,0,0,1,1.18-.3,3.5,3.5,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.64,2.64,0,0,0-.28-1.38,1.15,1.15,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.81,2.81,0,0,0-.37,1.65v3.92h-2.06v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1593,1107.63a2.27,2.27,0,0,1,.36-1.2,2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.42,6.42,0,0,1,1.23-.13,9,9,0,0,1,1.15.08,3.78,3.78,0,0,1,1,.31,2.06,2.06,0,0,1,.78.65,1.74,1.74,0,0,1,.31,1.09v3.89a7.43,7.43,0,0,0,.06,1,1.77,1.77,0,0,0,.2.7H1598a2.12,2.12,0,0,1-.1-.36c0-.12,0-.24-.05-.37a2.6,2.6,0,0,1-1.16.71,4.49,4.49,0,0,1-1.36.21,4,4,0,0,1-1-.13,2.38,2.38,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.38,2.38,0,0,1-.19-1,2.15,2.15,0,0,1,.22-1,1.78,1.78,0,0,1,.58-.66,2.51,2.51,0,0,1,.81-.37,7.15,7.15,0,0,1,.92-.19l.91-.12a5.57,5.57,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.58.58,0,0,0,.18-.49,1.14,1.14,0,0,0-.1-.53.84.84,0,0,0-.29-.3,1.3,1.3,0,0,0-.42-.15,4.17,4.17,0,0,0-.52,0,1.51,1.51,0,0,0-1,.26,1.16,1.16,0,0,0-.41.87Zm4.74,1.52a1.07,1.07,0,0,1-.32.18l-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19.89.89,0,0,0-.37.79.92.92,0,0,0,.1.46.74.74,0,0,0,.28.3,1.19,1.19,0,0,0,.4.15,2.52,2.52,0,0,0,.48.05,1.77,1.77,0,0,0,.94-.21,1.26,1.26,0,0,0,.49-.48,1.62,1.62,0,0,0,.2-.57c0-.2,0-.35,0-.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1603.52,1102.47v10.34h-2v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1609.26,1105.33v1.37h-1.51v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.17h.33l.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.63a6.48,6.48,0,0,1-.9-.06,2.15,2.15,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.18-.91v-4.42h-1.25v-1.37h1.25v-2.25h2v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1612.44,1111a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.29,3.05,3.05,0,0,1-1.2-.8,3.49,3.49,0,0,1-.76-1.24,4.59,4.59,0,0,1-.27-1.6,4.45,4.45,0,0,1,.27-1.56,3.89,3.89,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.78,3.78,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.42,3.42,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.07,2.07,0,0,0,1612.44,1111Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.13,2.13,0,0,0-.1.49h3.35A2.35,2.35,0,0,0,1614.76,1107.08Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1620.5,1105.33v1h0a2.33,2.33,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.51,5.51,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.53,2.53,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.46,1.42,1.42,0,0,0-1.21.5,2.8,2.8,0,0,0-.38,1.65v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1629.5,1110.58v2.23a2.19,2.19,0,0,1-.18.9,2.27,2.27,0,0,1-.48.72,2.65,2.65,0,0,1-.72.5,3.26,3.26,0,0,1-.87.27v-1a1.14,1.14,0,0,0,.41-.18,1.67,1.67,0,0,0,.34-.31,1.23,1.23,0,0,0,.21-.41,1.14,1.14,0,0,0,.06-.45h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1637.67,1102.47v8.43h5v1.91h-7.31v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1646.25,1102.47v10.34H1644v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1650.49,1112.81l-3.34-10.34h2.33l2.3,7.27h0l2.33-7.27h2.34l-3.43,10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1665.09,1102.47v1.92h-5.45v2.21h5v1.77h-5v2.53h5.57v1.91h-7.85v-10.34Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </a>
    {/* champagne your ecasting */}
    <Link to={`${url}single/${urlFormat('Champagne Your (E-)Casting')}`}>
      <g id="champagne-your-ecasting">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="250.25"
          y="542.89"
          width="433.37"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M345.94,638.84a3.94,3.94,0,0,0-2-1.62,3.89,3.89,0,0,0-1.36-.23,4.46,4.46,0,0,0-2.21.5,4.14,4.14,0,0,0-1.47,1.35,5.72,5.72,0,0,0-.82,1.92,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.2,4.2,0,0,0,1.47,1.34,4.46,4.46,0,0,0,2.21.5,3.55,3.55,0,0,0,2.76-1.07,5,5,0,0,0,1.21-2.85h3.73a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.87,6.87,0,0,1-2.35,1.43,8.81,8.81,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93,8.43,8.43,0,0,1-1.69-2.88A10.61,10.61,0,0,1,334,643a11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.92,8.92,0,0,1,3.62-.71,8.76,8.76,0,0,1,2.73.42,7.28,7.28,0,0,1,2.31,1.21,6.54,6.54,0,0,1,1.68,2,7.06,7.06,0,0,1,.83,2.7h-3.73A3,3,0,0,0,345.94,638.84Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M356.73,634.17v6.71h7.09v-6.71h3.85v17.5h-3.85v-7.55h-7.09v7.55h-3.85v-17.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M379.79,634.17l6.54,17.5h-4L381,647.78h-6.55l-1.37,3.89h-3.87l6.62-17.5Zm.22,10.74-2.21-6.43h-.05l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M393.27,634.17l4.1,12h.05l3.87-12h5.42v17.5h-3.6v-12.4h-.05l-4.29,12.4h-3l-4.29-12.28h-.05v12.28h-3.61v-17.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M418,634.17a7.29,7.29,0,0,1,2.8.47,5.11,5.11,0,0,1,1.87,1.27,4.73,4.73,0,0,1,1.06,1.79,6.61,6.61,0,0,1,.33,2.08,6.69,6.69,0,0,1-.33,2.07,4.81,4.81,0,0,1-1.06,1.81,5.22,5.22,0,0,1-1.87,1.26,7.28,7.28,0,0,1-2.8.48h-4v6.27H410.1v-17.5Zm-1.05,8.23a8.73,8.73,0,0,0,1.27-.09,3.21,3.21,0,0,0,1.08-.38,2.14,2.14,0,0,0,.75-.8,2.76,2.76,0,0,0,.28-1.35,2.82,2.82,0,0,0-.28-1.35,2.14,2.14,0,0,0-.75-.8,3.21,3.21,0,0,0-1.08-.38,8.73,8.73,0,0,0-1.27-.09h-3v5.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M433.37,634.17l6.55,17.5h-4l-1.33-3.89h-6.54l-1.38,3.89H422.8l6.62-17.5Zm.22,10.74-2.21-6.43h-.05l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M451.81,651.54a6.49,6.49,0,0,1-2.53.53,9,9,0,0,1-3.61-.7,7.74,7.74,0,0,1-2.71-1.93,8.45,8.45,0,0,1-1.7-2.88,10.61,10.61,0,0,1-.58-3.57,11,11,0,0,1,.58-3.64,8.78,8.78,0,0,1,1.7-2.93,7.69,7.69,0,0,1,2.71-2,8.88,8.88,0,0,1,3.61-.71,8.58,8.58,0,0,1,2.62.4,7.51,7.51,0,0,1,2.28,1.19,6.69,6.69,0,0,1,1.68,1.94,6.59,6.59,0,0,1,.81,2.65H453a3.68,3.68,0,0,0-1.33-2.21,3.83,3.83,0,0,0-2.38-.73,4.42,4.42,0,0,0-2.2.5,4,4,0,0,0-1.47,1.35,6,6,0,0,0-.83,1.92,9.65,9.65,0,0,0-.25,2.23,9,9,0,0,0,.25,2.15,5.87,5.87,0,0,0,.83,1.87,4,4,0,0,0,1.47,1.34,4.42,4.42,0,0,0,2.2.5,3.74,3.74,0,0,0,4.17-3.77h-3.87v-2.87h7.36v9.46h-2.46l-.39-2A5.83,5.83,0,0,1,451.81,651.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M463.88,634.17l7.3,11.74h.05V634.17h3.61v17.5H471L463.7,640h-.05v11.72h-3.6v-17.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M491.32,634.17v3.23h-9.25v3.75h8.49v3h-8.49v4.29h9.44v3.23H478.22v-17.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M499,634.17h4.32l4.09,6.91,4.07-6.91h4.3L509.32,645v6.71h-3.85v-6.81Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M517.11,639.35a8.78,8.78,0,0,1,1.7-2.93,7.69,7.69,0,0,1,2.71-2,9.58,9.58,0,0,1,7.24,0,7.65,7.65,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,10.77,10.77,0,0,1,.59,3.64,10.37,10.37,0,0,1-.59,3.57,8.27,8.27,0,0,1-1.69,2.88,7.7,7.7,0,0,1-2.7,1.93,9.71,9.71,0,0,1-7.24,0,7.74,7.74,0,0,1-2.71-1.93,8.45,8.45,0,0,1-1.7-2.88,10.61,10.61,0,0,1-.58-3.57A11,11,0,0,1,517.11,639.35Zm3.52,5.79a5.87,5.87,0,0,0,.83,1.87,4,4,0,0,0,1.47,1.34,5.11,5.11,0,0,0,4.41,0,4,4,0,0,0,1.47-1.34,5.64,5.64,0,0,0,.82-1.87,8.55,8.55,0,0,0,.26-2.15,9.14,9.14,0,0,0-.26-2.23,5.72,5.72,0,0,0-.82-1.92,4,4,0,0,0-1.47-1.35,5.11,5.11,0,0,0-4.41,0,4,4,0,0,0-1.47,1.35,6,6,0,0,0-.83,1.92,9.65,9.65,0,0,0-.25,2.23A9,9,0,0,0,520.63,645.14Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M549.24,650.34a9.63,9.63,0,0,1-11,0q-1.95-1.71-2-5.3V634.17h3.85v10.88a8.1,8.1,0,0,0,.12,1.4,3,3,0,0,0,.52,1.22,2.86,2.86,0,0,0,1.09.85,4.46,4.46,0,0,0,1.88.33,3.57,3.57,0,0,0,2.84-.92,4.38,4.38,0,0,0,.79-2.88V634.17h3.85v10.88A6.66,6.66,0,0,1,549.24,650.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M564,634.17a5.72,5.72,0,0,1,2.12.37,5,5,0,0,1,1.62,1.05,4.54,4.54,0,0,1,1,1.53,4.88,4.88,0,0,1,.35,1.88,5.18,5.18,0,0,1-.65,2.67,4.12,4.12,0,0,1-2.12,1.72v0a3.07,3.07,0,0,1,1.18.61,3.35,3.35,0,0,1,.76,1,4.3,4.3,0,0,1,.43,1.22,10,10,0,0,1,.18,1.32c0,.28,0,.6,0,1s.05.76.09,1.15a7.47,7.47,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.81,6.81,0,0,1-.39-2c0-.77-.13-1.51-.22-2.21a3.54,3.54,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85v-17.5Zm-1.37,7.92a2.91,2.91,0,0,0,2-.59,2.45,2.45,0,0,0,.66-1.92,2.34,2.34,0,0,0-.66-1.85,3,3,0,0,0-2-.57h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M592.16,634.17v3.23h-9.25v3.75h8.49v3h-8.49v4.29h9.44v3.23H579.06v-17.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M601.94,643.48v3h-7.38v-3Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M616.16,638.84a3.84,3.84,0,0,0-2-1.62,3.89,3.89,0,0,0-1.36-.23,4.46,4.46,0,0,0-2.21.5,4.14,4.14,0,0,0-1.47,1.35,5.72,5.72,0,0,0-.82,1.92A9.65,9.65,0,0,0,608,643a9,9,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.2,4.2,0,0,0,1.47,1.34,4.46,4.46,0,0,0,2.21.5,3.55,3.55,0,0,0,2.76-1.07,5,5,0,0,0,1.21-2.85h3.73a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.78,6.78,0,0,1-2.35,1.43,8.81,8.81,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93,8.43,8.43,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.92,8.92,0,0,1,3.62-.71,8.72,8.72,0,0,1,2.73.42,7.28,7.28,0,0,1,2.31,1.21,6.54,6.54,0,0,1,1.68,2,7.06,7.06,0,0,1,.83,2.7h-3.72A3.16,3.16,0,0,0,616.16,638.84Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M631.83,634.17l6.55,17.5h-4l-1.32-3.89h-6.55l-1.37,3.89h-3.88l6.63-17.5Zm.22,10.74-2.2-6.43h-.05l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M642.85,647.36a2.71,2.71,0,0,0,.84,1,3.72,3.72,0,0,0,1.24.57,5.92,5.92,0,0,0,1.47.18,7.29,7.29,0,0,0,1.1-.09,3.48,3.48,0,0,0,1.11-.33,2.47,2.47,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.65,3.65,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.28,6.28,0,0,1-1.67-.92,4.19,4.19,0,0,1-1.19-1.41,4.28,4.28,0,0,1-.45-2.05,4.66,4.66,0,0,1,.57-2.34,5.3,5.3,0,0,1,1.51-1.65,6.59,6.59,0,0,1,2.11-1,8.71,8.71,0,0,1,2.36-.32,11.34,11.34,0,0,1,2.63.3,6.76,6.76,0,0,1,2.25,1,5,5,0,0,1,1.55,1.75,5.33,5.33,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.25,2.25,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.2,4.2,0,0,0-.93.1,2.4,2.4,0,0,0-.84.34,2,2,0,0,0-.63.61,1.7,1.7,0,0,0-.24.94,1.57,1.57,0,0,0,.19.83,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.69c.33.06.78.18,1.36.35a6.63,6.63,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.29,4.29,0,0,1,.63,2.42,5.37,5.37,0,0,1-.47,2.23,4.93,4.93,0,0,1-1.38,1.78,7,7,0,0,1-2.28,1.16,10.75,10.75,0,0,1-3.16.42,10.86,10.86,0,0,1-2.8-.36A7.3,7.3,0,0,1,641,650.6a5.64,5.64,0,0,1-1.65-1.94,5.76,5.76,0,0,1-.59-2.8h3.73A3.14,3.14,0,0,0,642.85,647.36Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M654.44,637.4v-3.23h14.35v3.23h-5.25v14.27h-3.85V637.4Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M674.65,634.17v17.5H670.8v-17.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M681.86,634.17l7.31,11.74h.05V634.17h3.6v17.5H689L681.69,640h-.05v11.72H678v-17.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M706.58,651.54a6.49,6.49,0,0,1-2.53.53,9,9,0,0,1-3.61-.7,7.74,7.74,0,0,1-2.71-1.93,8.27,8.27,0,0,1-1.69-2.88,10.37,10.37,0,0,1-.59-3.57,10.77,10.77,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.69,7.69,0,0,1,2.71-2,8.88,8.88,0,0,1,3.61-.71,8.58,8.58,0,0,1,2.62.4,7.51,7.51,0,0,1,2.28,1.19,6.69,6.69,0,0,1,1.68,1.94,6.59,6.59,0,0,1,.81,2.65h-3.68a3.68,3.68,0,0,0-1.33-2.21,3.83,3.83,0,0,0-2.38-.73,4.42,4.42,0,0,0-2.2.5,4,4,0,0,0-1.47,1.35,6,6,0,0,0-.83,1.92,9.65,9.65,0,0,0-.25,2.23,9,9,0,0,0,.25,2.15,5.87,5.87,0,0,0,.83,1.87,4,4,0,0,0,1.47,1.34,4.42,4.42,0,0,0,2.2.5,3.74,3.74,0,0,0,4.17-3.77h-3.87v-2.87h7.36v9.46h-2.46l-.39-2A5.83,5.83,0,0,1,706.58,651.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M338.68,658.71a4.32,4.32,0,0,1,1.65.28,3.2,3.2,0,0,1,1.11.75,2.87,2.87,0,0,1,.62,1,4.08,4.08,0,0,1,0,2.46,2.91,2.91,0,0,1-.62,1.06,3.2,3.2,0,0,1-1.11.75,4.52,4.52,0,0,1-1.65.28h-2.39V669H334V658.71Zm-.62,4.86a4.56,4.56,0,0,0,.75-.06,1.68,1.68,0,0,0,.64-.22,1.25,1.25,0,0,0,.44-.47,2.06,2.06,0,0,0,0-1.59,1.18,1.18,0,0,0-.44-.47,1.88,1.88,0,0,0-.64-.23,4.56,4.56,0,0,0-.75-.06h-1.77v3.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M345.41,661.56V663h0a2.12,2.12,0,0,1,.39-.64,2.47,2.47,0,0,1,.56-.51,2.85,2.85,0,0,1,.68-.33,2.68,2.68,0,0,1,.76-.11,1.48,1.48,0,0,1,.44.07v1.91l-.34-.05-.39,0a2.13,2.13,0,0,0-1,.19,1.58,1.58,0,0,0-.63.51,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94V669h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M349.14,660.4v-1.69h2.06v1.69Zm2.06,1.16V669h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M356.39,662.91a1.44,1.44,0,0,0-.82.22,1.77,1.77,0,0,0-.54.58,2.63,2.63,0,0,0-.3.78,4.17,4.17,0,0,0-.09.85,3.88,3.88,0,0,0,.09.82,2.54,2.54,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.48,1.48,0,0,0,.81.22,1.51,1.51,0,0,0,1.14-.42,2,2,0,0,0,.5-1.1h2a3.4,3.4,0,0,1-1.16,2.27,3.75,3.75,0,0,1-2.45.78,4.06,4.06,0,0,1-1.54-.28,3.4,3.4,0,0,1-1.19-.79,3.53,3.53,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,4.9,4.9,0,0,1,.25-1.61,3.59,3.59,0,0,1,.75-1.29,3.27,3.27,0,0,1,1.2-.85,4.11,4.11,0,0,1,1.62-.3,4.94,4.94,0,0,1,1.28.17,3.4,3.4,0,0,1,1.1.53,2.89,2.89,0,0,1,.79.88,2.73,2.73,0,0,1,.34,1.25h-2A1.37,1.37,0,0,0,356.39,662.91Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M363.37,658.71v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.73h-2.49l-2.05-3.34-.8.77V669h-2.06V658.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M371.63,667.25a1.85,1.85,0,0,0,1.33.45,1.91,1.91,0,0,0,1.08-.31,1.37,1.37,0,0,0,.55-.66h1.81a3.43,3.43,0,0,1-1.34,1.92,3.86,3.86,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.3,3.54,3.54,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.36,5.36,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,371.63,667.25Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.4,1.77,1.77,0,0,0-.81.16,1.46,1.46,0,0,0-.52.42,1.39,1.39,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49h3.34A2.48,2.48,0,0,0,374,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M379.85,658.71V669H377.8V658.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M383.43,661.56v1h0a2.39,2.39,0,0,1,1-1,3,3,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.15,2.15,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.2,6.2,0,0,1,.13,1.27V669h-2.06v-4.22a2.69,2.69,0,0,0-.29-1.39,1.13,1.13,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64V669h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M395.37,668.09a2.09,2.09,0,0,1-.95.87,3.11,3.11,0,0,1-1.32.27,3.32,3.32,0,0,1-1.48-.32,3.12,3.12,0,0,1-1.05-.89,4.12,4.12,0,0,1-.62-1.28,5.33,5.33,0,0,1-.21-1.5,5.05,5.05,0,0,1,.21-1.46,3.9,3.9,0,0,1,.62-1.24,3.24,3.24,0,0,1,1-.86,3.12,3.12,0,0,1,1.44-.32,2.91,2.91,0,0,1,1.27.28,2.26,2.26,0,0,1,.95.83h0v-3.76h2.05V669h-2v-.95Zm-.09-3.7a2.17,2.17,0,0,0-.29-.75,1.54,1.54,0,0,0-1.39-.73,1.62,1.62,0,0,0-.85.2,1.58,1.58,0,0,0-.56.53,2.42,2.42,0,0,0-.3.76,4.41,4.41,0,0,0-.1.89,3.7,3.7,0,0,0,.11.87,2.25,2.25,0,0,0,.32.78,1.87,1.87,0,0,0,.57.55,1.5,1.5,0,0,0,.81.21,1.6,1.6,0,0,0,.85-.21A1.4,1.4,0,0,0,395,667a2.37,2.37,0,0,0,.29-.77,4.43,4.43,0,0,0,.09-.9A4.26,4.26,0,0,0,395.28,664.39Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M401.1,667.25a1.83,1.83,0,0,0,1.33.45,1.87,1.87,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.42,3.42,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.37,3.37,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.27-1.56,3.79,3.79,0,0,1,.79-1.26,3.63,3.63,0,0,1,1.2-.83,3.94,3.94,0,0,1,1.56-.3,3.48,3.48,0,0,1,1.66.37,3.13,3.13,0,0,1,1.17,1,4.21,4.21,0,0,1,.66,1.42,5.36,5.36,0,0,1,.14,1.66h-5.4A2.08,2.08,0,0,0,401.1,667.25Zm2.32-3.94a1.43,1.43,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.55,1.55,0,0,0-.52.42,1.36,1.36,0,0,0-.27.52,2.51,2.51,0,0,0-.1.49h3.35A2.39,2.39,0,0,0,403.42,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M417,658.71a3.31,3.31,0,0,1,1.25.22,2.89,2.89,0,0,1,1,.62,2.76,2.76,0,0,1,.61.9,2.89,2.89,0,0,1,.21,1.11,3.09,3.09,0,0,1-.39,1.58,2.38,2.38,0,0,1-1.25,1v0a2,2,0,0,1,.7.35,1.89,1.89,0,0,1,.44.57,2.67,2.67,0,0,1,.26.72,7,7,0,0,1,.11.78c0,.16,0,.35,0,.58s0,.44.05.68a4.19,4.19,0,0,0,.11.65,1.36,1.36,0,0,0,.24.53h-2.27a3.92,3.92,0,0,1-.23-1.17c0-.45-.08-.89-.13-1.3a2,2,0,0,0-.5-1.19A1.69,1.69,0,0,0,416,665h-2.27v4h-2.27V658.71Zm-.81,4.67a1.72,1.72,0,0,0,1.17-.34,1.46,1.46,0,0,0,.39-1.13,1.4,1.4,0,0,0-.39-1.1,1.77,1.77,0,0,0-1.17-.34h-2.49v2.91Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M421.73,663.68a3.45,3.45,0,0,1,2-2,4.68,4.68,0,0,1,3.19,0,3.45,3.45,0,0,1,1.23.8,3.5,3.5,0,0,1,.78,1.24,4.68,4.68,0,0,1,.27,1.63,4.59,4.59,0,0,1-.27,1.62,3.68,3.68,0,0,1-.78,1.24,3.57,3.57,0,0,1-1.23.79,4.85,4.85,0,0,1-3.19,0,3.48,3.48,0,0,1-1.21-.79,3.53,3.53,0,0,1-.78-1.24,4.37,4.37,0,0,1-.28-1.62A4.46,4.46,0,0,1,421.73,663.68Zm1.86,2.5a2.38,2.38,0,0,0,.3.77,1.45,1.45,0,0,0,.56.54,1.64,1.64,0,0,0,.86.21,1.71,1.71,0,0,0,.88-.21,1.39,1.39,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.14,4.14,0,0,0,.09-.87,4.24,4.24,0,0,0-.09-.88,2.29,2.29,0,0,0-.3-.77,1.53,1.53,0,0,0-.56-.54,1.71,1.71,0,0,0-.88-.21,1.64,1.64,0,0,0-.86.21,1.61,1.61,0,0,0-.56.54,2.29,2.29,0,0,0-.3.77,4.24,4.24,0,0,0-.08.88A4.14,4.14,0,0,0,423.59,666.18Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M432.64,658.71V669h-2.06V658.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M436.37,658.71V669h-2.05V658.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M440.19,667.25a1.85,1.85,0,0,0,1.33.45,1.91,1.91,0,0,0,1.08-.31,1.37,1.37,0,0,0,.55-.66H445a3.43,3.43,0,0,1-1.34,1.92,3.86,3.86,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.3,3.54,3.54,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.07,5.07,0,0,1,.14,1.66h-5.39A2.08,2.08,0,0,0,440.19,667.25Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.4,1.77,1.77,0,0,0-.81.16,1.46,1.46,0,0,0-.52.42,1.39,1.39,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49H443A2.48,2.48,0,0,0,442.52,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M448.25,661.56v1h.05a2.42,2.42,0,0,1,1-1,3.05,3.05,0,0,1,1.27-.29,3.52,3.52,0,0,1,1.36.22,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27V669h-2.05v-4.22a2.69,2.69,0,0,0-.29-1.39,1.14,1.14,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64V669H446.3v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M454.8,663.86a2.43,2.43,0,0,1,.36-1.2,2.49,2.49,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.41,6.41,0,0,1,1.23-.12,7.5,7.5,0,0,1,1.15.08,3.21,3.21,0,0,1,1.05.31,2.15,2.15,0,0,1,.79.64,1.84,1.84,0,0,1,.3,1.1v3.89a7.54,7.54,0,0,0,.06,1,1.67,1.67,0,0,0,.2.69h-2.08a3.57,3.57,0,0,1-.1-.35c0-.12,0-.25-.05-.37a2.69,2.69,0,0,1-1.16.71,4.76,4.76,0,0,1-1.36.2,3.61,3.61,0,0,1-1-.13,2.46,2.46,0,0,1-.81-.4,2,2,0,0,1-.54-.7,2.35,2.35,0,0,1-.19-1,2.21,2.21,0,0,1,.22-1,2,2,0,0,1,.58-.66,2.86,2.86,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19l.91-.12a5.57,5.57,0,0,0,.8-.13,1.34,1.34,0,0,0,.55-.25.56.56,0,0,0,.18-.48,1.11,1.11,0,0,0-.1-.53,1,1,0,0,0-.29-.31,1.1,1.1,0,0,0-.42-.14,2.82,2.82,0,0,0-.52,0,1.58,1.58,0,0,0-.95.26,1.2,1.2,0,0,0-.41.87Zm4.74,1.52a.77.77,0,0,1-.32.18,2.58,2.58,0,0,1-.42.11l-.47.07-.49.08a2.37,2.37,0,0,0-.46.11,1.2,1.2,0,0,0-.39.2.91.91,0,0,0-.27.31,1.15,1.15,0,0,0,0,.94.78.78,0,0,0,.28.29,1,1,0,0,0,.4.16,2.59,2.59,0,0,0,.48,0,1.85,1.85,0,0,0,.94-.2,1.35,1.35,0,0,0,.49-.49,1.56,1.56,0,0,0,.2-.57c0-.19,0-.35,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M465.15,661.56v1h0a2.42,2.42,0,0,1,1-1,3.06,3.06,0,0,1,1.28-.29,3.46,3.46,0,0,1,1.35.22,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27V669h-2v-4.22a2.69,2.69,0,0,0-.29-1.39,1.14,1.14,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64V669h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M478.84,669.59a2.46,2.46,0,0,1-.52,1.08,3.21,3.21,0,0,1-1.18.85,5,5,0,0,1-2.05.35,4.84,4.84,0,0,1-1.12-.14,3.63,3.63,0,0,1-1.05-.43,2.62,2.62,0,0,1-.78-.74,2.27,2.27,0,0,1-.35-1.1h2a1.18,1.18,0,0,0,.56.81,2,2,0,0,0,1,.22,1.5,1.5,0,0,0,1.27-.52,2.09,2.09,0,0,0,.38-1.32v-1h0a2,2,0,0,1-.94.87,3.08,3.08,0,0,1-1.3.29,3.26,3.26,0,0,1-1.45-.3,2.93,2.93,0,0,1-1-.81,3.34,3.34,0,0,1-.57-1.2,5.78,5.78,0,0,1-.18-1.46,4.59,4.59,0,0,1,.21-1.39,3.64,3.64,0,0,1,.62-1.19,3,3,0,0,1,1-.82,3.06,3.06,0,0,1,1.39-.3,2.92,2.92,0,0,1,1.29.27,2.25,2.25,0,0,1,.93.93h0v-1h2v7A4.51,4.51,0,0,1,478.84,669.59ZM476,667.1a1.63,1.63,0,0,0,.55-.46,2,2,0,0,0,.32-.66,2.43,2.43,0,0,0,.11-.77,5,5,0,0,0-.08-.88,2.37,2.37,0,0,0-.29-.73,1.65,1.65,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.18,1.42,1.42,0,0,0-.74.17,1.56,1.56,0,0,0-.52.47,1.92,1.92,0,0,0-.31.68,3.5,3.5,0,0,0-.09.8,3.71,3.71,0,0,0,.08.81,2.09,2.09,0,0,0,.27.71,1.44,1.44,0,0,0,.52.52,1.39,1.39,0,0,0,.79.21A1.59,1.59,0,0,0,476,667.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M482.82,667.25a1.83,1.83,0,0,0,1.33.45,1.9,1.9,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.42,3.42,0,0,1-1.33,1.92,3.91,3.91,0,0,1-2.17.58,4.3,4.3,0,0,1-1.6-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.94,3.94,0,0,1,1.56-.3,3.5,3.5,0,0,1,1.66.37,3.3,3.3,0,0,1,1.17,1,4,4,0,0,1,.65,1.42,5.07,5.07,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,482.82,667.25Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.46,1.46,0,0,0-.52.42,1.55,1.55,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49h3.34A2.3,2.3,0,0,0,485.14,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M491,658.71v3.76h0a2.24,2.24,0,0,1,1-.85,3.59,3.59,0,0,1,1.34-.26,2.83,2.83,0,0,1,2.1.94,3.46,3.46,0,0,1,.72,1.22,6,6,0,0,1,0,3.55,3.55,3.55,0,0,1-.72,1.22,2.83,2.83,0,0,1-1,.71,2.9,2.9,0,0,1-1.11.23,4.07,4.07,0,0,1-1.48-.26,1.87,1.87,0,0,1-1-.88h0V669h-2V658.71Zm3.33,5.69a2.55,2.55,0,0,0-.32-.77,1.71,1.71,0,0,0-.54-.53,1.72,1.72,0,0,0-1.57,0,1.55,1.55,0,0,0-.55.53,2.32,2.32,0,0,0-.32.77,4.15,4.15,0,0,0,0,1.81,2.26,2.26,0,0,0,.32.76,1.47,1.47,0,0,0,.55.53,1.64,1.64,0,0,0,1.57,0A1.61,1.61,0,0,0,494,667a2.49,2.49,0,0,0,.32-.76,4.15,4.15,0,0,0,0-1.81Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M497.81,663.68a3.36,3.36,0,0,1,.78-1.24,3.5,3.5,0,0,1,1.22-.8,4.21,4.21,0,0,1,1.59-.28,4.3,4.3,0,0,1,1.6.28,3.41,3.41,0,0,1,1.22.8,3.36,3.36,0,0,1,.78,1.24,4.46,4.46,0,0,1,.28,1.63,4.37,4.37,0,0,1-.28,1.62,3.38,3.38,0,0,1-2,2,4.51,4.51,0,0,1-1.6.27,4.42,4.42,0,0,1-1.59-.27,3.62,3.62,0,0,1-1.22-.79,3.53,3.53,0,0,1-.78-1.24,4.59,4.59,0,0,1-.27-1.62A4.68,4.68,0,0,1,497.81,663.68Zm1.87,2.5a2.37,2.37,0,0,0,.29.77,1.53,1.53,0,0,0,.56.54,1.65,1.65,0,0,0,.87.21,1.71,1.71,0,0,0,.88-.21,1.53,1.53,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.14,4.14,0,0,0,.08-.87,4.24,4.24,0,0,0-.08-.88,2.29,2.29,0,0,0-.3-.77,1.6,1.6,0,0,0-1.44-.75,1.65,1.65,0,0,0-.87.21,1.7,1.7,0,0,0-.56.54,2.28,2.28,0,0,0-.29.77,4.24,4.24,0,0,0-.09.88A4.14,4.14,0,0,0,499.68,666.18Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M510.72,661.56v1.37h-1.5v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.69.18l.34,0,.3,0V669a4.71,4.71,0,0,1-.58.06l-.62,0a6.32,6.32,0,0,1-.9-.07,2.2,2.2,0,0,1-.76-.25,1.4,1.4,0,0,1-.51-.54,1.84,1.84,0,0,1-.19-.91v-4.42h-1.24v-1.37h1.24v-2.24h2.06v2.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M513.91,667.25a1.81,1.81,0,0,0,1.33.45,1.9,1.9,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.47,3.47,0,0,1-1.33,1.92,3.92,3.92,0,0,1-2.18.58,4.25,4.25,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.3,3.52,3.52,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.07,5.07,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,513.91,667.25Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.46,1.46,0,0,0-.52.42,1.55,1.55,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49h3.34A2.39,2.39,0,0,0,516.23,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M526.28,658.71v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.73h-2.49l-2-3.34-.8.77V669h-2.06V658.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M534.3,661.56V663h0a2.12,2.12,0,0,1,.39-.64,2.47,2.47,0,0,1,.56-.51,2.85,2.85,0,0,1,.68-.33,2.67,2.67,0,0,1,.75-.11,1.5,1.5,0,0,1,.45.07v1.91l-.34-.05-.39,0a2.13,2.13,0,0,0-1,.19,1.58,1.58,0,0,0-.63.51,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94V669h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M539.91,667.25a1.86,1.86,0,0,0,1.34.45,1.9,1.9,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.43,3.43,0,0,1-1.34,1.92,3.86,3.86,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.3,3.54,3.54,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.36,5.36,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,539.91,667.25Zm2.33-3.94a1.45,1.45,0,0,0-1.12-.4,1.79,1.79,0,0,0-.82.16,1.46,1.46,0,0,0-.52.42,1.39,1.39,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49h3.34A2.48,2.48,0,0,0,542.24,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M546.08,660.4v-1.69h2v1.69Zm2,1.16V669h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M552,667.25a1.81,1.81,0,0,0,1.33.45,1.9,1.9,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.47,3.47,0,0,1-1.33,1.92,3.92,3.92,0,0,1-2.18.58,4.28,4.28,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.3,3.52,3.52,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.07,5.07,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,552,667.25Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.46,1.46,0,0,0-.52.42,1.55,1.55,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49h3.34A2.39,2.39,0,0,0,554.28,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M560,661.56V663h0a2.12,2.12,0,0,1,.39-.64,2.47,2.47,0,0,1,.56-.51,2.85,2.85,0,0,1,.68-.33,2.67,2.67,0,0,1,.75-.11,1.5,1.5,0,0,1,.45.07v1.91l-.34-.05-.4,0a2.12,2.12,0,0,0-.95.19,1.58,1.58,0,0,0-.63.51,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94V669h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M565.63,667.25a1.85,1.85,0,0,0,1.33.45,1.91,1.91,0,0,0,1.08-.31,1.37,1.37,0,0,0,.55-.66h1.8a3.37,3.37,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.46,3.46,0,0,1-1.2-.8,3.68,3.68,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.62,3.62,0,0,1,.78-1.26,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.3,3.54,3.54,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.07,5.07,0,0,1,.14,1.66h-5.39A2.08,2.08,0,0,0,565.63,667.25Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.4,1.77,1.77,0,0,0-.81.16,1.46,1.46,0,0,0-.52.42,1.39,1.39,0,0,0-.28.52,2.44,2.44,0,0,0-.09.49h3.34A2.48,2.48,0,0,0,568,663.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M573.69,661.56v1h0a2.42,2.42,0,0,1,1-1,3.05,3.05,0,0,1,1.27-.29,3.52,3.52,0,0,1,1.36.22,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27V669h-2v-4.22a2.69,2.69,0,0,0-.29-1.39,1.14,1.14,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64V669h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M582.7,666.81V669a2.27,2.27,0,0,1-.18.91,2.37,2.37,0,0,1-.49.71,2.32,2.32,0,0,1-.72.5,3.15,3.15,0,0,1-.87.27v-1a1.14,1.14,0,0,0,.41-.18,1.43,1.43,0,0,0,.34-.31,1.24,1.24,0,0,0,.28-.86h-1.05v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M593.46,660.62h-5.1v-1.91h8.13v1.79L591,667.13h5.68V669h-8.71v-1.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M597.87,661.77a5.18,5.18,0,0,1,1-1.73,4.68,4.68,0,0,1,1.6-1.16,5.66,5.66,0,0,1,4.28,0,4.54,4.54,0,0,1,1.59,1.16,5,5,0,0,1,1,1.73,6.51,6.51,0,0,1,.35,2.15,6.35,6.35,0,0,1-.35,2.11,5,5,0,0,1-1,1.7,4.47,4.47,0,0,1-1.59,1.13,5.79,5.79,0,0,1-4.28,0,4.6,4.6,0,0,1-1.6-1.13,5.14,5.14,0,0,1-1-1.7,6.11,6.11,0,0,1-.35-2.11A6.26,6.26,0,0,1,597.87,661.77Zm2.07,3.42a3.22,3.22,0,0,0,.49,1.1,2.51,2.51,0,0,0,.86.79,2.67,2.67,0,0,0,1.31.3,2.63,2.63,0,0,0,1.3-.3,2.45,2.45,0,0,0,.87-.79,3.41,3.41,0,0,0,.48-1.1,5.39,5.39,0,0,0,.16-1.27,5.81,5.81,0,0,0-.16-1.32,3.48,3.48,0,0,0-.48-1.13,2.39,2.39,0,0,0-.87-.8,2.63,2.63,0,0,0-1.3-.3,2.67,2.67,0,0,0-1.31.3,2.44,2.44,0,0,0-.86.8,3.29,3.29,0,0,0-.49,1.13,5.81,5.81,0,0,0-.15,1.32A5.38,5.38,0,0,0,599.94,665.19Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M609.12,661.77a5.38,5.38,0,0,1,1-1.73,4.68,4.68,0,0,1,1.6-1.16,5.66,5.66,0,0,1,4.28,0,4.54,4.54,0,0,1,1.59,1.16,5,5,0,0,1,1,1.73,6.51,6.51,0,0,1,.35,2.15,6.35,6.35,0,0,1-.35,2.11,5,5,0,0,1-1,1.7,4.47,4.47,0,0,1-1.59,1.13,5.79,5.79,0,0,1-4.28,0,4.6,4.6,0,0,1-1.6-1.13,5.34,5.34,0,0,1-1-1.7,6.35,6.35,0,0,1-.34-2.11A6.51,6.51,0,0,1,609.12,661.77Zm2.08,3.42a3.22,3.22,0,0,0,.49,1.1,2.45,2.45,0,0,0,.87.79,2.6,2.6,0,0,0,1.3.3,2.63,2.63,0,0,0,1.3-.3,2.45,2.45,0,0,0,.87-.79,3.41,3.41,0,0,0,.48-1.1,4.91,4.91,0,0,0,.16-1.27,5.3,5.3,0,0,0-.16-1.32,3.48,3.48,0,0,0-.48-1.13,2.39,2.39,0,0,0-.87-.8,2.63,2.63,0,0,0-1.3-.3,2.6,2.6,0,0,0-1.3.3,2.39,2.39,0,0,0-.87.8,3.29,3.29,0,0,0-.49,1.13,5.81,5.81,0,0,0-.15,1.32A5.38,5.38,0,0,0,611.2,665.19Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M623.69,658.71l2.41,7.11h0l2.29-7.11h3.2V669h-2.13v-7.32h0L626.93,669h-1.75l-2.54-7.25h0V669h-2.12V658.71Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    c{/* champagner konkret */}
    <Link to={`${url}single/workshop_paket__champagner_konkret_`}>
      <g id="champagner-konkret">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="230.2"
          y="693.65"
          width="466.13"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M325.89,789.59a4.16,4.16,0,0,0-.86-1,4.24,4.24,0,0,0-1.16-.65,4.18,4.18,0,0,0-1.36-.23,4.43,4.43,0,0,0-2.21.51,4,4,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.14,9.14,0,0,0-.26,2.23,8.55,8.55,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.13,4.13,0,0,0,1.47,1.34,4.54,4.54,0,0,0,2.21.5,3.55,3.55,0,0,0,2.75-1.08,5,5,0,0,0,1.22-2.84h3.73a9.26,9.26,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.8,6.8,0,0,1-2.36,1.42,8.55,8.55,0,0,1-3,.49,8.89,8.89,0,0,1-3.62-.7,7.59,7.59,0,0,1-2.71-1.92,8.46,8.46,0,0,1-1.69-2.88,10.41,10.41,0,0,1-.59-3.57,10.73,10.73,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.56,7.56,0,0,1,2.71-2,9.3,9.3,0,0,1,6.35-.3,7.15,7.15,0,0,1,2.3,1.22,6.54,6.54,0,0,1,1.68,2,7,7,0,0,1,.84,2.7h-3.73A3.09,3.09,0,0,0,325.89,789.59Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M336.68,784.92v6.72h7.09v-6.72h3.85v17.51h-3.85v-7.55h-7.09v7.55h-3.85V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M359.73,784.92l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.74-2.2-6.42h-.05l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M373.22,784.92l4.09,12h.05l3.88-12h5.42v17.51h-3.61V790H383l-4.29,12.41h-3l-4.3-12.28h-.05v12.28h-3.6V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M397.94,784.92a7.23,7.23,0,0,1,2.79.48,5.26,5.26,0,0,1,1.88,1.26,5.09,5.09,0,0,1,1.06,1.79,6.72,6.72,0,0,1,0,4.16,5,5,0,0,1-1.06,1.8,5,5,0,0,1-1.88,1.26,7.23,7.23,0,0,1-2.79.48h-4.05v6.28H390V784.92Zm-1.06,8.24a7.55,7.55,0,0,0,1.28-.1,2.83,2.83,0,0,0,1.08-.38,2,2,0,0,0,.75-.79,2.94,2.94,0,0,0,.28-1.35,2.88,2.88,0,0,0-.28-1.35,2.14,2.14,0,0,0-.75-.8,3,3,0,0,0-1.08-.38,8.74,8.74,0,0,0-1.28-.1h-3v5.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M413.31,784.92l6.55,17.51h-4l-1.33-3.9H408l-1.37,3.9h-3.87l6.62-17.51Zm.23,10.74-2.21-6.42h-.05L409,795.66Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M431.76,802.3a6.51,6.51,0,0,1-2.53.52,8.92,8.92,0,0,1-3.62-.7,7.68,7.68,0,0,1-2.71-1.92,8.62,8.62,0,0,1-1.69-2.88,10.65,10.65,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.65,7.65,0,0,1,2.71-2,8.76,8.76,0,0,1,3.62-.72,8.48,8.48,0,0,1,2.61.41,7.51,7.51,0,0,1,2.28,1.19A6.43,6.43,0,0,1,435.8,788a6.54,6.54,0,0,1,.81,2.64h-3.68a3.69,3.69,0,0,0-1.32-2.2,3.86,3.86,0,0,0-2.38-.74,4.46,4.46,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,6.08,6.08,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.85,5.85,0,0,0,.82,1.87,4.3,4.3,0,0,0,1.47,1.34,4.57,4.57,0,0,0,2.21.5,3.75,3.75,0,0,0,4.17-3.78h-3.88V793h7.36v9.46h-2.45l-.39-2A5.83,5.83,0,0,1,431.76,802.3Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M443.82,784.92l7.31,11.75h.05V784.92h3.6v17.51h-3.85l-7.28-11.72h0v11.72H440V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M471.26,784.92v3.24H462v3.75h8.48v3H462v4.29h9.44v3.24H458.17V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M483.5,784.92a5.68,5.68,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.83,4.83,0,0,1,.36,1.87,5.34,5.34,0,0,1-.65,2.68,4.08,4.08,0,0,1-2.13,1.71v.05a2.92,2.92,0,0,1,1.18.6,3.23,3.23,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,12.16,12.16,0,0,1,.19,1.33c0,.28,0,.6,0,1s.05.76.09,1.15a7.93,7.93,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V784.92Zm-1.37,7.92a2.86,2.86,0,0,0,2-.59,2.44,2.44,0,0,0,.67-1.91,2.33,2.33,0,0,0-.67-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M502.43,784.92v7.26l6.84-7.26h4.81l-6.84,6.92,7.5,10.59h-4.83l-5.27-7.87-2.21,2.23v5.64h-3.85V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M516.12,790.11a8.59,8.59,0,0,1,1.69-2.93,7.56,7.56,0,0,1,2.71-2,9.46,9.46,0,0,1,7.24,0,7.53,7.53,0,0,1,2.7,2,8.59,8.59,0,0,1,1.69,2.93,10.73,10.73,0,0,1,.59,3.64,10.41,10.41,0,0,1-.59,3.57,8.46,8.46,0,0,1-1.69,2.88,7.55,7.55,0,0,1-2.7,1.92,9.71,9.71,0,0,1-7.24,0,7.59,7.59,0,0,1-2.71-1.92,8.46,8.46,0,0,1-1.69-2.88,10.41,10.41,0,0,1-.59-3.57A10.73,10.73,0,0,1,516.12,790.11Zm3.51,5.79a5.87,5.87,0,0,0,.83,1.87,4.13,4.13,0,0,0,1.47,1.34,5.11,5.11,0,0,0,4.41,0,4.13,4.13,0,0,0,1.47-1.34,5.64,5.64,0,0,0,.82-1.87,8.55,8.55,0,0,0,.26-2.15,9.14,9.14,0,0,0-.26-2.23,5.86,5.86,0,0,0-.82-1.93,4,4,0,0,0-1.47-1.34,5,5,0,0,0-4.41,0,4,4,0,0,0-1.47,1.34,6.1,6.1,0,0,0-.83,1.93,9.65,9.65,0,0,0-.25,2.23A9,9,0,0,0,519.63,795.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M539.19,784.92l7.31,11.75h0V784.92h3.6v17.51H546.3L539,790.71h0v11.72h-3.6V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M557.39,784.92v7.26l6.84-7.26H569l-6.85,6.92,7.51,10.59h-4.83l-5.27-7.87-2.21,2.23v5.64h-3.85V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M580.69,784.92a5.72,5.72,0,0,1,2.12.38,4.86,4.86,0,0,1,1.61,1,4.71,4.71,0,0,1,1,1.54,5,5,0,0,1,.35,1.87,5.34,5.34,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a3,3,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.69,4.69,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s0,.76.08,1.15a9.67,9.67,0,0,0,.2,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.54,6.54,0,0,1-.4-2c-.05-.77-.12-1.51-.22-2.21a3.47,3.47,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85V784.92Zm-1.38,7.92a2.89,2.89,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M602,784.92v3.24H592.8v3.75h8.48v3H592.8v4.29h9.44v3.24H589V784.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M603.47,788.16v-3.24h14.34v3.24h-5.25v14.27h-3.85V788.16Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M315.7,817.25a1.69,1.69,0,0,0,.5.59,2.12,2.12,0,0,0,.73.33,3.58,3.58,0,0,0,.87.11,5.13,5.13,0,0,0,.65,0,2,2,0,0,0,.65-.2,1.42,1.42,0,0,0,.51-.39,1,1,0,0,0,.2-.65.91.91,0,0,0-.27-.68,2,2,0,0,0-.7-.43,7.08,7.08,0,0,0-1-.31c-.37-.08-.74-.18-1.12-.29a9.21,9.21,0,0,1-1.13-.35,4,4,0,0,1-1-.54,2.5,2.5,0,0,1-.7-.84,2.47,2.47,0,0,1-.27-1.2A2.7,2.7,0,0,1,314,811a3.06,3.06,0,0,1,.89-1,3.77,3.77,0,0,1,1.25-.58,5,5,0,0,1,1.38-.19,6.6,6.6,0,0,1,1.56.18,3.92,3.92,0,0,1,1.33.59,2.91,2.91,0,0,1,.91,1,3.15,3.15,0,0,1,.34,1.53h-2.2a1.71,1.71,0,0,0-.19-.77,1.36,1.36,0,0,0-.44-.48,2.17,2.17,0,0,0-.63-.24,3.84,3.84,0,0,0-.78-.07,2.62,2.62,0,0,0-.55,0,1.48,1.48,0,0,0-.5.21,1.16,1.16,0,0,0-.37.36,1,1,0,0,0-.14.55.91.91,0,0,0,.12.49,1,1,0,0,0,.45.35,7.15,7.15,0,0,0,.94.32l1.57.4c.2,0,.46.11.81.21a3.66,3.66,0,0,1,1,.49,3,3,0,0,1,.87.89,2.48,2.48,0,0,1,.37,1.42,3.25,3.25,0,0,1-.27,1.32,3.06,3.06,0,0,1-.82,1.05,4.08,4.08,0,0,1-1.35.69,7,7,0,0,1-3.51,0,4.12,4.12,0,0,1-1.42-.66,3.27,3.27,0,0,1-1-1.14,3.41,3.41,0,0,1-.35-1.65h2.2A1.94,1.94,0,0,0,315.7,817.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M323.18,814.44a3.65,3.65,0,0,1,.79-1.25,3.35,3.35,0,0,1,1.21-.79,4.21,4.21,0,0,1,1.59-.29,4.26,4.26,0,0,1,1.6.29,3.43,3.43,0,0,1,1.23.79,3.79,3.79,0,0,1,.78,1.25,5,5,0,0,1,0,3.25,3.69,3.69,0,0,1-.78,1.23,3.3,3.3,0,0,1-1.23.79,4.26,4.26,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.22,3.22,0,0,1-1.21-.79,3.57,3.57,0,0,1-.79-1.23,5,5,0,0,1,0-3.25Zm1.87,2.5a2.38,2.38,0,0,0,.3.77,1.58,1.58,0,0,0,.55.54,2,2,0,0,0,1.75,0,1.53,1.53,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.21,4.21,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2.24,2.24,0,0,0-.3-.76,1.55,1.55,0,0,0-.56-.55,1.93,1.93,0,0,0-1.75,0,1.61,1.61,0,0,0-.55.55,2.24,2.24,0,0,0-.3.76,4.41,4.41,0,0,0-.09.89A4.21,4.21,0,0,0,325.05,816.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M337.05,819.8v-1h0a2.36,2.36,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.46,3.46,0,0,1-1.35-.22,2,2,0,0,1-.84-.61,2.45,2.45,0,0,1-.42-1,5.63,5.63,0,0,1-.13-1.27v-4.6H334v4.22a2.63,2.63,0,0,0,.29,1.38,1.1,1.1,0,0,0,1,.46,1.42,1.42,0,0,0,1.21-.5,2.77,2.77,0,0,0,.38-1.64v-3.92H339v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M342.52,812.32v1.39h0a2.67,2.67,0,0,1,.39-.65,2.42,2.42,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.46,2.46,0,0,1,.76-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35-.05-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M349.51,813.66a1.42,1.42,0,0,0-.82.23,1.7,1.7,0,0,0-.55.58,2.21,2.21,0,0,0-.29.78,3.92,3.92,0,0,0,0,1.67,2.38,2.38,0,0,0,.28.76,1.58,1.58,0,0,0,.53.56,1.72,1.72,0,0,0,1.94-.2,1.85,1.85,0,0,0,.5-1.11h2a3.44,3.44,0,0,1-1.16,2.28,3.75,3.75,0,0,1-2.45.78,4.26,4.26,0,0,1-1.54-.28,3.44,3.44,0,0,1-1.19-.79,3.53,3.53,0,0,1-.77-1.21,4.26,4.26,0,0,1-.28-1.54,5,5,0,0,1,.26-1.62,3.92,3.92,0,0,1,.74-1.29,3.42,3.42,0,0,1,1.2-.84,4.19,4.19,0,0,1,1.63-.31,4.55,4.55,0,0,1,1.28.18,3.3,3.3,0,0,1,1.1.53,2.91,2.91,0,0,1,.79.88A2.85,2.85,0,0,1,353,815h-2A1.38,1.38,0,0,0,349.51,813.66Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M356.44,818a2.12,2.12,0,0,0,2.41.14,1.28,1.28,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.42,5.42,0,0,1,.15,1.67h-5.4A2,2,0,0,0,356.44,818Zm2.33-3.93a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,358.77,814.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M362,811.38v-1.91h8.46v1.91h-3.09v8.42h-2.28v-8.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M375.13,819.8v-1h-.05a2.34,2.34,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.46,3.46,0,0,1-1.35-.22,2,2,0,0,1-.84-.61,2.45,2.45,0,0,1-.42-1,5.63,5.63,0,0,1-.13-1.27v-4.6h2.06v4.22a2.53,2.53,0,0,0,.29,1.38,1.1,1.1,0,0,0,1,.46,1.43,1.43,0,0,0,1.21-.5,2.77,2.77,0,0,0,.38-1.64v-3.92h2.05v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M380.6,812.32v1h0a2.33,2.33,0,0,1,1-1,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.15,2.15,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.12,6.12,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M387.29,811.16v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M392.92,812.32v1h0a2.36,2.36,0,0,1,1-1,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.05v-4.23a2.68,2.68,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92H391v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M406.61,820.35a2.52,2.52,0,0,1-.52,1.08,3.11,3.11,0,0,1-1.18.84,4.88,4.88,0,0,1-2.05.35,4.35,4.35,0,0,1-1.12-.14,3.55,3.55,0,0,1-1-.42,2.57,2.57,0,0,1-.79-.75,2.23,2.23,0,0,1-.35-1.09h2a1.16,1.16,0,0,0,.56.8,2,2,0,0,0,1,.23,1.52,1.52,0,0,0,1.27-.52,2,2,0,0,0,.38-1.32v-1h0a2,2,0,0,1-.94.88,3.07,3.07,0,0,1-1.3.28,3.41,3.41,0,0,1-1.45-.29,2.81,2.81,0,0,1-1-.81,3.32,3.32,0,0,1-.58-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.59,3.59,0,0,1,.62-1.18,2.85,2.85,0,0,1,1-.82,3.06,3.06,0,0,1,1.39-.31,2.92,2.92,0,0,1,1.29.28,2.19,2.19,0,0,1,.93.93h0v-1h2v7A4.6,4.6,0,0,1,406.61,820.35Zm-2.81-2.49a1.65,1.65,0,0,0,.55-.46,2.55,2.55,0,0,0,.33-.66,2.88,2.88,0,0,0,.1-.78,4.85,4.85,0,0,0-.08-.87,2.28,2.28,0,0,0-.29-.73,1.65,1.65,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.42,1.42,0,0,0-.74.18,1.46,1.46,0,0,0-.52.47,1.85,1.85,0,0,0-.31.68,3.44,3.44,0,0,0-.09.8,3.56,3.56,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.52,1.52,0,0,0,.52.52,1.39,1.39,0,0,0,.79.2A1.59,1.59,0,0,0,403.8,817.86Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M414.26,819.8l-2.56-7.48h2.16l1.58,5.11h0l1.57-5.11h2l-2.53,7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M420,814.44a3.49,3.49,0,0,1,.78-1.25,3.39,3.39,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.39,3.39,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.4,3.4,0,0,1-.78,1.23,3.26,3.26,0,0,1-1.22.79,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.26,3.26,0,0,1-1.22-.79,3.4,3.4,0,0,1-.78-1.23,5,5,0,0,1,0-3.25Zm1.87,2.5a2.37,2.37,0,0,0,.29.77,1.61,1.61,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.71,1.71,0,0,0,.88-.2,1.61,1.61,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.21,4.21,0,0,0,.08-.87,4.4,4.4,0,0,0-.08-.89,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.71,1.71,0,0,0-.88-.21,1.58,1.58,0,0,0-1.43.76,2.23,2.23,0,0,0-.29.76,4.41,4.41,0,0,0-.09.89A4.21,4.21,0,0,0,421.85,816.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M430.73,812.32v1.39h0a2.45,2.45,0,0,1,1-1.15,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35-.05-.39,0a2.26,2.26,0,0,0-.95.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M443.75,818.84a2.17,2.17,0,0,1-.95.88,3.43,3.43,0,0,1-2.8-.06,2.82,2.82,0,0,1-1.05-.88,3.91,3.91,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.7,3.7,0,0,1,.62-1.24,3,3,0,0,1,2.48-1.19,3,3,0,0,1,1.27.29,2.1,2.1,0,0,1,.94.83h0v-3.76h2.06V819.8h-2v-1Zm-.09-3.69a2.24,2.24,0,0,0-.3-.76,1.68,1.68,0,0,0-.54-.52,1.54,1.54,0,0,0-.84-.21,1.6,1.6,0,0,0-.85.21,1.5,1.5,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,3.74,3.74,0,0,0-.1.89,4.3,4.3,0,0,0,.1.87,2.33,2.33,0,0,0,.33.77,1.83,1.83,0,0,0,.56.55,1.54,1.54,0,0,0,.82.21,1.6,1.6,0,0,0,.85-.2,1.64,1.64,0,0,0,.54-.54,2.68,2.68,0,0,0,.29-.78,4.33,4.33,0,0,0,.09-.89A4.26,4.26,0,0,0,443.66,815.15Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M449.48,818a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.8,3.8,0,0,1-.76-1.24,4.57,4.57,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.51,3.51,0,0,1,1.17,1,4.09,4.09,0,0,1,.65,1.42,5.13,5.13,0,0,1,.15,1.67H449A2.05,2.05,0,0,0,449.48,818Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.26,2.26,0,0,0,451.8,814.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M457.54,812.32v1.39h0a2.16,2.16,0,0,1,.39-.65,2.42,2.42,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.42,2.42,0,0,1,.76-.12,1.52,1.52,0,0,1,.44.08v1.91l-.34-.05-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M467.73,809.47v4.28l4-4.28h2.83l-4,4.08L475,819.8h-2.85L469,815.15l-1.3,1.32v3.33h-2.27V809.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M475.6,814.62a2.43,2.43,0,0,1,.36-1.2,2.58,2.58,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,8.74,8.74,0,0,1,1.14.08,3.85,3.85,0,0,1,1.06.31,2.06,2.06,0,0,1,.78.65,1.81,1.81,0,0,1,.3,1.09v3.89a7.43,7.43,0,0,0,.06,1,1.9,1.9,0,0,0,.2.7h-2.08a2.25,2.25,0,0,1-.09-.35,2.52,2.52,0,0,1-.05-.37,2.85,2.85,0,0,1-1.16.71,4.87,4.87,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.38,2.38,0,0,1-.2-1,2.15,2.15,0,0,1,.22-1,1.87,1.87,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37c.31-.08.61-.14.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.41,1.41,0,0,0,.55-.25.55.55,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.78,2.78,0,0,0-.51,0,1.59,1.59,0,0,0-1,.26,1.16,1.16,0,0,0-.4.87Zm4.75,1.52a1,1,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.31,3.31,0,0,0-.45.12,1.64,1.64,0,0,0-.4.19.86.86,0,0,0-.26.31,1,1,0,0,0-.11.48.89.89,0,0,0,.11.46.72.72,0,0,0,.27.3,1.25,1.25,0,0,0,.41.15,2.42,2.42,0,0,0,.47,0,1.75,1.75,0,0,0,.94-.21,1.29,1.29,0,0,0,.5-.48,1.6,1.6,0,0,0,.19-.57,3.15,3.15,0,0,0,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M486,812.32v1h0a2.89,2.89,0,0,1,1-.9,2.67,2.67,0,0,1,1.31-.32,3,3,0,0,1,1.29.28,1.78,1.78,0,0,1,.89,1,3.3,3.3,0,0,1,.91-.87,2.4,2.4,0,0,1,1.35-.38,3.85,3.85,0,0,1,1.1.15,2.27,2.27,0,0,1,.87.46,2.19,2.19,0,0,1,.57.83,3.54,3.54,0,0,1,.2,1.24v5h-2.06v-4.24c0-.25,0-.49,0-.71a1.46,1.46,0,0,0-.16-.58,1,1,0,0,0-.38-.39,1.36,1.36,0,0,0-.69-.14,1.32,1.32,0,0,0-.7.16,1.18,1.18,0,0,0-.42.44,1.64,1.64,0,0,0-.2.6,5.34,5.34,0,0,0-.05.69v4.17h-2.06v-4.2c0-.22,0-.44,0-.66a2,2,0,0,0-.12-.6,1,1,0,0,0-.37-.44,1.33,1.33,0,0,0-.74-.16,1.54,1.54,0,0,0-.38.06,1.27,1.27,0,0,0-.47.25,1.33,1.33,0,0,0-.38.52,2,2,0,0,0-.16.89v4.34h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M499.31,818a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.8,3.8,0,0,1-.76-1.24,4.82,4.82,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.89,3.89,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.42,3.42,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,499.31,818Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.13,2.13,0,0,0-.1.49h3.35A2.35,2.35,0,0,0,501.63,814.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M507.37,812.32v1.39h0a2.67,2.67,0,0,1,.39-.65,2.42,2.42,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.46,2.46,0,0,1,.76-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35-.05-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M511,814.62a2.43,2.43,0,0,1,.36-1.2,2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,9,9,0,0,1,1.14.08,3.85,3.85,0,0,1,1.06.31,2.06,2.06,0,0,1,.78.65,1.81,1.81,0,0,1,.3,1.09v3.89a7.43,7.43,0,0,0,.06,1,1.9,1.9,0,0,0,.2.7h-2.08c0-.12-.07-.23-.1-.35s0-.25-.05-.37a2.75,2.75,0,0,1-1.15.71,4.87,4.87,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.78,1.78,0,0,1-.55-.69,2.38,2.38,0,0,1-.19-1,2.15,2.15,0,0,1,.22-1,1.78,1.78,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37c.31-.08.61-.14.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.58.58,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.78,2.78,0,0,0-.51,0,1.59,1.59,0,0,0-1,.26,1.16,1.16,0,0,0-.41.87Zm4.75,1.52a1,1,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19,1,1,0,0,0-.27.31,1.14,1.14,0,0,0-.1.48,1,1,0,0,0,.1.46.74.74,0,0,0,.28.3,1.28,1.28,0,0,0,.4.15,2.61,2.61,0,0,0,.48,0,1.75,1.75,0,0,0,.94-.21,1.29,1.29,0,0,0,.5-.48,1.6,1.6,0,0,0,.19-.57c0-.2,0-.35,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M523.44,811.16v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M529.07,812.32v1h0a2.36,2.36,0,0,1,1-1,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M543.15,813.87h-3.61v-1.55h6.31v1.55L542,818.25h4.17v1.55h-6.88v-1.55Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M553.67,819.8l-1.33-5h0l-1.27,5h-2.13l-2.37-7.48h2.17l1.37,5.08h0l1.24-5.08h2l1.28,5.06h0l1.38-5.06h2.11l-2.36,7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M561.21,818a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.23,1.23,0,0,0,.55-.66H566a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.27,3.27,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.5,4.5,0,0,1,.27-1.57,3.75,3.75,0,0,1,.79-1.25,3.63,3.63,0,0,1,1.2-.83,4,4,0,0,1,1.56-.31,3.6,3.6,0,0,1,1.66.37,3.33,3.33,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,561.21,818Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.84,2.84,0,0,0-.1.49h3.35A2.35,2.35,0,0,0,563.53,814.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M567.38,811.16v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M580.45,809.47l3.86,10.33H582l-.78-2.3h-3.86l-.82,2.3h-2.28l3.91-10.33Zm.13,6.33L579.27,812h0l-1.34,3.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M586.94,812.32v1.39h0a2.45,2.45,0,0,1,1-1.15,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35-.05-.39,0a2.26,2.26,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37H585v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M592.68,809.47v3.76h0a2.18,2.18,0,0,1,1-.85,3.36,3.36,0,0,1,1.33-.27,2.79,2.79,0,0,1,1.12.24,2.9,2.9,0,0,1,1,.7,3.84,3.84,0,0,1,.72,1.23,5.23,5.23,0,0,1,.27,1.77,5.15,5.15,0,0,1-.27,1.77,3.84,3.84,0,0,1-.72,1.23,2.83,2.83,0,0,1-2.11.94,4,4,0,0,1-1.47-.26,1.91,1.91,0,0,1-1-.89h0v1h-2V809.47Zm3.32,5.68a2.25,2.25,0,0,0-.31-.76,1.74,1.74,0,0,0-.55-.53,1.49,1.49,0,0,0-.79-.2,1.53,1.53,0,0,0-.78.2,1.74,1.74,0,0,0-.55.53,2.25,2.25,0,0,0-.31.76,3.93,3.93,0,0,0-.11.92,3.8,3.8,0,0,0,.11.89,2.31,2.31,0,0,0,.31.77,1.74,1.74,0,0,0,.55.53,1.53,1.53,0,0,0,.78.19,1.49,1.49,0,0,0,.79-.19,1.74,1.74,0,0,0,.55-.53A2.31,2.31,0,0,0,596,817a3.8,3.8,0,0,0,.11-.89A3.93,3.93,0,0,0,596,815.15Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M601.66,818a1.86,1.86,0,0,0,1.34.45,1.82,1.82,0,0,0,1.07-.31,1.28,1.28,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.42,5.42,0,0,1,.15,1.67h-5.4A2,2,0,0,0,601.66,818Zm2.33-3.93a1.45,1.45,0,0,0-1.12-.41,1.78,1.78,0,0,0-.82.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,604,814.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M607.83,811.16v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M615.62,812.32v1.37h-1.51v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.63a6.48,6.48,0,0,1-.9-.06,2.15,2.15,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.18-.91v-4.42h-1.25v-1.37h1.25v-2.25h2.05v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M618.33,817.94a1.2,1.2,0,0,0,.35.39,1.5,1.5,0,0,0,.5.22,2.33,2.33,0,0,0,.59.08,2.58,2.58,0,0,0,.46,0,2,2,0,0,0,.43-.16,1.15,1.15,0,0,0,.33-.29.81.81,0,0,0,.13-.46c0-.31-.21-.54-.62-.69a12.17,12.17,0,0,0-1.71-.47,8.1,8.1,0,0,1-.88-.23,3.16,3.16,0,0,1-.76-.37,1.8,1.8,0,0,1-.54-.56,1.67,1.67,0,0,1-.2-.83,2.33,2.33,0,0,1,.28-1.19,2.1,2.1,0,0,1,.75-.73,3.23,3.23,0,0,1,1-.38,6.67,6.67,0,0,1,1.19-.11,5.76,5.76,0,0,1,1.18.12,3.28,3.28,0,0,1,1,.39,2.24,2.24,0,0,1,.74.73,2.48,2.48,0,0,1,.36,1.15h-2a.88.88,0,0,0-.44-.8,2.06,2.06,0,0,0-1-.21l-.38,0a1.9,1.9,0,0,0-.36.09.78.78,0,0,0-.29.21.62.62,0,0,0-.11.37.53.53,0,0,0,.2.45,1.56,1.56,0,0,0,.53.29c.22.07.46.13.74.19l.86.19q.45.11.87.24a2.86,2.86,0,0,1,.76.39,2,2,0,0,1,.54.59,1.82,1.82,0,0,1,.2.88,2.34,2.34,0,0,1-.3,1.23,2.22,2.22,0,0,1-.77.81,3.18,3.18,0,0,1-1.09.42,5.86,5.86,0,0,1-1.26.13,6.76,6.76,0,0,1-1.27-.13,3.39,3.39,0,0,1-1.11-.44,2.52,2.52,0,0,1-.79-.8,2.49,2.49,0,0,1-.34-1.25h2A1.15,1.15,0,0,0,618.33,817.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M628.71,815v1.76h-4.35V815Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M632.22,817.25a1.6,1.6,0,0,0,.5.59,2,2,0,0,0,.73.33,3.58,3.58,0,0,0,.87.11,5.13,5.13,0,0,0,.65,0,2,2,0,0,0,.65-.2,1.38,1.38,0,0,0,.5-.39,1,1,0,0,0,.21-.65.91.91,0,0,0-.27-.68,2.08,2.08,0,0,0-.7-.43,6.82,6.82,0,0,0-1-.31c-.36-.08-.74-.18-1.11-.29a8.7,8.7,0,0,1-1.13-.35,3.86,3.86,0,0,1-1-.54,2.53,2.53,0,0,1-.71-.84,2.59,2.59,0,0,1-.26-1.2,2.7,2.7,0,0,1,.34-1.39,3,3,0,0,1,.89-1,3.72,3.72,0,0,1,1.24-.58,5.12,5.12,0,0,1,1.39-.19,6.66,6.66,0,0,1,1.56.18,4,4,0,0,1,1.32.59,2.94,2.94,0,0,1,.92,1,3.15,3.15,0,0,1,.34,1.53H636a1.71,1.71,0,0,0-.2-.77,1.27,1.27,0,0,0-.44-.48,2,2,0,0,0-.63-.24,3.67,3.67,0,0,0-.77-.07,2.56,2.56,0,0,0-.55,0,1.48,1.48,0,0,0-.5.21,1.16,1.16,0,0,0-.37.36.94.94,0,0,0-.14.55.91.91,0,0,0,.11.49,1,1,0,0,0,.46.35,6.66,6.66,0,0,0,.94.32l1.57.4c.19,0,.46.11.8.21a3.66,3.66,0,0,1,1,.49,3,3,0,0,1,.88.89,2.56,2.56,0,0,1,.37,1.42,3.1,3.1,0,0,1-.28,1.32,2.84,2.84,0,0,1-.82,1.05,4.13,4.13,0,0,1-1.34.69,7,7,0,0,1-3.52,0,4.21,4.21,0,0,1-1.42-.66,3.25,3.25,0,0,1-1-1.14,3.3,3.3,0,0,1-.35-1.65H632A1.82,1.82,0,0,0,632.22,817.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M643.23,813.66a1.42,1.42,0,0,0-.82.23,1.7,1.7,0,0,0-.55.58,2.21,2.21,0,0,0-.29.78,3.92,3.92,0,0,0,0,1.67,2.38,2.38,0,0,0,.28.76,1.58,1.58,0,0,0,.53.56,1.72,1.72,0,0,0,1.94-.2,1.91,1.91,0,0,0,.5-1.11h2a3.44,3.44,0,0,1-1.16,2.28,3.75,3.75,0,0,1-2.45.78,4.26,4.26,0,0,1-1.54-.28,3.44,3.44,0,0,1-1.19-.79,3.53,3.53,0,0,1-.77-1.21,4.26,4.26,0,0,1-.28-1.54,5,5,0,0,1,.26-1.62,3.75,3.75,0,0,1,.74-1.29,3.42,3.42,0,0,1,1.2-.84,4.19,4.19,0,0,1,1.63-.31,4.55,4.55,0,0,1,1.28.18,3.3,3.3,0,0,1,1.1.53,2.91,2.91,0,0,1,.79.88,2.85,2.85,0,0,1,.34,1.25h-2A1.38,1.38,0,0,0,643.23,813.66Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M650,809.47v3.89h0a2.39,2.39,0,0,1,1-1,2.69,2.69,0,0,1,1.19-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.05v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.75,2.75,0,0,0-.38,1.65v3.92H648V809.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M658.5,812.32v1.39h0a2.39,2.39,0,0,1,.39-.65,2.42,2.42,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.46,2.46,0,0,1,.76-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35-.05-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M662.23,811.16v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M670,812.32v1.37h-1.5v3.71a.7.7,0,0,0,.86.87l.34,0,.3,0v1.59a4.71,4.71,0,0,1-.58.06h-.62a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.24,1.24,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42h-1.24v-1.37h1.24v-2.25h2.06v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M675.12,812.32v1.37h-1.51v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.63a6.48,6.48,0,0,1-.9-.06,2.08,2.08,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.19-.91v-4.42h-1.24v-1.37h1.24v-2.25h2.06v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M678.3,818a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.8,3.8,0,0,1-.76-1.24,4.82,4.82,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.89,3.89,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.42,3.42,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,678.3,818Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.13,2.13,0,0,0-.1.49h3.35A2.35,2.35,0,0,0,680.62,814.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M686.36,812.32v1h0a2.33,2.33,0,0,1,1-1,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.12,6.12,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.68,2.68,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M695.36,817.57v2.23a2.19,2.19,0,0,1-.18.9,2.27,2.27,0,0,1-.48.72,2.65,2.65,0,0,1-.72.5,3.26,3.26,0,0,1-.87.27v-1a1.14,1.14,0,0,0,.41-.18,1.67,1.67,0,0,0,.34-.31,1.41,1.41,0,0,0,.22-.4,1.44,1.44,0,0,0,0-.46h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M703.53,809.47v8.42h5v1.91h-7.31V809.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M712.11,809.47V819.8h-2.27V809.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M716.35,819.8,713,809.47h2.33l2.3,7.26h0l2.33-7.26h2.34l-3.43,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M731,809.47v1.91H725.5v2.21h5v1.77h-5v2.53h5.57v1.91h-7.85V809.47Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    {/* let go */}
    <Link
      to={`${url}single/let_go____mit_leichtigkeit_die__un__tiefen_der_rolle_erforschen`}
    >
      <g id="let-go">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="343.04"
          y="455.48"
          width="253.79"
          height="79.3"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M431.35,545.07v14.27h8.54v3.24H427.5V545.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M455.14,545.07v3.24H445.9v3.75h8.48v3H445.9v4.29h9.44v3.24H442.05V545.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M456.56,548.31v-3.24h14.35v3.24h-5.25v14.27h-3.85V548.31Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M490.11,562.44a6.27,6.27,0,0,1-2.52.53,8.92,8.92,0,0,1-3.62-.7,7.59,7.59,0,0,1-2.71-1.92,8.46,8.46,0,0,1-1.69-2.88,10.45,10.45,0,0,1-.59-3.57,10.73,10.73,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.71,7.71,0,0,1,2.71-2,8.92,8.92,0,0,1,3.62-.71,8.53,8.53,0,0,1,2.61.41,7.3,7.3,0,0,1,2.28,1.19,6.51,6.51,0,0,1,1.68,1.93,6.64,6.64,0,0,1,.81,2.65h-3.68a3.65,3.65,0,0,0-1.33-2.2,3.77,3.77,0,0,0-2.37-.74,4.54,4.54,0,0,0-2.21.5,4.15,4.15,0,0,0-1.47,1.35,5.86,5.86,0,0,0-.82,1.93,9.14,9.14,0,0,0-.26,2.23,8.48,8.48,0,0,0,.26,2.14,5.69,5.69,0,0,0,.82,1.88,4.13,4.13,0,0,0,1.47,1.34,4.54,4.54,0,0,0,2.21.5,3.75,3.75,0,0,0,4.16-3.78h-3.87v-2.87h7.36v9.47h-2.46l-.39-2A5.61,5.61,0,0,1,490.11,562.44Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M498.18,550.26a8.76,8.76,0,0,1,1.69-2.93,7.8,7.8,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,7.86,7.86,0,0,1,2.7,2,8.76,8.76,0,0,1,1.69,2.93,11,11,0,0,1,.59,3.64,10.7,10.7,0,0,1-.59,3.57,8.62,8.62,0,0,1-1.69,2.88,7.73,7.73,0,0,1-2.7,1.92,9.74,9.74,0,0,1-7.25,0,7.68,7.68,0,0,1-2.71-1.92,8.62,8.62,0,0,1-1.69-2.88,10.7,10.7,0,0,1-.59-3.57A11,11,0,0,1,498.18,550.26ZM501.7,556a5.69,5.69,0,0,0,.82,1.88,4.3,4.3,0,0,0,1.47,1.34,5.13,5.13,0,0,0,4.42,0,4.3,4.3,0,0,0,1.47-1.34,5.9,5.9,0,0,0,.82-1.88,9,9,0,0,0,.26-2.14,9.65,9.65,0,0,0-.26-2.23,6.08,6.08,0,0,0-.82-1.93,4.33,4.33,0,0,0-1.47-1.35,5.13,5.13,0,0,0-4.42,0,4.33,4.33,0,0,0-1.47,1.35,5.86,5.86,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23A9,9,0,0,0,501.7,556Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M521.08,558.8v3.78h-3.85V558.8ZM520,557.33h-1.74l-.93-7.55v-4.71H521v4.71Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M430,570.25l2.42,7.1h0l2.28-7.1h3.2v10.33h-2.13v-7.32h0l-2.53,7.32H431.5L429,573.33h0v7.25h-2.13V570.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M439.78,571.94v-1.69h2.05v1.69Zm2.05,1.16v7.48h-2.05V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M447.57,573.1v1.37h-1.51v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.17h.33l.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.63a6.48,6.48,0,0,1-.9-.06,2.08,2.08,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.19-.91v-4.42h-1.24V573.1H444v-2.25h2.06v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M455.06,570.25v8.42h5v1.91h-7.31V570.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M463.36,578.78a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.55,4.55,0,0,1-.27-1.6,4.45,4.45,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,463.36,578.78Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.35,2.35,0,0,0,465.68,574.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M469.52,571.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M476.77,574.44a1.42,1.42,0,0,0-.82.23,1.8,1.8,0,0,0-.55.58,2.42,2.42,0,0,0-.29.78,3.92,3.92,0,0,0,0,1.67,2.65,2.65,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.48,1.48,0,0,0,.81.21,1.5,1.5,0,0,0,1.14-.41,1.9,1.9,0,0,0,.49-1.11h2a3.43,3.43,0,0,1-1.16,2.28,3.75,3.75,0,0,1-2.45.78,4.26,4.26,0,0,1-1.54-.28,3.45,3.45,0,0,1-2-2A4.26,4.26,0,0,1,473,577a5,5,0,0,1,.25-1.62A3.92,3.92,0,0,1,474,574a3.37,3.37,0,0,1,1.21-.84,4.1,4.1,0,0,1,1.62-.31,4.55,4.55,0,0,1,1.28.18,3.3,3.3,0,0,1,1.1.53,2.91,2.91,0,0,1,.79.88,2.85,2.85,0,0,1,.34,1.25h-2A1.38,1.38,0,0,0,476.77,574.44Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M483.56,570.25v3.89h0a2.39,2.39,0,0,1,1-.95,2.69,2.69,0,0,1,1.19-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.65,2.65,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.46,1.42,1.42,0,0,0-1.21.5,2.8,2.8,0,0,0-.38,1.65v3.92H481.5V570.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M494.2,573.1v1.37h-1.51v3.71a1,1,0,0,0,.18.69,1,1,0,0,0,.69.17h.33l.31,0v1.59a4.93,4.93,0,0,1-.58.06H493a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.24,1.24,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42h-1.25V573.1h1.25v-2.25h2.05v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M495.24,571.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M506,581.13a2.6,2.6,0,0,1-.53,1.08,3.15,3.15,0,0,1-1.17.84,5,5,0,0,1-2.06.35,4.35,4.35,0,0,1-1.12-.14,3.55,3.55,0,0,1-1-.42,2.43,2.43,0,0,1-.78-.75,2.14,2.14,0,0,1-.36-1.09h2a1.2,1.2,0,0,0,.57.8,2,2,0,0,0,1,.23,1.5,1.5,0,0,0,1.26-.52,2,2,0,0,0,.39-1.32v-1h0a2.07,2.07,0,0,1-.95.88,3,3,0,0,1-1.3.28,3.33,3.33,0,0,1-1.44-.29,2.68,2.68,0,0,1-1-.82,3.34,3.34,0,0,1-.57-1.2,5.64,5.64,0,0,1-.19-1.45,4.7,4.7,0,0,1,.21-1.4,3.59,3.59,0,0,1,.62-1.18,2.88,2.88,0,0,1,1-.82,3,3,0,0,1,1.38-.31,2.92,2.92,0,0,1,1.29.28,2.21,2.21,0,0,1,.94.93h0v-1h1.95v7A5.18,5.18,0,0,1,506,581.13Zm-2.82-2.49a1.65,1.65,0,0,0,.55-.46,2.27,2.27,0,0,0,.33-.66,2.88,2.88,0,0,0,.11-.78,4.07,4.07,0,0,0-.09-.87,2.28,2.28,0,0,0-.29-.73,1.47,1.47,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.49,1.49,0,0,0-1.26.65,2,2,0,0,0-.31.68,3.44,3.44,0,0,0-.09.8,4.26,4.26,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.61,1.61,0,0,0,.52.52,1.41,1.41,0,0,0,.79.2A1.59,1.59,0,0,0,503.17,578.64Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M510,570.25v5.54l2.59-2.69H515l-2.83,2.75,3.14,4.73h-2.49l-2.05-3.34-.8.76v2.58H508V570.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M518.27,578.78a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66H523a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.55,4.55,0,0,1-.26-1.6,4.45,4.45,0,0,1,.27-1.56,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.51,3.51,0,0,1,1.17,1,4.09,4.09,0,0,1,.65,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,518.27,578.78Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.26,2.26,0,0,0,520.59,574.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M524.43,571.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M532.22,573.1v1.37h-1.51v3.71a1,1,0,0,0,.18.69,1,1,0,0,0,.69.17h.34l.3,0v1.59a4.71,4.71,0,0,1-.58.06H531a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.24,1.24,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42h-1.25V573.1h1.25v-2.25h2.05v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M542.54,579.62a2.11,2.11,0,0,1-.95.88,3.11,3.11,0,0,1-1.32.27,3.17,3.17,0,0,1-1.48-.33,3.09,3.09,0,0,1-1-.88,4.12,4.12,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.12,3.12,0,0,1,1-.86,3,3,0,0,1,1.44-.33,2.92,2.92,0,0,1,1.27.29,2.13,2.13,0,0,1,1,.83h0v-3.76h2v10.33h-1.95v-1Zm-.09-3.69a2.45,2.45,0,0,0-.29-.76,1.71,1.71,0,0,0-.55-.52,1.54,1.54,0,0,0-.84-.21,1.62,1.62,0,0,0-.85.21,1.58,1.58,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,3.92,3.92,0,0,0,0,1.76,2.33,2.33,0,0,0,.33.77,1.76,1.76,0,0,0,.57.55,1.48,1.48,0,0,0,.81.21,1.6,1.6,0,0,0,.85-.2,1.47,1.47,0,0,0,.54-.54,2.42,2.42,0,0,0,.29-.78,4.41,4.41,0,0,0,.09-.89A4.26,4.26,0,0,0,542.45,575.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M546.13,571.94v-1.69h2v1.69Zm2,1.16v7.48h-2V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M552,578.78a1.86,1.86,0,0,0,1.34.45,1.82,1.82,0,0,0,1.07-.31,1.28,1.28,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.55,4.55,0,0,1-.27-1.6,4.24,4.24,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.9,3.9,0,0,1,1.55-.31,3.64,3.64,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.42,5.42,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,552,578.78Zm2.33-3.93a1.45,1.45,0,0,0-1.12-.41,1.78,1.78,0,0,0-.82.17,1.54,1.54,0,0,0-.8.93,2.66,2.66,0,0,0-.09.5h3.34A2.43,2.43,0,0,0,554.33,574.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M564.54,573.19a15.27,15.27,0,0,0-.38,3.37,15.47,15.47,0,0,0,.37,3.4,13.19,13.19,0,0,0,1.19,3.24H564a13.2,13.2,0,0,1-1.41-3.14,11.31,11.31,0,0,1-.5-3.36,12.7,12.7,0,0,1,.12-1.7,13.75,13.75,0,0,1,.95-3.48A8.87,8.87,0,0,1,564,570h1.72A12.59,12.59,0,0,0,564.54,573.19Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M574.23,579.79a5.69,5.69,0,0,1-6.49,0,4,4,0,0,1-1.15-3.13v-6.42h2.28v6.42a4.57,4.57,0,0,0,.07.83,1.6,1.6,0,0,0,.95,1.22,2.57,2.57,0,0,0,1.1.2,2.14,2.14,0,0,0,1.68-.55,2.58,2.58,0,0,0,.46-1.7v-6.42h2.28v6.42A3.89,3.89,0,0,1,574.23,579.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M579.1,573.1v1h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.65,2.65,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.46,1.42,1.42,0,0,0-1.21.5,2.73,2.73,0,0,0-.38,1.65v3.92h-2.06V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M590.07,575.75v1.76h-4.36v-1.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M591.93,580a15,15,0,0,0,.38-3.35,15.56,15.56,0,0,0-.37-3.41,14.48,14.48,0,0,0-1.18-3.26h1.7a13.49,13.49,0,0,1,1.41,3.15,11.82,11.82,0,0,1,.5,3.38,12.81,12.81,0,0,1-.12,1.7,13.36,13.36,0,0,1-.35,1.76,12.22,12.22,0,0,1-.6,1.7,9.08,9.08,0,0,1-.84,1.51h-1.71A13.7,13.7,0,0,0,591.93,580Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M595.31,572.16v-1.91h8.46v1.91h-3.09v8.42h-2.27v-8.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M604.8,571.94v-1.69h2.06v1.69Zm2.06,1.16v7.48H604.8V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M610.68,578.78a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.8,3.8,0,0,1-.76-1.24,4.79,4.79,0,0,1-.26-1.6,4.45,4.45,0,0,1,.27-1.56,3.89,3.89,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.42,3.42,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,610.68,578.78Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.35A2.35,2.35,0,0,0,613,574.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M616,574.47V573.1h1.23v-.58a2.24,2.24,0,0,1,.63-1.64,2.53,2.53,0,0,1,1.88-.63,5.06,5.06,0,0,1,.55,0l.53,0v1.54a6,6,0,0,0-.75-.05.79.79,0,0,0-.6.2,1,1,0,0,0-.18.66v.45h1.42v1.37h-1.42v6.11h-2.06v-6.11Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M623.81,578.78a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.18.58,4.5,4.5,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.55,4.55,0,0,1-.27-1.6,4.45,4.45,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.31,3.64,3.64,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,623.81,578.78Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.26,2.26,0,0,0,626.13,574.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M631.87,573.1v1h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.65,2.65,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.46,1.45,1.45,0,0,0-1.22.5,2.81,2.81,0,0,0-.37,1.65v3.92h-2.06V573.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M431.91,596.62a2.17,2.17,0,0,1-1,.88,3.16,3.16,0,0,1-1.33.27,3.12,3.12,0,0,1-1.47-.33,2.91,2.91,0,0,1-1.05-.88,3.72,3.72,0,0,1-.62-1.28,5.06,5.06,0,0,1-.21-1.51,4.71,4.71,0,0,1,.21-1.45,3.53,3.53,0,0,1,.62-1.24,3,3,0,0,1,2.48-1.19,3,3,0,0,1,1.27.29,2.1,2.1,0,0,1,.94.83h0v-3.76h2.06v10.33h-2v-1Zm-.09-3.69a2.24,2.24,0,0,0-.3-.76,1.68,1.68,0,0,0-.54-.52,1.54,1.54,0,0,0-.84-.21,1.6,1.6,0,0,0-.85.21,1.5,1.5,0,0,0-.56.53,2.58,2.58,0,0,0-.31.76,4.33,4.33,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.33,2.33,0,0,0,.33.77,1.73,1.73,0,0,0,.56.55,1.54,1.54,0,0,0,.82.21,1.6,1.6,0,0,0,.85-.2,1.64,1.64,0,0,0,.54-.54,2.68,2.68,0,0,0,.29-.78,4.41,4.41,0,0,0,.09-.89A4.26,4.26,0,0,0,431.82,592.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M437.64,595.78a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.55,4.55,0,0,1-.26-1.6,4.45,4.45,0,0,1,.27-1.56,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.51,3.51,0,0,1,1.17,1,4.09,4.09,0,0,1,.65,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,437.64,595.78Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.26,2.26,0,0,0,440,591.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M445.7,590.1v1.39h0a2.16,2.16,0,0,1,.39-.65,2.72,2.72,0,0,1,.56-.51,3.26,3.26,0,0,1,.68-.32,2.37,2.37,0,0,1,.76-.12,1.52,1.52,0,0,1,.44.08v1.91l-.34,0-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V590.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M459.19,587.25a3.31,3.31,0,0,1,1.25.22,2.82,2.82,0,0,1,.95.61,2.75,2.75,0,0,1,.61.91,2.89,2.89,0,0,1,.21,1.11,3.15,3.15,0,0,1-.38,1.57,2.39,2.39,0,0,1-1.25,1v0a1.61,1.61,0,0,1,.69.35,1.93,1.93,0,0,1,.45.57,2.3,2.3,0,0,1,.25.71,5.23,5.23,0,0,1,.11.78c0,.17,0,.36,0,.58s0,.45.05.68a4.54,4.54,0,0,0,.12.66,1.43,1.43,0,0,0,.24.53h-2.28a4.26,4.26,0,0,1-.23-1.17c0-.46-.07-.89-.13-1.31a2.05,2.05,0,0,0-.49-1.18,1.69,1.69,0,0,0-1.23-.38h-2.27v4h-2.27V587.25Zm-.81,4.67a1.72,1.72,0,0,0,1.17-.35,1.45,1.45,0,0,0,.39-1.13,1.38,1.38,0,0,0-.39-1.09,1.72,1.72,0,0,0-1.17-.34h-2.49v2.91Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M463.89,592.22a3.4,3.4,0,0,1,.78-1.25,3.47,3.47,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.37,3.37,0,0,1,2,2,4.41,4.41,0,0,1,.28,1.62,4.46,4.46,0,0,1-.28,1.63,3.4,3.4,0,0,1-.78,1.23,3.26,3.26,0,0,1-1.22.79,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.34,3.34,0,0,1-1.22-.79,3.4,3.4,0,0,1-.78-1.23,4.68,4.68,0,0,1-.27-1.63A4.63,4.63,0,0,1,463.89,592.22Zm1.87,2.5a2.62,2.62,0,0,0,.29.77,1.61,1.61,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.71,1.71,0,0,0,.88-.2,1.61,1.61,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.24,4.24,0,0,0,.08-.88,4.37,4.37,0,0,0-.08-.88,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.71,1.71,0,0,0-.88-.21,1.58,1.58,0,0,0-1.43.76,2.45,2.45,0,0,0-.29.76,4.38,4.38,0,0,0-.09.88A4.24,4.24,0,0,0,465.76,594.72Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M474.8,587.25v10.33h-2.05V587.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M478.54,587.25v10.33h-2.06V587.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M482.36,595.78a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.28,1.28,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.8,3.8,0,0,1-.76-1.24,4.79,4.79,0,0,1-.26-1.6,4.45,4.45,0,0,1,.27-1.56,3.89,3.89,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.42,3.42,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,482.36,595.78Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.35A2.35,2.35,0,0,0,484.68,591.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M494.69,595.78a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.23,1.23,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.19,3.19,0,0,1-1.2-.81,3.49,3.49,0,0,1-.76-1.24,4.55,4.55,0,0,1-.27-1.6,4.45,4.45,0,0,1,.27-1.56,3.75,3.75,0,0,1,.79-1.25,3.63,3.63,0,0,1,1.2-.83,4,4,0,0,1,1.56-.31,3.6,3.6,0,0,1,1.66.37,3.33,3.33,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,494.69,595.78Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.35A2.35,2.35,0,0,0,497,591.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M502.75,590.1v1.39h0a2.67,2.67,0,0,1,.39-.65,2.78,2.78,0,0,1,.57-.51,3,3,0,0,1,.68-.32,2.36,2.36,0,0,1,.75-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35,0-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2.2,2.2,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37H500.8V590.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M505.65,591.47V590.1h1.23v-.58a2.23,2.23,0,0,1,.62-1.64,2.53,2.53,0,0,1,1.88-.63,5.06,5.06,0,0,1,.55,0l.54,0v1.54a6.33,6.33,0,0,0-.76-.05.79.79,0,0,0-.6.2,1,1,0,0,0-.18.66v.45h1.42v1.37h-1.42v6.11h-2.05v-6.11Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M511.29,592.22a3.4,3.4,0,0,1,.78-1.25,3.47,3.47,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.37,3.37,0,0,1,2,2,4.41,4.41,0,0,1,.28,1.62,4.46,4.46,0,0,1-.28,1.63,3.4,3.4,0,0,1-.78,1.23,3.26,3.26,0,0,1-1.22.79,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.34,3.34,0,0,1-1.22-.79,3.4,3.4,0,0,1-.78-1.23,4.68,4.68,0,0,1-.27-1.63A4.63,4.63,0,0,1,511.29,592.22Zm1.87,2.5a2.37,2.37,0,0,0,.29.77,1.61,1.61,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.71,1.71,0,0,0,.88-.2,1.61,1.61,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,5.05,5.05,0,0,0,.08-.88,5.24,5.24,0,0,0-.08-.88,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.71,1.71,0,0,0-.88-.21,1.58,1.58,0,0,0-1.43.76,2.23,2.23,0,0,0-.29.76,4.38,4.38,0,0,0-.09.88A4.24,4.24,0,0,0,513.16,594.72Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M522,590.1v1.39h0a2.67,2.67,0,0,1,.39-.65,3.06,3.06,0,0,1,.57-.51,3.26,3.26,0,0,1,.68-.32,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35,0a2.56,2.56,0,0,0-.39,0,2.26,2.26,0,0,0-.95.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V590.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M527.45,595.72a1.2,1.2,0,0,0,.35.39,1.5,1.5,0,0,0,.5.22,2.33,2.33,0,0,0,.59.08,2.49,2.49,0,0,0,.46,0,1.82,1.82,0,0,0,.43-.16,1.15,1.15,0,0,0,.33-.29.83.83,0,0,0,.13-.46c0-.31-.21-.54-.62-.69a12.17,12.17,0,0,0-1.71-.47,6.74,6.74,0,0,1-.88-.24,3,3,0,0,1-.76-.36,1.67,1.67,0,0,1-.53-.56,1.57,1.57,0,0,1-.21-.83,2.33,2.33,0,0,1,.28-1.19,2.1,2.1,0,0,1,.75-.73,3.49,3.49,0,0,1,1-.38,6.67,6.67,0,0,1,1.19-.11A5.76,5.76,0,0,1,530,590a3.28,3.28,0,0,1,1,.39,2.24,2.24,0,0,1,.74.73,2.48,2.48,0,0,1,.36,1.15h-2a.9.9,0,0,0-.45-.8,2.06,2.06,0,0,0-1-.21l-.38,0a1.36,1.36,0,0,0-.36.09.86.86,0,0,0-.29.21.62.62,0,0,0-.11.37.56.56,0,0,0,.2.45,1.48,1.48,0,0,0,.53.28,5.59,5.59,0,0,0,.74.2l.86.19q.45.1.87.24a2.86,2.86,0,0,1,.76.39,1.79,1.79,0,0,1,.54.59,1.82,1.82,0,0,1,.2.88,2.26,2.26,0,0,1-1.07,2,3.18,3.18,0,0,1-1.09.42,5.83,5.83,0,0,1-1.25.13,6.78,6.78,0,0,1-1.28-.13,3.26,3.26,0,0,1-1.1-.44,2.45,2.45,0,0,1-.8-.8,2.49,2.49,0,0,1-.34-1.25h1.95A1.15,1.15,0,0,0,527.45,595.72Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M537.07,591.44a1.45,1.45,0,0,0-.83.23,1.77,1.77,0,0,0-.54.58,2.44,2.44,0,0,0-.3.78,4.4,4.4,0,0,0,0,1.67,2.66,2.66,0,0,0,.29.76,1.64,1.64,0,0,0,.52.56,1.53,1.53,0,0,0,.81.21,1.48,1.48,0,0,0,1.14-.41,1.91,1.91,0,0,0,.5-1.11h2a3.38,3.38,0,0,1-1.16,2.28,3.72,3.72,0,0,1-2.44.78,4.26,4.26,0,0,1-1.54-.28,3.58,3.58,0,0,1-1.2-.79,3.66,3.66,0,0,1-.76-1.21,4.06,4.06,0,0,1-.28-1.54,5.29,5.29,0,0,1,.25-1.62,3.77,3.77,0,0,1,.75-1.29,3.42,3.42,0,0,1,1.2-.84,4.1,4.1,0,0,1,1.62-.31,4.5,4.5,0,0,1,1.28.18,3.21,3.21,0,0,1,1.1.53,2.67,2.67,0,0,1,.79.88,2.86,2.86,0,0,1,.35,1.25h-2A1.38,1.38,0,0,0,537.07,591.44Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M543.86,587.25v3.89h0a2.34,2.34,0,0,1,1-.95,2.65,2.65,0,0,1,1.18-.3,3.5,3.5,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.66,2.66,0,0,0-.28-1.38,1.15,1.15,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.81,2.81,0,0,0-.37,1.65v3.92H541.8V587.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M552.58,595.78a2.12,2.12,0,0,0,2.41.14,1.33,1.33,0,0,0,.55-.66h1.8a3.4,3.4,0,0,1-1.33,1.93,3.94,3.94,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.55,4.55,0,0,1-.27-1.6,4.24,4.24,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,4.11,4.11,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.39A2,2,0,0,0,552.58,595.78Zm2.33-3.93a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.66,2.66,0,0,0-.09.5h3.34A2.43,2.43,0,0,0,554.91,591.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M560.64,590.1v1h.05a2.36,2.36,0,0,1,1-.95,2.92,2.92,0,0,1,1.27-.3,3.5,3.5,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.66,2.66,0,0,0-.28-1.38,1.15,1.15,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.81,2.81,0,0,0-.37,1.65v3.92h-2.06V590.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M569.65,595.35v2.23a2.19,2.19,0,0,1-.18.9,2.29,2.29,0,0,1-.49.72,2.5,2.5,0,0,1-.72.5,3.15,3.15,0,0,1-.87.27v-1a1.14,1.14,0,0,0,.41-.18,1.43,1.43,0,0,0,.34-.31,1.31,1.31,0,0,0,.22-.4,1.22,1.22,0,0,0,.06-.46h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M577.81,587.25v8.42h5v1.91h-7.31V587.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M586.39,587.25v10.33h-2.27V587.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M590.63,597.58l-3.34-10.33h2.33l2.3,7.26h0l2.33-7.26h2.34l-3.42,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M605.24,587.25v1.91h-5.46v2.21h5v1.77h-5v2.53h5.57v1.91h-7.84V587.25Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    <Link to={`${url}single/${urlFormat('Champagne Your (E-)Casting')}`}>
      <g id="champagne-your-casting">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="55.48"
          y="618.27"
          width="402.18"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M151.17,714.21a3.69,3.69,0,0,0-.85-1,3.94,3.94,0,0,0-1.17-.65,3.89,3.89,0,0,0-1.36-.24,4.46,4.46,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,6.08,6.08,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.85,5.85,0,0,0,.82,1.87,4.2,4.2,0,0,0,1.47,1.34,4.46,4.46,0,0,0,2.21.5,3.56,3.56,0,0,0,2.76-1.08,4.93,4.93,0,0,0,1.21-2.84h3.73a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.94,6.94,0,0,1-2.35,1.43,8.81,8.81,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93,8.62,8.62,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.76,8.76,0,0,1,3.62-.71,9.08,9.08,0,0,1,2.74.41,7.57,7.57,0,0,1,2.3,1.22,6.54,6.54,0,0,1,1.68,2,7.06,7.06,0,0,1,.83,2.7h-3.72A3.27,3.27,0,0,0,151.17,714.21Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M162,709.54v6.72h7.09v-6.72h3.85v17.51h-3.85V719.5H162v7.55h-3.84V709.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M185,709.54l6.54,17.51h-4l-1.33-3.9H179.7l-1.38,3.9h-3.87l6.62-17.51Zm.22,10.74L183,713.86H183l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M198.5,709.54l4.1,12h.05l3.87-12h5.42v17.51h-3.6V714.64h-.05L204,727.05h-3l-4.29-12.28h-.05v12.28h-3.61V709.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M223.22,709.54a7.28,7.28,0,0,1,2.8.48,5.35,5.35,0,0,1,1.88,1.26,4.92,4.92,0,0,1,1,1.79,6.72,6.72,0,0,1,0,4.16,4.8,4.8,0,0,1-1,1.8A5.24,5.24,0,0,1,226,720.3a7.28,7.28,0,0,1-2.8.48h-4v6.27h-3.85V709.54Zm-1.05,8.24a8.87,8.87,0,0,0,1.28-.09,3,3,0,0,0,1.07-.39,2,2,0,0,0,.75-.79,3.39,3.39,0,0,0,0-2.7,2.14,2.14,0,0,0-.75-.8,3.23,3.23,0,0,0-1.07-.38,8.87,8.87,0,0,0-1.28-.09h-3v5.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M238.6,709.54l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9H228l6.62-17.51Zm.22,10.74-2.21-6.42h-.05l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M257,726.92a6.44,6.44,0,0,1-2.52.53,9.09,9.09,0,0,1-3.62-.7,7.74,7.74,0,0,1-2.71-1.93,8.46,8.46,0,0,1-1.69-2.88,10.37,10.37,0,0,1-.59-3.57,10.73,10.73,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.69,7.69,0,0,1,2.71-2,8.76,8.76,0,0,1,3.62-.71,8.47,8.47,0,0,1,2.61.4,7.51,7.51,0,0,1,2.28,1.19,6.43,6.43,0,0,1,1.68,1.94,6.59,6.59,0,0,1,.81,2.65h-3.68a3.71,3.71,0,0,0-1.32-2.21,3.88,3.88,0,0,0-2.38-.74,4.43,4.43,0,0,0-2.21.51,4,4,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.14,9.14,0,0,0-.26,2.23,8.55,8.55,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4,4,0,0,0,1.47,1.34,4.43,4.43,0,0,0,2.21.5,4.15,4.15,0,0,0,3-1,4.2,4.2,0,0,0,1.22-2.8h-3.88v-2.87h7.36v9.46h-2.46l-.39-2A5.69,5.69,0,0,1,257,726.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M269.11,709.54l7.31,11.75h0V709.54h3.61v17.51h-3.85l-7.28-11.72h-.05v11.72h-3.61V709.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M296.55,709.54v3.24H287.3v3.75h8.49v3H287.3v4.3h9.44v3.23H283.45V709.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M304.27,709.54h4.32l4.09,6.92,4.08-6.92h4.29l-6.5,10.79v6.72H310.7v-6.81Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M322.35,714.73A8.59,8.59,0,0,1,324,711.8a7.69,7.69,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,7.7,7.7,0,0,1,2.69,2,8.59,8.59,0,0,1,1.69,2.93,10.73,10.73,0,0,1,.59,3.64,10.37,10.37,0,0,1-.59,3.57,8.46,8.46,0,0,1-1.69,2.88,7.75,7.75,0,0,1-2.69,1.93,9.74,9.74,0,0,1-7.25,0,7.74,7.74,0,0,1-2.71-1.93,8.46,8.46,0,0,1-1.69-2.88,10.37,10.37,0,0,1-.59-3.57A10.73,10.73,0,0,1,322.35,714.73Zm3.52,5.79a5.64,5.64,0,0,0,.82,1.87,4,4,0,0,0,1.47,1.34,5.11,5.11,0,0,0,4.41,0,4,4,0,0,0,1.47-1.34,5.87,5.87,0,0,0,.83-1.87,9,9,0,0,0,.25-2.15,9.65,9.65,0,0,0-.25-2.23,6.1,6.1,0,0,0-.83-1.93,4,4,0,0,0-1.47-1.34,5,5,0,0,0-4.41,0,4,4,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.14,9.14,0,0,0-.26,2.23A8.55,8.55,0,0,0,325.87,720.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M354.47,725.72a9.63,9.63,0,0,1-11,0c-1.3-1.15-2-2.91-2-5.3V709.54h3.85v10.89a8.1,8.1,0,0,0,.13,1.4,2.71,2.71,0,0,0,1.6,2.07,4.46,4.46,0,0,0,1.88.33,3.57,3.57,0,0,0,2.84-.92,4.33,4.33,0,0,0,.79-2.88V709.54h3.85v10.89A6.64,6.64,0,0,1,354.47,725.72Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M369.21,709.54a5.68,5.68,0,0,1,2.12.38A4.87,4.87,0,0,1,373,711a4.54,4.54,0,0,1,1,1.53,4.83,4.83,0,0,1,.36,1.87,5.32,5.32,0,0,1-.65,2.68,4.1,4.1,0,0,1-2.12,1.71v0a3,3,0,0,1,1.17.6,3.23,3.23,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,12.52,12.52,0,0,1,.19,1.33c0,.28,0,.6.05,1s0,.76.08,1.15a7.68,7.68,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89H371a6.88,6.88,0,0,1-.39-2c-.05-.77-.12-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V709.54Zm-1.37,7.92a2.91,2.91,0,0,0,2-.58,2.43,2.43,0,0,0,.67-1.92,2.31,2.31,0,0,0-.67-1.85,3,3,0,0,0-2-.57h-4.22v4.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M395.52,714.21a3.89,3.89,0,0,0-.85-1,3.94,3.94,0,0,0-1.17-.65,3.89,3.89,0,0,0-1.36-.24,4.46,4.46,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.2,4.2,0,0,0,1.47,1.34,4.46,4.46,0,0,0,2.21.5,3.56,3.56,0,0,0,2.76-1.08,4.93,4.93,0,0,0,1.21-2.84h3.73a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.94,6.94,0,0,1-2.35,1.43,8.81,8.81,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93,8.62,8.62,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.76,8.76,0,0,1,3.62-.71,9,9,0,0,1,2.73.41,7.51,7.51,0,0,1,2.31,1.22,6.54,6.54,0,0,1,1.68,2,7.06,7.06,0,0,1,.83,2.7H396A3.27,3.27,0,0,0,395.52,714.21Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M411.19,709.54l6.55,17.51h-4l-1.33-3.9h-6.55l-1.37,3.9h-3.88l6.63-17.51Zm.23,10.74-2.21-6.42h0l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M422.21,722.74a2.71,2.71,0,0,0,.84,1,3.7,3.7,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4.08,4.08,0,0,0,1.11-.33,2.47,2.47,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.65,3.65,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.11,4.11,0,0,1-1.64-3.46,4.61,4.61,0,0,1,.57-2.34,5.3,5.3,0,0,1,1.51-1.65,6.38,6.38,0,0,1,2.11-1,8.69,8.69,0,0,1,2.35-.32,11.36,11.36,0,0,1,2.64.3,6.76,6.76,0,0,1,2.25,1,4.93,4.93,0,0,1,1.55,1.75,5.3,5.3,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.2,4.2,0,0,0-.93.1,2.4,2.4,0,0,0-.84.34,2,2,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.58,1.58,0,0,0,.19.84,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54c.68.18,1.56.41,2.66.68.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.38,5.38,0,0,1-.47,2.24,4.82,4.82,0,0,1-1.38,1.77,6.81,6.81,0,0,1-2.28,1.17,10.75,10.75,0,0,1-3.16.42,10.86,10.86,0,0,1-2.8-.36A7.12,7.12,0,0,1,420.4,726a5.52,5.52,0,0,1-1.65-1.93,5.76,5.76,0,0,1-.59-2.8h3.73A3.14,3.14,0,0,0,422.21,722.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M433.8,712.78v-3.24h14.35v3.24H442.9v14.27h-3.85V712.78Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M454,709.54v17.51h-3.85V709.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M461.22,709.54l7.31,11.75h.05V709.54h3.6v17.51h-3.85l-7.28-11.72H461v11.72h-3.6V709.54Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M485.94,726.92a6.48,6.48,0,0,1-2.52.53,9.06,9.06,0,0,1-3.62-.7,7.74,7.74,0,0,1-2.71-1.93,8.46,8.46,0,0,1-1.69-2.88,10.37,10.37,0,0,1-.59-3.57,10.73,10.73,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.69,7.69,0,0,1,2.71-2,8.73,8.73,0,0,1,3.62-.71,8.57,8.57,0,0,1,2.61.4,7.51,7.51,0,0,1,2.28,1.19,6.43,6.43,0,0,1,1.68,1.94,6.59,6.59,0,0,1,.81,2.65h-3.68a3.68,3.68,0,0,0-1.33-2.21,3.83,3.83,0,0,0-2.37-.74,4.43,4.43,0,0,0-2.21.51,4,4,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.14,9.14,0,0,0-.26,2.23,8.55,8.55,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4,4,0,0,0,1.47,1.34,4.43,4.43,0,0,0,2.21.5,3.75,3.75,0,0,0,4.16-3.77h-3.87v-2.87h7.36v9.46h-2.46l-.39-2A5.83,5.83,0,0,1,485.94,726.92Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M143.91,734.09a4.25,4.25,0,0,1,1.65.28,3.32,3.32,0,0,1,1.11.74,3,3,0,0,1,.62,1.06,4,4,0,0,1,.2,1.23,3.9,3.9,0,0,1-.2,1.22,2.87,2.87,0,0,1-.62,1.07,3,3,0,0,1-1.11.74,4.24,4.24,0,0,1-1.65.29h-2.39v3.7h-2.27V734.09Zm-.62,4.86a4.56,4.56,0,0,0,.75-.06,1.87,1.87,0,0,0,.64-.22,1.25,1.25,0,0,0,.44-.47,1.67,1.67,0,0,0,.17-.8,1.62,1.62,0,0,0-.17-.79,1.28,1.28,0,0,0-.44-.48,1.87,1.87,0,0,0-.64-.22,4.56,4.56,0,0,0-.75-.06h-1.77V739Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M150.64,736.94v1.39h0a2.88,2.88,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.45,2.45,0,0,1,.76-.11,1.6,1.6,0,0,1,.45.07v1.91l-.35-.05a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2.11,2.11,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M154.37,735.78v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M161.63,738.28a1.45,1.45,0,0,0-.83.23,1.77,1.77,0,0,0-.54.58,2.44,2.44,0,0,0-.3.78,4.09,4.09,0,0,0-.09.85,3.88,3.88,0,0,0,.09.82,2.65,2.65,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.48,1.48,0,0,0,.81.22,1.51,1.51,0,0,0,1.14-.42,2,2,0,0,0,.5-1.1h2a3.36,3.36,0,0,1-1.16,2.27,3.72,3.72,0,0,1-2.44.78,4,4,0,0,1-1.54-.28,3.44,3.44,0,0,1-1.2-.79,3.68,3.68,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,5,5,0,0,1,.25-1.62,3.64,3.64,0,0,1,.75-1.28A3.32,3.32,0,0,1,160,737a4.11,4.11,0,0,1,1.62-.3,4.54,4.54,0,0,1,1.28.17,3.4,3.4,0,0,1,1.1.53,2.78,2.78,0,0,1,.79.88,2.86,2.86,0,0,1,.35,1.25h-2A1.39,1.39,0,0,0,161.63,738.28Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M168.6,734.09v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.73h-2.49l-2-3.34-.8.76v2.58h-2V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M176.87,742.63a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81A3.5,3.5,0,0,1,180.3,744a3.92,3.92,0,0,1-2.18.58,4.28,4.28,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.68,4.68,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.3,3.64,3.64,0,0,1,1.67.36,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,176.87,742.63Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.39,2.39,0,0,0,179.19,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M185.09,734.09v10.33H183V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M188.66,736.94v1h.05a2.36,2.36,0,0,1,1-1,2.87,2.87,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.57,5.57,0,0,1,.13,1.27v4.6h-2.06V740.2a2.59,2.59,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M200.6,743.47a2.15,2.15,0,0,1-.95.87,3.11,3.11,0,0,1-1.32.27,3.19,3.19,0,0,1-1.48-.33,3,3,0,0,1-1-.88,4.12,4.12,0,0,1-.62-1.28,5.35,5.35,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.24,3.24,0,0,1,1-.86,3,3,0,0,1,1.45-.32,2.9,2.9,0,0,1,1.26.28,2.19,2.19,0,0,1,.95.83h0v-3.76h2.05v10.33h-2v-.95Zm-.08-3.7a2.11,2.11,0,0,0-.3-.75,1.61,1.61,0,0,0-.54-.53,1.59,1.59,0,0,0-.84-.21,1.64,1.64,0,0,0-.86.21,1.58,1.58,0,0,0-.56.53,2.57,2.57,0,0,0-.3.76,4.33,4.33,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.46,2.46,0,0,0,.32.78,1.87,1.87,0,0,0,.57.55,1.63,1.63,0,0,0,.82.21,1.56,1.56,0,0,0,.84-.21,1.5,1.5,0,0,0,.55-.54,2.73,2.73,0,0,0,.29-.77,5.29,5.29,0,0,0,.08-.9A5.08,5.08,0,0,0,200.52,739.77Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M206.33,742.63a1.86,1.86,0,0,0,1.34.45,1.83,1.83,0,0,0,1.07-.32,1.26,1.26,0,0,0,.55-.65h1.81a3.47,3.47,0,0,1-1.34,1.92,3.86,3.86,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.5,4.5,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25A3.68,3.68,0,0,1,206,737a3.86,3.86,0,0,1,1.55-.3,3.67,3.67,0,0,1,1.67.36,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.42,5.42,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,206.33,742.63Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.66,2.66,0,0,0-.09.5h3.34A2.48,2.48,0,0,0,208.66,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M222.25,734.09a3.37,3.37,0,0,1,1.26.22,2.85,2.85,0,0,1,1,.62,2.61,2.61,0,0,1,.61.9,2.89,2.89,0,0,1,.21,1.11,3.18,3.18,0,0,1-.38,1.58,2.45,2.45,0,0,1-1.26,1v0a1.84,1.84,0,0,1,.7.35,2.1,2.1,0,0,1,.45.57,2.59,2.59,0,0,1,.25.71,6.83,6.83,0,0,1,.11.79q0,.24,0,.57c0,.23,0,.45,0,.68a4.13,4.13,0,0,0,.11.66,1.73,1.73,0,0,0,.24.53h-2.27a3.92,3.92,0,0,1-.23-1.17c0-.46-.07-.89-.13-1.3a2.09,2.09,0,0,0-.49-1.19,1.69,1.69,0,0,0-1.23-.38H219v4h-2.27V734.09Zm-.81,4.67a1.75,1.75,0,0,0,1.18-.34,1.46,1.46,0,0,0,.39-1.13,1.39,1.39,0,0,0-.39-1.1,1.8,1.8,0,0,0-1.18-.34H219v2.91Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M227,739.06a3.49,3.49,0,0,1,.78-1.25A3.52,3.52,0,0,1,229,737a4.21,4.21,0,0,1,1.59-.28,4.3,4.3,0,0,1,1.6.28,3.62,3.62,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.68,4.68,0,0,1,.28,1.63,4.58,4.58,0,0,1-.28,1.62,3.53,3.53,0,0,1-.78,1.24,3.45,3.45,0,0,1-1.22.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.36,3.36,0,0,1-1.22-.78,3.53,3.53,0,0,1-.78-1.24,4.37,4.37,0,0,1-.28-1.62A4.46,4.46,0,0,1,227,739.06Zm1.86,2.5a2.38,2.38,0,0,0,.3.77,1.53,1.53,0,0,0,.56.54,1.65,1.65,0,0,0,.87.21,1.67,1.67,0,0,0,.87-.21,1.48,1.48,0,0,0,.57-.54,2.16,2.16,0,0,0,.29-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89A2,2,0,0,0,232,739a1.61,1.61,0,0,0-1.44-.76,1.65,1.65,0,0,0-.87.21,1.73,1.73,0,0,0-.56.55,2.24,2.24,0,0,0-.3.76,5.26,5.26,0,0,0-.08.89A4.94,4.94,0,0,0,228.82,741.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M237.87,734.09v10.33h-2.05V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M241.6,734.09v10.33h-2V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M245.43,742.63a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81a3.5,3.5,0,0,1-1.33,1.92,3.92,3.92,0,0,1-2.18.58,4.28,4.28,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.68,4.68,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.3,3.64,3.64,0,0,1,1.67.36,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,245.43,742.63Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.39,2.39,0,0,0,247.75,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M253.49,736.94v1h0a2.42,2.42,0,0,1,1-1,2.92,2.92,0,0,1,1.28-.29,3.46,3.46,0,0,1,1.35.22,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27v4.6H256.5V740.2a2.69,2.69,0,0,0-.29-1.39,1.14,1.14,0,0,0-1-.45,1.42,1.42,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M260,739.24a2.43,2.43,0,0,1,.36-1.2,2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.43,6.43,0,0,1,1.24-.12,7.46,7.46,0,0,1,1.14.08,3.27,3.27,0,0,1,1.06.31,2.12,2.12,0,0,1,.78.64,1.83,1.83,0,0,1,.3,1.09v3.9a7.66,7.66,0,0,0,.06,1,1.84,1.84,0,0,0,.2.69H265a3.57,3.57,0,0,1-.1-.35c0-.12,0-.25-.05-.37a2.65,2.65,0,0,1-1.15.71,4.88,4.88,0,0,1-1.37.2,3.49,3.49,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.78,1.78,0,0,1-.55-.69,2.35,2.35,0,0,1-.19-1,2.21,2.21,0,0,1,.22-1,2,2,0,0,1,.58-.66,3,3,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.57.57,0,0,0,.19-.49,1.09,1.09,0,0,0-.11-.52.73.73,0,0,0-.29-.31,1.1,1.1,0,0,0-.42-.14,2.82,2.82,0,0,0-.52,0,1.58,1.58,0,0,0-.95.26,1.16,1.16,0,0,0-.41.87Zm4.75,1.52a.88.88,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19.92.92,0,0,0-.37.79,1.09,1.09,0,0,0,.1.47.78.78,0,0,0,.28.29,1,1,0,0,0,.4.16,3.88,3.88,0,0,0,.48,0,1.85,1.85,0,0,0,.94-.2,1.35,1.35,0,0,0,.49-.49,1.45,1.45,0,0,0,.2-.57c0-.19,0-.35,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M270.38,736.94v1h0a2.42,2.42,0,0,1,1-1,2.92,2.92,0,0,1,1.28-.29,3.46,3.46,0,0,1,1.35.22,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27v4.6h-2.05V740.2a2.69,2.69,0,0,0-.29-1.39,1.13,1.13,0,0,0-1-.45,1.42,1.42,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M284.08,745a2.6,2.6,0,0,1-.53,1.08,3.28,3.28,0,0,1-1.17.85,5.14,5.14,0,0,1-2.06.34,4.82,4.82,0,0,1-1.12-.13,3.86,3.86,0,0,1-1-.43,2.33,2.33,0,0,1-.78-.75,2.11,2.11,0,0,1-.36-1.09h2a1.17,1.17,0,0,0,.57.8,2,2,0,0,0,1,.23,1.5,1.5,0,0,0,1.26-.52,2.09,2.09,0,0,0,.39-1.32v-1h0a2.11,2.11,0,0,1-1,.87,3,3,0,0,1-1.3.28,3.19,3.19,0,0,1-1.44-.29,2.75,2.75,0,0,1-1-.81,3.5,3.5,0,0,1-.57-1.2,5.36,5.36,0,0,1-.19-1.46A4.7,4.7,0,0,1,277,739a3.69,3.69,0,0,1,.62-1.18,2.88,2.88,0,0,1,1-.82,3,3,0,0,1,1.38-.3,2.92,2.92,0,0,1,1.29.27,2.21,2.21,0,0,1,.94.93h0v-1h1.95v7A5.18,5.18,0,0,1,284.08,745Zm-2.82-2.49a1.54,1.54,0,0,0,.55-.46,2,2,0,0,0,.33-.66,2.81,2.81,0,0,0,.11-.77,4.17,4.17,0,0,0-.09-.88,2.28,2.28,0,0,0-.29-.73,1.56,1.56,0,0,0-.54-.51,1.74,1.74,0,0,0-.84-.19,1.49,1.49,0,0,0-1.26.65,2.12,2.12,0,0,0-.31.68,3.44,3.44,0,0,0-.09.8,4.26,4.26,0,0,0,.08.8,2.07,2.07,0,0,0,.27.72,1.61,1.61,0,0,0,.52.52,1.41,1.41,0,0,0,.79.21A1.59,1.59,0,0,0,281.26,742.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M288.05,742.63a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81a3.45,3.45,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.7,3.7,0,0,1-.76-1.24,4.73,4.73,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.89,3.89,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.94,3.94,0,0,1,1.56-.3,3.63,3.63,0,0,1,1.66.36,3.34,3.34,0,0,1,1.17,1,4.16,4.16,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67h-5.4A2.1,2.1,0,0,0,288.05,742.63Zm2.32-3.94a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.35A2.39,2.39,0,0,0,290.37,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M296.21,734.09v3.76h0a2.18,2.18,0,0,1,1-.85,3.63,3.63,0,0,1,1.34-.26,2.69,2.69,0,0,1,1.11.23,2.83,2.83,0,0,1,1,.71,3.6,3.6,0,0,1,.71,1.22,5.77,5.77,0,0,1,0,3.55,3.7,3.7,0,0,1-.71,1.22,3,3,0,0,1-1,.71,2.87,2.87,0,0,1-1.11.23,4.07,4.07,0,0,1-1.48-.26,1.92,1.92,0,0,1-1-.88h0v.95h-1.95V734.09Zm3.33,5.69a2.32,2.32,0,0,0-.32-.77,1.61,1.61,0,0,0-.54-.53,1.64,1.64,0,0,0-1.57,0,1.64,1.64,0,0,0-.55.53,2.32,2.32,0,0,0-.32.77,3.79,3.79,0,0,0-.1.91,3.74,3.74,0,0,0,.1.89,2.32,2.32,0,0,0,.32.77,1.64,1.64,0,0,0,.55.53,1.64,1.64,0,0,0,1.57,0,1.61,1.61,0,0,0,.54-.53,2.32,2.32,0,0,0,.32-.77,3.74,3.74,0,0,0,.1-.89A3.79,3.79,0,0,0,299.54,739.78Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M303,739.06a3.79,3.79,0,0,1,.78-1.25A3.62,3.62,0,0,1,305,737a4.21,4.21,0,0,1,1.59-.28,4.3,4.3,0,0,1,1.6.28,3.57,3.57,0,0,1,1.23.79,3.79,3.79,0,0,1,.78,1.25,4.68,4.68,0,0,1,.27,1.63,4.59,4.59,0,0,1-.27,1.62,3.84,3.84,0,0,1-.78,1.24,3.4,3.4,0,0,1-1.23.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.45,3.45,0,0,1-1.22-.78,3.84,3.84,0,0,1-.78-1.24,4.59,4.59,0,0,1-.27-1.62A4.68,4.68,0,0,1,303,739.06Zm1.87,2.5a2.38,2.38,0,0,0,.3.77,1.5,1.5,0,0,0,.55.54,1.93,1.93,0,0,0,1.75,0,1.53,1.53,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2.24,2.24,0,0,0-.3-.76,1.73,1.73,0,0,0-.56-.55,1.67,1.67,0,0,0-2.3.55,2.24,2.24,0,0,0-.3.76,4.41,4.41,0,0,0-.09.89A4.14,4.14,0,0,0,304.91,741.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M316,736.94v1.37h-1.5V742a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.3,0v1.59a4.51,4.51,0,0,1-.58.06h-.62a6.37,6.37,0,0,1-.9-.06,2.2,2.2,0,0,1-.76-.26,1.37,1.37,0,0,1-.51-.53,2,2,0,0,1-.19-.91v-4.42h-1.24v-1.37h1.24V734.7h2.06v2.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M319.14,742.63a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81a3.45,3.45,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.3,4.3,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.7,3.7,0,0,1-.76-1.24,4.73,4.73,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.94,3.94,0,0,1,1.56-.3,3.63,3.63,0,0,1,1.66.36,3.43,3.43,0,0,1,1.17,1,4,4,0,0,1,.65,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,319.14,742.63Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5H322A2.3,2.3,0,0,0,321.46,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M331.51,734.09v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.73h-2.49l-2.05-3.34-.8.76v2.58h-2.05V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M339.53,736.94v1.39h0a2.88,2.88,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.45,2.45,0,0,1,.76-.11,1.6,1.6,0,0,1,.45.07v1.91l-.35-.05a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2.11,2.11,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M345.15,742.63a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81a3.5,3.5,0,0,1-1.33,1.92,3.91,3.91,0,0,1-2.17.58,4.3,4.3,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.68,4.68,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.94,3.94,0,0,1,1.56-.3,3.63,3.63,0,0,1,1.66.36,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,345.15,742.63Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5H348A2.39,2.39,0,0,0,347.47,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M351.31,735.78v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M357.19,742.63a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65H362a3.45,3.45,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.3,4.3,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.7,3.7,0,0,1-.76-1.24,4.84,4.84,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.94,3.94,0,0,1,1.56-.3,3.63,3.63,0,0,1,1.66.36,3.43,3.43,0,0,1,1.17,1,4,4,0,0,1,.65,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,357.19,742.63Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5H360A2.3,2.3,0,0,0,359.51,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M365.25,736.94v1.39h0a2.29,2.29,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.41,2.41,0,0,1,.76-.11,1.6,1.6,0,0,1,.45.07v1.91l-.35-.05a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M370.87,742.63a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81a3.47,3.47,0,0,1-1.34,1.92,3.86,3.86,0,0,1-2.17.58,4.25,4.25,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.5,4.5,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.9,3.9,0,0,1,1.55-.3,3.64,3.64,0,0,1,1.67.36,3.31,3.31,0,0,1,1.16,1,3.82,3.82,0,0,1,.66,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,370.87,742.63Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.39,2.39,0,0,0,373.19,738.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M378.93,736.94v1h0a2.42,2.42,0,0,1,1-1,2.92,2.92,0,0,1,1.28-.29,3.46,3.46,0,0,1,1.35.22,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27v4.6h-2.05V740.2a2.69,2.69,0,0,0-.29-1.39,1.14,1.14,0,0,0-1-.45,1.42,1.42,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92H377v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M387.93,742.19v2.23a2.27,2.27,0,0,1-.18.91,2.2,2.2,0,0,1-.49.71,2.4,2.4,0,0,1-.71.5,3.33,3.33,0,0,1-.88.27v-1a1.07,1.07,0,0,0,.41-.18,1.26,1.26,0,0,0,.34-.31,1.24,1.24,0,0,0,.28-.86h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M396.09,734.09v8.42h5v1.91h-7.31V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M404.67,734.09v10.33H402.4V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M408.92,744.42l-3.35-10.33h2.33l2.3,7.26h0l2.33-7.26h2.35l-3.43,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M423.52,734.09V736h-5.46v2.21h5V740h-5v2.53h5.57v1.91h-7.84V734.09Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    {/* Shakespeare animalisch */}
    <Link to={`${url}single/${urlFormat('Shakespeare animalisch')}`}>
      <g id="shakespeare-animalisch">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="466.15"
          y="618.27"
          width="388.38"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M553.55,722.94a2.72,2.72,0,0,0,.85,1,3.65,3.65,0,0,0,1.23.56,5.61,5.61,0,0,0,1.48.19,7.29,7.29,0,0,0,1.1-.09,4,4,0,0,0,1.1-.33,2.38,2.38,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.54,3.54,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.88-.49a15.29,15.29,0,0,1-1.92-.6,6.74,6.74,0,0,1-1.67-.92,4.36,4.36,0,0,1-1.18-1.41,4.2,4.2,0,0,1-.46-2,4.51,4.51,0,0,1,.58-2.34,5.21,5.21,0,0,1,1.51-1.66,6.7,6.7,0,0,1,2.11-1,9,9,0,0,1,2.35-.31,11.49,11.49,0,0,1,2.64.3,6.8,6.8,0,0,1,2.24,1,5.18,5.18,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.57,2.59h-3.72a3.13,3.13,0,0,0-.34-1.3,2,2,0,0,0-.74-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.45,2.45,0,0,0-.85.34,2.29,2.29,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.49,1.49,0,0,0,.2.84,1.72,1.72,0,0,0,.77.59,9.6,9.6,0,0,0,1.59.54L559,717c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.36,5.36,0,0,1,1.49,1.51,4.33,4.33,0,0,1,.62,2.41,5.41,5.41,0,0,1-.46,2.24,5,5,0,0,1-1.39,1.77,6.63,6.63,0,0,1-2.28,1.17,10.74,10.74,0,0,1-3.15.41,10.93,10.93,0,0,1-2.81-.35,7,7,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.87,5.87,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,553.55,722.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M570.37,709.74v6.72h7.09v-6.72h3.85v17.51h-3.85V719.7h-7.09v7.55h-3.85V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M593.42,709.74,600,727.25h-4l-1.32-3.9H588.1l-1.37,3.9h-3.88l6.63-17.51Zm.22,10.74-2.2-6.42h-.05l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M605.34,709.74V717l6.84-7.26H617l-6.84,6.92,7.5,10.59h-4.83l-5.27-7.87-2.21,2.23v5.64h-3.85V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M632.29,709.74V713h-9.24v3.75h8.48v3h-8.48V724h9.44v3.24H619.2V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M638,722.94a2.82,2.82,0,0,0,.85,1,3.62,3.62,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4,4,0,0,0,1.1-.33,2.38,2.38,0,0,0,.86-.67,1.7,1.7,0,0,0,.35-1.09,1.57,1.57,0,0,0-.46-1.16,3.54,3.54,0,0,0-1.19-.73,11.64,11.64,0,0,0-1.66-.52c-.63-.15-1.26-.31-1.89-.49a15.83,15.83,0,0,1-1.92-.6,6.51,6.51,0,0,1-1.66-.92,4.39,4.39,0,0,1-1.19-1.41,4.2,4.2,0,0,1-.46-2,4.61,4.61,0,0,1,.58-2.34,5.32,5.32,0,0,1,1.51-1.66,6.7,6.7,0,0,1,2.11-1,9,9,0,0,1,2.35-.31,11.36,11.36,0,0,1,2.64.3,6.8,6.8,0,0,1,2.24,1,5.08,5.08,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.57,2.59h-3.72a3.3,3.3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.45,2.45,0,0,0-.85.34,2.12,2.12,0,0,0-.62.61,1.61,1.61,0,0,0-.25.93,1.58,1.58,0,0,0,.2.84,1.78,1.78,0,0,0,.77.59,9.94,9.94,0,0,0,1.59.54l2.66.68c.33.07.79.19,1.37.36a6.27,6.27,0,0,1,1.72.82,5.23,5.23,0,0,1,1.49,1.51,4.25,4.25,0,0,1,.62,2.41,5.41,5.41,0,0,1-.46,2.24,4.82,4.82,0,0,1-1.39,1.77,6.63,6.63,0,0,1-2.28,1.17,10.74,10.74,0,0,1-3.15.41,11,11,0,0,1-2.81-.35,7,7,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.87,5.87,0,0,1-.58-2.8h3.72A3.26,3.26,0,0,0,638,722.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M658.9,709.74a7.28,7.28,0,0,1,2.8.48,5.31,5.31,0,0,1,1.87,1.26,4.94,4.94,0,0,1,1.06,1.79,6.72,6.72,0,0,1,0,4.16,4.82,4.82,0,0,1-1.06,1.8,5.2,5.2,0,0,1-1.87,1.27,7.49,7.49,0,0,1-2.8.47h-4v6.28H651V709.74Zm-1,8.24a7.51,7.51,0,0,0,1.27-.1,2.83,2.83,0,0,0,1.08-.38,2,2,0,0,0,.75-.79,2.82,2.82,0,0,0,.28-1.35A2.76,2.76,0,0,0,661,714a2.14,2.14,0,0,0-.75-.8,3,3,0,0,0-1.08-.38,8.71,8.71,0,0,0-1.27-.1h-3V718Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M680.46,709.74V713h-9.25v3.75h8.49v3h-8.49V724h9.44v3.24H667.36V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M692,709.74l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88L688,709.74Zm.22,10.74-2.2-6.42h0l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M709.49,709.74a5.68,5.68,0,0,1,2.12.38,4.87,4.87,0,0,1,1.62,1,4.54,4.54,0,0,1,1,1.53,4.83,4.83,0,0,1,.36,1.87,5.32,5.32,0,0,1-.65,2.68,4.1,4.1,0,0,1-2.12,1.71v0a3,3,0,0,1,1.17.6,3.23,3.23,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,12.16,12.16,0,0,1,.19,1.33c0,.28,0,.6.05,1s0,.76.08,1.15a7.68,7.68,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.41,3.41,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64H703.9v6.84h-3.85V709.74Zm-1.37,7.92a2.91,2.91,0,0,0,2-.58,2.45,2.45,0,0,0,.67-1.92,2.31,2.31,0,0,0-.67-1.85,3,3,0,0,0-2-.58H703.9v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M730.85,709.74V713h-9.24v3.75h8.48v3h-8.48V724h9.44v3.24H717.76V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M749.19,709.74l6.55,17.51h-4l-1.33-3.9h-6.55l-1.37,3.9h-3.88l6.63-17.51Zm.23,10.74-2.21-6.42h-.05l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M761.09,709.74l7.31,11.75h.05V709.74h3.6v17.51H768.2l-7.28-11.72h0v11.72h-3.61V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M779.28,709.74v17.51h-3.85V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M788.09,709.74l4.09,12h.05l3.88-12h5.42v17.51h-3.61V714.84h0l-4.29,12.41h-3L786.32,715h-.05v12.28h-3.6V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M813.64,709.74l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.74-2.2-6.42h0l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M825.56,709.74V724h8.53v3.24H821.71V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M840.1,709.74v17.51h-3.85V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M846.43,722.94a2.79,2.79,0,0,0,.84,1,3.8,3.8,0,0,0,1.24.56,5.59,5.59,0,0,0,1.47.19,7.31,7.31,0,0,0,1.11-.09,4,4,0,0,0,1.1-.33,2.38,2.38,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.54,3.54,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.52,4.52,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.51,4.51,0,0,1,.58-2.34,5.21,5.21,0,0,1,1.51-1.66,6.75,6.75,0,0,1,2.1-1,9.11,9.11,0,0,1,2.36-.31,11.34,11.34,0,0,1,2.63.3,6.85,6.85,0,0,1,2.25,1,5.18,5.18,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.57,2.59H852.8a3,3,0,0,0-.33-1.3,2,2,0,0,0-.74-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.37,2.37,0,0,0-.85.34,2.29,2.29,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.58,1.58,0,0,0,.19.84,1.82,1.82,0,0,0,.78.59,9.6,9.6,0,0,0,1.59.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.36,5.36,0,0,1,1.49,1.51,4.33,4.33,0,0,1,.62,2.41,5.26,5.26,0,0,1-.47,2.24,4.9,4.9,0,0,1-1.38,1.77,6.63,6.63,0,0,1-2.28,1.17,10.74,10.74,0,0,1-3.15.41,10.93,10.93,0,0,1-2.81-.35,7,7,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.87,5.87,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,846.43,722.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M870.63,714.41a3.73,3.73,0,0,0-.86-1,3.77,3.77,0,0,0-1.16-.65,3.89,3.89,0,0,0-1.36-.24,4.46,4.46,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.3,4.3,0,0,0,1.47,1.34,4.57,4.57,0,0,0,2.21.5,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.21-2.84H875a9,9,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.85,6.85,0,0,1-2.35,1.42,8.57,8.57,0,0,1-3,.49,9.09,9.09,0,0,1-3.62-.69,7.83,7.83,0,0,1-2.71-1.93,8.62,8.62,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,9.46,9.46,0,0,1,6.35-.3,7.51,7.51,0,0,1,2.31,1.22,6.54,6.54,0,0,1,1.68,2,7,7,0,0,1,.83,2.7h-3.73A3.09,3.09,0,0,0,870.63,714.41Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M881.42,709.74v6.72h7.09v-6.72h3.85v17.51h-3.85V719.7h-7.09v7.55h-3.85V709.74Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M553.11,734.29l2.42,7.1h0l2.28-7.1H561v10.33h-2.12V737.3h0l-2.54,7.32H554.6l-2.53-7.25h0v7.25h-2.13V734.29Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M562.87,739.26a3.49,3.49,0,0,1,.78-1.25,3.58,3.58,0,0,1,1.21-.79,4.56,4.56,0,0,1,3.2,0,3.62,3.62,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,5,5,0,0,1,0,3.25,3.53,3.53,0,0,1-.78,1.24,3.45,3.45,0,0,1-1.22.78,4.71,4.71,0,0,1-3.2,0,3.41,3.41,0,0,1-1.21-.78,3.53,3.53,0,0,1-.78-1.24,4.86,4.86,0,0,1,0-3.25Zm1.86,2.5a2.38,2.38,0,0,0,.3.77,1.53,1.53,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.67,1.67,0,0,0,.87-.2,1.48,1.48,0,0,0,.57-.54,2.16,2.16,0,0,0,.29-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2,2,0,0,0-.29-.76,1.58,1.58,0,0,0-.57-.55,1.67,1.67,0,0,0-.87-.21,1.58,1.58,0,0,0-1.43.76,2.24,2.24,0,0,0-.3.76,5.26,5.26,0,0,0-.08.89A4.94,4.94,0,0,0,564.73,741.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M573.62,737.14v1h0a2.39,2.39,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.51,5.51,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.68,2.68,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M580.29,739.26a3.79,3.79,0,0,1,.78-1.25,3.62,3.62,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.43,3.43,0,0,1,1.22.79,3.65,3.65,0,0,1,.79,1.25,5,5,0,0,1,0,3.25,3.71,3.71,0,0,1-.79,1.24,3.28,3.28,0,0,1-1.22.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.45,3.45,0,0,1-1.22-.78,3.84,3.84,0,0,1-.78-1.24,5,5,0,0,1,0-3.25Zm1.87,2.5a2.38,2.38,0,0,0,.3.77,1.5,1.5,0,0,0,.55.54,2,2,0,0,0,1.75,0,1.53,1.53,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.93,1.93,0,0,0-1.75,0,1.61,1.61,0,0,0-.55.55,2.24,2.24,0,0,0-.3.76,4.41,4.41,0,0,0-.09.89A4.14,4.14,0,0,0,582.16,741.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M591.21,734.29v10.33h-2.06V734.29Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M592.87,739.26a3.49,3.49,0,0,1,.78-1.25,3.52,3.52,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.52,3.52,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.53,3.53,0,0,1-.78,1.24,3.36,3.36,0,0,1-1.22.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.36,3.36,0,0,1-1.22-.78,3.53,3.53,0,0,1-.78-1.24,4.86,4.86,0,0,1,0-3.25Zm1.87,2.5a2.16,2.16,0,0,0,.29.77,1.53,1.53,0,0,0,.56.54,2,2,0,0,0,1.74,0,1.48,1.48,0,0,0,.57-.54,2.38,2.38,0,0,0,.3-.77,4.94,4.94,0,0,0,.08-.87,5.26,5.26,0,0,0-.08-.89,2.24,2.24,0,0,0-.3-.76,1.58,1.58,0,0,0-.57-.55,1.69,1.69,0,0,0-2.3.55,2,2,0,0,0-.29.76,4.41,4.41,0,0,0-.09.89A4.14,4.14,0,0,0,594.74,741.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M608.74,745.17a2.48,2.48,0,0,1-.53,1.08,3.28,3.28,0,0,1-1.17.85,5.11,5.11,0,0,1-2.06.34,4.88,4.88,0,0,1-1.12-.13,3.86,3.86,0,0,1-1-.43,2.53,2.53,0,0,1-.78-.75,2.14,2.14,0,0,1-.36-1.09h2.05a1.13,1.13,0,0,0,.56.8,2,2,0,0,0,1,.23,1.52,1.52,0,0,0,1.27-.52,2.09,2.09,0,0,0,.38-1.32v-1h0a2.06,2.06,0,0,1-1,.87,3,3,0,0,1-1.29.28,3.38,3.38,0,0,1-1.45-.29,2.75,2.75,0,0,1-1-.81,3.47,3.47,0,0,1-.57-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.57,3.57,0,0,1,.61-1.18,2.88,2.88,0,0,1,1-.82,3,3,0,0,1,1.38-.31,3,3,0,0,1,1.3.28,2.19,2.19,0,0,1,.93.93h0v-1h2v7A5.18,5.18,0,0,1,608.74,745.17Zm-2.82-2.49a1.46,1.46,0,0,0,.55-.46,2,2,0,0,0,.33-.66,2.87,2.87,0,0,0,.11-.77,4.1,4.1,0,0,0-.09-.88,2.07,2.07,0,0,0-.29-.73,1.52,1.52,0,0,0-.53-.51,1.74,1.74,0,0,0-.84-.19,1.49,1.49,0,0,0-.75.18,1.55,1.55,0,0,0-.52.47,1.91,1.91,0,0,0-.3.68,3,3,0,0,0-.1.8,4.26,4.26,0,0,0,.08.8,2.3,2.3,0,0,0,.28.72,1.58,1.58,0,0,0,.51.52,1.42,1.42,0,0,0,.8.2A1.55,1.55,0,0,0,605.92,742.68Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M612.71,742.83a1.89,1.89,0,0,0,1.33.44,1.83,1.83,0,0,0,1.08-.31,1.29,1.29,0,0,0,.55-.66h1.8a3.4,3.4,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.54,4.54,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.6,3.6,0,0,1,2-2.08,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.31,3.31,0,0,1,1.16,1,4,4,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67h-5.39A2.1,2.1,0,0,0,612.71,742.83Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.66,2.66,0,0,0-.09.5h3.34A2.43,2.43,0,0,0,615,738.89Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M627.91,744.62v-1h0a2.3,2.3,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.46,3.46,0,0,1-1.35-.22,2,2,0,0,1-.84-.61,2.45,2.45,0,0,1-.42-1,5.57,5.57,0,0,1-.13-1.27v-4.6h2.06v4.22a2.69,2.69,0,0,0,.29,1.39,1.12,1.12,0,0,0,1,.45,1.39,1.39,0,0,0,1.21-.5,2.77,2.77,0,0,0,.38-1.64v-3.92h2.05v7.48Zm-2.07-10.33V736h-2v-1.69Zm3,0V736h-2v-1.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M633.48,734.29v3.76h0a2.18,2.18,0,0,1,1-.85,3.45,3.45,0,0,1,1.34-.27,2.71,2.71,0,0,1,1.11.24,2.83,2.83,0,0,1,1,.71,3.51,3.51,0,0,1,.71,1.22,5,5,0,0,1,.28,1.77,4.91,4.91,0,0,1-.28,1.77,3.65,3.65,0,0,1-.71,1.23,3,3,0,0,1-1,.71,2.87,2.87,0,0,1-1.11.23,4.07,4.07,0,0,1-1.48-.26,1.92,1.92,0,0,1-1-.88h0v1h-2V734.29Zm3.33,5.68a2.26,2.26,0,0,0-.32-.76,1.61,1.61,0,0,0-.54-.53,1.64,1.64,0,0,0-1.57,0,1.64,1.64,0,0,0-.55.53,2.26,2.26,0,0,0-.32.76,3.93,3.93,0,0,0-.1.92,3.74,3.74,0,0,0,.1.89,2.32,2.32,0,0,0,.32.77,1.64,1.64,0,0,0,.55.53,1.72,1.72,0,0,0,1.57,0,1.61,1.61,0,0,0,.54-.53,2.32,2.32,0,0,0,.32-.77,3.74,3.74,0,0,0,.1-.89A3.93,3.93,0,0,0,636.81,740Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M642.47,742.83a1.87,1.87,0,0,0,1.33.44,1.8,1.8,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.19,3.19,0,0,1-1.2-.81,3.4,3.4,0,0,1-.76-1.24,4.76,4.76,0,0,1,0-3.16,3.89,3.89,0,0,1,.78-1.25,3.78,3.78,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.6,3.6,0,0,1,1.66.37,3.34,3.34,0,0,1,1.17,1,4.16,4.16,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67H642A2.1,2.1,0,0,0,642.47,742.83Zm2.32-3.94a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.35A2.35,2.35,0,0,0,644.79,738.89Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M650.53,737.14v1.39h0a2.88,2.88,0,0,1,.39-.65,2.73,2.73,0,0,1,.57-.5,2.68,2.68,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35,0a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2.2,2.2,0,0,0-.34.76,4.59,4.59,0,0,0-.1.94v3.37h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M660.19,742.07a1.67,1.67,0,0,0,.49.59,2.17,2.17,0,0,0,.74.33,3.43,3.43,0,0,0,.86.11,5,5,0,0,0,.65-.05,2.11,2.11,0,0,0,.66-.2,1.28,1.28,0,0,0,.5-.39,1,1,0,0,0,.21-.65.91.91,0,0,0-.27-.68,2.08,2.08,0,0,0-.7-.43,6.82,6.82,0,0,0-1-.31c-.37-.08-.74-.18-1.11-.29a8.25,8.25,0,0,1-1.13-.35,3.92,3.92,0,0,1-1-.54,2.44,2.44,0,0,1-1-2,2.8,2.8,0,0,1,.34-1.39,3.18,3.18,0,0,1,.89-1,3.87,3.87,0,0,1,1.25-.58A5.12,5.12,0,0,1,662,734a6.52,6.52,0,0,1,1.55.18,3.92,3.92,0,0,1,1.33.59,3,3,0,0,1,.92,1,3.26,3.26,0,0,1,.34,1.53H664a1.85,1.85,0,0,0-.2-.77,1.36,1.36,0,0,0-.44-.48,2,2,0,0,0-.63-.24,3.67,3.67,0,0,0-.77-.07,3.31,3.31,0,0,0-.55.05,1.4,1.4,0,0,0-.5.21,1.06,1.06,0,0,0-.37.36.94.94,0,0,0-.15.55.91.91,0,0,0,.12.49,1,1,0,0,0,.46.35,6.66,6.66,0,0,0,.94.32l1.57.4c.19,0,.46.11.8.21a3.66,3.66,0,0,1,1,.49,3,3,0,0,1,.88.89,2.56,2.56,0,0,1,.37,1.42,3.25,3.25,0,0,1-.28,1.32,3,3,0,0,1-.82,1,4.13,4.13,0,0,1-1.34.69,7,7,0,0,1-3.52,0,4.12,4.12,0,0,1-1.42-.66,3.18,3.18,0,0,1-1.32-2.79H660A1.82,1.82,0,0,0,660.19,742.07Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M667.67,739.26a3.49,3.49,0,0,1,.78-1.25,3.52,3.52,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.62,3.62,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.53,3.53,0,0,1-.78,1.24,3.45,3.45,0,0,1-1.22.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.36,3.36,0,0,1-1.22-.78,3.53,3.53,0,0,1-.78-1.24,4.86,4.86,0,0,1,0-3.25Zm1.86,2.5a2.38,2.38,0,0,0,.3.77,1.53,1.53,0,0,0,.56.54,1.65,1.65,0,0,0,.87.2,1.67,1.67,0,0,0,.87-.2,1.48,1.48,0,0,0,.57-.54,2.16,2.16,0,0,0,.29-.77,4.14,4.14,0,0,0,.09-.87A4.41,4.41,0,0,0,673,740a2,2,0,0,0-.29-.76,1.58,1.58,0,0,0-.57-.55,1.67,1.67,0,0,0-.87-.21,1.58,1.58,0,0,0-1.43.76,2.24,2.24,0,0,0-.3.76,5.26,5.26,0,0,0-.08.89A4.94,4.94,0,0,0,669.53,741.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M681.53,744.62v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22A1.94,1.94,0,0,1,677,744a2.32,2.32,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2v4.22a2.69,2.69,0,0,0,.29,1.39,1.13,1.13,0,0,0,1,.45,1.42,1.42,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M687,737.14v1.39h0a2.88,2.88,0,0,1,.39-.65,3,3,0,0,1,.57-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35,0a2.55,2.55,0,0,0-.39,0,2.24,2.24,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M694,738.48a1.44,1.44,0,0,0-.82.23,1.77,1.77,0,0,0-.54.58,2.44,2.44,0,0,0-.3.78,4.09,4.09,0,0,0-.09.85,3.88,3.88,0,0,0,.09.82,2.65,2.65,0,0,0,.28.76,1.67,1.67,0,0,0,.53.56,1.48,1.48,0,0,0,.81.21,1.5,1.5,0,0,0,1.14-.41,2,2,0,0,0,.5-1.11h2a3.4,3.4,0,0,1-1.16,2.28,3.74,3.74,0,0,1-2.44.78,4.32,4.32,0,0,1-1.55-.28,3.49,3.49,0,0,1-1.19-.79,3.68,3.68,0,0,1-.77-1.21,4.26,4.26,0,0,1-.27-1.54,5,5,0,0,1,.25-1.62,3.64,3.64,0,0,1,.75-1.28,3.24,3.24,0,0,1,1.2-.85,4.1,4.1,0,0,1,1.62-.31,4.55,4.55,0,0,1,1.28.18,3.21,3.21,0,0,1,1.1.53,2.78,2.78,0,0,1,.79.88,2.86,2.86,0,0,1,.35,1.25h-2A1.38,1.38,0,0,0,694,738.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M700.93,742.83a1.87,1.87,0,0,0,1.33.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.84,4.84,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.43,3.43,0,0,1,1.17,1,4,4,0,0,1,.65,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.1,2.1,0,0,0,700.93,742.83Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.26,2.26,0,0,0,703.25,738.89Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M706.44,736.2v-1.91h8.47v1.91h-3.1v8.42h-2.27V736.2Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M719.61,744.62v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.32,2.32,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2.05v4.22a2.69,2.69,0,0,0,.29,1.39,1.13,1.13,0,0,0,1,.45,1.39,1.39,0,0,0,1.21-.5,2.7,2.7,0,0,0,.38-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M725.08,737.14v1h0a2.36,2.36,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.54,3.54,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.58,2.58,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M731.77,736v-1.69h2.06V736Zm2.06,1.16v7.48h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M737.4,737.14v1h0a2.39,2.39,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.12,6.12,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M751.1,745.17a2.48,2.48,0,0,1-.53,1.08,3.28,3.28,0,0,1-1.17.85,5.11,5.11,0,0,1-2.06.34,4.88,4.88,0,0,1-1.12-.13,3.86,3.86,0,0,1-1-.43,2.43,2.43,0,0,1-.78-.75A2.14,2.14,0,0,1,744,745h2a1.17,1.17,0,0,0,.57.8,2,2,0,0,0,1,.23,1.49,1.49,0,0,0,1.26-.52,2,2,0,0,0,.39-1.32v-1h0a2.06,2.06,0,0,1-1,.87,3,3,0,0,1-1.29.28,3.38,3.38,0,0,1-1.45-.29,2.75,2.75,0,0,1-1-.81,3.47,3.47,0,0,1-.57-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.57,3.57,0,0,1,.61-1.18,2.88,2.88,0,0,1,1-.82,3,3,0,0,1,1.38-.31,3,3,0,0,1,1.3.28,2.19,2.19,0,0,1,.93.93h0v-1h2v7A5.18,5.18,0,0,1,751.1,745.17Zm-2.82-2.49a1.46,1.46,0,0,0,.55-.46,2,2,0,0,0,.33-.66,2.87,2.87,0,0,0,.11-.77,4.1,4.1,0,0,0-.09-.88,2.07,2.07,0,0,0-.29-.73,1.52,1.52,0,0,0-.53-.51,1.8,1.8,0,0,0-.84-.19,1.49,1.49,0,0,0-.75.18,1.46,1.46,0,0,0-.52.47,1.91,1.91,0,0,0-.3.68,3,3,0,0,0-.1.8,4.26,4.26,0,0,0,.08.8,2.08,2.08,0,0,0,.28.72,1.58,1.58,0,0,0,.51.52,1.42,1.42,0,0,0,.8.2A1.55,1.55,0,0,0,748.28,742.68Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M761.5,744.5a3.78,3.78,0,0,1-1.58.31,4.33,4.33,0,0,1-1.31-.2,3,3,0,0,1-1.06-.61,2.73,2.73,0,0,1-.71-1,3.07,3.07,0,0,1-.26-1.31,2.71,2.71,0,0,1,.18-1,3.13,3.13,0,0,1,.52-.83,4.2,4.2,0,0,1,.74-.65,5.49,5.49,0,0,1,.87-.51,7.38,7.38,0,0,1-.7-1,2.41,2.41,0,0,1-.28-1.19,2.07,2.07,0,0,1,.22-1,2.43,2.43,0,0,1,.6-.75,2.87,2.87,0,0,1,.86-.47,3.15,3.15,0,0,1,1-.17,3.59,3.59,0,0,1,1.11.16,2.61,2.61,0,0,1,.89.47,2.29,2.29,0,0,1,.61.77,2.45,2.45,0,0,1,.23,1.08,2.31,2.31,0,0,1-.53,1.53,4.53,4.53,0,0,1-1.31,1l1.33,1.64a3.06,3.06,0,0,0,.25-.6c.05-.21.1-.42.13-.63h1.78a5.57,5.57,0,0,1-.32,1.34,4.44,4.44,0,0,1-.68,1.2l2.1,2.45h-2.52l-.84-1A4.33,4.33,0,0,1,761.5,744.5Zm-2.06-4.16a2.08,2.08,0,0,0-.46.37,2.16,2.16,0,0,0-.33.48,1.37,1.37,0,0,0-.12.58,1.24,1.24,0,0,0,.12.53,1.41,1.41,0,0,0,.33.43,1.49,1.49,0,0,0,.47.29,1.51,1.51,0,0,0,.56.11,1.91,1.91,0,0,0,1-.25,3.61,3.61,0,0,0,.77-.66L760,740.06A4,4,0,0,0,759.44,740.34Zm1.92-4.51a.9.9,0,0,0-.66-.28.91.91,0,0,0-.65.25.87.87,0,0,0-.27.66,1.32,1.32,0,0,0,.26.84c.17.23.35.46.55.71a4.59,4.59,0,0,0,.74-.6,1.18,1.18,0,0,0,.31-.88A1,1,0,0,0,761.36,735.83Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M776.2,734.29l3.87,10.33h-2.36l-.78-2.3h-3.87l-.81,2.3H770l3.91-10.33Zm.13,6.34-1.3-3.8h0l-1.34,3.8Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M782.7,737.14v1h0a2.39,2.39,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.51,5.51,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.58,2.58,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M789.39,736v-1.69h2.05V736Zm2.05,1.16v7.48h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M795.06,737.14v1h0a3,3,0,0,1,1-.9,2.65,2.65,0,0,1,1.31-.32,3,3,0,0,1,1.29.28,1.81,1.81,0,0,1,.89,1,3.17,3.17,0,0,1,.91-.87,2.4,2.4,0,0,1,1.35-.38,3.85,3.85,0,0,1,1.1.15,2.27,2.27,0,0,1,.87.46,2,2,0,0,1,.56.84,3.23,3.23,0,0,1,.21,1.23v5H802.5v-4.24c0-.25,0-.49,0-.71a1.51,1.51,0,0,0-.16-.58,1,1,0,0,0-.38-.39,1.36,1.36,0,0,0-.69-.14,1.32,1.32,0,0,0-.7.16,1.18,1.18,0,0,0-.42.44,1.64,1.64,0,0,0-.2.6,4,4,0,0,0,0,.69v4.17h-2.06v-4.2c0-.22,0-.44,0-.66a1.74,1.74,0,0,0-.13-.6.91.91,0,0,0-.36-.44,1.38,1.38,0,0,0-.74-.16,1.69,1.69,0,0,0-.39.06,1.23,1.23,0,0,0-.46.25,1.33,1.33,0,0,0-.38.52,2.05,2.05,0,0,0-.16.89v4.34h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M806.09,739.44a2.43,2.43,0,0,1,.36-1.2,2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,9,9,0,0,1,1.14.08,3.54,3.54,0,0,1,1.06.32,2,2,0,0,1,.78.64,1.83,1.83,0,0,1,.3,1.09V743a7.66,7.66,0,0,0,.06,1,1.9,1.9,0,0,0,.2.69h-2.08c0-.12-.07-.23-.1-.35s0-.25-.05-.37a2.75,2.75,0,0,1-1.15.71,4.87,4.87,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.87,1.87,0,0,1-.55-.69,2.35,2.35,0,0,1-.19-1,2.21,2.21,0,0,1,.22-1.05,1.86,1.86,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37c.31-.08.61-.14.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.57.57,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.78,2.78,0,0,0-.51,0,1.59,1.59,0,0,0-1,.26,1.13,1.13,0,0,0-.4.87Zm4.75,1.52a.88.88,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19.92.92,0,0,0-.37.79,1.09,1.09,0,0,0,.1.47.78.78,0,0,0,.28.29.92.92,0,0,0,.41.15,2.42,2.42,0,0,0,.47.05,1.85,1.85,0,0,0,.94-.2,1.38,1.38,0,0,0,.5-.49,1.6,1.6,0,0,0,.19-.57,3,3,0,0,0,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M816.6,734.29v10.33h-2.06V734.29Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M824.66,744.62l-1.33-5h0l-1.27,5H819.9l-2.38-7.48h2.17l1.38,5.08h0l1.24-5.08h2l1.27,5.06h0l1.38-5.06h2.11l-2.36,7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M830,739.26a3.79,3.79,0,0,1,.78-1.25,3.62,3.62,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.57,3.57,0,0,1,1.23.79,3.79,3.79,0,0,1,.78,1.25,5,5,0,0,1,0,3.25,3.84,3.84,0,0,1-.78,1.24,3.4,3.4,0,0,1-1.23.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.45,3.45,0,0,1-1.22-.78,3.84,3.84,0,0,1-.78-1.24,5,5,0,0,1,0-3.25Zm1.87,2.5a2.38,2.38,0,0,0,.3.77,1.5,1.5,0,0,0,.55.54,2,2,0,0,0,1.75,0,1.53,1.53,0,0,0,.56-.54,2.38,2.38,0,0,0,.3-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2.24,2.24,0,0,0-.3-.76,1.64,1.64,0,0,0-.56-.55,1.93,1.93,0,0,0-1.75,0,1.61,1.61,0,0,0-.55.55,2.24,2.24,0,0,0-.3.76,4.41,4.41,0,0,0-.09.89A4.14,4.14,0,0,0,831.91,741.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M840.8,737.14v1.39h0a2.29,2.29,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.34,0a2.75,2.75,0,0,0-.4,0,2.27,2.27,0,0,0-.95.18,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M846.72,734.29v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.73h-2.49l-2.06-3.34-.79.76v2.58h-2.06V734.29Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M855.15,742.39v2.23a2.27,2.27,0,0,1-.18.91,2.34,2.34,0,0,1-.48.71,2.5,2.5,0,0,1-.72.5,3.15,3.15,0,0,1-.87.27v-1a1.14,1.14,0,0,0,.41-.18,1.43,1.43,0,0,0,.34-.31,1.2,1.2,0,0,0,.22-.4,1.44,1.44,0,0,0,0-.46h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M863.32,734.29v8.42h5v1.91H861V734.29Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M871.9,734.29v10.33h-2.27V734.29Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M876.14,744.62l-3.34-10.33h2.33l2.3,7.26h0l2.33-7.26h2.34l-3.43,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M890.74,734.29v1.91h-5.45v2.21h5v1.77h-5v2.53h5.57v1.91H883V734.29Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    <Link to={`${url}single/interviewtraining`}>
      <g id="interviewtraining">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="1271.7"
          y="332.43"
          width="296.38"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1360,423.7v17.51h-3.85V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1367.22,423.7l7.31,11.75h0V423.7h3.61v17.51h-3.85l-7.29-11.72h0v11.72h-3.6V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1380.2,426.94V423.7h14.34v3.24h-5.25v14.27h-3.84V426.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1409.65,423.7v3.24h-9.25v3.75h8.49v3h-8.49V438h9.45v3.24h-13.3V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1421.89,423.7a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,5,5,0,0,1,.35,1.87,5.32,5.32,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71V433a3,3,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.69,4.69,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s0,.76.09,1.15a7.69,7.69,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.54,6.54,0,0,1-.4-2c0-.77-.12-1.51-.22-2.21a3.47,3.47,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85V423.7Zm-1.38,7.92a2.92,2.92,0,0,0,2-.58,2.48,2.48,0,0,0,.66-1.92,2.34,2.34,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1433.51,441.21l-5.66-17.51h3.94l3.9,12.31h0l4-12.31h4l-5.81,17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1449,423.7v17.51h-3.85V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1465.49,423.7v3.24h-9.25v3.75h8.49v3h-8.49V438h9.44v3.24h-13.29V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1481.13,441.21l-3-11.92h-.05l-2.92,11.92h-3.9l-4.63-17.51h3.85l2.77,11.92h0l3-11.92h3.6l3,12.07h0l2.87-12.07h3.78L1485,441.21Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1490.06,426.94V423.7h14.35v3.24h-5.25v14.27h-3.85V426.94Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1515.86,423.7a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.82,4.82,0,0,1,.35,1.87,5.22,5.22,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71V433a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s0,.76.09,1.15a7.69,7.69,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.13-1.51-.22-2.21a3.48,3.48,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V423.7Zm-1.37,7.92a2.91,2.91,0,0,0,2-.58,2.48,2.48,0,0,0,.66-1.92,2.34,2.34,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1532.85,423.7l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.74-2.2-6.42h0l-2.28,6.42Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1544.77,423.7v17.51h-3.85V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1552,423.7l7.31,11.75h0V423.7h3.6v17.51h-3.85l-7.28-11.72h0v11.72h-3.6V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1570.18,423.7v17.51h-3.85V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1577.39,423.7l7.31,11.75h0V423.7h3.61v17.51h-3.85l-7.29-11.72h0v11.72h-3.61V423.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1602.11,441.08a6.51,6.51,0,0,1-2.53.52,9.09,9.09,0,0,1-3.62-.69,7.83,7.83,0,0,1-2.71-1.93,8.62,8.62,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.76,8.76,0,0,1,1.69-2.93,7.65,7.65,0,0,1,2.71-2,8.76,8.76,0,0,1,3.62-.71,8.47,8.47,0,0,1,2.61.4,7.51,7.51,0,0,1,2.28,1.19,6.43,6.43,0,0,1,1.68,1.94,6.59,6.59,0,0,1,.81,2.65h-3.68a3.68,3.68,0,0,0-1.32-2.21,3.88,3.88,0,0,0-2.38-.74,4.46,4.46,0,0,0-2.21.51,4.2,4.2,0,0,0-1.47,1.34,6.08,6.08,0,0,0-.82,1.93,9.65,9.65,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.85,5.85,0,0,0,.82,1.87,4.3,4.3,0,0,0,1.47,1.34,4.57,4.57,0,0,0,2.21.5,4.17,4.17,0,0,0,3-1,4.24,4.24,0,0,0,1.21-2.8h-3.87v-2.87h7.35v9.46h-2.45l-.39-2A5.83,5.83,0,0,1,1602.11,441.08Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1357.21,456a1.6,1.6,0,0,0,.5.59,2,2,0,0,0,.73.33,3.58,3.58,0,0,0,.87.11,5.13,5.13,0,0,0,.65-.05,2,2,0,0,0,.65-.2,1.39,1.39,0,0,0,.51-.39,1,1,0,0,0,.2-.65.91.91,0,0,0-.27-.68,2.08,2.08,0,0,0-.7-.43,6.82,6.82,0,0,0-1-.31c-.36-.08-.73-.18-1.11-.29a8.7,8.7,0,0,1-1.13-.35,3.86,3.86,0,0,1-1-.54,2.53,2.53,0,0,1-.71-.84,2.59,2.59,0,0,1-.26-1.2,2.7,2.7,0,0,1,.34-1.39,3,3,0,0,1,.89-1,3.72,3.72,0,0,1,1.24-.58A5.12,5.12,0,0,1,1359,448a6.66,6.66,0,0,1,1.56.18,3.86,3.86,0,0,1,1.32.59,2.94,2.94,0,0,1,.92,1,3.15,3.15,0,0,1,.34,1.53H1361a1.85,1.85,0,0,0-.19-.77,1.38,1.38,0,0,0-.45-.48,2,2,0,0,0-.63-.24,3.67,3.67,0,0,0-.77-.07,3.41,3.41,0,0,0-.55.05,1.48,1.48,0,0,0-.5.21,1.16,1.16,0,0,0-.37.36,1.15,1.15,0,0,0,0,1,1,1,0,0,0,.46.35,7.15,7.15,0,0,0,.94.32l1.57.4c.19,0,.46.11.8.21a3.41,3.41,0,0,1,1,.49,3,3,0,0,1,.88.89,2.56,2.56,0,0,1,.37,1.42,3.1,3.1,0,0,1-.28,1.32,2.84,2.84,0,0,1-.82,1.05,4.13,4.13,0,0,1-1.34.69,7,7,0,0,1-3.52,0,4.21,4.21,0,0,1-1.42-.66,3.25,3.25,0,0,1-1-1.14,3.41,3.41,0,0,1-.35-1.65h2.2A1.82,1.82,0,0,0,1357.21,456Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1368.76,451.1v1.37h-1.51v3.71a.71.71,0,0,0,.87.87l.33,0,.31,0v1.59a4.93,4.93,0,0,1-.58.06h-.62a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.33,1.33,0,0,1-.52-.53,2,2,0,0,1-.18-.91v-4.42H1364V451.1h1.25v-2.25h2v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1370,452.2a2.39,2.39,0,0,1,.81-.77,3.44,3.44,0,0,1,1.1-.41,6.53,6.53,0,0,1,1.24-.13,8.74,8.74,0,0,1,1.14.08,3.54,3.54,0,0,1,1.06.32,2,2,0,0,1,.78.64,1.76,1.76,0,0,1,.31,1.09v3.9c0,.33,0,.66,0,1a1.71,1.71,0,0,0,.21.69h-2.09a2.25,2.25,0,0,1-.09-.35,2.35,2.35,0,0,1,0-.37,2.85,2.85,0,0,1-1.16.71,4.81,4.81,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.35,2.35,0,0,1-.2-1,2.2,2.2,0,0,1,.23-1.05,1.86,1.86,0,0,1,.58-.66,2.51,2.51,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19c.3,0,.61-.09.91-.12a5.2,5.2,0,0,0,.79-.13,1.41,1.41,0,0,0,.55-.25.54.54,0,0,0,.19-.49,1,1,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.78,2.78,0,0,0-.51,0,1.58,1.58,0,0,0-1,.26,1.13,1.13,0,0,0-.41.87h-2.06A2.33,2.33,0,0,1,1370,452.2Zm2.59-3.95v1.69h-2v-1.69Zm1.47,6.85-.42.11-.47.07-.5.07a3.74,3.74,0,0,0-.45.12,1.42,1.42,0,0,0-.39.19,1,1,0,0,0-.27.32.94.94,0,0,0-.1.47,1,1,0,0,0,.1.47.84.84,0,0,0,.27.29,1,1,0,0,0,.41.15,2.44,2.44,0,0,0,.48.05,1.88,1.88,0,0,0,.94-.2,1.43,1.43,0,0,0,.49-.49,1.62,1.62,0,0,0,.2-.57c0-.19,0-.35,0-.46v-.77A.83.83,0,0,1,1374.08,455.1Zm1.51-6.85v1.69h-2v-1.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1380,451.1v1.39h0a2.88,2.88,0,0,1,.39-.65,2.73,2.73,0,0,1,.57-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.65,1.65,0,0,1,.45.08v1.91a2.66,2.66,0,0,0-.35-.05,2.55,2.55,0,0,0-.39,0,2.32,2.32,0,0,0-1,.18,1.78,1.78,0,0,0-.63.52,2,2,0,0,0-.33.76,4,4,0,0,0-.11.94v3.37h-2V451.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1385.92,448.25v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.73h-2.49l-2-3.34-.8.76v2.58h-2.05V448.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1397.05,458.58v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.32,2.32,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2v4.22a2.69,2.69,0,0,0,.29,1.39,1.13,1.13,0,0,0,1,.45,1.39,1.39,0,0,0,1.21-.5A2.7,2.7,0,0,0,1397,455V451.1H1399v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1402.52,451.1v1h0a2.36,2.36,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.54,3.54,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.58,2.58,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2V451.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1416.22,459.13a2.48,2.48,0,0,1-.53,1.08,3.28,3.28,0,0,1-1.17.85,5.09,5.09,0,0,1-2,.34,5,5,0,0,1-1.13-.13,4,4,0,0,1-1-.43,2.53,2.53,0,0,1-.78-.75,2.14,2.14,0,0,1-.35-1.09h2a1.13,1.13,0,0,0,.56.8,2,2,0,0,0,1,.23,1.52,1.52,0,0,0,1.27-.52,2.09,2.09,0,0,0,.38-1.32v-1h0a2.06,2.06,0,0,1-.95.87,3,3,0,0,1-1.29.28,3.41,3.41,0,0,1-1.45-.29,2.84,2.84,0,0,1-1-.81,3.47,3.47,0,0,1-.57-1.21,5.65,5.65,0,0,1-.18-1.45,4.7,4.7,0,0,1,.21-1.4,3.41,3.41,0,0,1,.62-1.18,2.76,2.76,0,0,1,1-.82,3,3,0,0,1,1.38-.31,3,3,0,0,1,1.3.28,2.19,2.19,0,0,1,.93.93h0v-1h1.95v7A5.18,5.18,0,0,1,1416.22,459.13Zm-2.81-2.49a1.63,1.63,0,0,0,.55-.46,2.13,2.13,0,0,0,.32-.66,2.87,2.87,0,0,0,.11-.77,4.1,4.1,0,0,0-.09-.88,1.9,1.9,0,0,0-.29-.73,1.52,1.52,0,0,0-.53-.51,1.74,1.74,0,0,0-.84-.19,1.49,1.49,0,0,0-.75.18,1.55,1.55,0,0,0-.52.47,1.91,1.91,0,0,0-.3.68,3.44,3.44,0,0,0-.1.8,4.26,4.26,0,0,0,.08.8,2.3,2.3,0,0,0,.28.72,1.58,1.58,0,0,0,.51.52,1.44,1.44,0,0,0,.8.2A1.62,1.62,0,0,0,1413.41,456.64Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1427.33,457.62a2.11,2.11,0,0,1-.95.88,3.11,3.11,0,0,1-1.32.27,3.19,3.19,0,0,1-1.48-.33,3,3,0,0,1-1-.88,4.12,4.12,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.12,3.12,0,0,1,1-.86,3,3,0,0,1,1.44-.33,2.92,2.92,0,0,1,1.27.29,2.19,2.19,0,0,1,1,.83h0v-3.76h2v10.33h-2v-1Zm-.09-3.69a2,2,0,0,0-.29-.76,1.61,1.61,0,0,0-.55-.52,1.54,1.54,0,0,0-.84-.21,1.62,1.62,0,0,0-.85.21,1.58,1.58,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,4.33,4.33,0,0,0-.1.89,3.7,3.7,0,0,0,.11.87,2.13,2.13,0,0,0,.32.77,1.76,1.76,0,0,0,.57.55,1.5,1.5,0,0,0,.81.21,1.6,1.6,0,0,0,.85-.2,1.4,1.4,0,0,0,.54-.54,2.21,2.21,0,0,0,.29-.78,4.33,4.33,0,0,0,.09-.89A4.26,4.26,0,0,0,1427.24,453.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1433.06,456.79a1.87,1.87,0,0,0,1.33.44,1.78,1.78,0,0,0,1.07-.31,1.2,1.2,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.76,4.76,0,0,1,0-3.16,3.75,3.75,0,0,1,.79-1.25,3.63,3.63,0,0,1,1.2-.83,4,4,0,0,1,1.56-.31,3.67,3.67,0,0,1,1.67.37,3.31,3.31,0,0,1,1.16,1,4.16,4.16,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67h-5.4A2.1,2.1,0,0,0,1433.06,456.79Zm2.32-3.94a1.44,1.44,0,0,0-1.12-.41,1.74,1.74,0,0,0-.81.17,1.6,1.6,0,0,0-.53.41,1.53,1.53,0,0,0-.27.52,2.75,2.75,0,0,0-.1.5h3.35A2.35,2.35,0,0,0,1435.38,452.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1441.12,451.1v1.39h0a2.88,2.88,0,0,1,.39-.65,2.73,2.73,0,0,1,.57-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35-.05a2.55,2.55,0,0,0-.39,0,2.32,2.32,0,0,0-1,.18,1.75,1.75,0,0,0-.62.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.11.94v3.37h-2V451.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1450.78,456a1.6,1.6,0,0,0,.5.59,2,2,0,0,0,.73.33,3.58,3.58,0,0,0,.87.11,5.27,5.27,0,0,0,.65-.05,2,2,0,0,0,.65-.2,1.28,1.28,0,0,0,.5-.39,1,1,0,0,0,.21-.65.91.91,0,0,0-.27-.68,2.08,2.08,0,0,0-.7-.43,6.82,6.82,0,0,0-1-.31c-.36-.08-.73-.18-1.11-.29a8.7,8.7,0,0,1-1.13-.35,3.86,3.86,0,0,1-1-.54,2.53,2.53,0,0,1-.71-.84,2.59,2.59,0,0,1-.26-1.2,2.7,2.7,0,0,1,.34-1.39,3,3,0,0,1,.89-1,3.72,3.72,0,0,1,1.24-.58,5.12,5.12,0,0,1,1.39-.19,6.66,6.66,0,0,1,1.56.18,4,4,0,0,1,1.32.59,2.94,2.94,0,0,1,.92,1,3.15,3.15,0,0,1,.34,1.53h-2.2a1.71,1.71,0,0,0-.2-.77,1.27,1.27,0,0,0-.44-.48,2,2,0,0,0-.63-.24,3.67,3.67,0,0,0-.77-.07,3.41,3.41,0,0,0-.55.05,1.48,1.48,0,0,0-.5.21,1.16,1.16,0,0,0-.37.36.94.94,0,0,0-.14.55.91.91,0,0,0,.11.49,1,1,0,0,0,.46.35,6.66,6.66,0,0,0,.94.32l1.57.4c.19,0,.46.11.8.21a3.66,3.66,0,0,1,1,.49,3,3,0,0,1,.88.89,2.56,2.56,0,0,1,.37,1.42,3.1,3.1,0,0,1-.28,1.32,2.84,2.84,0,0,1-.82,1.05,4.13,4.13,0,0,1-1.34.69,7,7,0,0,1-3.52,0,4.21,4.21,0,0,1-1.42-.66,3.25,3.25,0,0,1-1-1.14,3.3,3.3,0,0,1-.35-1.65h2.2A1.82,1.82,0,0,0,1450.78,456Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1458.26,453.22A3.49,3.49,0,0,1,1459,452a3.52,3.52,0,0,1,1.22-.79,4.21,4.21,0,0,1,1.59-.29,4.3,4.3,0,0,1,1.6.29,3.52,3.52,0,0,1,1.22.79,3.49,3.49,0,0,1,.78,1.25,4.86,4.86,0,0,1,0,3.25,3.53,3.53,0,0,1-.78,1.24,3.36,3.36,0,0,1-1.22.78,4.3,4.3,0,0,1-1.6.28,4.21,4.21,0,0,1-1.59-.28,3.36,3.36,0,0,1-1.22-.78,3.53,3.53,0,0,1-.78-1.24,4.86,4.86,0,0,1,0-3.25Zm1.87,2.5a2.16,2.16,0,0,0,.29.77,1.53,1.53,0,0,0,.56.54,2,2,0,0,0,1.74,0,1.48,1.48,0,0,0,.57-.54,2.38,2.38,0,0,0,.3-.77,4.94,4.94,0,0,0,.08-.87,5.26,5.26,0,0,0-.08-.89,2.24,2.24,0,0,0-.3-.76,1.58,1.58,0,0,0-.57-.55,1.69,1.69,0,0,0-2.3.55,2,2,0,0,0-.29.76,4.41,4.41,0,0,0-.09.89A4.14,4.14,0,0,0,1460.13,455.72Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1472.12,458.58v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.46,2.46,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2v4.22a2.69,2.69,0,0,0,.29,1.39,1.14,1.14,0,0,0,1,.45,1.41,1.41,0,0,0,1.22-.5A2.78,2.78,0,0,0,1472,455V451.1h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1477.49,458.58l-2.56-7.48h2.16l1.58,5.11h0l1.57-5.11h2l-2.53,7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1485.37,456.79a1.87,1.87,0,0,0,1.33.44,1.82,1.82,0,0,0,1.07-.31,1.24,1.24,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.54,3.54,0,0,1-.76-1.24,4.84,4.84,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.43,3.43,0,0,1,1.17,1,4.16,4.16,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67h-5.4A2.1,2.1,0,0,0,1485.37,456.79Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.1,2.1,0,0,0-.1.5h3.34A2.26,2.26,0,0,0,1487.69,452.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1493.43,451.1v1.39h0a2.29,2.29,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,2.85,2.85,0,0,1,.68-.33,2.42,2.42,0,0,1,.76-.12,1.52,1.52,0,0,1,.44.08v1.91l-.34-.05a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06V451.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1497.38,452.2a2.39,2.39,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,9,9,0,0,1,1.14.08,3.63,3.63,0,0,1,1.06.32,2.1,2.1,0,0,1,.78.64,1.83,1.83,0,0,1,.3,1.09v3.9a7.66,7.66,0,0,0,.06,1,1.72,1.72,0,0,0,.2.69H1502c0-.12-.07-.23-.1-.35s0-.25-.05-.37a2.82,2.82,0,0,1-1.15.71,4.88,4.88,0,0,1-1.37.2,3.88,3.88,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.78,1.78,0,0,1-.55-.69,2.35,2.35,0,0,1-.19-1,2.21,2.21,0,0,1,.22-1.05,1.86,1.86,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37c.31-.08.61-.14.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.57.57,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.84.84,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.82,2.82,0,0,0-.52,0,1.58,1.58,0,0,0-1,.26,1.16,1.16,0,0,0-.41.87h-2A2.43,2.43,0,0,1,1497.38,452.2Zm2.59-3.95v1.69h-2v-1.69Zm1.47,6.85-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19.92.92,0,0,0-.37.79,1.09,1.09,0,0,0,.1.47.78.78,0,0,0,.28.29.88.88,0,0,0,.4.15,2.61,2.61,0,0,0,.48.05,1.85,1.85,0,0,0,.94-.2,1.35,1.35,0,0,0,.49-.49,1.45,1.45,0,0,0,.2-.57c0-.19,0-.35,0-.46v-.77A.88.88,0,0,1,1501.44,455.1Zm1.51-6.85v1.69h-2v-1.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1507.37,451.1v1h0a2.42,2.42,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.45,1.42,1.42,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06V451.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1514.05,449.94v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06V451.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1521.84,451.1v1.37h-1.51v3.71a1,1,0,0,0,.18.69,1,1,0,0,0,.69.18l.34,0,.3,0v1.59a4.71,4.71,0,0,1-.58.06h-.62a6.62,6.62,0,0,1-.91-.06,2.22,2.22,0,0,1-.75-.26,1.24,1.24,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42H1517V451.1h1.24v-2.25h2v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1523.1,452.2a2.39,2.39,0,0,1,.81-.77A3.59,3.59,0,0,1,1525,451a6.42,6.42,0,0,1,1.23-.13,9,9,0,0,1,1.15.08,3.48,3.48,0,0,1,1,.32,2.06,2.06,0,0,1,.79.64,1.83,1.83,0,0,1,.3,1.09v3.9a7.66,7.66,0,0,0,.06,1,1.72,1.72,0,0,0,.2.69h-2.08c0-.12-.07-.23-.1-.35s0-.25,0-.37a2.79,2.79,0,0,1-1.16.71,4.76,4.76,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.38,2.38,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.35,2.35,0,0,1-.19-1,2.21,2.21,0,0,1,.22-1.05,1.86,1.86,0,0,1,.58-.66,2.61,2.61,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19l.91-.12a5.57,5.57,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.57.57,0,0,0,.18-.49,1.14,1.14,0,0,0-.1-.53.84.84,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.82,2.82,0,0,0-.52,0,1.58,1.58,0,0,0-1,.26,1.16,1.16,0,0,0-.41.87h-2A2.43,2.43,0,0,1,1523.1,452.2Zm2.59-3.95v1.69h-2v-1.69Zm1.47,6.85-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.42,1.42,0,0,0-.39.19,1,1,0,0,0-.27.32.94.94,0,0,0-.1.47,1,1,0,0,0,.1.47.78.78,0,0,0,.28.29.88.88,0,0,0,.4.15,2.61,2.61,0,0,0,.48.05,1.85,1.85,0,0,0,.94-.2,1.35,1.35,0,0,0,.49-.49,1.62,1.62,0,0,0,.2-.57c0-.19,0-.35,0-.46v-.77A.77.77,0,0,1,1527.16,455.1Zm1.51-6.85v1.69h-2v-1.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1535.24,451.1v1.37h-1.5v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.7.18l.33,0,.3,0v1.59a4.51,4.51,0,0,1-.58.06H1534a6.37,6.37,0,0,1-.9-.06,2.2,2.2,0,0,1-.76-.26,1.37,1.37,0,0,1-.51-.53,1.86,1.86,0,0,1-.19-.91v-4.42h-1.24V451.1h1.24v-2.25h2.06v2.25Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    <a href="https://rothcoaching-sources.com/de/workshops/single/kompass_drop_in___online">
      <g id="kompass-drop-in">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="1261.75"
          y="167.22"
          width="364.75"
          height="67.26"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1350.06,258.5v7.26l6.84-7.26h4.81l-6.84,6.91,7.5,10.6h-4.83l-5.27-7.87-2.21,2.23V276h-3.85V258.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1363.74,263.69a8.91,8.91,0,0,1,1.69-2.94,7.9,7.9,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,8,8,0,0,1,2.7,2,8.91,8.91,0,0,1,1.69,2.94,10.94,10.94,0,0,1,.59,3.64,10.59,10.59,0,0,1-.59,3.56,8.57,8.57,0,0,1-1.69,2.89,7.86,7.86,0,0,1-2.7,1.92,9.74,9.74,0,0,1-7.25,0,7.81,7.81,0,0,1-2.71-1.92,8.57,8.57,0,0,1-1.69-2.89,10.59,10.59,0,0,1-.59-3.56A10.94,10.94,0,0,1,1363.74,263.69Zm3.52,5.78a5.9,5.9,0,0,0,.82,1.88,4.4,4.4,0,0,0,1.47,1.34,5.13,5.13,0,0,0,4.42,0,4.4,4.4,0,0,0,1.47-1.34,5.69,5.69,0,0,0,.82-1.88,9,9,0,0,0,.26-2.14,9.74,9.74,0,0,0-.26-2.24,5.81,5.81,0,0,0-.82-1.92,4.33,4.33,0,0,0-1.47-1.35,5.13,5.13,0,0,0-4.42,0,4.33,4.33,0,0,0-1.47,1.35,6,6,0,0,0-.82,1.92,9.74,9.74,0,0,0-.26,2.24A9,9,0,0,0,1367.26,269.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1388.41,258.5l4.1,12h0l3.87-12h5.42V276h-3.6V263.6h0L1393.91,276h-3l-4.29-12.29h-.05V276H1383V258.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1413.13,258.5a7.28,7.28,0,0,1,2.8.48,5.1,5.1,0,0,1,1.87,1.26,4.73,4.73,0,0,1,1.06,1.79,6.61,6.61,0,0,1,.33,2.08,6.7,6.7,0,0,1-.33,2.08,4.69,4.69,0,0,1-1.06,1.8,5.22,5.22,0,0,1-1.87,1.26,7.28,7.28,0,0,1-2.8.48h-4V276h-3.85V258.5Zm-1.05,8.24a7.51,7.51,0,0,0,1.27-.1,2.83,2.83,0,0,0,1.08-.38,2.07,2.07,0,0,0,.75-.8,3.37,3.37,0,0,0,0-2.69,2.07,2.07,0,0,0-.75-.8,2.83,2.83,0,0,0-1.08-.38,7.51,7.51,0,0,0-1.27-.1h-3v5.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1428.51,258.5l6.55,17.51h-4l-1.33-3.9h-6.54l-1.38,3.9h-3.87l6.62-17.51Zm.22,10.74-2.21-6.43h0l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1439.52,271.69a2.75,2.75,0,0,0,.85,1,3.65,3.65,0,0,0,1.23.56,6.06,6.06,0,0,0,1.48.18,8.77,8.77,0,0,0,1.1-.08,4,4,0,0,0,1.1-.33,2.5,2.5,0,0,0,.86-.68,1.67,1.67,0,0,0,.34-1.09,1.54,1.54,0,0,0-.45-1.15,3.56,3.56,0,0,0-1.19-.74,11.84,11.84,0,0,0-1.67-.51c-.62-.15-1.25-.31-1.88-.49a19.5,19.5,0,0,1-1.92-.6,7.08,7.08,0,0,1-1.67-.92,4.36,4.36,0,0,1-1.18-1.41,4.22,4.22,0,0,1-.46-2.05,4.51,4.51,0,0,1,.58-2.34,5,5,0,0,1,1.51-1.66,6.53,6.53,0,0,1,2.1-1,9.12,9.12,0,0,1,2.36-.32,11,11,0,0,1,2.64.31,6.49,6.49,0,0,1,2.24,1,5.15,5.15,0,0,1,1.56,1.75,5.47,5.47,0,0,1,.57,2.59h-3.72a3.22,3.22,0,0,0-.34-1.3,2.14,2.14,0,0,0-.74-.81,3.27,3.27,0,0,0-1.07-.41,6,6,0,0,0-1.31-.13,4.9,4.9,0,0,0-.93.1,2.66,2.66,0,0,0-.85.34,2.32,2.32,0,0,0-.63.62,1.65,1.65,0,0,0-.24.93,1.48,1.48,0,0,0,.2.83,1.66,1.66,0,0,0,.77.59,9.6,9.6,0,0,0,1.59.54l2.66.69c.33.06.78.18,1.36.35a6.68,6.68,0,0,1,1.73.83,5.19,5.19,0,0,1,1.49,1.5,4.37,4.37,0,0,1,.62,2.42,5.36,5.36,0,0,1-.46,2.23,5,5,0,0,1-1.39,1.78,6.45,6.45,0,0,1-2.28,1.16,10.73,10.73,0,0,1-3.15.42,10.93,10.93,0,0,1-2.81-.36,6.79,6.79,0,0,1-2.4-1.11,5.48,5.48,0,0,1-1.66-1.94,5.8,5.8,0,0,1-.59-2.79h3.73A3.25,3.25,0,0,0,1439.52,271.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1455.43,271.69a3,3,0,0,0,.85,1,3.62,3.62,0,0,0,1.24.56,5.92,5.92,0,0,0,1.47.18,8.77,8.77,0,0,0,1.1-.08,4.08,4.08,0,0,0,1.11-.33,2.5,2.5,0,0,0,.86-.68,1.67,1.67,0,0,0,.34-1.09,1.54,1.54,0,0,0-.45-1.15,3.66,3.66,0,0,0-1.19-.74,11.84,11.84,0,0,0-1.67-.51c-.62-.15-1.25-.31-1.89-.49s-1.29-.37-1.91-.6a6.89,6.89,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.34,4.34,0,0,1-.45-2.05,4.61,4.61,0,0,1,.57-2.34,5.23,5.23,0,0,1,1.51-1.66,6.59,6.59,0,0,1,2.11-1,9.11,9.11,0,0,1,2.35-.32,10.85,10.85,0,0,1,2.64.31,6.41,6.41,0,0,1,2.24,1,5,5,0,0,1,1.56,1.75,5.36,5.36,0,0,1,.58,2.59h-3.73a3.07,3.07,0,0,0-.33-1.3,2.25,2.25,0,0,0-.75-.81,3.21,3.21,0,0,0-1.06-.41,6.09,6.09,0,0,0-1.32-.13,4.9,4.9,0,0,0-.93.1,2.6,2.6,0,0,0-.84.34,2.07,2.07,0,0,0-.63.62,1.65,1.65,0,0,0-.24.93,1.57,1.57,0,0,0,.19.83,1.71,1.71,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.69c.32.06.78.18,1.36.35a6.68,6.68,0,0,1,1.73.83,5.16,5.16,0,0,1,1.48,1.5,4.29,4.29,0,0,1,.63,2.42,5.37,5.37,0,0,1-.47,2.23,4.85,4.85,0,0,1-1.38,1.78A6.58,6.58,0,0,1,1462,276a10.63,10.63,0,0,1-3.15.42,10.86,10.86,0,0,1-2.8-.36,6.92,6.92,0,0,1-2.41-1.11A5.55,5.55,0,0,1,1452,273a5.69,5.69,0,0,1-.59-2.79h3.73A3.25,3.25,0,0,0,1455.43,271.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1482.78,258.5a9,9,0,0,1,3.15.54,7,7,0,0,1,2.53,1.62,7.52,7.52,0,0,1,1.66,2.69,11,11,0,0,1,.6,3.81,12,12,0,0,1-.49,3.53,7.62,7.62,0,0,1-1.48,2.79,6.88,6.88,0,0,1-2.48,1.85,8.36,8.36,0,0,1-3.49.68h-7.56V258.5Zm-.27,14.27a5,5,0,0,0,1.62-.27,3.56,3.56,0,0,0,1.39-.89,4.59,4.59,0,0,0,1-1.63,7.38,7.38,0,0,0,.36-2.46,10.32,10.32,0,0,0-.25-2.39,5,5,0,0,0-.85-1.83,3.77,3.77,0,0,0-1.56-1.16,6.19,6.19,0,0,0-2.39-.4h-2.75v11Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1502.84,258.5a5.52,5.52,0,0,1,2.12.38,4.85,4.85,0,0,1,1.62,1,4.54,4.54,0,0,1,1,1.53,5.07,5.07,0,0,1,.35,1.88,5.18,5.18,0,0,1-.65,2.67,4.07,4.07,0,0,1-2.12,1.72v0a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.24,4.24,0,0,1,.43,1.21,10,10,0,0,1,.18,1.32c0,.28,0,.61,0,1s0,.76.09,1.16a7.55,7.55,0,0,0,.19,1.11,2.66,2.66,0,0,0,.41.9h-3.85a7,7,0,0,1-.39-2c0-.77-.13-1.5-.23-2.21a3.47,3.47,0,0,0-.83-2,2.88,2.88,0,0,0-2.08-.63h-3.85V276h-3.85V258.5Zm-1.38,7.92a2.92,2.92,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,2.92,2.92,0,0,0-2-.58h-4.21v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1510.93,263.69a8.91,8.91,0,0,1,1.69-2.94,7.9,7.9,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,8,8,0,0,1,2.7,2,8.91,8.91,0,0,1,1.69,2.94,10.94,10.94,0,0,1,.59,3.64,10.59,10.59,0,0,1-.59,3.56,8.57,8.57,0,0,1-1.69,2.89,7.86,7.86,0,0,1-2.7,1.92,9.74,9.74,0,0,1-7.25,0,7.81,7.81,0,0,1-2.71-1.92,8.57,8.57,0,0,1-1.69-2.89,10.59,10.59,0,0,1-.59-3.56A10.94,10.94,0,0,1,1510.93,263.69Zm3.52,5.78a5.69,5.69,0,0,0,.82,1.88,4.4,4.4,0,0,0,1.47,1.34,5.13,5.13,0,0,0,4.42,0,4.4,4.4,0,0,0,1.47-1.34,5.9,5.9,0,0,0,.82-1.88,9,9,0,0,0,.26-2.14,9.74,9.74,0,0,0-.26-2.24,6,6,0,0,0-.82-1.92,4.33,4.33,0,0,0-1.47-1.35,5.13,5.13,0,0,0-4.42,0,4.33,4.33,0,0,0-1.47,1.35,5.81,5.81,0,0,0-.82,1.92,9.74,9.74,0,0,0-.26,2.24A9,9,0,0,0,1514.45,269.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1538.08,258.5a7.26,7.26,0,0,1,2.79.48,5.14,5.14,0,0,1,1.88,1.26,4.84,4.84,0,0,1,1,1.79,6.61,6.61,0,0,1,.33,2.08,6.7,6.7,0,0,1-.33,2.08,4.8,4.8,0,0,1-1,1.8,5.26,5.26,0,0,1-1.88,1.26,7.26,7.26,0,0,1-2.79.48h-4V276h-3.85V258.5Zm-1.06,8.24a7.63,7.63,0,0,0,1.28-.1,2.83,2.83,0,0,0,1.08-.38,2,2,0,0,0,.74-.8,3.26,3.26,0,0,0,0-2.69,2,2,0,0,0-.74-.8,2.83,2.83,0,0,0-1.08-.38,7.63,7.63,0,0,0-1.28-.1h-3v5.25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1553.53,267.82v3h-7.38v-3Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1560.37,258.5V276h-3.85V258.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1567.58,258.5l7.31,11.75h0V258.5h3.6V276h-3.85l-7.28-11.72h-.05V276h-3.61V258.5Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1350.67,283l3.86,10.34h-2.36l-.78-2.3h-3.86l-.81,2.3h-2.29l3.91-10.34Zm.13,6.34-1.3-3.79h0l-1.35,3.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1357.33,283v10.34h-2.06V283Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1361.06,283v10.34H1359V283Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1364.88,291.58a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.57,4.21,4.21,0,0,1-1.59-.28,3.16,3.16,0,0,1-1.2-.8,3.44,3.44,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.27-1.56,3.75,3.75,0,0,1,.79-1.25,3.49,3.49,0,0,1,1.2-.83,3.78,3.78,0,0,1,1.56-.31,3.48,3.48,0,0,1,1.66.37,3.33,3.33,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.39,5.39,0,0,1,.14,1.66h-5.4A2.08,2.08,0,0,0,1364.88,291.58Zm2.32-3.94a1.47,1.47,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.53,1.53,0,0,0-.52.41,1.44,1.44,0,0,0-.27.52,2.67,2.67,0,0,0-.1.49h3.35A2.43,2.43,0,0,0,1367.2,287.64Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1378.44,287.44h-3.6v-1.55h6.31v1.55l-3.9,4.39h4.17v1.55h-6.87v-1.55Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1389,293.38l-1.33-5h0l-1.27,5h-2.13l-2.37-7.49H1384l1.38,5.08h0l1.25-5.08h2l1.27,5.07h0l1.37-5.07h2.12l-2.36,7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1396.5,291.58a1.86,1.86,0,0,0,1.34.45,1.82,1.82,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.57,4.21,4.21,0,0,1-1.59-.28,3.33,3.33,0,0,1-1.2-.8,3.59,3.59,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.53,3.53,0,0,1,1.21-.83,3.73,3.73,0,0,1,1.55-.31,3.52,3.52,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.1,5.1,0,0,1,.15,1.66H1396A2.12,2.12,0,0,0,1396.5,291.58Zm2.33-3.94a1.48,1.48,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.44,1.44,0,0,0-.52.41,1.47,1.47,0,0,0-.28.52,2.57,2.57,0,0,0-.09.49h3.34A2.52,2.52,0,0,0,1398.83,287.64Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1402.67,284.74V283h2.06v1.7Zm2.06,1.15v7.49h-2.06v-7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1418.17,293.38l-1.75-7h0l-1.72,7h-2.3L1409.63,283h2.27l1.64,7h0l1.79-7h2.13l1.77,7.12h0L1421,283h2.23l-2.78,10.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1423.25,288a3.59,3.59,0,0,1,.78-1.24,3.64,3.64,0,0,1,1.22-.8,4.42,4.42,0,0,1,1.59-.28,4.51,4.51,0,0,1,1.6.28,3.43,3.43,0,0,1,2,2,4.46,4.46,0,0,1,.28,1.63,4.37,4.37,0,0,1-.28,1.62,3.36,3.36,0,0,1-.78,1.24,3.3,3.3,0,0,1-1.22.79,4.51,4.51,0,0,1-1.6.27,4.42,4.42,0,0,1-1.59-.27,3.38,3.38,0,0,1-2-2,4.59,4.59,0,0,1-.27-1.62A4.68,4.68,0,0,1,1423.25,288Zm1.87,2.51a2.47,2.47,0,0,0,.3.77,1.58,1.58,0,0,0,.55.54,1.75,1.75,0,0,0,.87.2,1.82,1.82,0,0,0,.88-.2,1.7,1.7,0,0,0,.56-.54,2.47,2.47,0,0,0,.3-.77,4.88,4.88,0,0,0,0-1.76,2.29,2.29,0,0,0-.3-.77,1.6,1.6,0,0,0-1.44-.75,1.65,1.65,0,0,0-.87.21,1.58,1.58,0,0,0-.55.54,2.29,2.29,0,0,0-.3.77,4.35,4.35,0,0,0,0,1.76Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1435.63,287.24a1.45,1.45,0,0,0-.83.22,1.87,1.87,0,0,0-.54.58,2.63,2.63,0,0,0-.3.78,4.14,4.14,0,0,0-.08.85,4,4,0,0,0,.08.83,2.65,2.65,0,0,0,.28.76,1.64,1.64,0,0,0,.53.55,1.4,1.4,0,0,0,.81.22,1.54,1.54,0,0,0,1.14-.41,2,2,0,0,0,.5-1.11h2a3.36,3.36,0,0,1-1.16,2.27,3.72,3.72,0,0,1-2.44.78,4,4,0,0,1-1.54-.28,3.44,3.44,0,0,1-1.2-.79,3.64,3.64,0,0,1-.77-1.2,4.31,4.31,0,0,1-.27-1.55,4.94,4.94,0,0,1,.25-1.61,3.59,3.59,0,0,1,.75-1.29,3.42,3.42,0,0,1,1.2-.84,3.94,3.94,0,0,1,1.62-.31,4.94,4.94,0,0,1,1.28.17,3.62,3.62,0,0,1,1.1.53,2.77,2.77,0,0,1,1.14,2.14h-2A1.39,1.39,0,0,0,1435.63,287.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1442.41,283v3.9h0a2.34,2.34,0,0,1,1-.95,2.65,2.65,0,0,1,1.18-.3,3.54,3.54,0,0,1,1.36.23,1.94,1.94,0,0,1,.83.62,2.39,2.39,0,0,1,.42,1,5.48,5.48,0,0,1,.13,1.26v4.61h-2.06v-4.23a2.53,2.53,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2.05V283Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1451.14,291.58a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.57,4.21,4.21,0,0,1-1.59-.28,3.24,3.24,0,0,1-1.2-.8,3.59,3.59,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.27-1.56,3.75,3.75,0,0,1,.79-1.25,3.49,3.49,0,0,1,1.2-.83,3.78,3.78,0,0,1,1.56-.31,3.48,3.48,0,0,1,1.66.37,3.33,3.33,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.39,5.39,0,0,1,.14,1.66h-5.4A2.08,2.08,0,0,0,1451.14,291.58Zm2.32-3.94a1.47,1.47,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.53,1.53,0,0,0-.52.41,1.44,1.44,0,0,0-.27.52,2.67,2.67,0,0,0-.1.49H1454A2.43,2.43,0,0,0,1453.46,287.64Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1459.2,285.89v1.05h0a2.3,2.3,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.54,3.54,0,0,1,1.36.23,1.88,1.88,0,0,1,.83.62,2.54,2.54,0,0,1,.43,1,6.13,6.13,0,0,1,.12,1.26v4.61h-2.06v-4.23a2.53,2.53,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2v-7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1475.17,292.42a2.11,2.11,0,0,1-1,.88,3.29,3.29,0,0,1-1.33.26,3.12,3.12,0,0,1-1.47-.32,2.91,2.91,0,0,1-1.05-.88,4,4,0,0,1-.62-1.28,5.45,5.45,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.61,3.61,0,0,1,.62-1.24,3,3,0,0,1,1-.86,3.19,3.19,0,0,1,2.72,0,2.27,2.27,0,0,1,1,.84h0V283h2.06v10.34h-2v-1Zm-.09-3.7a2.27,2.27,0,0,0-.3-.75,1.71,1.71,0,0,0-.54-.53,1.63,1.63,0,0,0-.84-.2,1.56,1.56,0,0,0-1.41.74,2.24,2.24,0,0,0-.3.76,3.92,3.92,0,0,0,0,1.76,2.42,2.42,0,0,0,.33.77,1.83,1.83,0,0,0,.56.55,1.54,1.54,0,0,0,.82.21,1.7,1.7,0,0,0,.85-.2,1.64,1.64,0,0,0,.54-.54,2.42,2.42,0,0,0,.29-.78,4.43,4.43,0,0,0,.09-.9A4.33,4.33,0,0,0,1475.08,288.72Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1480.9,291.58a2.1,2.1,0,0,0,2.4.14,1.32,1.32,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.57,4.21,4.21,0,0,1-1.59-.28,3.28,3.28,0,0,1-1.21-.8,3.74,3.74,0,0,1-.76-1.25,4.73,4.73,0,0,1-.26-1.59,4.41,4.41,0,0,1,.27-1.56,3.58,3.58,0,0,1,.78-1.25,3.53,3.53,0,0,1,1.21-.83,3.78,3.78,0,0,1,1.56-.31,3.38,3.38,0,0,1,2.83,1.36,4.3,4.3,0,0,1,.66,1.42,5.39,5.39,0,0,1,.14,1.66h-5.4A2.08,2.08,0,0,0,1480.9,291.58Zm2.32-3.94a1.48,1.48,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.48,1.48,0,0,0-.79.93,2,2,0,0,0-.1.49h3.34A2.34,2.34,0,0,0,1483.22,287.64Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1489,285.89v1.05h0a2.33,2.33,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,1.91,1.91,0,0,1,.84.62,2.39,2.39,0,0,1,.42,1,6.1,6.1,0,0,1,.13,1.26v4.61H1492v-4.23a2.63,2.63,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.46,1.42,1.42,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2v-7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1505,283l3.87,10.34h-2.36l-.78-2.3h-3.87l-.81,2.3h-2.29l3.91-10.34Zm.13,6.34-1.3-3.79h0l-1.35,3.79Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1511.48,285.89v1.05h0a2.33,2.33,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,1.91,1.91,0,0,1,.84.62,2.39,2.39,0,0,1,.42,1,6.1,6.1,0,0,1,.13,1.26v4.61h-2.06v-4.23a2.63,2.63,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.42,1.42,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2v-7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1519.84,291.52a1.2,1.2,0,0,0,.35.39,1.62,1.62,0,0,0,.5.22,2.32,2.32,0,0,0,.59.07,1.77,1.77,0,0,0,.45-.05,1.29,1.29,0,0,0,.44-.16.81.81,0,0,0,.32-.29.73.73,0,0,0,.13-.45c0-.31-.2-.54-.61-.7a13.74,13.74,0,0,0-1.72-.46c-.29-.07-.59-.15-.87-.24a2.81,2.81,0,0,1-.76-.36,1.92,1.92,0,0,1-.54-.56,1.68,1.68,0,0,1-.2-.84,2.22,2.22,0,0,1,.28-1.18,2,2,0,0,1,.75-.73,3.15,3.15,0,0,1,1-.38,6.67,6.67,0,0,1,1.19-.11,5.76,5.76,0,0,1,1.18.12,2.85,2.85,0,0,1,1,.39,2.24,2.24,0,0,1,.74.73,2.44,2.44,0,0,1,.36,1.15h-2a.89.89,0,0,0-.45-.8,2,2,0,0,0-1-.21,2.55,2.55,0,0,0-.38,0,1.12,1.12,0,0,0-.37.09.83.83,0,0,0-.28.21.54.54,0,0,0-.11.37.56.56,0,0,0,.2.45,1.4,1.4,0,0,0,.53.28,7,7,0,0,0,.74.2l.86.19a6.2,6.2,0,0,1,.87.24,3.18,3.18,0,0,1,.76.38,1.94,1.94,0,0,1,.54.6,1.78,1.78,0,0,1,.2.87,2.35,2.35,0,0,1-.3,1.24,2.27,2.27,0,0,1-.77.8,3,3,0,0,1-1.09.43,6.56,6.56,0,0,1-1.26.12,6,6,0,0,1-1.27-.13,3.38,3.38,0,0,1-1.11-.43,2.42,2.42,0,0,1-.79-.8,2.53,2.53,0,0,1-.34-1.26h2A1.16,1.16,0,0,0,1519.84,291.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1529.46,287.24a1.45,1.45,0,0,0-.83.22,1.87,1.87,0,0,0-.54.58,2.63,2.63,0,0,0-.3.78,4.14,4.14,0,0,0-.08.85,4,4,0,0,0,.08.83,2.65,2.65,0,0,0,.28.76,1.64,1.64,0,0,0,.53.55,1.4,1.4,0,0,0,.81.22,1.54,1.54,0,0,0,1.14-.41,2,2,0,0,0,.5-1.11h2a3.36,3.36,0,0,1-1.16,2.27,3.72,3.72,0,0,1-2.44.78,4,4,0,0,1-1.54-.28,3.44,3.44,0,0,1-1.2-.79,3.64,3.64,0,0,1-.77-1.2,4.31,4.31,0,0,1-.27-1.55,4.94,4.94,0,0,1,.25-1.61,3.59,3.59,0,0,1,.75-1.29,3.42,3.42,0,0,1,1.2-.84,3.94,3.94,0,0,1,1.62-.31,4.94,4.94,0,0,1,1.28.17,3.62,3.62,0,0,1,1.1.53,2.77,2.77,0,0,1,1.14,2.14h-2A1.39,1.39,0,0,0,1529.46,287.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1536.24,283v3.9h0a2.34,2.34,0,0,1,1-.95,2.65,2.65,0,0,1,1.18-.3,3.54,3.54,0,0,1,1.36.23,1.88,1.88,0,0,1,.83.62,2.39,2.39,0,0,1,.42,1,5.48,5.48,0,0,1,.13,1.26v4.61h-2.06v-4.23a2.53,2.53,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2V283Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1544.88,283v10.34h-2.05V283Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1551.57,293.38v-1.05h0a2.33,2.33,0,0,1-1,.95,3,3,0,0,1-1.27.28,3.68,3.68,0,0,1-1.35-.21,2.05,2.05,0,0,1-.84-.62,2.39,2.39,0,0,1-.42-1,6.1,6.1,0,0,1-.13-1.26v-4.61h2.06v4.23a2.63,2.63,0,0,0,.29,1.38,1.11,1.11,0,0,0,1,.46,1.42,1.42,0,0,0,1.21-.5,2.77,2.77,0,0,0,.38-1.64v-3.93h2.06v7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1556.82,291.52a1.2,1.2,0,0,0,.35.39,1.62,1.62,0,0,0,.5.22,2.27,2.27,0,0,0,.59.07,1.82,1.82,0,0,0,.45-.05,1.29,1.29,0,0,0,.44-.16.81.81,0,0,0,.32-.29.73.73,0,0,0,.13-.45c0-.31-.2-.54-.61-.7a13.74,13.74,0,0,0-1.72-.46q-.45-.1-.87-.24a2.81,2.81,0,0,1-.76-.36,1.92,1.92,0,0,1-.54-.56,1.68,1.68,0,0,1-.2-.84,2.22,2.22,0,0,1,.28-1.18,2,2,0,0,1,.75-.73,3.15,3.15,0,0,1,1-.38,6.67,6.67,0,0,1,1.19-.11,5.83,5.83,0,0,1,1.18.12,2.85,2.85,0,0,1,1,.39,2.24,2.24,0,0,1,.74.73,2.44,2.44,0,0,1,.36,1.15h-2a.89.89,0,0,0-.45-.8,2,2,0,0,0-1-.21,2.55,2.55,0,0,0-.38,0,1.12,1.12,0,0,0-.37.09.83.83,0,0,0-.28.21.54.54,0,0,0-.11.37.56.56,0,0,0,.2.45,1.4,1.4,0,0,0,.53.28,7,7,0,0,0,.74.2l.86.19a6.59,6.59,0,0,1,.87.24,3.18,3.18,0,0,1,.76.38,1.94,1.94,0,0,1,.54.6,1.78,1.78,0,0,1,.2.87,2.28,2.28,0,0,1-1.07,2,3.07,3.07,0,0,1-1.09.43,6.64,6.64,0,0,1-1.26.12,6,6,0,0,1-1.27-.13,3.3,3.3,0,0,1-1.11-.43,2.42,2.42,0,0,1-.79-.8,2.53,2.53,0,0,1-.34-1.26h2A1.16,1.16,0,0,0,1556.82,291.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1564.59,291.52a1.2,1.2,0,0,0,.35.39,1.62,1.62,0,0,0,.5.22,2.32,2.32,0,0,0,.59.07,1.9,1.9,0,0,0,.46-.05,1.31,1.31,0,0,0,.43-.16.91.91,0,0,0,.33-.29.8.8,0,0,0,.13-.45c0-.31-.21-.54-.62-.7a13.74,13.74,0,0,0-1.72-.46c-.29-.07-.59-.15-.87-.24a2.81,2.81,0,0,1-.76-.36,1.92,1.92,0,0,1-.54-.56,1.68,1.68,0,0,1-.2-.84,2.31,2.31,0,0,1,.28-1.18,2,2,0,0,1,.75-.73,3.23,3.23,0,0,1,1-.38,6.67,6.67,0,0,1,1.19-.11,5.76,5.76,0,0,1,1.18.12,2.85,2.85,0,0,1,1,.39,2.15,2.15,0,0,1,.74.73,2.44,2.44,0,0,1,.36,1.15h-2a.87.87,0,0,0-.44-.8,2.09,2.09,0,0,0-1-.21,2.55,2.55,0,0,0-.38,0,1.18,1.18,0,0,0-.37.09.83.83,0,0,0-.28.21.6.6,0,0,0-.11.37.56.56,0,0,0,.2.45,1.4,1.4,0,0,0,.53.28,7,7,0,0,0,.74.2l.86.19a6.2,6.2,0,0,1,.87.24,3.18,3.18,0,0,1,.76.38,1.94,1.94,0,0,1,.54.6,1.78,1.78,0,0,1,.2.87,2.35,2.35,0,0,1-.3,1.24,2.27,2.27,0,0,1-.77.8,3,3,0,0,1-1.09.43,6.56,6.56,0,0,1-1.26.12,6,6,0,0,1-1.27-.13,3.38,3.38,0,0,1-1.11-.43,2.42,2.42,0,0,1-.79-.8,2.53,2.53,0,0,1-.34-1.26h2A1.16,1.16,0,0,0,1564.59,291.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1573.87,287.27v-1.38h1.23v-.58a2.22,2.22,0,0,1,.63-1.63,2.49,2.49,0,0,1,1.88-.64l.55,0,.53,0v1.53c-.24,0-.49,0-.75,0a.79.79,0,0,0-.6.2,1,1,0,0,0-.18.65v.45h1.42v1.38h-1.42v6.11h-2.06v-6.11Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1579.53,284.74V283h2.06v1.7Zm2.06,1.15v7.49h-2.06v-7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1585.16,285.89v1.05h0a2.3,2.3,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.54,3.54,0,0,1,1.36.23,1.88,1.88,0,0,1,.83.62,2.54,2.54,0,0,1,.43,1,6.13,6.13,0,0,1,.12,1.26v4.61h-2.06v-4.23a2.53,2.53,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2v-7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1597.1,292.42a2.09,2.09,0,0,1-.94.88,3.36,3.36,0,0,1-1.33.26,3.19,3.19,0,0,1-1.48-.32,3,3,0,0,1-1-.88,4,4,0,0,1-.63-1.28,5.45,5.45,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.63,3.63,0,0,1,.63-1.24,3,3,0,0,1,1-.86,3.17,3.17,0,0,1,2.71,0,2.21,2.21,0,0,1,1,.84h0V283h2.06v10.34h-2v-1Zm-.08-3.7a2.27,2.27,0,0,0-.3-.75,1.71,1.71,0,0,0-.54-.53,1.63,1.63,0,0,0-.84-.2,1.74,1.74,0,0,0-.86.2,1.67,1.67,0,0,0-.55.54,2.48,2.48,0,0,0-.31.76,4.26,4.26,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.42,2.42,0,0,0,.33.77,1.64,1.64,0,0,0,.56.55,1.54,1.54,0,0,0,.82.21,1.63,1.63,0,0,0,.84-.2,1.67,1.67,0,0,0,.55-.54,2.68,2.68,0,0,0,.29-.78,5.29,5.29,0,0,0,.08-.9A5.17,5.17,0,0,0,1597,288.72Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1602.83,291.58a1.86,1.86,0,0,0,1.34.45,1.82,1.82,0,0,0,1.07-.31,1.32,1.32,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.57,4.25,4.25,0,0,1-1.59-.28,3.33,3.33,0,0,1-1.2-.8,3.59,3.59,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.53,3.53,0,0,1,1.21-.83,3.73,3.73,0,0,1,1.55-.31,3.52,3.52,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.1,5.1,0,0,1,.15,1.66h-5.4A2.12,2.12,0,0,0,1602.83,291.58Zm2.33-3.94a1.48,1.48,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.44,1.44,0,0,0-.52.41,1.65,1.65,0,0,0-.28.52,2.57,2.57,0,0,0-.09.49h3.34A2.43,2.43,0,0,0,1605.16,287.64Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1610.9,285.89v1.05h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,1.88,1.88,0,0,1,.83.62,2.27,2.27,0,0,1,.43,1,6.13,6.13,0,0,1,.12,1.26v4.61h-2v-4.23a2.63,2.63,0,0,0-.29-1.38,1.12,1.12,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.93h-2.06v-7.49Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1619.9,291.15v2.23a2.25,2.25,0,0,1-.67,1.62,2.59,2.59,0,0,1-.71.5,3,3,0,0,1-.88.26v-1a1.05,1.05,0,0,0,.41-.17,1.45,1.45,0,0,0,.34-.31,1.25,1.25,0,0,0,.22-.41,1.17,1.17,0,0,0,.06-.45h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1630.67,285h-5.11V283h8.13v1.8l-5.54,6.63h5.69v1.91h-8.72v-1.8Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1635.07,286.1a5.13,5.13,0,0,1,1-1.73,4.47,4.47,0,0,1,1.6-1.15,5.64,5.64,0,0,1,4.27,0,4.52,4.52,0,0,1,1.59,1.15,5.13,5.13,0,0,1,1,1.73,6.51,6.51,0,0,1,.35,2.15,6.31,6.31,0,0,1-.35,2.11,4.9,4.9,0,0,1-1,1.7,4.62,4.62,0,0,1-1.59,1.14,5.76,5.76,0,0,1-4.27,0,4.57,4.57,0,0,1-1.6-1.14,4.9,4.9,0,0,1-1-1.7,6.07,6.07,0,0,1-.35-2.11A6.26,6.26,0,0,1,1635.07,286.1Zm2.07,3.42a3.72,3.72,0,0,0,.49,1.11,2.55,2.55,0,0,0,.87.79,3.06,3.06,0,0,0,2.6,0,2.55,2.55,0,0,0,.87-.79,3.72,3.72,0,0,0,.49-1.11,5.32,5.32,0,0,0,.15-1.27,5.66,5.66,0,0,0-.15-1.31,3.67,3.67,0,0,0-.49-1.14,2.48,2.48,0,0,0-.87-.8,3.06,3.06,0,0,0-2.6,0,2.48,2.48,0,0,0-.87.8,3.67,3.67,0,0,0-.49,1.14,5.66,5.66,0,0,0-.15,1.31A5.32,5.32,0,0,0,1637.14,289.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1646.33,286.1a5.13,5.13,0,0,1,1-1.73,4.52,4.52,0,0,1,1.59-1.15,5.27,5.27,0,0,1,2.14-.42,5.21,5.21,0,0,1,2.14.42,4.52,4.52,0,0,1,1.59,1.15,5.13,5.13,0,0,1,1,1.73,6.51,6.51,0,0,1,.35,2.15,6.31,6.31,0,0,1-.35,2.11,4.9,4.9,0,0,1-1,1.7,4.62,4.62,0,0,1-1.59,1.14,5.37,5.37,0,0,1-2.14.41,5.44,5.44,0,0,1-2.14-.41,4.62,4.62,0,0,1-1.59-1.14,4.9,4.9,0,0,1-1-1.7,6.31,6.31,0,0,1-.35-2.11A6.51,6.51,0,0,1,1646.33,286.1Zm2.07,3.42a3.72,3.72,0,0,0,.49,1.11,2.55,2.55,0,0,0,.87.79,3.06,3.06,0,0,0,2.6,0,2.55,2.55,0,0,0,.87-.79,3.72,3.72,0,0,0,.49-1.11,5.32,5.32,0,0,0,.15-1.27,5.66,5.66,0,0,0-.15-1.31,3.67,3.67,0,0,0-.49-1.14,2.48,2.48,0,0,0-.87-.8,3.06,3.06,0,0,0-2.6,0,2.48,2.48,0,0,0-.87.8,3.67,3.67,0,0,0-.49,1.14,5.66,5.66,0,0,0-.15,1.31A5.32,5.32,0,0,0,1648.4,289.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1660.89,283l2.41,7.11h0l2.29-7.11h3.2v10.34h-2.13v-7.33h0l-2.53,7.33h-1.75l-2.53-7.26h0v7.26h-2.13V283Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </a>
    <g id="masterclass-helden-und-loser">
      <rect
        class="e8181e97-3015-49de-82f8-4bd3baa60643"
        x="1287.23"
        y="631.32"
        width="335.24"
        height="95.68"
      />
      <g>
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1377.11,722.59l4.1,12h0l3.87-12h5.42V740.1H1387V727.69h0l-4.29,12.41h-3l-4.29-12.28h0V740.1h-3.61V722.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1402.67,722.59l6.54,17.51h-4l-1.33-3.9h-6.55l-1.37,3.9h-3.87l6.62-17.51Zm.22,10.74-2.21-6.42h0l-2.28,6.42Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1413.68,735.79a2.79,2.79,0,0,0,.84,1,3.7,3.7,0,0,0,1.24.56,5.92,5.92,0,0,0,1.47.19,7.41,7.41,0,0,0,1.11-.09,4,4,0,0,0,1.1-.33,2.47,2.47,0,0,0,.86-.67,1.7,1.7,0,0,0,.34-1.1,1.55,1.55,0,0,0-.45-1.15,3.65,3.65,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.61,4.61,0,0,1,.57-2.34,5.45,5.45,0,0,1,1.51-1.66,6.81,6.81,0,0,1,2.11-1,9.12,9.12,0,0,1,2.36-.32,11.34,11.34,0,0,1,2.63.31,7,7,0,0,1,2.25,1,5.05,5.05,0,0,1,1.55,1.76,5.3,5.3,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.74,6.74,0,0,0-1.32-.12,4.88,4.88,0,0,0-.93.09,2.41,2.41,0,0,0-.84.35,2,2,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.61,1.61,0,0,0,.19.84,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.08,5.08,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.34,5.34,0,0,1-.47,2.23,4.85,4.85,0,0,1-1.38,1.78,6.81,6.81,0,0,1-2.28,1.17,11.11,11.11,0,0,1-3.16.41,10.85,10.85,0,0,1-2.8-.35,7.12,7.12,0,0,1-2.41-1.12,5.46,5.46,0,0,1-1.65-1.94,5.69,5.69,0,0,1-.59-2.79h3.73A3.14,3.14,0,0,0,1413.68,735.79Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1425.28,725.83v-3.24h14.34v3.24h-5.25V740.1h-3.85V725.83Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1454.73,722.59v3.24h-9.25v3.75H1454v3h-8.49v4.29h9.44v3.24h-13.29V722.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1467,722.59a5.73,5.73,0,0,1,2.13.38,5.07,5.07,0,0,1,1.61,1,4.53,4.53,0,0,1,1,1.54,4.83,4.83,0,0,1,.36,1.87,5.34,5.34,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a3,3,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.69,4.69,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6.05,1s0,.76.08,1.15a7.93,7.93,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.47,3.47,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85V722.59Zm-1.37,7.92a2.89,2.89,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1486.46,727.26a4,4,0,0,0-.86-1,4.24,4.24,0,0,0-1.16-.65,4.18,4.18,0,0,0-1.36-.23,4.43,4.43,0,0,0-2.21.51,4,4,0,0,0-1.47,1.34,5.86,5.86,0,0,0-.82,1.93,9.14,9.14,0,0,0-.26,2.23,8.55,8.55,0,0,0,.26,2.15,5.64,5.64,0,0,0,.82,1.87,4.13,4.13,0,0,0,1.47,1.34,4.54,4.54,0,0,0,2.21.5,3.55,3.55,0,0,0,2.75-1.08,5,5,0,0,0,1.22-2.84h3.73a9.26,9.26,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25A6.8,6.8,0,0,1,1486,740a8.51,8.51,0,0,1-3,.49,8.92,8.92,0,0,1-3.62-.7,7.59,7.59,0,0,1-2.71-1.92,8.46,8.46,0,0,1-1.69-2.88,10.41,10.41,0,0,1-.59-3.57,10.73,10.73,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.56,7.56,0,0,1,2.71-2,9.3,9.3,0,0,1,6.35-.3,7.3,7.3,0,0,1,2.31,1.22,6.66,6.66,0,0,1,1.67,2,7,7,0,0,1,.84,2.7h-3.73A3.09,3.09,0,0,0,1486.46,727.26Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1497.25,722.59v14.27h8.53v3.24H1493.4V722.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1516.67,722.59l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.74-2.2-6.42h0l-2.28,6.42Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1527.68,735.79a2.93,2.93,0,0,0,.85,1,3.62,3.62,0,0,0,1.24.56,5.92,5.92,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4.08,4.08,0,0,0,1.11-.33,2.35,2.35,0,0,0,.85-.67,1.7,1.7,0,0,0,.35-1.1,1.52,1.52,0,0,0-.46-1.15,3.44,3.44,0,0,0-1.19-.73,11.64,11.64,0,0,0-1.66-.52c-.62-.15-1.25-.31-1.89-.49a15.14,15.14,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.61,4.61,0,0,1,.57-2.34,5.45,5.45,0,0,1,1.51-1.66,6.7,6.7,0,0,1,2.11-1,9,9,0,0,1,2.35-.32,11.36,11.36,0,0,1,2.64.31,6.8,6.8,0,0,1,2.24,1,5,5,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3.07,3.07,0,0,0-1.07-.42,6.64,6.64,0,0,0-1.31-.12,4.88,4.88,0,0,0-.93.09,2.55,2.55,0,0,0-.85.35,2.12,2.12,0,0,0-.62.61,1.69,1.69,0,0,0-.25.93,1.61,1.61,0,0,0,.2.84,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.68c.32.07.78.19,1.36.36a6.47,6.47,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.34,5.34,0,0,1-.47,2.23,4.77,4.77,0,0,1-1.39,1.78,6.63,6.63,0,0,1-2.28,1.17,11.89,11.89,0,0,1-6,.06,7.16,7.16,0,0,1-2.4-1.12,5.37,5.37,0,0,1-1.65-1.94,5.69,5.69,0,0,1-.59-2.79h3.73A3.26,3.26,0,0,0,1527.68,735.79Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1543.6,735.79a2.72,2.72,0,0,0,.85,1,3.65,3.65,0,0,0,1.23.56,6,6,0,0,0,1.47.19,7.31,7.31,0,0,0,1.11-.09,4,4,0,0,0,1.1-.33,2.38,2.38,0,0,0,.86-.67,1.7,1.7,0,0,0,.34-1.1,1.55,1.55,0,0,0-.45-1.15,3.54,3.54,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.52,4.52,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.51,4.51,0,0,1,.58-2.34,5.21,5.21,0,0,1,1.51-1.66,6.75,6.75,0,0,1,2.1-1,9.12,9.12,0,0,1,2.36-.32,11.49,11.49,0,0,1,2.64.31,6.8,6.8,0,0,1,2.24,1,5.18,5.18,0,0,1,1.56,1.76,5.42,5.42,0,0,1,.57,2.59H1550a3,3,0,0,0-.33-1.3,2,2,0,0,0-.74-.81,3.15,3.15,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,5.07,5.07,0,0,0-.94.09,2.41,2.41,0,0,0-.84.35,2.29,2.29,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.61,1.61,0,0,0,.19.84,1.82,1.82,0,0,0,.78.59,9.6,9.6,0,0,0,1.59.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.23,5.23,0,0,1,1.49,1.51,4.33,4.33,0,0,1,.62,2.41,5.33,5.33,0,0,1-.46,2.23,5,5,0,0,1-1.39,1.78,6.63,6.63,0,0,1-2.28,1.17,11.09,11.09,0,0,1-3.15.41,10.93,10.93,0,0,1-2.81-.35,7,7,0,0,1-2.4-1.12,5.39,5.39,0,0,1-1.66-1.94,5.8,5.8,0,0,1-.59-2.79h3.73A3.26,3.26,0,0,0,1543.6,735.79Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1375.54,748.59v6.72h7.09v-6.72h3.85V766.1h-3.85v-7.55h-7.09v7.55h-3.85V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1403,748.59v3.24h-9.25v3.75h8.49v3h-8.49v4.29h9.45v3.24h-13.3V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1409.61,748.59v14.27h8.53v3.24h-12.39V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1427.85,748.59a9,9,0,0,1,3.15.54,6.87,6.87,0,0,1,2.53,1.62,7.63,7.63,0,0,1,1.67,2.7,10.9,10.9,0,0,1,.6,3.8,12,12,0,0,1-.5,3.53,7.74,7.74,0,0,1-1.48,2.8,7,7,0,0,1-2.48,1.85,8.33,8.33,0,0,1-3.49.67h-7.55V748.59Zm-.27,14.27a4.92,4.92,0,0,0,1.62-.27,3.45,3.45,0,0,0,1.4-.89,4.72,4.72,0,0,0,1-1.63,7.3,7.3,0,0,0,.37-2.45,10.23,10.23,0,0,0-.26-2.39,4.76,4.76,0,0,0-.85-1.83,3.74,3.74,0,0,0-1.55-1.17,6.27,6.27,0,0,0-2.4-.4h-2.74v11Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1445.56,750.58l.66,1.89-2.9.95,1.82,2.41-1.62,1.2-1.81-2.53L1440,757l-1.64-1.2,1.81-2.41-2.84-.95.66-1.89,2.77,1.08v-3.07h2v3.07Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1452.3,748.59V766.1h-3.85V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1459.51,748.59l7.31,11.75h0V748.59h3.6V766.1h-3.85l-7.28-11.72h0V766.1h-3.61V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1477.68,748.59l7.31,11.75h0V748.59h3.6V766.1h-3.85l-7.28-11.72h0V766.1h-3.6V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1505.12,748.59v3.24h-9.24v3.75h8.48v3h-8.48v4.29h9.44v3.24H1492V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1511.74,748.59l7.31,11.75h0V748.59h3.61V766.1h-3.85l-7.29-11.72h0V766.1h-3.6V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1542.35,748.59a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,4.82,4.82,0,0,1,.35,1.87,5.24,5.24,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.35,4.35,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s0,.76.09,1.15a7.93,7.93,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.88,6.88,0,0,1-.39-2c0-.77-.13-1.51-.22-2.21a3.48,3.48,0,0,0-.84-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V748.59Zm-1.37,7.92a2.86,2.86,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.22v4.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1563.71,748.59v3.24h-9.25v3.75H1563v3h-8.49v4.29h9.44v3.24h-13.29V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1578.81,748.59v3.24h-8.46v4h7.34v3h-7.34v7.23h-3.85V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1590.49,748.59a5.72,5.72,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.68,4.68,0,0,1,1,1.54,5,5,0,0,1,.35,1.87,5.24,5.24,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v.05a2.92,2.92,0,0,1,1.18.6,3.4,3.4,0,0,1,.76,1,4.69,4.69,0,0,1,.43,1.21,10.11,10.11,0,0,1,.18,1.33c0,.28,0,.6,0,1s0,.76.09,1.15a7.93,7.93,0,0,0,.19,1.12,2.52,2.52,0,0,0,.41.89h-3.85a6.54,6.54,0,0,1-.4-2c0-.77-.12-1.51-.22-2.21a3.41,3.41,0,0,0-.83-2,2.83,2.83,0,0,0-2.08-.64h-3.85v6.84h-3.85V748.59Zm-1.38,7.92a2.87,2.87,0,0,0,2-.59,2.44,2.44,0,0,0,.66-1.91,2.33,2.33,0,0,0-.66-1.85,3,3,0,0,0-2-.58h-4.21v4.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1611.85,748.59v3.24h-9.25v3.75h8.49v3h-8.49v4.29H1612v3.24h-13.29V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1617.58,761.79a2.93,2.93,0,0,0,.85,1,3.7,3.7,0,0,0,1.24.56,5.92,5.92,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,4.08,4.08,0,0,0,1.11-.33,2.47,2.47,0,0,0,.86-.67,1.7,1.7,0,0,0,.34-1.1,1.55,1.55,0,0,0-.45-1.15,3.65,3.65,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.15-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.57,6.57,0,0,1-1.67-.92,4.39,4.39,0,0,1-1.19-1.41,4.31,4.31,0,0,1-.45-2,4.61,4.61,0,0,1,.57-2.34,5.45,5.45,0,0,1,1.51-1.66,6.81,6.81,0,0,1,2.11-1,9.11,9.11,0,0,1,2.35-.32,11.36,11.36,0,0,1,2.64.31,6.8,6.8,0,0,1,2.24,1,5,5,0,0,1,1.56,1.76,5.3,5.3,0,0,1,.58,2.59H1624a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.88,4.88,0,0,0-.93.09,2.41,2.41,0,0,0-.84.35,2,2,0,0,0-.63.61,1.69,1.69,0,0,0-.24.93,1.61,1.61,0,0,0,.19.84,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.68c.33.07.78.19,1.36.36a6.33,6.33,0,0,1,1.73.82,5.08,5.08,0,0,1,1.48,1.51,4.25,4.25,0,0,1,.63,2.41,5.34,5.34,0,0,1-.47,2.23,4.85,4.85,0,0,1-1.38,1.78,6.77,6.77,0,0,1-2.29,1.17,11,11,0,0,1-3.15.41,10.85,10.85,0,0,1-2.8-.35,7.12,7.12,0,0,1-2.41-1.12,5.46,5.46,0,0,1-1.65-1.94,5.69,5.69,0,0,1-.59-2.79h3.73A3.26,3.26,0,0,0,1617.58,761.79Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1634.41,748.59v6.72h7.09v-6.72h3.85V766.1h-3.85v-7.55h-7.09v7.55h-3.85V748.59Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1373,773.14l4.31,6.93h0v-6.93h2.13v10.33h-2.28l-4.29-6.92h0v6.92h-2.13V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1386.28,783.47v-1h0a2.39,2.39,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.66,3.66,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.32,2.32,0,0,1-.43-1,6.3,6.3,0,0,1-.12-1.27V776h2v4.22a2.63,2.63,0,0,0,.29,1.38,1.11,1.11,0,0,0,1,.46,1.42,1.42,0,0,0,1.21-.5,2.7,2.7,0,0,0,.38-1.64V776h2.06v7.48Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1391.75,776v1.39h0a2.45,2.45,0,0,1,1-1.15,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.53,1.53,0,0,1,.45.08v1.91l-.35,0-.39,0a2.23,2.23,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.11.94v3.37h-2V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1398.67,777.36V776h1.23v-.58a2.27,2.27,0,0,1,.62-1.64,2.57,2.57,0,0,1,1.89-.63,5.15,5.15,0,0,1,.55,0l.53,0v1.54a6,6,0,0,0-.75-.05.79.79,0,0,0-.6.2,1,1,0,0,0-.18.66V776h1.42v1.37H1402v6.11h-2.06v-6.11Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1409.34,783.47v-1h0a2.34,2.34,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.76,3.76,0,0,1-1.36-.22,2,2,0,0,1-.83-.61,2.45,2.45,0,0,1-.42-1,5.63,5.63,0,0,1-.13-1.27V776h2.06v4.22a2.53,2.53,0,0,0,.29,1.38,1.1,1.1,0,0,0,1,.46,1.44,1.44,0,0,0,1.22-.5,2.77,2.77,0,0,0,.38-1.64V776h2v7.48Zm-2.07-10.33v1.69h-2v-1.69Zm3,0v1.69h-2v-1.69Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1414.81,776v1.39h0a2.16,2.16,0,0,1,.39-.65,2.42,2.42,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.42,2.42,0,0,1,.76-.12,1.52,1.52,0,0,1,.44.08v1.91l-.34,0-.39,0a2.28,2.28,0,0,0-1,.19,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.05V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1425,773.14v4h4.18v-4h2.27v10.33h-2.27V779H1425v4.46h-2.27V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1435.43,781.67a1.86,1.86,0,0,0,1.34.45,1.82,1.82,0,0,0,1.07-.31,1.33,1.33,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.9,3.9,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.42,5.42,0,0,1,.15,1.67h-5.4A2,2,0,0,0,1435.43,781.67Zm2.33-3.93a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,1437.76,777.74Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1443.65,773.14v10.33h-2.05V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1450.59,782.51a2.11,2.11,0,0,1-.95.88,3.43,3.43,0,0,1-2.8-.06,2.82,2.82,0,0,1-1-.88,3.91,3.91,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.7,3.7,0,0,1,.62-1.24,3,3,0,0,1,2.48-1.19,3,3,0,0,1,1.27.29,2.18,2.18,0,0,1,1,.83h0v-3.76h2.06v10.33h-2v-1Zm-.09-3.69a2.24,2.24,0,0,0-.3-.76,1.68,1.68,0,0,0-.54-.52,1.54,1.54,0,0,0-.84-.21,1.6,1.6,0,0,0-.85.21,1.58,1.58,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,3.74,3.74,0,0,0-.1.89,4.3,4.3,0,0,0,.1.87,2.33,2.33,0,0,0,.33.77,1.83,1.83,0,0,0,.56.55,1.54,1.54,0,0,0,.82.21,1.6,1.6,0,0,0,.85-.2,1.55,1.55,0,0,0,.54-.54,2.42,2.42,0,0,0,.29-.78,4.33,4.33,0,0,0,.09-.89A4.26,4.26,0,0,0,1450.5,778.82Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1454.18,774.83v-1.69h2v1.69Zm2,1.16v7.48h-2V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1459.81,776v1h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.14,1.14,0,0,0-1-.45,1.45,1.45,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92h-2.06V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1468.39,776v1h0a2.33,2.33,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.12,6.12,0,0,1,.13,1.27v4.6h-2.06v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1477.22,781.67a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66H1482a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.51,4.51,0,0,1-1.6-.28,3.35,3.35,0,0,1-1.2-.81,3.8,3.8,0,0,1-.76-1.24,4.82,4.82,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.73,3.73,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.51,3.51,0,0,1,1.17,1,4.09,4.09,0,0,1,.65,1.42,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,1477.22,781.67Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.72,2.72,0,0,0-.09.49h3.34A2.26,2.26,0,0,0,1479.54,777.74Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1485.28,776v1h0a2.33,2.33,0,0,1,1-.95,2.89,2.89,0,0,1,1.27-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.83,2.83,0,0,0-.38,1.65v3.92h-2.05V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1501,783.47v-1h0a2.34,2.34,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.76,3.76,0,0,1-1.36-.22,1.94,1.94,0,0,1-.83-.61,2.61,2.61,0,0,1-.43-1,6.3,6.3,0,0,1-.12-1.27V776H1498v4.22a2.53,2.53,0,0,0,.29,1.38,1.1,1.1,0,0,0,1,.46,1.44,1.44,0,0,0,1.22-.5,2.77,2.77,0,0,0,.38-1.64V776h2v7.48Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1506.47,776v1h0a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.45,3.45,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2v-4.23a2.68,2.68,0,0,0-.29-1.38,1.13,1.13,0,0,0-1-.45,1.41,1.41,0,0,0-1.21.49,2.75,2.75,0,0,0-.38,1.65v3.92h-2.06V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1518.41,782.51a2.11,2.11,0,0,1-1,.88,3.11,3.11,0,0,1-1.32.27,3.17,3.17,0,0,1-1.48-.33,3,3,0,0,1-1.05-.88,4.12,4.12,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.12,3.12,0,0,1,1-.86,3,3,0,0,1,1.44-.33,2.92,2.92,0,0,1,1.27.29,2.13,2.13,0,0,1,1,.83h0v-3.76h2.05v10.33h-2v-1Zm-.09-3.69a2.23,2.23,0,0,0-.29-.76,1.82,1.82,0,0,0-.55-.52,1.54,1.54,0,0,0-.84-.21,1.6,1.6,0,0,0-.85.21,1.58,1.58,0,0,0-.56.53,2.32,2.32,0,0,0-.3.76,3.92,3.92,0,0,0,0,1.76,2.33,2.33,0,0,0,.33.77,1.76,1.76,0,0,0,.57.55,1.48,1.48,0,0,0,.81.21,1.6,1.6,0,0,0,.85-.2,1.47,1.47,0,0,0,.54-.54,2.42,2.42,0,0,0,.29-.78,4.33,4.33,0,0,0,.09-.89A4.26,4.26,0,0,0,1518.32,778.82Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1528.45,773.14v4h4.19v-4h2.27v10.33h-2.27V779h-4.19v4.46h-2.27V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1538.89,781.67a2.1,2.1,0,0,0,2.4.14,1.28,1.28,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.8,3.8,0,0,1-.76-1.24,4.82,4.82,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.73,3.73,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,4,4,0,0,1,1.56-.31,3.63,3.63,0,0,1,1.66.37,3.42,3.42,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,1538.89,781.67Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.13,2.13,0,0,0-.1.49h3.34A2.26,2.26,0,0,0,1541.21,777.74Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1547.11,773.14v10.33h-2.06V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1554,782.51a2.11,2.11,0,0,1-1,.88,3.11,3.11,0,0,1-1.32.27,3.19,3.19,0,0,1-1.48-.33,3,3,0,0,1-1-.88,4.12,4.12,0,0,1-.62-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.9,3.9,0,0,1,.62-1.24,3.12,3.12,0,0,1,1-.86,3,3,0,0,1,1.45-.33,2.91,2.91,0,0,1,1.26.29,2.13,2.13,0,0,1,1,.83h0v-3.76h2v10.33h-2v-1Zm-.08-3.69a2.24,2.24,0,0,0-.3-.76,1.68,1.68,0,0,0-.54-.52,1.59,1.59,0,0,0-.84-.21,1.64,1.64,0,0,0-.86.21,1.47,1.47,0,0,0-.55.53,2.14,2.14,0,0,0-.31.76,4.33,4.33,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.32,2.32,0,0,0,.32.77,1.76,1.76,0,0,0,.57.55,1.51,1.51,0,0,0,.82.21,1.56,1.56,0,0,0,.84-.2,1.58,1.58,0,0,0,.55-.54,2.68,2.68,0,0,0,.29-.78,5.17,5.17,0,0,0,.08-.89A5.08,5.08,0,0,0,1554,778.82Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1559.77,781.67a2.12,2.12,0,0,0,2.41.14,1.33,1.33,0,0,0,.55-.66h1.81a3.46,3.46,0,0,1-1.34,1.93,3.94,3.94,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.64,3.64,0,0,1-.76-1.24,4.58,4.58,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.86,3.86,0,0,1,1.55-.31,3.67,3.67,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,4.11,4.11,0,0,1,.66,1.42,5.42,5.42,0,0,1,.15,1.67h-5.4A2,2,0,0,0,1559.77,781.67Zm2.33-3.93a1.46,1.46,0,0,0-1.13-.41,1.77,1.77,0,0,0-.81.17,1.54,1.54,0,0,0-.8.93,2.72,2.72,0,0,0-.09.49h3.34A2.44,2.44,0,0,0,1562.1,777.74Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1567.83,776v1h.05a2.36,2.36,0,0,1,1-.95,2.93,2.93,0,0,1,1.28-.3,3.48,3.48,0,0,1,1.35.23,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.69,2.69,0,0,0-.28-1.38,1.14,1.14,0,0,0-1-.45,1.43,1.43,0,0,0-1.22.49,2.83,2.83,0,0,0-.37,1.65v3.92h-2.06V776Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1576.84,781.24v2.23a2.19,2.19,0,0,1-.18.9,2.29,2.29,0,0,1-.49.72,2.59,2.59,0,0,1-.71.5,3.33,3.33,0,0,1-.88.27v-1a1.14,1.14,0,0,0,.41-.18,1.45,1.45,0,0,0,.34-.31,1.2,1.2,0,0,0,.22-.4,1.22,1.22,0,0,0,.06-.46h-1v-2.23Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1585,773.14v8.42h5v1.91h-7.31V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1593.58,773.14v10.33h-2.27V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1597.82,783.47l-3.34-10.33h2.33l2.3,7.26h0l2.33-7.26h2.35l-3.44,10.33Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1612.43,773.14v1.91H1607v2.21h5V779h-5v2.53h5.57v1.91h-7.84V773.14Z"
          transform="translate(-62.75 -75.53)"
        />
      </g>
    </g>
    <Link to={`${url}single/_trust__halbjahresprogramm_`}>
      <g id="masterclass-trust">
        <rect
          class="e8181e97-3015-49de-82f8-4bd3baa60643"
          x="705.49"
          y="958.4"
          width="310.98"
          height="83.98"
        />
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M795.37,1049.67l4.1,12h0l3.87-12h5.42v17.51h-3.6v-12.4h-.05l-4.3,12.4h-3l-4.29-12.28h-.05v12.28H790v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M820.92,1049.67l6.55,17.51h-4l-1.32-3.9H815.6l-1.37,3.9h-3.88l6.63-17.51Zm.23,10.75-2.21-6.43h-.05l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M831.94,1062.87a2.71,2.71,0,0,0,.84,1,3.7,3.7,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,3.48,3.48,0,0,0,1.11-.33,2.47,2.47,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.65,3.65,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.28,6.28,0,0,1-1.67-.92,4.19,4.19,0,0,1-1.19-1.41,4.28,4.28,0,0,1-.45-2,4.64,4.64,0,0,1,.57-2.34,5.3,5.3,0,0,1,1.51-1.65,6.59,6.59,0,0,1,2.11-1,8.71,8.71,0,0,1,2.36-.32,11.34,11.34,0,0,1,2.63.3,6.76,6.76,0,0,1,2.25,1,4.93,4.93,0,0,1,1.55,1.75,5.3,5.3,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3,3,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.2,4.2,0,0,0-.93.1,2.4,2.4,0,0,0-.84.34,2,2,0,0,0-.63.61,1.7,1.7,0,0,0-.24.94,1.57,1.57,0,0,0,.19.83,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54c.68.18,1.56.41,2.66.68.33.07.78.19,1.36.36a6.63,6.63,0,0,1,1.73.82,5.33,5.33,0,0,1,1.48,1.51,4.26,4.26,0,0,1,.63,2.42,5.37,5.37,0,0,1-.47,2.23,4.82,4.82,0,0,1-1.38,1.77,6.81,6.81,0,0,1-2.28,1.17,10.75,10.75,0,0,1-3.16.42,10.86,10.86,0,0,1-2.8-.36,7.12,7.12,0,0,1-2.41-1.12,5.52,5.52,0,0,1-1.65-1.93,5.76,5.76,0,0,1-.59-2.8h3.73A3.14,3.14,0,0,0,831.94,1062.87Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M843.53,1052.91v-3.24h14.35v3.24h-5.25v14.27h-3.85v-14.27Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M873,1049.67v3.24h-9.25v3.75h8.49v3h-8.49V1064h9.44v3.23H859.89v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M885.22,1049.67a5.64,5.64,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.4,4.4,0,0,1,1,1.53,4.83,4.83,0,0,1,.36,1.87,5.29,5.29,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v0a3,3,0,0,1,1.94,1.56,4.68,4.68,0,0,1,.42,1.21,10.39,10.39,0,0,1,.19,1.33c0,.28,0,.6,0,1s0,.76.08,1.15a7.44,7.44,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89H887a6.81,6.81,0,0,1-.39-2c0-.77-.12-1.51-.22-2.21a3.5,3.5,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85v-17.51Zm-1.37,7.93a2.94,2.94,0,0,0,2-.59,2.47,2.47,0,0,0,.66-1.92,2.34,2.34,0,0,0-.66-1.85,3,3,0,0,0-2-.57h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M904.72,1054.35a4,4,0,0,0-.86-1,3.77,3.77,0,0,0-1.16-.65,4,4,0,0,0-1.37-.24,4.42,4.42,0,0,0-2.2.51,4,4,0,0,0-1.47,1.35,6,6,0,0,0-.83,1.92,9.65,9.65,0,0,0-.25,2.23,9,9,0,0,0,.25,2.15,5.87,5.87,0,0,0,.83,1.87,4,4,0,0,0,1.47,1.34,4.42,4.42,0,0,0,2.2.5,3.56,3.56,0,0,0,2.76-1.08,4.91,4.91,0,0,0,1.22-2.84H909a9.2,9.2,0,0,1-.76,3,7.13,7.13,0,0,1-1.62,2.25,6.82,6.82,0,0,1-2.36,1.43,8.81,8.81,0,0,1-3,.49,9,9,0,0,1-3.61-.7A7.74,7.74,0,0,1,895,1065a8.46,8.46,0,0,1-1.69-2.88,10.37,10.37,0,0,1-.59-3.57,10.73,10.73,0,0,1,.59-3.64,8.59,8.59,0,0,1,1.69-2.93,7.69,7.69,0,0,1,2.71-2,8.71,8.71,0,0,1,3.61-.71,9.08,9.08,0,0,1,2.74.41,7.51,7.51,0,0,1,2.31,1.22,6.66,6.66,0,0,1,1.67,2,7.07,7.07,0,0,1,.84,2.7h-3.73A3,3,0,0,0,904.72,1054.35Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M915.51,1049.67V1064H924v3.23H911.66v-17.51Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M934.93,1049.67l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.75L933,1054h-.05l-2.28,6.43Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M945.94,1062.87a2.83,2.83,0,0,0,.85,1,3.62,3.62,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,3.56,3.56,0,0,0,1.11-.33,2.44,2.44,0,0,0,.85-.67,1.7,1.7,0,0,0,.35-1.09,1.53,1.53,0,0,0-.46-1.16,3.54,3.54,0,0,0-1.19-.73,11.64,11.64,0,0,0-1.66-.52c-.62-.14-1.25-.31-1.89-.49a15.14,15.14,0,0,1-1.91-.6,6.13,6.13,0,0,1-1.67-.92,4.19,4.19,0,0,1-1.19-1.41,4.28,4.28,0,0,1-.46-2,4.64,4.64,0,0,1,.58-2.34,5.3,5.3,0,0,1,1.51-1.65,6.49,6.49,0,0,1,2.11-1,8.64,8.64,0,0,1,2.35-.32,11.36,11.36,0,0,1,2.64.3,6.61,6.61,0,0,1,2.24,1,4.87,4.87,0,0,1,1.56,1.75,5.42,5.42,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.53,2.53,0,0,0-.85.34,2.12,2.12,0,0,0-.62.61,1.63,1.63,0,0,0-.25.94,1.57,1.57,0,0,0,.2.83,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54c.67.18,1.56.41,2.66.68.32.07.78.19,1.36.36a6.79,6.79,0,0,1,1.73.82,5.33,5.33,0,0,1,1.48,1.51,4.26,4.26,0,0,1,.62,2.42,5.36,5.36,0,0,1-.46,2.23,4.74,4.74,0,0,1-1.39,1.77,6.63,6.63,0,0,1-2.28,1.17,10.68,10.68,0,0,1-3.15.42,11,11,0,0,1-2.81-.36,7.07,7.07,0,0,1-2.4-1.12,5.54,5.54,0,0,1-1.66-1.93,5.87,5.87,0,0,1-.58-2.8h3.72A3.26,3.26,0,0,0,945.94,1062.87Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M961.86,1062.87a2.71,2.71,0,0,0,.84,1,3.8,3.8,0,0,0,1.24.56,5.59,5.59,0,0,0,1.47.19,7.41,7.41,0,0,0,1.11-.09,3.42,3.42,0,0,0,1.1-.33,2.47,2.47,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.56,1.56,0,0,0-.45-1.16,3.54,3.54,0,0,0-1.19-.73,11.79,11.79,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6A6.28,6.28,0,0,1,960,1058a4.11,4.11,0,0,1-1.64-3.46,4.54,4.54,0,0,1,.58-2.34,5.15,5.15,0,0,1,1.5-1.65,6.69,6.69,0,0,1,2.11-1,8.71,8.71,0,0,1,2.36-.32,11.34,11.34,0,0,1,2.63.3,6.67,6.67,0,0,1,2.25,1,4.93,4.93,0,0,1,1.55,1.75,5.3,5.3,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.08,2.08,0,0,0-.74-.81,3.15,3.15,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.29,4.29,0,0,0-.94.1,2.4,2.4,0,0,0-.84.34,2.16,2.16,0,0,0-.63.61,1.7,1.7,0,0,0-.24.94,1.57,1.57,0,0,0,.19.83,1.76,1.76,0,0,0,.78.59,9.6,9.6,0,0,0,1.59.54l2.66.68c.33.07.78.19,1.36.36a6.63,6.63,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.26,4.26,0,0,1,.63,2.42,5.37,5.37,0,0,1-.47,2.23,4.82,4.82,0,0,1-1.38,1.77,6.63,6.63,0,0,1-2.28,1.17,10.73,10.73,0,0,1-3.15.42,10.93,10.93,0,0,1-2.81-.36,7,7,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.76,5.76,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,961.86,1062.87Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M980.27,1052.91v-3.24h14.35v3.24h-5.25v14.27h-3.85v-14.27Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1006.07,1049.67a5.68,5.68,0,0,1,2.12.38,5,5,0,0,1,1.62,1,4.4,4.4,0,0,1,1,1.53,4.83,4.83,0,0,1,.36,1.87,5.29,5.29,0,0,1-.65,2.68,4.06,4.06,0,0,1-2.12,1.71v0a3,3,0,0,1,1.94,1.56,4.68,4.68,0,0,1,.42,1.21,10.39,10.39,0,0,1,.19,1.33c0,.28,0,.6,0,1s0,.76.08,1.15a7.44,7.44,0,0,0,.2,1.12,2.51,2.51,0,0,0,.4.89h-3.85a6.81,6.81,0,0,1-.39-2c-.05-.77-.12-1.51-.22-2.21a3.5,3.5,0,0,0-.83-2,2.86,2.86,0,0,0-2.09-.64h-3.85v6.84h-3.85v-17.51Zm-1.37,7.93a3,3,0,0,0,2-.59,2.47,2.47,0,0,0,.66-1.92,2.34,2.34,0,0,0-.66-1.85,3,3,0,0,0-2-.57h-4.22v4.93Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1027.21,1065.85a9.63,9.63,0,0,1-11,0q-2-1.71-2-5.3v-10.89h3.85v10.89a7.22,7.22,0,0,0,.13,1.4,2.71,2.71,0,0,0,1.6,2.07,4.46,4.46,0,0,0,1.88.33,3.57,3.57,0,0,0,2.84-.92,4.33,4.33,0,0,0,.79-2.88v-10.89h3.85v10.89A6.64,6.64,0,0,1,1027.21,1065.85Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1035.45,1062.87a2.74,2.74,0,0,0,.85,1,3.62,3.62,0,0,0,1.24.56,5.49,5.49,0,0,0,1.47.19,7.29,7.29,0,0,0,1.1-.09,3.42,3.42,0,0,0,1.1-.33,2.38,2.38,0,0,0,.86-.67,1.7,1.7,0,0,0,.35-1.09,1.57,1.57,0,0,0-.46-1.16,3.54,3.54,0,0,0-1.19-.73,11.64,11.64,0,0,0-1.66-.52c-.63-.14-1.26-.31-1.89-.49a15.83,15.83,0,0,1-1.92-.6,6.22,6.22,0,0,1-1.66-.92,4.15,4.15,0,0,1-1.65-3.46,4.64,4.64,0,0,1,.58-2.34,5.18,5.18,0,0,1,1.51-1.65,6.49,6.49,0,0,1,2.11-1,8.64,8.64,0,0,1,2.35-.32,11.36,11.36,0,0,1,2.64.3,6.61,6.61,0,0,1,2.24,1,5,5,0,0,1,1.56,1.75,5.42,5.42,0,0,1,.57,2.59h-3.72a3.35,3.35,0,0,0-.33-1.3,2.1,2.1,0,0,0-.75-.81,3.07,3.07,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.53,2.53,0,0,0-.85.34,2.12,2.12,0,0,0-.62.61,1.63,1.63,0,0,0-.25.94,1.57,1.57,0,0,0,.2.83,1.78,1.78,0,0,0,.77.59,9.94,9.94,0,0,0,1.59.54c.68.18,1.57.41,2.67.68.32.07.78.19,1.36.36a6.79,6.79,0,0,1,1.73.82,5.33,5.33,0,0,1,1.48,1.51,4.26,4.26,0,0,1,.62,2.42,5.36,5.36,0,0,1-.46,2.23,4.74,4.74,0,0,1-1.39,1.77,6.63,6.63,0,0,1-2.28,1.17,10.73,10.73,0,0,1-3.15.42,11,11,0,0,1-2.81-.36,7,7,0,0,1-2.4-1.12,5.44,5.44,0,0,1-1.66-1.93,5.75,5.75,0,0,1-.58-2.8h3.72A3.26,3.26,0,0,0,1035.45,1062.87Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1047.05,1052.91v-3.24h14.35v3.24h-5.25v14.27h-3.85v-14.27Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M791.5,1084.55l-3.34-10.33h2.33l2.3,7.26h0l2.33-7.26h2.34l-3.43,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M799.56,1082.76a2.09,2.09,0,0,0,2.41.13,1.31,1.31,0,0,0,.55-.65h1.81a3.43,3.43,0,0,1-1.34,1.92,3.86,3.86,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.5,4.5,0,0,1-.27-1.59,4.29,4.29,0,0,1,.28-1.57,3.6,3.6,0,0,1,2-2.08,3.86,3.86,0,0,1,1.55-.3,3.54,3.54,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,4,4,0,0,1,.66,1.42,5.36,5.36,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,799.56,1082.76Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.4,1.77,1.77,0,0,0-.81.16,1.55,1.55,0,0,0-.52.41,1.51,1.51,0,0,0-.28.53,2.44,2.44,0,0,0-.09.49h3.34A2.48,2.48,0,0,0,801.89,1078.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M807.62,1077.07v1.39h0a2.88,2.88,0,0,1,.39-.65,3,3,0,0,1,.57-.5,2.85,2.85,0,0,1,.68-.33,2.67,2.67,0,0,1,.75-.11,1.5,1.5,0,0,1,.45.07v1.91l-.35,0a2.55,2.55,0,0,0-.39,0,2.27,2.27,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2,2,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M815.41,1077.07v1.37h-1.5v3.71a1,1,0,0,0,.17.69,1,1,0,0,0,.69.18l.34,0,.3,0v1.59a4.71,4.71,0,0,1-.58.06h-.62a6.37,6.37,0,0,1-.9-.06,2.2,2.2,0,0,1-.76-.25,1.4,1.4,0,0,1-.51-.54,1.84,1.84,0,0,1-.19-.91v-4.42h-1.24v-1.37h1.24v-2.24h2.06v2.24Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M818.35,1077.07v1.39h0a2.29,2.29,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,3,3,0,0,1,.68-.33,2.74,2.74,0,0,1,.76-.11,1.48,1.48,0,0,1,.44.07v1.91l-.34,0a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37H816.4v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M821.94,1079.37a2.43,2.43,0,0,1,.36-1.2,2.49,2.49,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.43,6.43,0,0,1,1.24-.12,7.46,7.46,0,0,1,1.14.08,3.34,3.34,0,0,1,1.06.31,2.2,2.2,0,0,1,.78.64,1.83,1.83,0,0,1,.3,1.09v3.9a7.54,7.54,0,0,0,.06,1,1.67,1.67,0,0,0,.2.69h-2.08a3.57,3.57,0,0,1-.1-.35c0-.12,0-.25-.05-.37a2.69,2.69,0,0,1-1.16.71,4.76,4.76,0,0,1-1.36.2,3.49,3.49,0,0,1-1-.13,2.36,2.36,0,0,1-.81-.4,1.89,1.89,0,0,1-.55-.7,2.35,2.35,0,0,1-.19-1,2.21,2.21,0,0,1,.22-1,2,2,0,0,1,.58-.66,3,3,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19l.91-.12a5.35,5.35,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.57.57,0,0,0,.19-.49,1.09,1.09,0,0,0-.11-.52,1,1,0,0,0-.29-.31,1.1,1.1,0,0,0-.42-.14,2.82,2.82,0,0,0-.52,0,1.58,1.58,0,0,0-1,.26,1.18,1.18,0,0,0-.41.87Zm4.75,1.52a.88.88,0,0,1-.33.18l-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.2,1.2,0,0,0-.39.2.91.91,0,0,0-.27.31,1.06,1.06,0,0,0-.1.47,1.09,1.09,0,0,0,.1.47.78.78,0,0,0,.28.29,1,1,0,0,0,.4.16,2.59,2.59,0,0,0,.48,0,1.85,1.85,0,0,0,.94-.2,1.35,1.35,0,0,0,.49-.49,1.62,1.62,0,0,0,.2-.57c0-.19,0-.35,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M835.4,1084.55v-1h0a2.36,2.36,0,0,1-1,.94,3,3,0,0,1-1.27.29,3.55,3.55,0,0,1-1.36-.22,2,2,0,0,1-.83-.61,2.45,2.45,0,0,1-.42-1,5.57,5.57,0,0,1-.13-1.27v-4.6h2.06v4.23a2.58,2.58,0,0,0,.29,1.38,1.12,1.12,0,0,0,1,.45,1.4,1.4,0,0,0,1.21-.5,2.77,2.77,0,0,0,.38-1.64v-3.92h2.05v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M841.12,1082.76a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81a3.47,3.47,0,0,1-1.33,1.92,3.92,3.92,0,0,1-2.18.58,4.25,4.25,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.68,4.68,0,0,1,0-3.16,3.6,3.6,0,0,1,2-2.08,3.93,3.93,0,0,1,1.55-.3,3.52,3.52,0,0,1,1.67.37,3.18,3.18,0,0,1,1.16,1,3.86,3.86,0,0,1,.66,1.42,5.07,5.07,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,841.12,1082.76Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.55,1.55,0,0,0-.52.41,1.69,1.69,0,0,0-.28.53,2.44,2.44,0,0,0-.09.49H844A2.39,2.39,0,0,0,843.44,1078.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M849.18,1077.07v1h0a2.42,2.42,0,0,1,1-.95,3.06,3.06,0,0,1,1.28-.29,3.46,3.46,0,0,1,1.35.22,2,2,0,0,1,.83.62,2.32,2.32,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27v4.6h-2v-4.22a2.69,2.69,0,0,0-.29-1.39,1.13,1.13,0,0,0-1-.45,1.42,1.42,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M858.18,1082.32v2.23a2.27,2.27,0,0,1-.18.91,2.2,2.2,0,0,1-.49.71,2.4,2.4,0,0,1-.71.5,3.33,3.33,0,0,1-.88.27v-1a1.07,1.07,0,0,0,.41-.18,1.26,1.26,0,0,0,.34-.31,1.24,1.24,0,0,0,.28-.86h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M870.19,1084.47a3.76,3.76,0,0,1-1.49.31,5.19,5.19,0,0,1-2.13-.41,4.42,4.42,0,0,1-1.6-1.13,5,5,0,0,1-1-1.71,6.25,6.25,0,0,1-.35-2.1,6.51,6.51,0,0,1,.35-2.15,5,5,0,0,1,1-1.73,4.49,4.49,0,0,1,1.6-1.16,5.19,5.19,0,0,1,2.13-.42,5,5,0,0,1,1.54.24,4.33,4.33,0,0,1,1.35.7,3.8,3.8,0,0,1,1,1.15,3.86,3.86,0,0,1,.48,1.56h-2.17a2.22,2.22,0,0,0-.78-1.3,2.3,2.3,0,0,0-1.41-.44,2.63,2.63,0,0,0-1.3.3,2.39,2.39,0,0,0-.87.8,3.48,3.48,0,0,0-.48,1.13,5.24,5.24,0,0,0-.16,1.32,4.91,4.91,0,0,0,.16,1.27,3.41,3.41,0,0,0,.48,1.1,2.45,2.45,0,0,0,.87.79,2.63,2.63,0,0,0,1.3.3,2.49,2.49,0,0,0,1.75-.57,2.53,2.53,0,0,0,.71-1.66h-2.28V1079h4.34v5.59h-1.45l-.23-1.17A3.49,3.49,0,0,1,870.19,1084.47Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M877,1082.76a2.08,2.08,0,0,0,2.4.13,1.26,1.26,0,0,0,.55-.65h1.81a3.42,3.42,0,0,1-1.33,1.92,3.89,3.89,0,0,1-2.17.58,4.21,4.21,0,0,1-1.59-.28,3.31,3.31,0,0,1-1.21-.81,3.7,3.7,0,0,1-.76-1.24,4.73,4.73,0,0,1-.26-1.59,4.5,4.5,0,0,1,.27-1.57,3.6,3.6,0,0,1,2-2.08,3.94,3.94,0,0,1,1.56-.3,3.5,3.5,0,0,1,1.66.37,3.3,3.3,0,0,1,1.17,1,4.21,4.21,0,0,1,.66,1.42,5.36,5.36,0,0,1,.14,1.66h-5.4A2.08,2.08,0,0,0,877,1082.76Zm2.32-3.94a1.43,1.43,0,0,0-1.12-.4,1.82,1.82,0,0,0-.82.16,1.55,1.55,0,0,0-.52.41,1.48,1.48,0,0,0-.27.53,1.94,1.94,0,0,0-.1.49h3.35A2.39,2.39,0,0,0,879.36,1078.82Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M885.1,1077.07v1h0a2.39,2.39,0,0,1,1-.95,3,3,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.15,2.15,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,6.2,6.2,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.69,2.69,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2.05v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M896.79,1084.55v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.46,2.46,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2v4.23a2.68,2.68,0,0,0,.29,1.38,1.14,1.14,0,0,0,1,.45,1.41,1.41,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M902,1082.69a1.12,1.12,0,0,0,.36.39,1.58,1.58,0,0,0,.49.23,2.32,2.32,0,0,0,.59.07,2.58,2.58,0,0,0,.46-.05,1.82,1.82,0,0,0,.43-.16,1,1,0,0,0,.33-.29.81.81,0,0,0,.13-.46c0-.3-.21-.54-.62-.69a12.37,12.37,0,0,0-1.71-.46c-.3-.07-.59-.15-.88-.24a3,3,0,0,1-.76-.36,1.7,1.7,0,0,1-.53-.57,1.53,1.53,0,0,1-.21-.83,2.25,2.25,0,0,1,.29-1.19,2,2,0,0,1,.74-.73,3.22,3.22,0,0,1,1-.37,5.86,5.86,0,0,1,1.19-.11,6.56,6.56,0,0,1,1.18.11,3.28,3.28,0,0,1,1,.39,2.34,2.34,0,0,1,.74.73,2.48,2.48,0,0,1,.36,1.15h-1.95a.9.9,0,0,0-.45-.8,2.06,2.06,0,0,0-1-.21l-.37,0a1.34,1.34,0,0,0-.37.1.78.78,0,0,0-.29.21.58.58,0,0,0-.11.37.56.56,0,0,0,.2.44,1.66,1.66,0,0,0,.53.29c.22.07.46.13.74.19l.86.19a8,8,0,0,1,.87.25,2.58,2.58,0,0,1,.76.38,1.9,1.9,0,0,1,.54.59,1.82,1.82,0,0,1,.2.88,2.4,2.4,0,0,1-.3,1.24,2.27,2.27,0,0,1-.77.8,3.19,3.19,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,6,6,0,0,1-1.28-.13,3.62,3.62,0,0,1-1.11-.43,2.55,2.55,0,0,1-.79-.81,2.49,2.49,0,0,1-.34-1.25h1.95A1.15,1.15,0,0,0,902,1082.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M909.81,1082.69a1.12,1.12,0,0,0,.36.39,1.53,1.53,0,0,0,.5.23,2.2,2.2,0,0,0,.58.07,2.49,2.49,0,0,0,.46-.05,1.82,1.82,0,0,0,.43-.16,1,1,0,0,0,.33-.29.74.74,0,0,0,.13-.46c0-.3-.21-.54-.62-.69a12.37,12.37,0,0,0-1.71-.46c-.3-.07-.59-.15-.88-.24a3,3,0,0,1-.76-.36,1.7,1.7,0,0,1-.53-.57,1.53,1.53,0,0,1-.21-.83,2.25,2.25,0,0,1,.29-1.19,2.07,2.07,0,0,1,.74-.73,3.22,3.22,0,0,1,1-.37,5.92,5.92,0,0,1,1.19-.11,6.47,6.47,0,0,1,1.18.11,3.28,3.28,0,0,1,1,.39,2.49,2.49,0,0,1,.75.73,2.6,2.6,0,0,1,.35,1.15h-2a.9.9,0,0,0-.45-.8,2.06,2.06,0,0,0-1-.21l-.37,0a1.34,1.34,0,0,0-.37.1.74.74,0,0,0-.28.21.53.53,0,0,0-.12.37.56.56,0,0,0,.2.44,1.66,1.66,0,0,0,.53.29c.22.07.47.13.75.19l.85.19a8.84,8.84,0,0,1,.88.25,2.85,2.85,0,0,1,.76.38,1.86,1.86,0,0,1,.53.59,1.71,1.71,0,0,1,.2.88,2.5,2.5,0,0,1-.29,1.24,2.39,2.39,0,0,1-.78.8,3.19,3.19,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,6,6,0,0,1-1.28-.13,3.46,3.46,0,0,1-1.1-.43,2.4,2.4,0,0,1-1.14-2.06h1.95A1.15,1.15,0,0,0,909.81,1082.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M924.94,1084.55v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.46,2.46,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2v4.23a2.68,2.68,0,0,0,.29,1.38,1.14,1.14,0,0,0,1,.45,1.41,1.41,0,0,0,1.22-.5,2.78,2.78,0,0,0,.37-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M930.42,1077.07v1h0a2.42,2.42,0,0,1,1-.95,3.06,3.06,0,0,1,1.28-.29,3.5,3.5,0,0,1,1.35.22,2,2,0,0,1,.83.62,2.46,2.46,0,0,1,.43,1,6.23,6.23,0,0,1,.12,1.27v4.6h-2.05v-4.22a2.69,2.69,0,0,0-.29-1.39,1.14,1.14,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M942.36,1083.6a2.2,2.2,0,0,1-1,.87,3.43,3.43,0,0,1-2.8-.06,2.82,2.82,0,0,1-1.05-.88,3.91,3.91,0,0,1-.62-1.28,5,5,0,0,1-.21-1.5,4.73,4.73,0,0,1,.21-1.46,3.7,3.7,0,0,1,.62-1.24,3,3,0,0,1,2.48-1.18,2.94,2.94,0,0,1,1.27.28,2.16,2.16,0,0,1,.94.83h0v-3.76h2.06v10.33h-2v-1Zm-.09-3.7a2.11,2.11,0,0,0-.3-.75,1.61,1.61,0,0,0-.54-.53,1.54,1.54,0,0,0-.84-.2,1.6,1.6,0,0,0-.85.2,1.5,1.5,0,0,0-.56.53,2.42,2.42,0,0,0-.3.76,3.8,3.8,0,0,0-.1.89,3.69,3.69,0,0,0,.1.87,2.48,2.48,0,0,0,.33.78,1.84,1.84,0,0,0,.56.55,1.54,1.54,0,0,0,.82.21,1.6,1.6,0,0,0,.85-.21,1.55,1.55,0,0,0,.54-.54,2.62,2.62,0,0,0,.29-.77,4.43,4.43,0,0,0,.09-.9A4.26,4.26,0,0,0,942.27,1079.9Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M954.79,1074.22a4.32,4.32,0,0,1,1.65.28,3.06,3.06,0,0,1,1.1.75,2.76,2.76,0,0,1,.63,1,4.08,4.08,0,0,1,0,2.46,2.8,2.8,0,0,1-.63,1.06,2.92,2.92,0,0,1-1.1.74,4.31,4.31,0,0,1-1.65.29H952.4v3.7h-2.27v-10.33Zm-.62,4.86a4.56,4.56,0,0,0,.75-.06,1.87,1.87,0,0,0,.64-.22,1.25,1.25,0,0,0,.44-.47,1.67,1.67,0,0,0,.16-.8,1.63,1.63,0,0,0-.16-.79,1.18,1.18,0,0,0-.44-.47,1.88,1.88,0,0,0-.64-.23,4.56,4.56,0,0,0-.75-.06H952.4v3.1Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M961.52,1077.07v1.39h0a2.29,2.29,0,0,1,.39-.65,2.67,2.67,0,0,1,.56-.5,2.85,2.85,0,0,1,.68-.33,2.68,2.68,0,0,1,.76-.11,1.48,1.48,0,0,1,.44.07v1.91l-.34,0a2.68,2.68,0,0,0-.39,0,2.28,2.28,0,0,0-1,.18,1.7,1.7,0,0,0-.63.52,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94v3.37h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M965.47,1078.17a2.49,2.49,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.41,6.41,0,0,1,1.23-.12,7.5,7.5,0,0,1,1.15.08,3.21,3.21,0,0,1,1.05.31,2.15,2.15,0,0,1,.79.64,1.83,1.83,0,0,1,.3,1.09v3.9a7.54,7.54,0,0,0,.06,1,1.67,1.67,0,0,0,.2.69h-2.08a3.57,3.57,0,0,1-.1-.35c0-.12,0-.25,0-.37a2.69,2.69,0,0,1-1.16.71,4.76,4.76,0,0,1-1.36.2,3.61,3.61,0,0,1-1-.13,2.56,2.56,0,0,1-.81-.4,2,2,0,0,1-.54-.7,2.35,2.35,0,0,1-.19-1,2.21,2.21,0,0,1,.22-1,2,2,0,0,1,.58-.66,3,3,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19l.91-.12a5.57,5.57,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.25.57.57,0,0,0,.18-.49,1.09,1.09,0,0,0-.1-.52,1,1,0,0,0-.29-.31,1.1,1.1,0,0,0-.42-.14,2.82,2.82,0,0,0-.52,0,1.58,1.58,0,0,0-.95.26,1.18,1.18,0,0,0-.41.87h-2A2.43,2.43,0,0,1,965.47,1078.17Zm2.59-4v1.69h-1.95v-1.69Zm1.47,6.85-.42.11-.47.07-.49.07a3.45,3.45,0,0,0-.46.12,1.2,1.2,0,0,0-.39.2.91.91,0,0,0-.27.31.94.94,0,0,0-.1.47,1,1,0,0,0,.1.47.78.78,0,0,0,.28.29,1,1,0,0,0,.4.16,2.59,2.59,0,0,0,.48,0,1.85,1.85,0,0,0,.94-.2,1.35,1.35,0,0,0,.49-.49,1.62,1.62,0,0,0,.2-.57c0-.19,0-.35,0-.46v-.77A.77.77,0,0,1,969.53,1081.07Zm1.51-6.85v1.69h-1.95v-1.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M976.93,1078.62h-3.6v-1.55h6.31v1.55l-3.89,4.38h4.16v1.55H973V1083Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M981.07,1075.91v-1.69h2.06v1.69Zm2.06,1.16v7.48h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M986.48,1082.69a1.2,1.2,0,0,0,.35.39,1.64,1.64,0,0,0,.5.23,2.27,2.27,0,0,0,.59.07,2.48,2.48,0,0,0,.45-.05,1.91,1.91,0,0,0,.44-.16.89.89,0,0,0,.32-.29.74.74,0,0,0,.13-.46c0-.3-.2-.54-.61-.69a12.19,12.19,0,0,0-1.72-.46q-.45-.11-.87-.24a2.81,2.81,0,0,1-.76-.36,1.84,1.84,0,0,1-.54-.57,1.62,1.62,0,0,1-.2-.83,2.26,2.26,0,0,1,.28-1.19,2.1,2.1,0,0,1,.75-.73,3.14,3.14,0,0,1,1-.37,5.79,5.79,0,0,1,1.19-.11,6.64,6.64,0,0,1,1.18.11,3.28,3.28,0,0,1,1,.39,2.57,2.57,0,0,1,.74.73,2.48,2.48,0,0,1,.36,1.15h-2a.9.9,0,0,0-.45-.8,2,2,0,0,0-1-.21l-.38,0a1.44,1.44,0,0,0-.37.1.83.83,0,0,0-.28.21.62.62,0,0,0,.09.81,1.56,1.56,0,0,0,.53.29,7.16,7.16,0,0,0,.74.19l.86.19a8.65,8.65,0,0,1,.87.25,2.58,2.58,0,0,1,.76.38,2,2,0,0,1,.54.59,1.82,1.82,0,0,1,.2.88,2.4,2.4,0,0,1-.3,1.24,2.36,2.36,0,0,1-.77.8,3.24,3.24,0,0,1-1.1.43,6.46,6.46,0,0,1-1.25.12,6,6,0,0,1-1.27-.13,3.52,3.52,0,0,1-1.11-.43,2.45,2.45,0,0,1-.79-.81,2.49,2.49,0,0,1-.34-1.25h2A1.15,1.15,0,0,0,986.48,1082.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M992.58,1075.91v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M996.3,1079.19a3.36,3.36,0,0,1,.78-1.24,3.46,3.46,0,0,1,1.21-.8,4.71,4.71,0,0,1,3.2,0,3.5,3.5,0,0,1,1.22.8,3.36,3.36,0,0,1,.78,1.24,4.68,4.68,0,0,1,.28,1.63,4.58,4.58,0,0,1-.28,1.62,3.53,3.53,0,0,1-.78,1.24,3.45,3.45,0,0,1-1.22.78,4.71,4.71,0,0,1-3.2,0,3.41,3.41,0,0,1-1.21-.78,3.53,3.53,0,0,1-.78-1.24,4.37,4.37,0,0,1-.28-1.62A4.46,4.46,0,0,1,996.3,1079.19Zm1.86,2.5a2.38,2.38,0,0,0,.3.77,1.53,1.53,0,0,0,.56.54,1.65,1.65,0,0,0,.87.21,1.67,1.67,0,0,0,.87-.21,1.48,1.48,0,0,0,.57-.54,2.16,2.16,0,0,0,.29-.77,4.14,4.14,0,0,0,.09-.87,4.41,4.41,0,0,0-.09-.89,2.11,2.11,0,0,0-.29-.76,1.67,1.67,0,0,0-.57-.55,1.78,1.78,0,0,0-.87-.2,1.75,1.75,0,0,0-.87.2,1.73,1.73,0,0,0-.56.55,2.32,2.32,0,0,0-.3.76,5.26,5.26,0,0,0-.08.89A4.94,4.94,0,0,0,998.16,1081.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1007.05,1077.07v1h0a2.39,2.39,0,0,1,1-.95,3,3,0,0,1,1.27-.29,3.46,3.46,0,0,1,1.35.22,2.08,2.08,0,0,1,.84.62,2.45,2.45,0,0,1,.42,1,5.57,5.57,0,0,1,.13,1.27v4.6h-2.06v-4.22a2.69,2.69,0,0,0-.29-1.39,1.12,1.12,0,0,0-1-.45,1.39,1.39,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.92h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M789.1,1093.28v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M794.77,1094.44v1h0a3,3,0,0,1,1-.9,2.67,2.67,0,0,1,1.31-.32,3,3,0,0,1,1.29.28,1.81,1.81,0,0,1,.89,1,3.3,3.3,0,0,1,.91-.87,2.4,2.4,0,0,1,1.35-.38,3.85,3.85,0,0,1,1.1.15,2.27,2.27,0,0,1,.87.46,2.07,2.07,0,0,1,.56.83,3.32,3.32,0,0,1,.21,1.24v5h-2.06v-4.24c0-.25,0-.49,0-.71a1.51,1.51,0,0,0-.16-.58,1,1,0,0,0-.38-.39,1.36,1.36,0,0,0-.69-.14,1.32,1.32,0,0,0-.7.16,1.18,1.18,0,0,0-.42.44,1.64,1.64,0,0,0-.2.6,4,4,0,0,0,0,.69v4.17h-2.06v-4.2c0-.22,0-.44,0-.66a1.74,1.74,0,0,0-.13-.6.91.91,0,0,0-.36-.44,1.36,1.36,0,0,0-.74-.16,1.69,1.69,0,0,0-.39.06,1.23,1.23,0,0,0-.46.25,1.33,1.33,0,0,0-.38.52,2.05,2.05,0,0,0-.16.89v4.34h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M812.4,1091.59v4.28l4-4.28h2.84l-4,4.08,4.43,6.25h-2.85l-3.12-4.65-1.3,1.32v3.33h-2.27v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M820.27,1096.74a2.33,2.33,0,0,1,.37-1.2,2.39,2.39,0,0,1,.81-.77,3.44,3.44,0,0,1,1.1-.41,6.53,6.53,0,0,1,1.24-.13,8.74,8.74,0,0,1,1.14.08,3.54,3.54,0,0,1,1.06.32,2,2,0,0,1,.78.64,1.74,1.74,0,0,1,.31,1.09v3.9c0,.33,0,.66,0,1a1.71,1.71,0,0,0,.21.69h-2.09a2.25,2.25,0,0,1-.09-.35,2.35,2.35,0,0,1,0-.37,2.85,2.85,0,0,1-1.16.71,4.81,4.81,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.35,2.35,0,0,1-.2-1,2.2,2.2,0,0,1,.23-1.05,1.86,1.86,0,0,1,.58-.66,2.51,2.51,0,0,1,.81-.37,9.37,9.37,0,0,1,.92-.19c.3,0,.61-.09.91-.12a5.2,5.2,0,0,0,.79-.13,1.41,1.41,0,0,0,.55-.25.55.55,0,0,0,.19-.49,1,1,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.78,2.78,0,0,0-.51,0,1.61,1.61,0,0,0-1,.26,1.16,1.16,0,0,0-.4.87Zm4.75,1.52a.83.83,0,0,1-.32.18l-.42.11-.47.07-.5.07-.45.12a1.42,1.42,0,0,0-.39.19,1,1,0,0,0-.27.32.94.94,0,0,0-.1.47,1,1,0,0,0,.1.47.84.84,0,0,0,.27.29,1.06,1.06,0,0,0,.41.15,2.44,2.44,0,0,0,.48,0,1.77,1.77,0,0,0,.94-.21,1.33,1.33,0,0,0,.49-.48,1.62,1.62,0,0,0,.2-.57c0-.19,0-.35,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M830.67,1094.44v1h0a3,3,0,0,1,1-.9,2.67,2.67,0,0,1,1.31-.32,3.09,3.09,0,0,1,1.3.28,1.85,1.85,0,0,1,.89,1,3.12,3.12,0,0,1,.9-.87,2.4,2.4,0,0,1,1.35-.38,3.8,3.8,0,0,1,1.1.15,2.27,2.27,0,0,1,.87.46,2.19,2.19,0,0,1,.57.83,3.54,3.54,0,0,1,.2,1.24v5h-2v-4.24c0-.25,0-.49,0-.71a1.71,1.71,0,0,0-.16-.58,1,1,0,0,0-.39-.39,1.32,1.32,0,0,0-.68-.14,1.15,1.15,0,0,0-1.13.6,1.84,1.84,0,0,0-.2.6,5.34,5.34,0,0,0,0,.69v4.17h-2.05v-4.2c0-.22,0-.44,0-.66a2,2,0,0,0-.12-.6,1,1,0,0,0-.36-.44,1.39,1.39,0,0,0-.75-.16,1.62,1.62,0,0,0-.38.06,1.27,1.27,0,0,0-.47.25,1.43,1.43,0,0,0-.38.52,2.05,2.05,0,0,0-.16.89v4.34h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M844,1100.12a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.2,1.2,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,3.89,3.89,0,0,1-2.17.58,4.42,4.42,0,0,1-1.59-.28,3.27,3.27,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.76,4.76,0,0,1,0-3.16,3.75,3.75,0,0,1,.79-1.25,3.63,3.63,0,0,1,1.2-.83,4,4,0,0,1,1.56-.31,3.67,3.67,0,0,1,1.67.37,3.31,3.31,0,0,1,1.16,1,4.16,4.16,0,0,1,.66,1.41,5.42,5.42,0,0,1,.14,1.67h-5.4A2.05,2.05,0,0,0,844,1100.12Zm2.32-3.93a1.44,1.44,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.66,1.66,0,0,0-.52.41,1.53,1.53,0,0,0-.27.52,2.75,2.75,0,0,0-.1.5h3.35A2.35,2.35,0,0,0,846.3,1096.19Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M852,1094.44v1.39h0a2.88,2.88,0,0,1,.39-.65,2.73,2.73,0,0,1,.57-.5,2.85,2.85,0,0,1,.68-.33,2.36,2.36,0,0,1,.75-.12,1.65,1.65,0,0,1,.45.08v1.91l-.35,0a2.55,2.55,0,0,0-.39,0,2.32,2.32,0,0,0-1,.18,1.66,1.66,0,0,0-.62.52,1.89,1.89,0,0,0-.34.76,4,4,0,0,0-.11.94v3.37h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M855.63,1096.74a2.43,2.43,0,0,1,.36-1.2,2.58,2.58,0,0,1,.81-.77,3.59,3.59,0,0,1,1.11-.41,6.44,6.44,0,0,1,1.24-.13,8.74,8.74,0,0,1,1.14.08,3.54,3.54,0,0,1,1.06.32,2,2,0,0,1,.78.64,1.81,1.81,0,0,1,.3,1.09v3.9a7.66,7.66,0,0,0,.06,1,1.71,1.71,0,0,0,.21.69h-2.09a2.25,2.25,0,0,1-.09-.35,2.35,2.35,0,0,1,0-.37,2.85,2.85,0,0,1-1.16.71,4.87,4.87,0,0,1-1.36.2,4,4,0,0,1-1-.13,2.21,2.21,0,0,1-.81-.41,1.85,1.85,0,0,1-.54-.69,2.61,2.61,0,0,1,0-2.05,2,2,0,0,1,.58-.66,2.72,2.72,0,0,1,.81-.37c.31-.08.61-.14.92-.19l.92-.12a5.42,5.42,0,0,0,.79-.13,1.41,1.41,0,0,0,.55-.25.55.55,0,0,0,.19-.49,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.1,1.1,0,0,0-.42-.14,2.78,2.78,0,0,0-.51,0,1.59,1.59,0,0,0-1,.26,1.16,1.16,0,0,0-.4.87Zm4.75,1.52a.88.88,0,0,1-.33.18l-.42.11-.47.07-.49.07-.45.12a1.42,1.42,0,0,0-.39.19.83.83,0,0,0-.27.32.94.94,0,0,0-.1.47,1,1,0,0,0,.1.47.75.75,0,0,0,.27.29,1.06,1.06,0,0,0,.41.15,2.42,2.42,0,0,0,.47,0,1.81,1.81,0,0,0,.95-.21,1.33,1.33,0,0,0,.49-.48,1.6,1.6,0,0,0,.19-.57,3,3,0,0,0,0-.46Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M865.76,1100.06a1.2,1.2,0,0,0,.35.39,1.64,1.64,0,0,0,.5.23,2.8,2.8,0,0,0,.59.07,2.48,2.48,0,0,0,.45,0,1.91,1.91,0,0,0,.44-.16.89.89,0,0,0,.32-.29.74.74,0,0,0,.13-.46c0-.31-.2-.54-.61-.69a12,12,0,0,0-1.72-.47,7.92,7.92,0,0,1-.87-.23,3.16,3.16,0,0,1-.76-.37,1.7,1.7,0,0,1-.54-.56,1.64,1.64,0,0,1-.2-.83,2.26,2.26,0,0,1,.28-1.19,2.1,2.1,0,0,1,.75-.73,3.29,3.29,0,0,1,1-.38,6.56,6.56,0,0,1,1.18-.11,5.69,5.69,0,0,1,1.18.12,3.1,3.1,0,0,1,1,.39,2.37,2.37,0,0,1,.75.73,2.48,2.48,0,0,1,.36,1.15h-2a.9.9,0,0,0-.45-.8,2,2,0,0,0-1-.21l-.38,0a1.44,1.44,0,0,0-.37.1.63.63,0,0,0-.28.21.52.52,0,0,0-.12.36.54.54,0,0,0,.21.45,1.61,1.61,0,0,0,.52.29c.22.07.47.13.75.19l.85.19a8.84,8.84,0,0,1,.88.25,2.71,2.71,0,0,1,.76.38,2,2,0,0,1,.54.59,1.82,1.82,0,0,1,.2.88,2.34,2.34,0,0,1-.3,1.23,2.4,2.4,0,0,1-.77.81,3.46,3.46,0,0,1-1.1.43,6.46,6.46,0,0,1-1.25.12,6.67,6.67,0,0,1-1.27-.13,3.23,3.23,0,0,1-1.11-.44,2.55,2.55,0,0,1-.8-.8,2.49,2.49,0,0,1-.33-1.25h2A1,1,0,0,0,865.76,1100.06Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M873.75,1094.44v1h0a2.25,2.25,0,0,1,1-.88,3,3,0,0,1,1.27-.28,3.25,3.25,0,0,1,1.52.34,3,3,0,0,1,1.06.88,3.81,3.81,0,0,1,.62,1.28,5.94,5.94,0,0,1,.2,1.53,5.4,5.4,0,0,1-.2,1.45,3.75,3.75,0,0,1-.62,1.23,3,3,0,0,1-1,.85,3.12,3.12,0,0,1-1.44.32,3,3,0,0,1-1.28-.28,2.45,2.45,0,0,1-1-.84h0v3.55H871.8v-10.1Zm2.67,5.93a1.55,1.55,0,0,0,.55-.53,2.16,2.16,0,0,0,.3-.76,4.31,4.31,0,0,0,.09-.88,3.71,3.71,0,0,0-.1-.88,2.24,2.24,0,0,0-.31-.78,1.73,1.73,0,0,0-.56-.55,1.77,1.77,0,0,0-1.67,0,1.61,1.61,0,0,0-.55.55,2.24,2.24,0,0,0-.3.76,4.43,4.43,0,0,0-.09.9,3.71,3.71,0,0,0,.1.88,2.16,2.16,0,0,0,.3.76,1.58,1.58,0,0,0,1.4.73A1.56,1.56,0,0,0,876.42,1100.37Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M880.7,1093.28v-1.69h2v1.69Zm2,1.16v7.48h-2v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M886.58,1100.12a2.1,2.1,0,0,0,2.4.14,1.24,1.24,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,3.92,3.92,0,0,1-2.18.58,4.5,4.5,0,0,1-1.59-.28,3.35,3.35,0,0,1-1.2-.81,3.54,3.54,0,0,1-.76-1.24,4.68,4.68,0,0,1,0-3.16,3.58,3.58,0,0,1,.78-1.25,3.68,3.68,0,0,1,1.21-.83,3.93,3.93,0,0,1,1.55-.31,3.64,3.64,0,0,1,1.67.37,3.43,3.43,0,0,1,1.17,1,4,4,0,0,1,.65,1.41,5.13,5.13,0,0,1,.15,1.67h-5.4A2.05,2.05,0,0,0,886.58,1100.12Zm2.32-3.93a1.45,1.45,0,0,0-1.12-.41,1.81,1.81,0,0,0-.82.17,1.55,1.55,0,0,0-.52.41,1.76,1.76,0,0,0-.28.52,2.66,2.66,0,0,0-.09.5h3.34A2.26,2.26,0,0,0,888.9,1096.19Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M894.8,1091.59v10.33h-2.06v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M898.79,1099.69v2.23a2.27,2.27,0,0,1-.18.91,2.34,2.34,0,0,1-.48.71,2.81,2.81,0,0,1-.72.5,3.33,3.33,0,0,1-.88.27v-1a1.12,1.12,0,0,0,.42-.18,1.43,1.43,0,0,0,.34-.31,1.18,1.18,0,0,0,.21-.4,1.22,1.22,0,0,0,.06-.46h-1v-2.23Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M907,1091.59V1100h5v1.91h-7.31v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M915.54,1091.59v10.33h-2.28v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M919.78,1101.92l-3.35-10.33h2.33l2.31,7.26h0l2.33-7.26h2.35l-3.43,10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M934.38,1091.59v1.91h-5.46v2.21h5v1.77h-5V1100h5.58v1.91h-7.85v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M945.19,1101.92v-1h0a2.33,2.33,0,0,1-1,.94,3.06,3.06,0,0,1-1.28.29,3.46,3.46,0,0,1-1.35-.22,1.94,1.94,0,0,1-.83-.61,2.32,2.32,0,0,1-.43-1,6.23,6.23,0,0,1-.12-1.27v-4.6h2v4.22a2.69,2.69,0,0,0,.29,1.39,1.13,1.13,0,0,0,1,.45,1.42,1.42,0,0,0,1.21-.5,2.7,2.7,0,0,0,.38-1.64v-3.92h2.06v7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M950.66,1094.44v1h.05a2.36,2.36,0,0,1,1-1,2.89,2.89,0,0,1,1.27-.3,3.5,3.5,0,0,1,1.36.23,2,2,0,0,1,.83.62,2.61,2.61,0,0,1,.43,1,6.16,6.16,0,0,1,.12,1.27v4.6h-2.06v-4.23a2.58,2.58,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.45,1.41,1.41,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.92h-2.06v-7.48Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M962.6,1101a2.09,2.09,0,0,1-.94.88,3.16,3.16,0,0,1-1.33.27,3.12,3.12,0,0,1-1.47-.33,2.82,2.82,0,0,1-1.05-.88,3.93,3.93,0,0,1-.63-1.28,5.4,5.4,0,0,1-.21-1.51,5,5,0,0,1,.21-1.45,3.72,3.72,0,0,1,.63-1.24,3,3,0,0,1,2.48-1.19,2.91,2.91,0,0,1,1.26.29,2.14,2.14,0,0,1,1,.83h0v-3.76h2.06v10.33h-2v-1Zm-.08-3.69a2.24,2.24,0,0,0-.3-.76,1.68,1.68,0,0,0-.54-.52,1.54,1.54,0,0,0-.84-.21,1.66,1.66,0,0,0-.86.21,1.55,1.55,0,0,0-.55.53,2.58,2.58,0,0,0-.31.76,4.33,4.33,0,0,0-.09.89,3.69,3.69,0,0,0,.1.87,2.33,2.33,0,0,0,.33.77,1.73,1.73,0,0,0,.56.55,1.54,1.54,0,0,0,.82.21,1.6,1.6,0,0,0,.85-.2,1.55,1.55,0,0,0,.54-.54,2.68,2.68,0,0,0,.29-.78,4.34,4.34,0,0,0,.08-.89A4.27,4.27,0,0,0,962.52,1097.27Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M975.25,1093.5h-5.11v-1.91h8.14v1.79l-5.54,6.63h5.68v1.91h-8.71v-1.8Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M979.65,1094.65a5.2,5.2,0,0,1,1-1.73,4.68,4.68,0,0,1,1.6-1.16,5.66,5.66,0,0,1,4.28,0,4.54,4.54,0,0,1,1.59,1.16,5.2,5.2,0,0,1,1,1.73,6.46,6.46,0,0,1,.35,2.15,6.25,6.25,0,0,1-.35,2.1,5.06,5.06,0,0,1-1,1.7,4.5,4.5,0,0,1-1.59,1.14,5.79,5.79,0,0,1-4.28,0,4.63,4.63,0,0,1-1.6-1.14,5.06,5.06,0,0,1-1-1.7,6.25,6.25,0,0,1-.34-2.1A6.46,6.46,0,0,1,979.65,1094.65Zm2.08,3.41a3.46,3.46,0,0,0,.48,1.11,2.64,2.64,0,0,0,.87.79,2.67,2.67,0,0,0,1.31.3,2.63,2.63,0,0,0,1.3-.3,2.54,2.54,0,0,0,.87-.79,3.46,3.46,0,0,0,.48-1.11,5.3,5.3,0,0,0,.15-1.26,5.68,5.68,0,0,0-.15-1.32,3.53,3.53,0,0,0-.48-1.14,2.54,2.54,0,0,0-.87-.79,2.63,2.63,0,0,0-1.3-.3,2.67,2.67,0,0,0-1.31.3,2.64,2.64,0,0,0-.87.79,3.53,3.53,0,0,0-.48,1.14,5.68,5.68,0,0,0-.15,1.32A5.3,5.3,0,0,0,981.73,1098.06Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M990.91,1094.65a5.38,5.38,0,0,1,1-1.73,4.68,4.68,0,0,1,1.6-1.16,5.66,5.66,0,0,1,4.28,0,4.54,4.54,0,0,1,1.59,1.16,5,5,0,0,1,1,1.73,6.46,6.46,0,0,1,.35,2.15,6.25,6.25,0,0,1-.35,2.1,4.9,4.9,0,0,1-1,1.7,4.5,4.5,0,0,1-1.59,1.14,5.79,5.79,0,0,1-4.28,0,4.63,4.63,0,0,1-1.6-1.14,5.23,5.23,0,0,1-1-1.7,6.25,6.25,0,0,1-.34-2.1A6.46,6.46,0,0,1,990.91,1094.65Zm2.08,3.41a3.27,3.27,0,0,0,.49,1.11,2.51,2.51,0,0,0,.86.79,3,3,0,0,0,2.61,0,2.45,2.45,0,0,0,.87-.79,3.46,3.46,0,0,0,.48-1.11,5.31,5.31,0,0,0,.16-1.26,5.68,5.68,0,0,0-.16-1.32,3.53,3.53,0,0,0-.48-1.14,2.45,2.45,0,0,0-.87-.79,3,3,0,0,0-2.61,0,2.51,2.51,0,0,0-.86.79,3.34,3.34,0,0,0-.49,1.14,5.68,5.68,0,0,0-.15,1.32A5.3,5.3,0,0,0,993,1098.06Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1005.47,1091.59l2.42,7.1h0l2.29-7.1h3.2v10.33h-2.13v-7.32h0l-2.53,7.32H1007l-2.53-7.25h0v7.25h-2.12v-10.33Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
      </g>
    </Link>
    <g id="masterclass-konkret-und-geheimnisvoll">
      <rect
        class="e8181e97-3015-49de-82f8-4bd3baa60643"
        x="225.35"
        y="952.72"
        width="397.31"
        height="96.23"
      />
      <g>
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M315.23,1044l4.09,12h.05l3.88-12h5.42v17.51h-3.61V1049.1H325l-4.29,12.41h-3l-4.29-12.29h0v12.29h-3.6V1044Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M340.78,1044l6.55,17.51h-4l-1.32-3.9h-6.55l-1.37,3.9h-3.88l6.62-17.51Zm.22,10.74-2.21-6.43h0l-2.29,6.43Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M351.79,1057.19a2.74,2.74,0,0,0,.85,1,3.63,3.63,0,0,0,1.24.57,5.92,5.92,0,0,0,1.47.18,7.29,7.29,0,0,0,1.1-.09,3.56,3.56,0,0,0,1.11-.33,2.33,2.33,0,0,0,.85-.67,1.7,1.7,0,0,0,.35-1.09,1.49,1.49,0,0,0-.46-1.15,3.56,3.56,0,0,0-1.19-.74,11.64,11.64,0,0,0-1.66-.52c-.63-.14-1.25-.31-1.89-.49a15.14,15.14,0,0,1-1.91-.6,6.13,6.13,0,0,1-1.67-.92,4.19,4.19,0,0,1-1.19-1.41,4.27,4.27,0,0,1-.46-2,4.68,4.68,0,0,1,.58-2.35,5.18,5.18,0,0,1,1.51-1.65,6.49,6.49,0,0,1,2.11-1,9,9,0,0,1,2.35-.32,10.85,10.85,0,0,1,2.64.31,6.41,6.41,0,0,1,2.24,1,5,5,0,0,1,1.56,1.75,5.44,5.44,0,0,1,.58,2.59h-3.73a3.18,3.18,0,0,0-.33-1.3,2.25,2.25,0,0,0-.75-.81,3.28,3.28,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.53,2.53,0,0,0-.85.34,2.25,2.25,0,0,0-.62.61,1.63,1.63,0,0,0-.25.94,1.57,1.57,0,0,0,.2.83,1.78,1.78,0,0,0,.77.59,9.94,9.94,0,0,0,1.59.54l2.67.69c.32.06.78.18,1.36.35a6.79,6.79,0,0,1,1.73.82,5.33,5.33,0,0,1,1.48,1.51,4.29,4.29,0,0,1,.62,2.42,5.36,5.36,0,0,1-.46,2.23,4.85,4.85,0,0,1-1.39,1.78,6.61,6.61,0,0,1-2.28,1.16,10.73,10.73,0,0,1-3.15.42,11,11,0,0,1-2.81-.36,7.05,7.05,0,0,1-2.4-1.11,5.56,5.56,0,0,1-1.66-1.94,5.87,5.87,0,0,1-.58-2.8h3.72A3.26,3.26,0,0,0,351.79,1057.19Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M363.39,1047.23V1044h14.35v3.23h-5.25v14.28h-3.85v-14.28Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M392.84,1044v3.23H383.6V1051h8.48v3H383.6v4.29H393v3.24H379.75V1044Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M405.08,1044a5.49,5.49,0,0,1,2.12.38,4.85,4.85,0,0,1,1.62,1,4.54,4.54,0,0,1,1,1.53,4.88,4.88,0,0,1,.36,1.88,5.28,5.28,0,0,1-.65,2.67,4.15,4.15,0,0,1-2.13,1.72v0a2.92,2.92,0,0,1,1.18.6,3.32,3.32,0,0,1,.76,1,4.41,4.41,0,0,1,.43,1.22,12,12,0,0,1,.19,1.32c0,.28,0,.61,0,1s.05.76.09,1.16a7.54,7.54,0,0,0,.2,1.11,2.65,2.65,0,0,0,.4.9h-3.85a7,7,0,0,1-.39-2c-.05-.77-.12-1.5-.22-2.21a3.47,3.47,0,0,0-.84-2,2.88,2.88,0,0,0-2.08-.64h-3.85v6.85h-3.85V1044Zm-1.37,7.92a2.91,2.91,0,0,0,2-.59,2.44,2.44,0,0,0,.67-1.91,2.37,2.37,0,0,0-.67-1.86,3,3,0,0,0-2-.57h-4.22v4.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M424.58,1048.67a4,4,0,0,0-.86-1,3.92,3.92,0,0,0-1.17-.65,3.85,3.85,0,0,0-1.36-.23,4.42,4.42,0,0,0-2.2.5,4.09,4.09,0,0,0-1.48,1.35,5.93,5.93,0,0,0-.82,1.92,9.72,9.72,0,0,0-.26,2.23,9,9,0,0,0,.26,2.15,5.9,5.9,0,0,0,.82,1.88,4.22,4.22,0,0,0,1.48,1.33,4.42,4.42,0,0,0,2.2.51,3.56,3.56,0,0,0,2.76-1.08,5,5,0,0,0,1.22-2.85h3.72a8.89,8.89,0,0,1-.76,3,7.29,7.29,0,0,1-1.62,2.26,6.91,6.91,0,0,1-2.35,1.42,9.47,9.47,0,0,1-6.58-.21,7.65,7.65,0,0,1-2.71-1.93,8.45,8.45,0,0,1-1.7-2.88,10.61,10.61,0,0,1-.59-3.57,11,11,0,0,1,.59-3.64,8.88,8.88,0,0,1,1.7-2.93,7.6,7.6,0,0,1,2.71-2,8.88,8.88,0,0,1,3.61-.71,8.78,8.78,0,0,1,2.74.42,7.23,7.23,0,0,1,2.3,1.21,6.54,6.54,0,0,1,1.68,2,7.25,7.25,0,0,1,.84,2.7H425A3.2,3.2,0,0,0,424.58,1048.67Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M435.37,1044v14.27h8.53v3.24H431.52V1044Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M454.79,1044l6.55,17.51h-4l-1.33-3.9h-6.54l-1.38,3.9h-3.87l6.62-17.51Zm.22,10.74-2.21-6.43h-.05l-2.28,6.43Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M465.8,1057.19a2.64,2.64,0,0,0,.85,1,3.67,3.67,0,0,0,1.23.57,6.06,6.06,0,0,0,1.48.18,7.29,7.29,0,0,0,1.1-.09,3.42,3.42,0,0,0,1.1-.33,2.28,2.28,0,0,0,.86-.67,1.69,1.69,0,0,0,.34-1.09,1.52,1.52,0,0,0-.45-1.15,3.56,3.56,0,0,0-1.19-.74,11.79,11.79,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.88-.49a15.29,15.29,0,0,1-1.92-.6,6.44,6.44,0,0,1-1.67-.92,4.18,4.18,0,0,1-1.64-3.45,4.58,4.58,0,0,1,.58-2.35,5.07,5.07,0,0,1,1.51-1.65,6.49,6.49,0,0,1,2.11-1,9,9,0,0,1,2.35-.32,11,11,0,0,1,2.64.31,6.41,6.41,0,0,1,2.24,1,5.15,5.15,0,0,1,1.56,1.75,5.44,5.44,0,0,1,.57,2.59h-3.72a3.17,3.17,0,0,0-.34-1.3,2.14,2.14,0,0,0-.74-.81,3.28,3.28,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.32,2.32,0,0,0-1.48.95,1.7,1.7,0,0,0-.24.94,1.48,1.48,0,0,0,.2.83,1.72,1.72,0,0,0,.77.59,9.6,9.6,0,0,0,1.59.54l2.66.69c.33.06.78.18,1.36.35a6.63,6.63,0,0,1,1.73.82,5.23,5.23,0,0,1,1.49,1.51,4.37,4.37,0,0,1,.62,2.42,5.36,5.36,0,0,1-.46,2.23,5.07,5.07,0,0,1-1.39,1.78,6.61,6.61,0,0,1-2.28,1.16,10.73,10.73,0,0,1-3.15.42,10.93,10.93,0,0,1-2.81-.36,7,7,0,0,1-2.4-1.11,5.56,5.56,0,0,1-1.66-1.94,5.87,5.87,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,465.8,1057.19Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M481.71,1057.19a2.83,2.83,0,0,0,.85,1,3.72,3.72,0,0,0,1.24.57,5.92,5.92,0,0,0,1.47.18,7.29,7.29,0,0,0,1.1-.09,3.48,3.48,0,0,0,1.11-.33,2.36,2.36,0,0,0,.86-.67,1.75,1.75,0,0,0,.34-1.09,1.52,1.52,0,0,0-.45-1.15,3.66,3.66,0,0,0-1.19-.74,11.79,11.79,0,0,0-1.67-.52c-.62-.14-1.25-.31-1.89-.49a15.68,15.68,0,0,1-1.91-.6,6.28,6.28,0,0,1-1.67-.92,4.19,4.19,0,0,1-1.19-1.41,4.27,4.27,0,0,1-.45-2,4.67,4.67,0,0,1,.57-2.35,5.3,5.3,0,0,1,1.51-1.65,6.59,6.59,0,0,1,2.11-1,9.11,9.11,0,0,1,2.35-.32,10.85,10.85,0,0,1,2.64.31,6.54,6.54,0,0,1,2.25,1,5,5,0,0,1,1.55,1.75,5.33,5.33,0,0,1,.58,2.59h-3.73a3,3,0,0,0-.33-1.3,2.25,2.25,0,0,0-.75-.81,3.22,3.22,0,0,0-1.06-.42,6.82,6.82,0,0,0-1.32-.12,4.2,4.2,0,0,0-.93.1,2.4,2.4,0,0,0-.84.34,2.16,2.16,0,0,0-.63.61,1.71,1.71,0,0,0-.25.94,1.57,1.57,0,0,0,.2.83,1.78,1.78,0,0,0,.77.59,10.12,10.12,0,0,0,1.6.54l2.66.69c.33.06.78.18,1.36.35a6.63,6.63,0,0,1,1.73.82,5.2,5.2,0,0,1,1.48,1.51,4.29,4.29,0,0,1,.63,2.42,5.37,5.37,0,0,1-.47,2.23,4.93,4.93,0,0,1-1.38,1.78,6.76,6.76,0,0,1-2.29,1.16,10.63,10.63,0,0,1-3.15.42,10.86,10.86,0,0,1-2.8-.36,7.1,7.1,0,0,1-2.41-1.11,5.64,5.64,0,0,1-1.65-1.94,5.76,5.76,0,0,1-.59-2.8h3.73A3.26,3.26,0,0,0,481.71,1057.19Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M313.66,1070v7.25l6.84-7.25h4.81l-6.85,6.91,7.51,10.6h-4.83l-5.27-7.88-2.21,2.24v5.64h-3.85V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M327.34,1075.18a8.86,8.86,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,7.83,7.83,0,0,1,2.7,2,8.86,8.86,0,0,1,1.69,2.93,11,11,0,0,1,.59,3.64,10.61,10.61,0,0,1-.59,3.57,8.43,8.43,0,0,1-1.69,2.88,7.89,7.89,0,0,1-2.7,1.93,9.74,9.74,0,0,1-7.25,0,7.83,7.83,0,0,1-2.71-1.93,8.43,8.43,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57A11,11,0,0,1,327.34,1075.18Zm3.52,5.79a5.9,5.9,0,0,0,.82,1.88,4.27,4.27,0,0,0,1.47,1.33,5,5,0,0,0,4.42,0,4.27,4.27,0,0,0,1.47-1.33,5.69,5.69,0,0,0,.82-1.88,9,9,0,0,0,.26-2.15,9.72,9.72,0,0,0-.26-2.23,5.72,5.72,0,0,0-.82-1.92,4.14,4.14,0,0,0-1.47-1.35,5.13,5.13,0,0,0-4.42,0,4.14,4.14,0,0,0-1.47,1.35,5.93,5.93,0,0,0-.82,1.92,9.72,9.72,0,0,0-.26,2.23A9,9,0,0,0,330.86,1081Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M350.42,1070l7.31,11.74h0V1070h3.6v17.51h-3.85l-7.28-11.73h-.05v11.73h-3.61V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M368.61,1070v7.25l6.85-7.25h4.8l-6.84,6.91,7.51,10.6h-4.84l-5.27-7.88-2.21,2.24v5.64h-3.85V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M391.91,1070a5.49,5.49,0,0,1,2.12.38,4.85,4.85,0,0,1,1.62,1,4.54,4.54,0,0,1,1,1.53,4.88,4.88,0,0,1,.36,1.88,5.28,5.28,0,0,1-.65,2.67,4.16,4.16,0,0,1-2.12,1.72v0a3,3,0,0,1,1.17.6,3.32,3.32,0,0,1,.76,1,4.41,4.41,0,0,1,.43,1.22,12,12,0,0,1,.19,1.32c0,.28,0,.61.05,1s0,.76.08,1.16a7.54,7.54,0,0,0,.2,1.11,2.65,2.65,0,0,0,.4.9H393.7a7,7,0,0,1-.39-2c-.05-.77-.12-1.5-.22-2.21a3.47,3.47,0,0,0-.84-2,2.88,2.88,0,0,0-2.08-.64h-3.85v6.85h-3.85V1070Zm-1.37,7.92a2.91,2.91,0,0,0,2-.59,2.41,2.41,0,0,0,.67-1.91,2.33,2.33,0,0,0-.67-1.86,3,3,0,0,0-2-.57h-4.22v4.93Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M413.27,1070v3.23H404V1077h8.48v3H404v4.29h9.44v3.24H400.18V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M414.69,1073.23V1070H429v3.23h-5.25v14.28h-3.85v-14.28Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M449.74,1080v2.62h-4.88v4.89h-2.63v-4.89h-4.88V1080h4.88v-4.88h2.63V1080Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M469.77,1087.37a6.44,6.44,0,0,1-2.52.53,8.92,8.92,0,0,1-3.62-.7,7.83,7.83,0,0,1-2.71-1.93,8.27,8.27,0,0,1-1.69-2.88,10.37,10.37,0,0,1-.59-3.57,10.77,10.77,0,0,1,.59-3.64,8.69,8.69,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,8.92,8.92,0,0,1,3.62-.71,8.47,8.47,0,0,1,2.61.4,7.51,7.51,0,0,1,2.28,1.19,6.45,6.45,0,0,1,2.49,4.59H471a3.68,3.68,0,0,0-1.33-2.21,3.82,3.82,0,0,0-2.37-.73,4.43,4.43,0,0,0-2.21.5,4,4,0,0,0-1.47,1.35,5.72,5.72,0,0,0-.82,1.92,9.19,9.19,0,0,0-.26,2.23,8.5,8.5,0,0,0,.26,2.15,5.69,5.69,0,0,0,.82,1.88,4.1,4.1,0,0,0,1.47,1.33,4.43,4.43,0,0,0,2.21.51,4.19,4.19,0,0,0,2.95-1,4.24,4.24,0,0,0,1.22-2.81h-3.88V1078h7.36v9.47h-2.45l-.4-2A5.69,5.69,0,0,1,469.77,1087.37Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M491.11,1070v3.23h-9.25V1077h8.49v3h-8.49v4.29h9.44v3.24H478V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M497.75,1070v6.72h7.09V1070h3.85v17.51h-3.85V1080h-7.09v7.56H493.9V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M525.17,1070v3.23h-9.25V1077h8.49v3h-8.49v4.29h9.45v3.24h-13.3V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M531.82,1070v17.51H528V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M540.62,1070l4.09,12h0l3.88-12h5.42v17.51h-3.61V1075.1h-.05l-4.29,12.41h-3l-4.3-12.29h-.05v12.29h-3.6V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M561.27,1070l7.31,11.74h0V1070h3.61v17.51h-3.85l-7.28-11.73h-.05v11.73h-3.61V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M579.46,1070v17.51h-3.85V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M585.79,1083.19a2.74,2.74,0,0,0,.85,1,3.63,3.63,0,0,0,1.24.57,5.92,5.92,0,0,0,1.47.18,7.29,7.29,0,0,0,1.1-.09,3.42,3.42,0,0,0,1.1-.33,2.28,2.28,0,0,0,.86-.67,1.7,1.7,0,0,0,.35-1.09,1.53,1.53,0,0,0-.46-1.15,3.56,3.56,0,0,0-1.19-.74,11.64,11.64,0,0,0-1.66-.52c-.63-.14-1.26-.31-1.89-.49a15.83,15.83,0,0,1-1.92-.6,6.22,6.22,0,0,1-1.66-.92,4.15,4.15,0,0,1-1.65-3.45,4.68,4.68,0,0,1,.58-2.35,5.18,5.18,0,0,1,1.51-1.65,6.49,6.49,0,0,1,2.11-1,9,9,0,0,1,2.35-.32,10.91,10.91,0,0,1,2.64.31,6.41,6.41,0,0,1,2.24,1,5,5,0,0,1,1.56,1.75,5.44,5.44,0,0,1,.57,2.59h-3.72a3.35,3.35,0,0,0-.33-1.3,2.25,2.25,0,0,0-.75-.81,3.28,3.28,0,0,0-1.07-.42,6.71,6.71,0,0,0-1.31-.12,4.2,4.2,0,0,0-.93.1,2.45,2.45,0,0,0-.85.34,2.25,2.25,0,0,0-.62.61,1.63,1.63,0,0,0-.25.94,1.57,1.57,0,0,0,.2.83,1.78,1.78,0,0,0,.77.59,9.94,9.94,0,0,0,1.59.54l2.66.69c.33.06.78.18,1.37.35a6.58,6.58,0,0,1,1.72.82,5.23,5.23,0,0,1,1.49,1.51,4.37,4.37,0,0,1,.62,2.42,5.36,5.36,0,0,1-.46,2.23,4.85,4.85,0,0,1-1.39,1.78,6.61,6.61,0,0,1-2.28,1.16,10.73,10.73,0,0,1-3.15.42,11,11,0,0,1-2.81-.36,7,7,0,0,1-2.4-1.11,5.56,5.56,0,0,1-1.66-1.94,5.75,5.75,0,0,1-.58-2.8h3.72A3.26,3.26,0,0,0,585.79,1083.19Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M602.56,1087.51,596.9,1070h4l3.9,12.31h0l3.94-12.31h4l-5.81,17.51Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M614,1075.18a8.86,8.86,0,0,1,1.69-2.93,7.78,7.78,0,0,1,2.71-2,9.61,9.61,0,0,1,7.25,0,7.83,7.83,0,0,1,2.7,2,8.86,8.86,0,0,1,1.69,2.93,11,11,0,0,1,.59,3.64,10.61,10.61,0,0,1-.59,3.57,8.43,8.43,0,0,1-1.69,2.88,7.89,7.89,0,0,1-2.7,1.93,9.74,9.74,0,0,1-7.25,0,7.83,7.83,0,0,1-2.71-1.93,8.43,8.43,0,0,1-1.69-2.88,10.61,10.61,0,0,1-.59-3.57A11,11,0,0,1,614,1075.18Zm3.52,5.79a5.9,5.9,0,0,0,.82,1.88,4.27,4.27,0,0,0,1.47,1.33,5,5,0,0,0,4.42,0,4.27,4.27,0,0,0,1.47-1.33,5.69,5.69,0,0,0,.82-1.88,9,9,0,0,0,.26-2.15,9.72,9.72,0,0,0-.26-2.23,5.72,5.72,0,0,0-.82-1.92,4.14,4.14,0,0,0-1.47-1.35,5.13,5.13,0,0,0-4.42,0,4.14,4.14,0,0,0-1.47,1.35,5.93,5.93,0,0,0-.82,1.92,9.72,9.72,0,0,0-.26,2.23A9,9,0,0,0,617.56,1081Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M637.14,1070v14.27h8.54v3.24H633.29V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M651.68,1070v14.27h8.54v3.24H647.83V1070Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M311.39,1096.17v4.29l4-4.29h2.83l-4,4.08,4.43,6.26H315.8l-3.11-4.65-1.3,1.32v3.33h-2.27v-10.34Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M319.26,1101.32a2.43,2.43,0,0,1,.36-1.2,2.36,2.36,0,0,1,.81-.76,3.6,3.6,0,0,1,1.11-.42,6.43,6.43,0,0,1,1.24-.12,8.86,8.86,0,0,1,1.14.08,3.54,3.54,0,0,1,1.06.31,2.06,2.06,0,0,1,.78.65,1.78,1.78,0,0,1,.3,1.09v3.89a7.54,7.54,0,0,0,.06,1,2,2,0,0,0,.2.7h-2.08a2.37,2.37,0,0,1-.09-.36,2.2,2.2,0,0,1-.06-.37,2.56,2.56,0,0,1-1.15.71,4.53,4.53,0,0,1-1.36.2,3.56,3.56,0,0,1-1-.13,2.2,2.2,0,0,1-.81-.4,2,2,0,0,1-.55-.7,2.35,2.35,0,0,1-.19-1,2.14,2.14,0,0,1,.22-1,1.87,1.87,0,0,1,.58-.66,2.49,2.49,0,0,1,.81-.37c.31-.08.61-.15.92-.2s.61-.08.91-.11a7.05,7.05,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.26.54.54,0,0,0,.19-.48,1.13,1.13,0,0,0-.11-.53.76.76,0,0,0-.29-.3,1.11,1.11,0,0,0-.42-.15,4.12,4.12,0,0,0-.51,0,1.52,1.52,0,0,0-1,.26,1.12,1.12,0,0,0-.4.86Zm4.75,1.52a.88.88,0,0,1-.33.18,2.58,2.58,0,0,1-.42.11l-.47.08-.49.07-.46.11a1.43,1.43,0,0,0-.39.2.86.86,0,0,0-.26.31,1,1,0,0,0-.11.48.92.92,0,0,0,.11.46.66.66,0,0,0,.27.3,1.25,1.25,0,0,0,.41.15,2.42,2.42,0,0,0,.47,0,1.75,1.75,0,0,0,.94-.2,1.36,1.36,0,0,0,.5-.48,1.58,1.58,0,0,0,.19-.58,3,3,0,0,0,0-.46Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M329.65,1099v1h0a2.89,2.89,0,0,1,1-.9,2.67,2.67,0,0,1,1.31-.32,3,3,0,0,1,1.29.27,1.85,1.85,0,0,1,.89,1,3.42,3.42,0,0,1,.91-.86,2.4,2.4,0,0,1,1.35-.38,4.25,4.25,0,0,1,1.1.14,2.28,2.28,0,0,1,.87.47,2.07,2.07,0,0,1,.56.83,3.28,3.28,0,0,1,.21,1.24v5h-2.06v-4.25c0-.25,0-.48,0-.7a1.41,1.41,0,0,0-.16-.58.84.84,0,0,0-.38-.39,1.36,1.36,0,0,0-.69-.15,1.32,1.32,0,0,0-.7.17,1.15,1.15,0,0,0-.42.43,1.69,1.69,0,0,0-.2.61,4,4,0,0,0-.05.69v4.17H332.4v-4.2c0-.22,0-.44,0-.66a1.65,1.65,0,0,0-.13-.6.91.91,0,0,0-.36-.44,1.44,1.44,0,0,0-1.13-.1,1.27,1.27,0,0,0-.46.24,1.41,1.41,0,0,0-.38.52,2.08,2.08,0,0,0-.16.89v4.35h-2.06V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M343,1104.71a2.1,2.1,0,0,0,2.4.14,1.32,1.32,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.57,4.3,4.3,0,0,1-1.6-.28,3.33,3.33,0,0,1-1.2-.8,3.74,3.74,0,0,1-.76-1.25,4.73,4.73,0,0,1-.26-1.59,4.41,4.41,0,0,1,.27-1.56,3.58,3.58,0,0,1,.78-1.25,3.7,3.7,0,0,1,1.21-.84,3.94,3.94,0,0,1,1.56-.3,3.38,3.38,0,0,1,2.83,1.36,4.09,4.09,0,0,1,.65,1.42,5.07,5.07,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,343,1104.71Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.48,1.48,0,0,0-.79.93,2,2,0,0,0-.1.49h3.34A2.3,2.3,0,0,0,345.29,1100.77Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M351,1099v1.39h0a2.5,2.5,0,0,1,.39-.64,2.47,2.47,0,0,1,.56-.51,2.63,2.63,0,0,1,.68-.32,2.46,2.46,0,0,1,.76-.12,1.6,1.6,0,0,1,.45.07v1.91l-.35,0-.39,0a2.13,2.13,0,0,0-1,.19,1.67,1.67,0,0,0-.63.51,2.11,2.11,0,0,0-.34.76,4,4,0,0,0-.1.94v3.38h-2.05V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M354.62,1101.32a2.43,2.43,0,0,1,.36-1.2,2.36,2.36,0,0,1,.81-.76,3.6,3.6,0,0,1,1.11-.42,6.43,6.43,0,0,1,1.24-.12,9,9,0,0,1,1.14.08,3.54,3.54,0,0,1,1.06.31,2.23,2.23,0,0,1,.78.65,1.78,1.78,0,0,1,.3,1.09v3.89a7.54,7.54,0,0,0,.06,1,1.77,1.77,0,0,0,.2.7H359.6c0-.12-.07-.24-.1-.36s0-.24-.05-.37a2.6,2.6,0,0,1-1.16.71,4.44,4.44,0,0,1-1.36.2,3.49,3.49,0,0,1-1-.13,2.32,2.32,0,0,1-.82-.4,2,2,0,0,1-.54-.7,2.35,2.35,0,0,1-.19-1,2.14,2.14,0,0,1,.22-1,1.78,1.78,0,0,1,.58-.66,2.49,2.49,0,0,1,.81-.37,9.4,9.4,0,0,1,.92-.2c.31,0,.61-.08.91-.11a7.05,7.05,0,0,0,.8-.13,1.48,1.48,0,0,0,.55-.26.56.56,0,0,0,.19-.48,1.13,1.13,0,0,0-.11-.53.84.84,0,0,0-.29-.3,1.11,1.11,0,0,0-.42-.15,4.17,4.17,0,0,0-.52,0,1.51,1.51,0,0,0-.95.26,1.16,1.16,0,0,0-.41.86Zm4.75,1.52a.88.88,0,0,1-.33.18,2.58,2.58,0,0,1-.42.11l-.47.08-.49.07-.46.11a1.43,1.43,0,0,0-.39.2,1,1,0,0,0-.27.31,1.14,1.14,0,0,0-.1.48,1,1,0,0,0,.1.46.74.74,0,0,0,.28.3,1.19,1.19,0,0,0,.4.15,2.59,2.59,0,0,0,.48,0,1.75,1.75,0,0,0,.94-.2,1.33,1.33,0,0,0,.49-.48,1.44,1.44,0,0,0,.2-.58c0-.19,0-.34,0-.46Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M369.45,1106.51l-1.33-5h0l-1.27,5h-2.13l-2.37-7.49h2.17l1.37,5.08h0l1.25-5.08h2l1.27,5.07h0l1.37-5.07h2.12l-2.36,7.49Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M374.84,1101.14a3.45,3.45,0,0,1,.78-1.24,3.6,3.6,0,0,1,1.21-.8,4.71,4.71,0,0,1,3.2,0,3.64,3.64,0,0,1,1.22.8,3.45,3.45,0,0,1,.78,1.24,4.68,4.68,0,0,1,.28,1.63,4.58,4.58,0,0,1-.28,1.62,3.36,3.36,0,0,1-.78,1.24,3.47,3.47,0,0,1-1.22.79,4.88,4.88,0,0,1-3.2,0,3.43,3.43,0,0,1-1.21-.79,3.36,3.36,0,0,1-.78-1.24,4.37,4.37,0,0,1-.28-1.62A4.46,4.46,0,0,1,374.84,1101.14Zm1.87,2.51a2.11,2.11,0,0,0,.29.76,1.64,1.64,0,0,0,.56.55,1.75,1.75,0,0,0,.87.2,1.78,1.78,0,0,0,.87-.2,1.58,1.58,0,0,0,.57-.55,2.11,2.11,0,0,0,.29-.76,4.35,4.35,0,0,0,0-1.76,2.09,2.09,0,0,0-.29-.77,1.64,1.64,0,0,0-.57-.54,1.67,1.67,0,0,0-.87-.21,1.65,1.65,0,0,0-.87.21,1.7,1.7,0,0,0-.56.54,2.09,2.09,0,0,0-.29.77,4.35,4.35,0,0,0,0,1.76Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M385.59,1099v1.39h0a2.81,2.81,0,0,1,.39-.64,2.32,2.32,0,0,1,.57-.51,2.7,2.7,0,0,1,1.43-.44,1.6,1.6,0,0,1,.45.07v1.91l-.35,0-.39,0a2.13,2.13,0,0,0-1,.19,1.67,1.67,0,0,0-.63.51,2.29,2.29,0,0,0-.34.76,4.67,4.67,0,0,0-.1.94v3.38h-2.05V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M391.51,1096.17v5.54l2.59-2.69h2.43l-2.82,2.75,3.14,4.74h-2.49l-2.05-3.35-.8.77v2.58h-2.05v-10.34Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M399.3,1104.65a1.34,1.34,0,0,0,.36.39,1.52,1.52,0,0,0,.5.22,2.27,2.27,0,0,0,.59.07,1.82,1.82,0,0,0,.45,0,1.23,1.23,0,0,0,.43-.16.84.84,0,0,0,.33-.29.73.73,0,0,0,.13-.45c0-.31-.2-.54-.61-.7a14.24,14.24,0,0,0-1.72-.46c-.3-.07-.59-.15-.88-.24a3.13,3.13,0,0,1-.76-.36,1.7,1.7,0,0,1-.53-.57,1.53,1.53,0,0,1-.2-.83,2.19,2.19,0,0,1,.28-1.18,2.13,2.13,0,0,1,.75-.74,3.28,3.28,0,0,1,1-.37,6.56,6.56,0,0,1,1.18-.11,5.69,5.69,0,0,1,1.18.12,2.85,2.85,0,0,1,1,.39,2.27,2.27,0,0,1,.75.73,2.33,2.33,0,0,1,.35,1.15H402a.88.88,0,0,0-.45-.8,2,2,0,0,0-1-.21,2.48,2.48,0,0,0-.38,0,1.06,1.06,0,0,0-.37.09.83.83,0,0,0-.28.21.55.55,0,0,0-.12.37.57.57,0,0,0,.21.45,1.6,1.6,0,0,0,.52.28c.22.07.47.14.75.2l.85.18a8.84,8.84,0,0,1,.88.25,3,3,0,0,1,.76.38,1.8,1.8,0,0,1,.53.6,1.67,1.67,0,0,1,.21.87,2.37,2.37,0,0,1-.3,1.24,2.46,2.46,0,0,1-.77.8,3,3,0,0,1-1.1.43,6.46,6.46,0,0,1-1.25.12,6,6,0,0,1-1.27-.13,3.22,3.22,0,0,1-1.11-.43,2.55,2.55,0,0,1-.8-.8,2.63,2.63,0,0,1-.34-1.26h2A1.16,1.16,0,0,0,399.3,1104.65Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M407.4,1096.17v3.89h.05a2.35,2.35,0,0,1,1-.94,2.72,2.72,0,0,1,1.19-.3,3.66,3.66,0,0,1,1.35.22,2,2,0,0,1,.84.63,2.39,2.39,0,0,1,.42,1,5.48,5.48,0,0,1,.13,1.26v4.61h-2.06v-4.23a2.53,2.53,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2v-10.34Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M414,1101.14a3.74,3.74,0,0,1,.78-1.24,3.64,3.64,0,0,1,1.22-.8,4.21,4.21,0,0,1,1.59-.28,4.3,4.3,0,0,1,1.6.28,3.59,3.59,0,0,1,1.23.8,3.74,3.74,0,0,1,.78,1.24,4.68,4.68,0,0,1,.27,1.63,4.59,4.59,0,0,1-.27,1.62,3.64,3.64,0,0,1-.78,1.24,3.43,3.43,0,0,1-1.23.79,4.51,4.51,0,0,1-1.6.27,4.42,4.42,0,0,1-1.59-.27,3.47,3.47,0,0,1-1.22-.79,3.64,3.64,0,0,1-.78-1.24,4.59,4.59,0,0,1-.27-1.62A4.68,4.68,0,0,1,414,1101.14Zm1.87,2.51a2.32,2.32,0,0,0,.3.76,1.61,1.61,0,0,0,.55.55,2,2,0,0,0,1.75,0,1.64,1.64,0,0,0,.56-.55,2.32,2.32,0,0,0,.3-.76,4.35,4.35,0,0,0,0-1.76,2.29,2.29,0,0,0-.3-.77,1.8,1.8,0,0,0-2.31-.54,1.67,1.67,0,0,0-.55.54,2.29,2.29,0,0,0-.3.77,4.35,4.35,0,0,0,0,1.76Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M424.73,1099v1h0a2.31,2.31,0,0,1,.95-.89,3,3,0,0,1,1.27-.27,3.25,3.25,0,0,1,1.52.33,3.17,3.17,0,0,1,1.06.89,3.55,3.55,0,0,1,.62,1.28,5.42,5.42,0,0,1,.21,1.52,4.94,4.94,0,0,1-.21,1.45,3.64,3.64,0,0,1-.61,1.23,3.18,3.18,0,0,1-1,.86,3.12,3.12,0,0,1-1.44.31,2.89,2.89,0,0,1-1.28-.28,2.4,2.4,0,0,1-1-.83h0v3.54h-2.06V1099Zm2.67,5.94a1.74,1.74,0,0,0,.55-.53,2,2,0,0,0,.29-.76,4.4,4.4,0,0,0,0-1.77,2.64,2.64,0,0,0-.31-.77,1.83,1.83,0,0,0-.56-.55,1.57,1.57,0,0,0-.83-.21,1.6,1.6,0,0,0-.85.21,1.67,1.67,0,0,0-.55.54,2.29,2.29,0,0,0-.3.77,5.29,5.29,0,0,0-.08.9,4.38,4.38,0,0,0,.09.88,2.24,2.24,0,0,0,.3.76,1.58,1.58,0,0,0,1.4.73A1.7,1.7,0,0,0,427.4,1105Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M437.59,1104a1.77,1.77,0,0,0,.5.58,2.34,2.34,0,0,0,.73.34,3.58,3.58,0,0,0,.87.11,5,5,0,0,0,.65,0,2.41,2.41,0,0,0,.66-.2,1.41,1.41,0,0,0,.5-.4,1,1,0,0,0,.2-.64.89.89,0,0,0-.26-.68,2.15,2.15,0,0,0-.71-.44,7.68,7.68,0,0,0-1-.3c-.37-.09-.74-.18-1.11-.29a9.91,9.91,0,0,1-1.13-.35,4,4,0,0,1-1-.55,2.56,2.56,0,0,1-.7-.83,2.52,2.52,0,0,1-.27-1.21,2.77,2.77,0,0,1,.34-1.38,3.22,3.22,0,0,1,.89-1,4,4,0,0,1,1.25-.58,5.47,5.47,0,0,1,1.39-.18,6.52,6.52,0,0,1,1.55.18,3.9,3.9,0,0,1,1.33.58,3.07,3.07,0,0,1,.92,1,3.25,3.25,0,0,1,.34,1.52h-2.2a1.84,1.84,0,0,0-.2-.76,1.28,1.28,0,0,0-.44-.48,1.77,1.77,0,0,0-.63-.25,4.59,4.59,0,0,0-.77-.07,2.51,2.51,0,0,0-.55.06,1.59,1.59,0,0,0-.5.2,1.27,1.27,0,0,0-.37.36,1,1,0,0,0-.15.55.93.93,0,0,0,.12.5,1.06,1.06,0,0,0,.45.34,5.19,5.19,0,0,0,.95.32l1.57.41c.19,0,.46.11.8.21a3.92,3.92,0,0,1,1,.48,3.11,3.11,0,0,1,.88.89,2.59,2.59,0,0,1,.36,1.43,3.17,3.17,0,0,1-.27,1.31,2.78,2.78,0,0,1-.82,1,3.77,3.77,0,0,1-1.34.69,6.2,6.2,0,0,1-1.86.25,6.51,6.51,0,0,1-1.66-.21,4.12,4.12,0,0,1-1.42-.66,3.27,3.27,0,0,1-1-1.14,3.46,3.46,0,0,1-.34-1.65h2.2A2,2,0,0,0,437.59,1104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M445.08,1101.14a3.45,3.45,0,0,1,.78-1.24,3.6,3.6,0,0,1,1.21-.8,4.71,4.71,0,0,1,3.2,0,3.64,3.64,0,0,1,1.22.8,3.45,3.45,0,0,1,.78,1.24,4.68,4.68,0,0,1,.27,1.63,4.59,4.59,0,0,1-.27,1.62,3.36,3.36,0,0,1-.78,1.24,3.47,3.47,0,0,1-1.22.79,4.88,4.88,0,0,1-3.2,0,3.43,3.43,0,0,1-1.21-.79,3.36,3.36,0,0,1-.78-1.24,4.37,4.37,0,0,1-.28-1.62A4.46,4.46,0,0,1,445.08,1101.14Zm1.86,2.51a2.32,2.32,0,0,0,.3.76,1.64,1.64,0,0,0,.56.55,1.75,1.75,0,0,0,.87.2,1.8,1.8,0,0,0,.87-.2,1.58,1.58,0,0,0,.57-.55,2.31,2.31,0,0,0,.29-.76,4.35,4.35,0,0,0,0-1.76,2.28,2.28,0,0,0-.29-.77,1.64,1.64,0,0,0-.57-.54,1.7,1.7,0,0,0-.87-.21,1.65,1.65,0,0,0-.87.21,1.7,1.7,0,0,0-.56.54,2.29,2.29,0,0,0-.3.77,4.88,4.88,0,0,0,0,1.76Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M458.94,1106.51v-1h0a2.28,2.28,0,0,1-1,.94,2.92,2.92,0,0,1-1.28.29,3.68,3.68,0,0,1-1.35-.21,2,2,0,0,1-.83-.62,2.27,2.27,0,0,1-.43-1,6.16,6.16,0,0,1-.12-1.27V1099h2.05v4.23a2.63,2.63,0,0,0,.29,1.38,1.11,1.11,0,0,0,1,.46,1.42,1.42,0,0,0,1.21-.5,2.79,2.79,0,0,0,.38-1.64V1099h2.06v7.49Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M464.41,1099v1.39h0a2.81,2.81,0,0,1,.39-.64,2.51,2.51,0,0,1,.57-.51,2.49,2.49,0,0,1,.68-.32,2.36,2.36,0,0,1,.75-.12,1.6,1.6,0,0,1,.45.07v1.91l-.35,0-.39,0a2.16,2.16,0,0,0-1,.19,1.57,1.57,0,0,0-.62.51,2,2,0,0,0-.34.76,4,4,0,0,0-.11.94v3.38h-2.05V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M471.4,1100.37a1.44,1.44,0,0,0-.82.22,1.87,1.87,0,0,0-.54.58,2.63,2.63,0,0,0-.3.78,4.17,4.17,0,0,0-.09.85,3.81,3.81,0,0,0,.09.82,2.45,2.45,0,0,0,.28.76,1.58,1.58,0,0,0,.53.56,1.4,1.4,0,0,0,.81.22,1.54,1.54,0,0,0,1.14-.41,2,2,0,0,0,.5-1.11h2a3.4,3.4,0,0,1-1.16,2.27,3.75,3.75,0,0,1-2.45.78,4.06,4.06,0,0,1-1.54-.28,3.49,3.49,0,0,1-1.19-.79,3.44,3.44,0,0,1-.77-1.21,4.22,4.22,0,0,1-.27-1.54,4.94,4.94,0,0,1,.25-1.61,3.59,3.59,0,0,1,.75-1.29,3.39,3.39,0,0,1,1.2-.85,4.11,4.11,0,0,1,1.62-.3,4.94,4.94,0,0,1,1.28.17,3.62,3.62,0,0,1,1.1.53,3,3,0,0,1,.79.89,2.69,2.69,0,0,1,.34,1.25h-2A1.38,1.38,0,0,0,471.4,1100.37Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M478.34,1104.71a2.1,2.1,0,0,0,2.4.14,1.32,1.32,0,0,0,.55-.66h1.81a3.49,3.49,0,0,1-1.33,1.93,4,4,0,0,1-2.17.57,4.3,4.3,0,0,1-1.6-.28,3.33,3.33,0,0,1-1.2-.8,3.59,3.59,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.7,3.7,0,0,1,1.21-.84,3.94,3.94,0,0,1,1.56-.3,3.5,3.5,0,0,1,1.66.37,3.38,3.38,0,0,1,1.16,1,3.94,3.94,0,0,1,.66,1.42,5.07,5.07,0,0,1,.15,1.66h-5.4A2.08,2.08,0,0,0,478.34,1104.71Zm2.32-3.94a1.45,1.45,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.44,1.44,0,0,0-.52.41,1.65,1.65,0,0,0-.28.52,2.57,2.57,0,0,0-.09.49h3.34A2.3,2.3,0,0,0,480.66,1100.77Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M483.85,1098.08v-1.91h8.47v1.91h-3.1v8.43H487v-8.43Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M497,1106.51v-1h0a2.25,2.25,0,0,1-1,.94,2.87,2.87,0,0,1-1.27.29,3.68,3.68,0,0,1-1.35-.21,2,2,0,0,1-.83-.62,2.27,2.27,0,0,1-.43-1,6.16,6.16,0,0,1-.12-1.27V1099H494v4.23a2.63,2.63,0,0,0,.29,1.38,1.11,1.11,0,0,0,1,.46,1.42,1.42,0,0,0,1.21-.5,2.79,2.79,0,0,0,.38-1.64V1099H499v7.49Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M502.49,1099v1h.05a2.28,2.28,0,0,1,1-.94,2.89,2.89,0,0,1,1.27-.3,3.76,3.76,0,0,1,1.36.22,2,2,0,0,1,.83.63,2.39,2.39,0,0,1,.42,1,5.48,5.48,0,0,1,.13,1.26v4.61H505.5v-4.23a2.53,2.53,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M509.18,1097.87v-1.7h2.05v1.7Zm2.05,1.15v7.49h-2.05V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M514.81,1099v1h0a2.3,2.3,0,0,1,1-.94,2.85,2.85,0,0,1,1.27-.3,3.66,3.66,0,0,1,1.35.22,2,2,0,0,1,.83.63,2.27,2.27,0,0,1,.43,1,6.13,6.13,0,0,1,.12,1.26v4.61h-2v-4.23a2.63,2.63,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.46,1.42,1.42,0,0,0-1.21.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2.06V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M528.51,1107.06a2.5,2.5,0,0,1-.53,1.07,3.06,3.06,0,0,1-1.17.85,5.13,5.13,0,0,1-2.06.35,4.84,4.84,0,0,1-1.12-.14,3.31,3.31,0,0,1-1-.43,2.4,2.4,0,0,1-.78-.74,2.17,2.17,0,0,1-.36-1.09h2a1.2,1.2,0,0,0,.57.8,2,2,0,0,0,1,.22,1.46,1.46,0,0,0,1.26-.52,2,2,0,0,0,.39-1.31v-1h0a2,2,0,0,1-.95.88,3.38,3.38,0,0,1-2.74,0,2.75,2.75,0,0,1-1-.81,3.34,3.34,0,0,1-.57-1.2,5.7,5.7,0,0,1-.18-1.45,4.65,4.65,0,0,1,.21-1.4,3.62,3.62,0,0,1,.61-1.19,3.12,3.12,0,0,1,1-.82,3,3,0,0,1,1.38-.3,2.92,2.92,0,0,1,1.29.27,2.33,2.33,0,0,1,.94.93h0v-1h2v7A5.18,5.18,0,0,1,528.51,1107.06Zm-2.82-2.49a1.57,1.57,0,0,0,.55-.47,1.86,1.86,0,0,0,.33-.66,2.76,2.76,0,0,0,.11-.77,4.17,4.17,0,0,0-.09-.88,2.23,2.23,0,0,0-.29-.73,1.37,1.37,0,0,0-.54-.5,1.62,1.62,0,0,0-.83-.19,1.6,1.6,0,0,0-.75.17,1.67,1.67,0,0,0-.52.47,2.22,2.22,0,0,0-.31.68,3.59,3.59,0,0,0-.09.81,4.34,4.34,0,0,0,.08.8,2.16,2.16,0,0,0,.28.72,1.69,1.69,0,0,0,.51.52,1.51,1.51,0,0,0,.8.2A1.7,1.7,0,0,0,525.69,1104.57Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M536.79,1096.17v10.34h-2.27v-10.34Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M540.53,1099v1h0a2.33,2.33,0,0,1,1-.94,2.93,2.93,0,0,1,1.28-.3,3.66,3.66,0,0,1,1.35.22,2,2,0,0,1,.83.63,2.27,2.27,0,0,1,.43,1,6.13,6.13,0,0,1,.12,1.26v4.61h-2.05v-4.23a2.63,2.63,0,0,0-.29-1.38,1.11,1.11,0,0,0-1-.46,1.45,1.45,0,0,0-1.22.5,2.78,2.78,0,0,0-.37,1.64v3.93h-2.06V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M551.27,1099v1.38h-1.51v3.7a1,1,0,0,0,.18.7,1,1,0,0,0,.69.17H551l.31,0v1.6l-.58,0-.62,0a5.33,5.33,0,0,1-.91-.07,2.14,2.14,0,0,1-.75-.25,1.35,1.35,0,0,1-.52-.54,2,2,0,0,1-.18-.91v-4.41h-1.25V1099h1.25v-2.24h2V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M554.45,1104.71a1.83,1.83,0,0,0,1.33.45,1.8,1.8,0,0,0,1.07-.31,1.27,1.27,0,0,0,.55-.66h1.81a3.45,3.45,0,0,1-1.33,1.93,4,4,0,0,1-2.17.57,4.21,4.21,0,0,1-1.59-.28,3.16,3.16,0,0,1-1.2-.8,3.44,3.44,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.41,4.41,0,0,1,.27-1.56A3.75,3.75,0,0,1,553,1100a3.66,3.66,0,0,1,1.2-.84,3.94,3.94,0,0,1,1.56-.3,3.48,3.48,0,0,1,1.66.37,3.33,3.33,0,0,1,1.17,1,4.3,4.3,0,0,1,.66,1.42,5.36,5.36,0,0,1,.14,1.66h-5.4A2.08,2.08,0,0,0,554.45,1104.71Zm2.32-3.94a1.43,1.43,0,0,0-1.12-.4,1.69,1.69,0,0,0-.82.17,1.53,1.53,0,0,0-.52.41,1.44,1.44,0,0,0-.27.52,2,2,0,0,0-.1.49h3.35A2.39,2.39,0,0,0,556.77,1100.77Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M562.51,1099v1h0a2.28,2.28,0,0,1,1-.94,2.89,2.89,0,0,1,1.27-.3,3.76,3.76,0,0,1,1.36.22,2,2,0,0,1,.83.63,2.54,2.54,0,0,1,.43,1,6.13,6.13,0,0,1,.12,1.26v4.61h-2.06v-4.23a2.53,2.53,0,0,0-.29-1.38,1.1,1.1,0,0,0-1-.46,1.44,1.44,0,0,0-1.22.5,2.77,2.77,0,0,0-.38,1.64v3.93h-2.05V1099Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M570.87,1104.65a1.31,1.31,0,0,0,.35.39,1.73,1.73,0,0,0,.5.22,2.32,2.32,0,0,0,.59.07,1.85,1.85,0,0,0,.46,0,1.23,1.23,0,0,0,.43-.16.91.91,0,0,0,.33-.29.8.8,0,0,0,.13-.45c0-.31-.21-.54-.62-.7a14,14,0,0,0-1.71-.46c-.3-.07-.59-.15-.88-.24a3,3,0,0,1-.76-.36,1.7,1.7,0,0,1-.53-.57,1.53,1.53,0,0,1-.21-.83,2.29,2.29,0,0,1,.28-1.18,2.13,2.13,0,0,1,.75-.74,3.47,3.47,0,0,1,1-.37,6.67,6.67,0,0,1,1.19-.11,5.76,5.76,0,0,1,1.18.12,2.85,2.85,0,0,1,1,.39,2.15,2.15,0,0,1,.74.73,2.44,2.44,0,0,1,.36,1.15h-2a.88.88,0,0,0-.45-.8,2.06,2.06,0,0,0-1-.21,2.62,2.62,0,0,0-.38,0,1.06,1.06,0,0,0-.36.09.78.78,0,0,0-.29.21.6.6,0,0,0-.11.37.59.59,0,0,0,.2.45,1.65,1.65,0,0,0,.53.28c.22.07.46.14.74.2l.86.18a8,8,0,0,1,.87.25,2.85,2.85,0,0,1,.76.38,1.83,1.83,0,0,1,.54.6,1.78,1.78,0,0,1,.2.87,2.47,2.47,0,0,1-.29,1.24,2.39,2.39,0,0,1-.78.8,3,3,0,0,1-1.09.43,6.54,6.54,0,0,1-1.25.12,6,6,0,0,1-1.28-.13,3.24,3.24,0,0,1-1.1-.43,2.45,2.45,0,0,1-.8-.8,2.53,2.53,0,0,1-.34-1.26h2A1.16,1.16,0,0,0,570.87,1104.65Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M579.11,1104.71a2.12,2.12,0,0,0,2.41.14,1.37,1.37,0,0,0,.55-.66h1.81a3.51,3.51,0,0,1-1.34,1.93,4,4,0,0,1-2.17.57,4.21,4.21,0,0,1-1.59-.28,3.33,3.33,0,0,1-1.2-.8,3.59,3.59,0,0,1-.76-1.25,4.5,4.5,0,0,1-.27-1.59,4.2,4.2,0,0,1,.28-1.56,3.58,3.58,0,0,1,.78-1.25,3.7,3.7,0,0,1,1.21-.84,3.86,3.86,0,0,1,1.55-.3,3.54,3.54,0,0,1,1.67.37,3.38,3.38,0,0,1,1.16,1,4.11,4.11,0,0,1,.66,1.42,5.07,5.07,0,0,1,.14,1.66h-5.39A2.08,2.08,0,0,0,579.11,1104.71Zm2.33-3.94a1.46,1.46,0,0,0-1.13-.4,1.65,1.65,0,0,0-.81.17,1.44,1.44,0,0,0-.52.41,1.47,1.47,0,0,0-.28.52,2.57,2.57,0,0,0-.09.49H582A2.48,2.48,0,0,0,581.44,1100.77Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M587.59,1104.28v2.23a2.26,2.26,0,0,1-.18.9,2.39,2.39,0,0,1-.48.72,2.5,2.5,0,0,1-.72.5,2.83,2.83,0,0,1-.87.26v-1a1.32,1.32,0,0,0,.41-.17,1.67,1.67,0,0,0,.34-.31,1.25,1.25,0,0,0,.22-.41,1.38,1.38,0,0,0,.05-.45h-1v-2.23Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M595.76,1096.17v8.42h5v1.92h-7.31v-10.34Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M604.34,1096.17v10.34h-2.27v-10.34Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M608.58,1106.51l-3.34-10.34h2.33l2.3,7.27h0l2.33-7.27h2.34l-3.43,10.34Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M623.18,1096.17v1.91h-5.45v2.22h5v1.76h-5v2.53h5.57v1.92h-7.84v-10.34Z"
          transform="translate(-62.75 -75.53)"
        />
      </g>
    </g>
    <g id="">
      <line
        class="b2be1c92-1e94-435a-af47-91f31698e216"
        x1="1043.63"
        y1="19"
        x2="1043.63"
        y2="31.5"
      />
      <line
        class="e3fa05c6-3c9a-48f0-96e5-c1a58cab248b"
        x1="1043.63"
        y1="56.7"
        x2="1043.63"
        y2="951.4"
      />
      <line
        class="b2be1c92-1e94-435a-af47-91f31698e216"
        x1="1043.63"
        y1="964"
        x2="1043.63"
        y2="976.5"
      />
    </g>
    <g id="">
      <g>
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1229.59,104v10.35l9.76-10.35h6.86l-9.76,9.86L1247.16,129h-6.9l-7.52-11.24-3.15,3.18V129h-5.5V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1267.74,127.12c-1.9,1.64-4.51,2.46-7.85,2.46s-6-.81-7.85-2.45-2.79-4.15-2.79-7.56V104h5.5v15.53a12,12,0,0,0,.17,2,4.14,4.14,0,0,0,.74,1.73,4.07,4.07,0,0,0,1.56,1.23,6.39,6.39,0,0,0,2.67.47c2,0,3.32-.44,4.07-1.31a6.32,6.32,0,0,0,1.12-4.12V104h5.49v15.53C1270.57,123,1269.62,125.47,1267.74,127.12Zm-9.07-30v4.09H1254V97.14Zm7.21,0v4.09h-4.72V97.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1280.75,104l10.43,16.76h.07V104h5.15v25h-5.5l-10.39-16.74h-.07V129h-5.15V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1305.43,122.87a4,4,0,0,0,1.21,1.41,5.33,5.33,0,0,0,1.77.81,8.49,8.49,0,0,0,2.09.26,11.13,11.13,0,0,0,1.58-.12,5.27,5.27,0,0,0,1.58-.48,3.47,3.47,0,0,0,1.22-1,2.39,2.39,0,0,0,.49-1.56,2.2,2.2,0,0,0-.65-1.64,5.18,5.18,0,0,0-1.69-1,18.36,18.36,0,0,0-2.39-.74c-.88-.21-1.78-.44-2.69-.69a25.45,25.45,0,0,1-2.73-.86,9.58,9.58,0,0,1-2.38-1.31,6.18,6.18,0,0,1-1.7-2,6.12,6.12,0,0,1-.64-2.92,6.51,6.51,0,0,1,.82-3.34,7.4,7.4,0,0,1,2.15-2.37,9.78,9.78,0,0,1,3-1.4,12.94,12.94,0,0,1,3.36-.45,15.93,15.93,0,0,1,3.76.44,9.38,9.38,0,0,1,3.2,1.41,7.18,7.18,0,0,1,2.23,2.51,7.62,7.62,0,0,1,.82,3.69h-5.32a4.46,4.46,0,0,0-.47-1.86,3.12,3.12,0,0,0-1.07-1.15,4.56,4.56,0,0,0-1.52-.6,10,10,0,0,0-1.88-.17,6.76,6.76,0,0,0-1.33.14,3.53,3.53,0,0,0-1.2.49,3.08,3.08,0,0,0-.89.87,2.32,2.32,0,0,0-.36,1.33,2.28,2.28,0,0,0,.28,1.19,2.44,2.44,0,0,0,1.11.84,13.09,13.09,0,0,0,2.27.77c1,.26,2.24.59,3.8,1,.47.1,1.11.27,1.94.51a9.72,9.72,0,0,1,2.47,1.17,7.53,7.53,0,0,1,2.12,2.16,6.18,6.18,0,0,1,.89,3.45,7.67,7.67,0,0,1-.66,3.18,7,7,0,0,1-2,2.54,9.62,9.62,0,0,1-3.26,1.66,15.25,15.25,0,0,1-4.5.59,15.66,15.66,0,0,1-4-.5,9.94,9.94,0,0,1-3.43-1.6,7.8,7.8,0,0,1-2.36-2.76,8.1,8.1,0,0,1-.84-4H1305A4.67,4.67,0,0,0,1305.43,122.87Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1322,108.65V104h20.48v4.61H1335V129h-5.5V108.65Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1350.82,104V124.4H1363V129h-17.67V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1384.77,104v4.61h-13.19V114h12.11v4.27h-12.11v6.12h13.47V129h-19V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1402.24,104a8.1,8.1,0,0,1,3,.54,7.26,7.26,0,0,1,2.31,1.48,6.6,6.6,0,0,1,1.47,2.19,7.18,7.18,0,0,1,.5,2.68,7.63,7.63,0,0,1-.92,3.82,5.9,5.9,0,0,1-3,2.45v.07a4.23,4.23,0,0,1,1.68.85,4.82,4.82,0,0,1,1.08,1.37,6.34,6.34,0,0,1,.62,1.73,17.51,17.51,0,0,1,.26,1.89c0,.4,0,.86.07,1.4s.06,1.08.12,1.64a11.1,11.1,0,0,0,.28,1.6,3.76,3.76,0,0,0,.58,1.28h-5.5a9.86,9.86,0,0,1-.56-2.84c-.07-1.1-.17-2.15-.31-3.15a5,5,0,0,0-1.19-2.87,4.09,4.09,0,0,0-3-.91h-5.49V129h-5.5V104Zm-2,11.3a4.14,4.14,0,0,0,2.83-.84,3.49,3.49,0,0,0,1-2.73,3.33,3.33,0,0,0-1-2.64,4.19,4.19,0,0,0-2.83-.83h-6v7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1425.3,104a10.27,10.27,0,0,1,4,.68,7.35,7.35,0,0,1,2.68,1.8,7,7,0,0,1,1.51,2.55,9.62,9.62,0,0,1,0,5.94,7,7,0,0,1-1.51,2.57,7.35,7.35,0,0,1-2.68,1.8,10.27,10.27,0,0,1-4,.68h-5.77v9H1414V104Zm-1.5,11.75a10.82,10.82,0,0,0,1.82-.14,4.21,4.21,0,0,0,1.54-.54,3,3,0,0,0,1.07-1.14,4.09,4.09,0,0,0,.4-1.92,4.16,4.16,0,0,0-.4-1.93,3,3,0,0,0-1.07-1.13,4.21,4.21,0,0,0-1.54-.54,10.82,10.82,0,0,0-1.82-.15h-4.27v7.49Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1456.07,104v4.61h-13.2V114H1455v4.27h-12.11v6.12h13.48V129h-19V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1473.53,104a8.1,8.1,0,0,1,3,.54,7,7,0,0,1,2.31,1.48,6.6,6.6,0,0,1,1.47,2.19,7,7,0,0,1,.51,2.68,7.53,7.53,0,0,1-.93,3.82,5.9,5.9,0,0,1-3,2.45v.07a4.16,4.16,0,0,1,1.68.85,4.68,4.68,0,0,1,1.09,1.37,6.33,6.33,0,0,1,.61,1.73,13.62,13.62,0,0,1,.26,1.89c0,.4,0,.86.07,1.4s.07,1.08.12,1.64a12.67,12.67,0,0,0,.28,1.6,4,4,0,0,0,.58,1.28h-5.49a9.53,9.53,0,0,1-.56-2.84c-.07-1.1-.18-2.15-.32-3.15a4.9,4.9,0,0,0-1.19-2.87,4.06,4.06,0,0,0-3-.91h-5.5V129h-5.49V104Zm-2,11.3a4.17,4.17,0,0,0,2.84-.84,3.49,3.49,0,0,0,.94-2.73,3.33,3.33,0,0,0-.94-2.64,4.22,4.22,0,0,0-2.84-.83h-6v7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1489.53,122.87a3.93,3.93,0,0,0,1.2,1.41,5.52,5.52,0,0,0,1.77.81,8.63,8.63,0,0,0,2.1.26,11.24,11.24,0,0,0,1.58-.12,5.21,5.21,0,0,0,1.57-.48,3.42,3.42,0,0,0,1.23-1,2.44,2.44,0,0,0,.49-1.56,2.2,2.2,0,0,0-.65-1.64,5.13,5.13,0,0,0-1.7-1,18.19,18.19,0,0,0-2.38-.74c-.89-.21-1.78-.44-2.69-.69a24.1,24.1,0,0,1-2.73-.86,9.58,9.58,0,0,1-2.38-1.31,6.18,6.18,0,0,1-1.7-2,6.11,6.11,0,0,1-.65-2.92,6.61,6.61,0,0,1,.82-3.34,7.43,7.43,0,0,1,2.16-2.37,9.68,9.68,0,0,1,3-1.4,12.94,12.94,0,0,1,3.36-.45,16,16,0,0,1,3.76.44,9.38,9.38,0,0,1,3.2,1.41,7.15,7.15,0,0,1,2.22,2.51,7.62,7.62,0,0,1,.83,3.69h-5.32a4.45,4.45,0,0,0-.48-1.86,3,3,0,0,0-1.06-1.15,4.62,4.62,0,0,0-1.53-.6,9.84,9.84,0,0,0-1.87-.17,6.68,6.68,0,0,0-1.33.14,3.67,3.67,0,0,0-1.21.49,3.21,3.21,0,0,0-.89.87,2.39,2.39,0,0,0-.35,1.33,2.18,2.18,0,0,0,.28,1.19,2.46,2.46,0,0,0,1.1.84,13.24,13.24,0,0,0,2.28.77c1,.26,2.23.59,3.8,1,.46.1,1.11.27,1.94.51a9.72,9.72,0,0,1,2.47,1.17,7.5,7.5,0,0,1,2.11,2.16,6.1,6.1,0,0,1,.9,3.45,7.68,7.68,0,0,1-.67,3.18,6.83,6.83,0,0,1-2,2.54,9.57,9.57,0,0,1-3.25,1.66,15.34,15.34,0,0,1-4.5.59,15.74,15.74,0,0,1-4-.5,10.12,10.12,0,0,1-3.43-1.6,7.8,7.8,0,0,1-2.36-2.76,8.33,8.33,0,0,1-.84-4h5.32A4.54,4.54,0,0,0,1489.53,122.87Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1507.8,111.44a12.59,12.59,0,0,1,2.41-4.19,10.93,10.93,0,0,1,3.87-2.79,13.61,13.61,0,0,1,10.34,0,10.94,10.94,0,0,1,3.85,2.79,12.61,12.61,0,0,1,2.42,4.19,15.61,15.61,0,0,1,.84,5.19,15.12,15.12,0,0,1-.84,5.1,12.24,12.24,0,0,1-2.42,4.11,11.1,11.1,0,0,1-3.85,2.75,14,14,0,0,1-10.34,0,11.08,11.08,0,0,1-3.87-2.75,12.22,12.22,0,0,1-2.41-4.11,15.12,15.12,0,0,1-.84-5.1A15.61,15.61,0,0,1,1507.8,111.44Zm5,8.26a8.19,8.19,0,0,0,1.17,2.68,6.1,6.1,0,0,0,2.1,1.9,7.25,7.25,0,0,0,6.3,0,6.1,6.1,0,0,0,2.1-1.9,8.19,8.19,0,0,0,1.17-2.68,12.47,12.47,0,0,0,.37-3.07,13.43,13.43,0,0,0-.37-3.18,8.28,8.28,0,0,0-1.17-2.75,6.05,6.05,0,0,0-2.1-1.92,7.25,7.25,0,0,0-6.3,0,6.05,6.05,0,0,0-2.1,1.92,8.28,8.28,0,0,0-1.17,2.75,13.43,13.43,0,0,0-.37,3.18A12.47,12.47,0,0,0,1512.82,119.7Zm5.2-22.56v4.09h-4.73V97.14Zm7.21,0v4.09h-4.73V97.14Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1540.73,104l10.43,16.76h.07V104h5.15v25h-5.5l-10.39-16.74h-.07V129h-5.15V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1566.7,104V124.4h12.18V129h-17.67V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1587.46,104v25H1582V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1608.32,110.7a5.75,5.75,0,0,0-1.23-1.38,5.51,5.51,0,0,0-1.66-.93,5.77,5.77,0,0,0-1.94-.33,6.38,6.38,0,0,0-3.15.72,5.88,5.88,0,0,0-2.1,1.92,8.51,8.51,0,0,0-1.18,2.75,14,14,0,0,0-.36,3.18,13,13,0,0,0,.36,3.07,8.42,8.42,0,0,0,1.18,2.68,5.92,5.92,0,0,0,2.1,1.9,6.38,6.38,0,0,0,3.15.72,5,5,0,0,0,3.93-1.54,7.1,7.1,0,0,0,1.74-4.06h5.32a12.71,12.71,0,0,1-1.09,4.23,10.12,10.12,0,0,1-2.31,3.23,9.86,9.86,0,0,1-3.36,2,12.19,12.19,0,0,1-4.23.7,12.92,12.92,0,0,1-5.17-1,11,11,0,0,1-3.86-2.75,12.24,12.24,0,0,1-2.42-4.11,15.12,15.12,0,0,1-.84-5.1,15.61,15.61,0,0,1,.84-5.19,12.61,12.61,0,0,1,2.42-4.19,10.89,10.89,0,0,1,3.86-2.79,13.33,13.33,0,0,1,9.07-.42,10.46,10.46,0,0,1,3.29,1.73,9.34,9.34,0,0,1,2.4,2.81,10.28,10.28,0,0,1,1.19,3.86H1609A4.72,4.72,0,0,0,1608.32,110.7Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1623.72,104v9.58h10.11V104h5.5v25h-5.5V118.25h-10.11V129h-5.5V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1649.65,104v10.35l9.77-10.35h6.86l-9.77,9.86L1667.22,129h-6.89l-7.53-11.24-3.15,3.18V129h-5.49V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1688.12,104v4.61h-13.2V114H1687v4.27h-12.11v6.12h13.48V129h-19V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1697.6,104v25h-5.49V104Z"
          transform="translate(-62.75 -75.53)"
        />
        <path
          class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
          d="M1700.47,108.65V104H1721v4.61h-7.49V129H1708V108.65Z"
          transform="translate(-62.75 -75.53)"
        />
      </g>
      <g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="1454.85"
            y1="550.51"
            x2="1454.85"
            y2="586.44"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="1454.85 605.5 1443.87 578.64 1454.85 585.02 1465.82 578.64 1454.85 605.5"
          />
        </g>
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M163.85,102.14a7.87,7.87,0,0,1,3,.55,6.89,6.89,0,0,1,2.31,1.48,6.5,6.5,0,0,1,1.47,2.19,7,7,0,0,1,.51,2.68,7.47,7.47,0,0,1-.93,3.81,5.77,5.77,0,0,1-3,2.45v.07a4.42,4.42,0,0,1,1.69.86A4.82,4.82,0,0,1,170,117.6a6.33,6.33,0,0,1,.61,1.73,13.62,13.62,0,0,1,.26,1.89c0,.4.05.86.07,1.4s.07,1.08.13,1.64a11.1,11.1,0,0,0,.28,1.6,3.63,3.63,0,0,0,.58,1.27h-5.5a9.72,9.72,0,0,1-.56-2.83c-.07-1.1-.18-2.15-.31-3.15a5,5,0,0,0-1.2-2.87,4,4,0,0,0-3-.91h-5.49v9.76h-5.5v-25Zm-1.95,11.31a4.14,4.14,0,0,0,2.83-.84,3.49,3.49,0,0,0,.94-2.73,3.33,3.33,0,0,0-.94-2.64,4.19,4.19,0,0,0-2.83-.83h-6v7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M175.4,109.55a12.51,12.51,0,0,1,2.42-4.19,11.08,11.08,0,0,1,3.87-2.8,13.74,13.74,0,0,1,10.34,0,11.09,11.09,0,0,1,3.85,2.8,12.49,12.49,0,0,1,2.41,4.19,15.56,15.56,0,0,1,.84,5.19,15.16,15.16,0,0,1-.84,5.1,12.22,12.22,0,0,1-2.41,4.11A11.1,11.1,0,0,1,192,126.7a14,14,0,0,1-10.34,0,11.08,11.08,0,0,1-3.87-2.75,12.24,12.24,0,0,1-2.42-4.11,15.16,15.16,0,0,1-.84-5.1A15.56,15.56,0,0,1,175.4,109.55Zm5,8.26a8,8,0,0,0,1.17,2.67,6,6,0,0,0,2.1,1.91,7.25,7.25,0,0,0,6.3,0,6,6,0,0,0,2.1-1.91,8,8,0,0,0,1.17-2.67,12.59,12.59,0,0,0,.37-3.07,13.43,13.43,0,0,0-.37-3.18,8.28,8.28,0,0,0-1.17-2.75,6.05,6.05,0,0,0-2.1-1.92,7.25,7.25,0,0,0-6.3,0,6.05,6.05,0,0,0-2.1,1.92,8.28,8.28,0,0,0-1.17,2.75,13.43,13.43,0,0,0-.37,3.18A12.59,12.59,0,0,0,180.43,117.81Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M208.37,102.14v20.37h12.18v4.62H202.88v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M229.13,102.14v20.37h12.18v4.62H223.63v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M263.08,102.14v4.62h-13.2v5.36H262v4.27H249.88v6.12h13.48v4.62h-19v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M272.53,102.14,283,118.91H283V102.14h5.14v25h-5.49l-10.4-16.73h-.07v16.73h-5.14v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M303,115.44v4.27H292.44v-4.27Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M327.6,126.85a9.55,9.55,0,0,1-3.83.74,10,10,0,0,1-3.17-.49,7.28,7.28,0,0,1-2.57-1.45,7,7,0,0,1-1.72-2.35,7.57,7.57,0,0,1-.63-3.17,6.22,6.22,0,0,1,.46-2.43,7.08,7.08,0,0,1,1.24-2,10.2,10.2,0,0,1,1.8-1.6,17.09,17.09,0,0,1,2.1-1.22,16.32,16.32,0,0,1-1.69-2.45,5.75,5.75,0,0,1-.69-2.87,5,5,0,0,1,.55-2.35,5.62,5.62,0,0,1,1.45-1.8,6.58,6.58,0,0,1,2.08-1.15,7.38,7.38,0,0,1,2.43-.41,8.9,8.9,0,0,1,2.68.39,6.34,6.34,0,0,1,2.17,1.14,5.32,5.32,0,0,1,1.47,1.87,5.91,5.91,0,0,1,.54,2.59,5.53,5.53,0,0,1-1.27,3.71,11,11,0,0,1-3.17,2.52l3.22,4a7,7,0,0,0,.59-1.45,11.41,11.41,0,0,0,.32-1.52h4.3a12.52,12.52,0,0,1-.77,3.25,10.73,10.73,0,0,1-1.64,2.91l5.07,5.91h-6.09l-2-2.48A10.61,10.61,0,0,1,327.6,126.85Zm-5-10.08a5.51,5.51,0,0,0-1.12.91,4.46,4.46,0,0,0-.78,1.16,3.23,3.23,0,0,0-.3,1.4,2.9,2.9,0,0,0,.3,1.28,3.39,3.39,0,0,0,.78,1,4.11,4.11,0,0,0,1.14.7,3.64,3.64,0,0,0,1.35.26,4.47,4.47,0,0,0,2.33-.61,8.61,8.61,0,0,0,1.87-1.6l-4.27-5.21A10.57,10.57,0,0,0,322.61,116.77Zm4.64-10.88a2.14,2.14,0,0,0-1.59-.7,2.26,2.26,0,0,0-1.58.61,2.07,2.07,0,0,0-.66,1.59,3.27,3.27,0,0,0,.63,2c.42.56.86,1.14,1.33,1.72a9.47,9.47,0,0,0,1.8-1.44,2.91,2.91,0,0,0,.75-2.13A2.31,2.31,0,0,0,327.25,105.89Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M354.88,121a4,4,0,0,0,1.21,1.42,5.33,5.33,0,0,0,1.77.81,8.57,8.57,0,0,0,2.1.26,11.1,11.1,0,0,0,1.57-.12,5.58,5.58,0,0,0,1.58-.48,3.3,3.3,0,0,0,1.22-1,2.41,2.41,0,0,0,.49-1.56,2.19,2.19,0,0,0-.64-1.64,5.24,5.24,0,0,0-1.7-1,17.2,17.2,0,0,0-2.38-.74c-.89-.21-1.79-.44-2.7-.7a23.33,23.33,0,0,1-2.73-.85,9.46,9.46,0,0,1-2.38-1.32,5.91,5.91,0,0,1-2.34-4.93,6.51,6.51,0,0,1,.82-3.34,7.52,7.52,0,0,1,2.15-2.37,9.56,9.56,0,0,1,3-1.4,12.94,12.94,0,0,1,3.36-.45,16.08,16.08,0,0,1,3.77.44,9.46,9.46,0,0,1,3.2,1.41,7.25,7.25,0,0,1,2.22,2.51,7.62,7.62,0,0,1,.82,3.69H364a4.31,4.31,0,0,0-.47-1.86,3,3,0,0,0-1.07-1.15,4.56,4.56,0,0,0-1.52-.6,9.92,9.92,0,0,0-1.87-.17,6.6,6.6,0,0,0-1.33.14,3.5,3.5,0,0,0-1.21.49,3,3,0,0,0-.89.87,2.31,2.31,0,0,0-.35,1.33,2.21,2.21,0,0,0,.28,1.19,2.35,2.35,0,0,0,1.1.84,13.24,13.24,0,0,0,2.28.77c1,.26,2.23.59,3.79,1q.7.15,1.95.51a9.66,9.66,0,0,1,2.46,1.17,7.41,7.41,0,0,1,2.12,2.16,6.06,6.06,0,0,1,.89,3.44,7.69,7.69,0,0,1-.66,3.19,6.91,6.91,0,0,1-2,2.54,9.57,9.57,0,0,1-3.25,1.66,15.34,15.34,0,0,1-4.5.59,16.2,16.2,0,0,1-4-.5,10,10,0,0,1-3.43-1.6,7.8,7.8,0,0,1-2.36-2.76,8.1,8.1,0,0,1-.84-4h5.32A4.59,4.59,0,0,0,354.88,121Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M385.19,106.76H372.84v-4.62h19.67v4.34l-13.41,16h13.76v4.62H371.79v-4.34Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M414.77,102.14v4.62h-13.2v5.36h12.11v4.27H401.57v6.12h13.48v4.62h-19v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M424.22,102.14l10.43,16.77h.07V102.14h5.14v25h-5.49L424,110.4h-.07v16.73h-5.14v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M463.38,102.14v4.62H450.19v5.36H462.3v4.27H450.19v6.12h13.47v4.62h-19v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M472.83,102.14l10.43,16.77h.07V102.14h5.15v25H483L472.59,110.4h-.07v16.73h-5.15v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M505.77,102.14l9.34,25h-5.7l-1.89-5.56h-9.35l-2,5.56h-5.53l9.45-25Zm.31,15.33-3.15-9.17h-.07l-3.25,9.17Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M530.76,102.14a7.82,7.82,0,0,1,3,.55,6.89,6.89,0,0,1,2.31,1.48,6.22,6.22,0,0,1,1.47,2.19,7,7,0,0,1,.51,2.68,7.47,7.47,0,0,1-.93,3.81,5.74,5.74,0,0,1-3,2.45v.07a4.45,4.45,0,0,1,1.68.86,4.82,4.82,0,0,1,1.08,1.37,6,6,0,0,1,.61,1.73,15.36,15.36,0,0,1,.27,1.89c0,.4,0,.86.07,1.4s.06,1.08.12,1.64a11.1,11.1,0,0,0,.28,1.6,3.63,3.63,0,0,0,.58,1.27h-5.5a9.72,9.72,0,0,1-.56-2.83c-.07-1.1-.17-2.15-.31-3.15a5,5,0,0,0-1.19-2.87,4.09,4.09,0,0,0-3-.91h-5.49v9.76h-5.5v-25Zm-2,11.31a4.14,4.14,0,0,0,2.83-.84,3.49,3.49,0,0,0,1-2.73,3.33,3.33,0,0,0-1-2.64,4.19,4.19,0,0,0-2.83-.83h-6v7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M554.31,102.14a15.6,15.6,0,0,1,3.26.32,7,7,0,0,1,2.52,1,4.79,4.79,0,0,1,1.62,1.91,6.61,6.61,0,0,1,.58,2.94,5.41,5.41,0,0,1-.86,3.15,6.13,6.13,0,0,1-2.53,2.06,6,6,0,0,1,3.44,2.33,6.93,6.93,0,0,1,1.14,4,6.86,6.86,0,0,1-.73,3.27,6.36,6.36,0,0,1-2,2.26,8.77,8.77,0,0,1-2.84,1.29,12.75,12.75,0,0,1-3.27.42H542.55v-25Zm-.7,10.12a3.94,3.94,0,0,0,2.42-.7,2.66,2.66,0,0,0,.94-2.28,2.89,2.89,0,0,0-.31-1.43,2.37,2.37,0,0,0-.84-.88,3.55,3.55,0,0,0-1.21-.43,8.24,8.24,0,0,0-1.42-.13h-5.14v5.85Zm.32,10.6a7.74,7.74,0,0,0,1.54-.15,3.73,3.73,0,0,0,1.29-.53,2.62,2.62,0,0,0,.89-1,3.31,3.31,0,0,0,.34-1.61,3.23,3.23,0,0,0-1.09-2.74A4.61,4.61,0,0,0,554,116h-6v6.86Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M585.88,102.14v4.62H572.69v5.36H584.8v4.27H572.69v6.12h13.47v4.62h-19v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M595.37,102.14v25h-5.5v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M598.24,106.76v-4.62h20.47v4.62h-7.49v20.37h-5.49V106.76Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
        <g>
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1015.61,862.42l-2.29,3.79h0a2.63,2.63,0,0,1,1.54-.23,4.1,4.1,0,0,1,1.51.55,3.38,3.38,0,0,1,1,.91,3.3,3.3,0,0,1,.56,1.31,4.1,4.1,0,0,1,0,1.67,6,6,0,0,1-.8,2,6.15,6.15,0,0,1-1.35,1.61,4.46,4.46,0,0,1-1.47.8,3.45,3.45,0,0,1-1.42.11,3.33,3.33,0,0,1-1.26-.45,4.47,4.47,0,0,1-1.33-1.16,2.17,2.17,0,0,1-.45-1.48l0,0-.58,1-2-1.19,6.28-10.38Zm-.11,7.74a2.73,2.73,0,0,0,.14-1,1.92,1.92,0,0,0-.22-.86,1.89,1.89,0,0,0-.67-.68,1.92,1.92,0,0,0-.91-.28,2,2,0,0,0-.87.2,2.87,2.87,0,0,0-.79.58,4.91,4.91,0,0,0-1.1,1.82,2.73,2.73,0,0,0-.14,1,1.88,1.88,0,0,0,.23.87,1.93,1.93,0,0,0,1.58,1,1.9,1.9,0,0,0,.86-.2,2.68,2.68,0,0,0,.79-.58,4.94,4.94,0,0,0,.65-.84A4.8,4.8,0,0,0,1015.5,870.16Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1022.82,870.7l-4.54,7.52-2.07-1.25,4.55-7.52Zm-1.36-2.42,1-1.7,2.06,1.25-1,1.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1022.78,878.38a1.26,1.26,0,0,0,.12.61,1.66,1.66,0,0,0,.36.53,2.62,2.62,0,0,0,.55.43,2.78,2.78,0,0,0,.49.23,2,2,0,0,0,.53.1,1.23,1.23,0,0,0,.5-.09.93.93,0,0,0,.41-.38.9.9,0,0,0-.2-1.07,14.23,14.23,0,0,0-1.44-1.51c-.26-.25-.5-.51-.73-.77a3.23,3.23,0,0,1-.55-.83,2,2,0,0,1-.19-.89,1.85,1.85,0,0,1,.3-1,2.42,2.42,0,0,1,2.19-1.31,4,4,0,0,1,1.28.26,7.3,7.3,0,0,1,1.26.61,7.22,7.22,0,0,1,1.12.83,3.56,3.56,0,0,1,.78,1,2.66,2.66,0,0,1,.31,1.18,3,3,0,0,1-.34,1.38l-2-1.19a1.05,1.05,0,0,0,0-1.08,2.34,2.34,0,0,0-.83-.79l-.39-.21a1.5,1.5,0,0,0-.43-.13.94.94,0,0,0-.41,0,.65.65,0,0,0-.34.3.63.63,0,0,0-.07.58,1.68,1.68,0,0,0,.36.6,6.26,6.26,0,0,0,.63.65c.24.23.49.46.74.71a8.88,8.88,0,0,1,.73.78,3.19,3.19,0,0,1,.53.84,2.14,2.14,0,0,1,.18.93,2.07,2.07,0,0,1-.33,1,2.81,2.81,0,0,1-1,1.06,2.7,2.7,0,0,1-1.26.34,3.85,3.85,0,0,1-1.36-.23,7.39,7.39,0,0,1-1.33-.64,7.81,7.81,0,0,1-1.21-.91,3.7,3.7,0,0,1-.84-1.11,2.83,2.83,0,0,1-.32-1.29,3.08,3.08,0,0,1,.42-1.46l2,1.19A1.22,1.22,0,0,0,1022.78,878.38Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1038.82,882.49l-3.62-2.19.94-1.56,6.34,3.83-.94,1.56-6.58,2,4.19,2.54-.94,1.55-6.91-4.17.94-1.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1044.41,894l.63-1,0,0a2.69,2.69,0,0,1-1.59.33,3.5,3.5,0,0,1-1.46-.48,4.07,4.07,0,0,1-1.22-1,2.24,2.24,0,0,1-.47-1.12,2.89,2.89,0,0,1,.16-1.24,7.5,7.5,0,0,1,.65-1.35l2.79-4.62,2.07,1.25-2.57,4.24a3.18,3.18,0,0,0-.55,1.57,1.33,1.33,0,0,0,.76,1.08,1.69,1.69,0,0,0,1.53.24,3.27,3.27,0,0,0,1.37-1.42l2.38-4,2.07,1.25-4.54,7.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1059.4,891.48l-1.8,2.07v.05a3.07,3.07,0,0,1,1.27-.06,3.51,3.51,0,0,1,1.2.48,3.6,3.6,0,0,1,1.17,1.08,3.52,3.52,0,0,1,.53,1.34,3.8,3.8,0,0,1,0,1.46,5.36,5.36,0,0,1-.6,1.48,4.3,4.3,0,0,1-1.19,1.28,4.52,4.52,0,0,1-1.53.67,4.41,4.41,0,0,1-1.66,0,4.6,4.6,0,0,1-1.61-.62,5.9,5.9,0,0,1-1.3-1.06,4.09,4.09,0,0,1-.84-1.33,3.55,3.55,0,0,1,.3-3.12l2.06,1.25a2,2,0,0,0-.15,1.43,2,2,0,0,0,.9,1.11,1.9,1.9,0,0,0,.84.28,2.06,2.06,0,0,0,.82-.11,2.09,2.09,0,0,0,.72-.41,2.7,2.7,0,0,0,.9-1.47,1.9,1.9,0,0,0,0-.82,2,2,0,0,0-.3-.75,2.24,2.24,0,0,0-.66-.59,2.18,2.18,0,0,0-1-.35,2.62,2.62,0,0,0-1,.22l-1.86-1.12,4.41-5,5.68,3.44-1,1.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1064.51,895.73a5.26,5.26,0,0,1,1.79-1.13,3.38,3.38,0,0,1,1.71-.16,4.66,4.66,0,0,1,1.59.6,4.6,4.6,0,0,1,1.29,1.14,3.44,3.44,0,0,1,.67,1.6,5.1,5.1,0,0,1-.17,2.11,10.18,10.18,0,0,1-1.21,2.68,10.54,10.54,0,0,1-1.84,2.37,5.18,5.18,0,0,1-1.8,1.14,3.53,3.53,0,0,1-1.72.16,4.75,4.75,0,0,1-1.61-.62,4.57,4.57,0,0,1-1.27-1.12,3.33,3.33,0,0,1-.66-1.59,5.41,5.41,0,0,1,.18-2.13,10.65,10.65,0,0,1,1.25-2.73A10.27,10.27,0,0,1,1064.51,895.73Zm-.35,4.64a6.62,6.62,0,0,0-.54,1.3,3,3,0,0,0-.1,1.28,1.42,1.42,0,0,0,.71,1,1.46,1.46,0,0,0,1.23.18,3,3,0,0,0,1.09-.68,6.45,6.45,0,0,0,.9-1.09c.27-.4.49-.74.67-1l.37-.64a8.56,8.56,0,0,0,.41-.8,8.18,8.18,0,0,0,.32-.87,2.92,2.92,0,0,0,.14-.86,1.87,1.87,0,0,0-.19-.78,1.4,1.4,0,0,0-.59-.62,1.5,1.5,0,0,0-.82-.24,1.74,1.74,0,0,0-.77.21,3.34,3.34,0,0,0-.7.52,8,8,0,0,0-.62.69c-.18.25-.35.49-.51.74l-.39.63C1064.6,899.59,1064.39,899.94,1064.16,900.37Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1084.69,904l-12.53,7.19-1.26-.77,12.55-7.17Zm-10.85-4a2.87,2.87,0,0,1,1-.65,2.46,2.46,0,0,1,1.15-.15,3,3,0,0,1,1.24.44,2.87,2.87,0,0,1,1,.92,2.39,2.39,0,0,1,.36,1.08,3.1,3.1,0,0,1-.17,1.18,6.53,6.53,0,0,1-.57,1.2,4.38,4.38,0,0,1-.81,1,2.7,2.7,0,0,1-1,.6,2.37,2.37,0,0,1-1.1.11,3.08,3.08,0,0,1-1.23-.47,3.16,3.16,0,0,1-1-.89,2.29,2.29,0,0,1-.39-1,2.63,2.63,0,0,1,.13-1.13A5.23,5.23,0,0,1,1073,901,4.74,4.74,0,0,1,1073.84,900Zm.34,2.49a2.47,2.47,0,0,0-.23.6,1.21,1.21,0,0,0,0,.6.88.88,0,0,0,.43.5,1,1,0,0,0,.64.15,1.33,1.33,0,0,0,.55-.26,2.47,2.47,0,0,0,.44-.47,5.34,5.34,0,0,0,.33-.5c.09-.14.19-.33.3-.54a3.44,3.44,0,0,0,.26-.67,1.49,1.49,0,0,0,0-.66.77.77,0,0,0-.38-.51.87.87,0,0,0-.66-.14,1.38,1.38,0,0,0-.58.29,2.18,2.18,0,0,0-.48.52c-.15.2-.27.39-.37.55Zm4.23,6.9a2.86,2.86,0,0,1,1-.61,2.37,2.37,0,0,1,1.13-.13,3,3,0,0,1,1.24.45,2.84,2.84,0,0,1,1,.91,2.29,2.29,0,0,1,.37,1.05,2.9,2.9,0,0,1-.15,1.15,5.57,5.57,0,0,1-.56,1.19,4.67,4.67,0,0,1-.81,1,3.05,3.05,0,0,1-1,.62,2.34,2.34,0,0,1-1.12.13,3.5,3.5,0,0,1-2.23-1.36,2.2,2.2,0,0,1-.38-1.05,3,3,0,0,1,.14-1.16,5.81,5.81,0,0,1,.56-1.19A4.67,4.67,0,0,1,1078.41,909.4Zm.35,2.48a2.75,2.75,0,0,0-.25.63,1.34,1.34,0,0,0,0,.63,1,1,0,0,0,1.07.65,1.4,1.4,0,0,0,.57-.29,2.39,2.39,0,0,0,.46-.49c.14-.19.25-.37.34-.52s.19-.32.29-.53a2.71,2.71,0,0,0,.24-.63,1.49,1.49,0,0,0,0-.63.75.75,0,0,0-.38-.5.93.93,0,0,0-.65-.16,1.28,1.28,0,0,0-.57.26,2.16,2.16,0,0,0-.46.49q-.21.3-.36.54C1079,911.49,1078.87,911.67,1078.76,911.88Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1101,914l-1.16,1.92-5.48-3.31-1.35,2.23,5,3-1.08,1.77-5-3-1.54,2.55,5.6,3.38-1.16,1.92-7.88-4.76,6.27-10.39Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1102.71,919l-.85,1.39,0,0a3.12,3.12,0,0,1,.79-.41,3.2,3.2,0,0,1,.87-.17,3.25,3.25,0,0,1,.88.09,2.85,2.85,0,0,1,.83.34,1.82,1.82,0,0,1,.41.35l-1.16,1.92-.32-.27-.38-.26a2.84,2.84,0,0,0-1.08-.39,2,2,0,0,0-.94.14,2.38,2.38,0,0,0-.8.56,4.77,4.77,0,0,0-.68.88l-2,3.39-2.06-1.25,4.54-7.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1108.41,922.4l-.62,1,0,0a3.36,3.36,0,0,1,1.53-.31,3.21,3.21,0,0,1,1.51.47,3.66,3.66,0,0,1,1.14,1.07,2.11,2.11,0,0,1,.3,1.51,3.81,3.81,0,0,1,1.44-.32,2.89,2.89,0,0,1,1.59.44,4.42,4.42,0,0,1,1,.82,2.66,2.66,0,0,1,.59,1,2.57,2.57,0,0,1,.06,1.18,3.82,3.82,0,0,1-.55,1.37l-3,5-2.07-1.25,2.58-4.26c.15-.25.29-.5.4-.73a1.67,1.67,0,0,0,.19-.68,1.08,1.08,0,0,0-.14-.63,1.87,1.87,0,0,0-.61-.56,1.63,1.63,0,0,0-.8-.26,1.61,1.61,0,0,0-.69.18,2,2,0,0,0-.57.49,5,5,0,0,0-.47.66l-2.53,4.19-2.07-1.25,2.55-4.21.39-.68a2.25,2.25,0,0,0,.24-.67,1.2,1.2,0,0,0-.1-.67,1.62,1.62,0,0,0-.65-.62,2.25,2.25,0,0,0-.42-.17,1.58,1.58,0,0,0-.62,0,1.72,1.72,0,0,0-.7.29,2.57,2.57,0,0,0-.7.8L1104,930l-2.06-1.25,4.54-7.53Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1119.19,930.43a2.74,2.74,0,0,1,1.28-.28,4.18,4.18,0,0,1,1.37.25,8,8,0,0,1,1.32.63,10,10,0,0,1,1.1.78,4.1,4.1,0,0,1,.87,1,2.44,2.44,0,0,1,.4,1.12,2.23,2.23,0,0,1-.36,1.29l-2.37,3.91a11,11,0,0,0-.53,1,2.09,2.09,0,0,0-.22.82l-2.09-1.26a3.31,3.31,0,0,1,.12-.42c.05-.13.11-.27.17-.4a3.17,3.17,0,0,1-1.59,0,5.28,5.28,0,0,1-1.49-.62,4.33,4.33,0,0,1-.93-.74,2.54,2.54,0,0,1-.57-.9,2.22,2.22,0,0,1-.12-1,2.84,2.84,0,0,1,.41-1.12,2.57,2.57,0,0,1,.86-.92,2.29,2.29,0,0,1,1-.31,3.12,3.12,0,0,1,1,.12c.36.1.71.23,1.05.36s.66.29,1,.44a6.41,6.41,0,0,0,.88.35,1.54,1.54,0,0,0,.71.08.65.65,0,0,0,.48-.37,1.14,1.14,0,0,0,.21-.6.89.89,0,0,0-.1-.48,1.4,1.4,0,0,0-.34-.4,3.85,3.85,0,0,0-.49-.35,1.82,1.82,0,0,0-1.12-.31,1.33,1.33,0,0,0-.93.62l-2.07-1.25A2.85,2.85,0,0,1,1119.19,930.43Zm2.32,5.38-.48-.15c-.17-.06-.35-.13-.52-.21l-.54-.23-.53-.16a1.81,1.81,0,0,0-.51,0,1.25,1.25,0,0,0-.46.15,1.33,1.33,0,0,0-.39.42,1.19,1.19,0,0,0-.18.53.8.8,0,0,0,.1.46,1.17,1.17,0,0,0,.31.4,3,3,0,0,0,.45.34,2.11,2.11,0,0,0,1.07.36,1.62,1.62,0,0,0,.79-.18,1.93,1.93,0,0,0,.55-.46c.14-.18.24-.33.31-.44l.47-.77A1.34,1.34,0,0,1,1121.51,935.81Zm2.69-7.78-1,1.7-2-1.19,1-1.7Zm3,1.81-1,1.7-2-1.19,1-1.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1128.6,933.44a3.31,3.31,0,0,1,1.18-.82,3.16,3.16,0,0,1,1.53-.2,4.88,4.88,0,0,1,2,.75,5.47,5.47,0,0,1,1.15.92,3.58,3.58,0,0,1,.74,1.15,2.85,2.85,0,0,1,.21,1.3,3.22,3.22,0,0,1-.49,1.42,2.69,2.69,0,0,1-1.13,1,2.08,2.08,0,0,1-1.44.16l0,0a2.54,2.54,0,0,1,.62.84,2.61,2.61,0,0,1,.24,1,2.8,2.8,0,0,1-.12,1,4,4,0,0,1-.42,1,4.35,4.35,0,0,1-1,1.15,3.77,3.77,0,0,1-1.3.68,3.69,3.69,0,0,1-1.44.14,3.6,3.6,0,0,1-1.44-.5,9.06,9.06,0,0,1-1.14-.81l1-1.67.26.23.29.2a2,2,0,0,0,.77.27,1.77,1.77,0,0,0,.76,0,2,2,0,0,0,.69-.35,2.44,2.44,0,0,0,.54-.61,2.22,2.22,0,0,0,.34-1.54,2,2,0,0,0-1-1.31l-.25-.14-.27-.14.82-1.37.16.13.14.1a3.42,3.42,0,0,0,.62.27,1.66,1.66,0,0,0,.62.06,1.43,1.43,0,0,0,.59-.2,1.32,1.32,0,0,0,.48-.51,1.6,1.6,0,0,0,.28-.81,1.24,1.24,0,0,0-.15-.62,1.26,1.26,0,0,0-.36-.43,4.45,4.45,0,0,0-.38-.28,1.41,1.41,0,0,0-1.19-.19,1.8,1.8,0,0,0-1,.84l-4.53,7.51-2.07-1.25,4.32-7.15A7.17,7.17,0,0,1,1128.6,933.44Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1139,940.85l-4.54,7.53-2.07-1.25,4.55-7.53Zm-1.36-2.41,1-1.7,2.06,1.25-1,1.7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1142.81,954.21a3,3,0,0,1-1.19.76,3.65,3.65,0,0,1-1.69.14,6,6,0,0,1-2.27-.9,5.74,5.74,0,0,1-1.05-.82,4.18,4.18,0,0,1-.79-1.06,2.93,2.93,0,0,1-.33-1.23,2.47,2.47,0,0,1,.31-1.31l2,1.24a1.37,1.37,0,0,0,.08,1.15,2.45,2.45,0,0,0,.84.82,1.75,1.75,0,0,0,1.59.24,2.41,2.41,0,0,0,1.18-1.09l.6-1,0,0a2.35,2.35,0,0,1-1.49.31,3.59,3.59,0,0,1-1.47-.51,3.81,3.81,0,0,1-1.27-1.17,3.17,3.17,0,0,1-.51-1.43,3.78,3.78,0,0,1,.15-1.55,6.81,6.81,0,0,1,.7-1.57,5.48,5.48,0,0,1,1.06-1.28,4.36,4.36,0,0,1,1.34-.82,3.49,3.49,0,0,1,1.51-.21,3.62,3.62,0,0,1,1.57.53,3.37,3.37,0,0,1,1.14,1.07,2.66,2.66,0,0,1,.37,1.5h0l.61-1,2,1.19-4.25,7A5.76,5.76,0,0,1,1142.81,954.21Zm-1.32-4.21a2,2,0,0,0,.84-.14,2.32,2.32,0,0,0,.72-.46,2.86,2.86,0,0,0,.58-.71,5,5,0,0,0,.45-.94,2.45,2.45,0,0,0,.15-.91,1.76,1.76,0,0,0-.23-.83,2,2,0,0,0-.73-.7,1.71,1.71,0,0,0-.85-.28,1.75,1.75,0,0,0-.81.16,2.28,2.28,0,0,0-.72.5,4,4,0,0,0-.59.75,4.4,4.4,0,0,0-.4.85,2.58,2.58,0,0,0-.16.89,1.92,1.92,0,0,0,.2.84,1.7,1.7,0,0,0,.68.68A1.89,1.89,0,0,0,1141.49,950Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1150,957.81l.63-1,0,0a2.7,2.7,0,0,1-1.59.33,3.36,3.36,0,0,1-1.46-.48,4.25,4.25,0,0,1-1.23-1,2.31,2.31,0,0,1-.46-1.12,2.89,2.89,0,0,1,.16-1.24,7.12,7.12,0,0,1,.65-1.34l2.79-4.63,2.07,1.25-2.57,4.25a3.12,3.12,0,0,0-.55,1.56,1.31,1.31,0,0,0,.76,1.08,1.69,1.69,0,0,0,1.53.24,3.27,3.27,0,0,0,1.37-1.42l2.38-3.95,2.07,1.25L1152,959Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1160.06,953.61l-.63,1,0,0a2.76,2.76,0,0,1,1.59-.34,3.6,3.6,0,0,1,1.46.48,3.94,3.94,0,0,1,1.22,1,2.25,2.25,0,0,1,.46,1.13,2.69,2.69,0,0,1-.16,1.23,7.12,7.12,0,0,1-.64,1.35l-2.8,4.63-2.06-1.25,2.56-4.25a3,3,0,0,0,.55-1.57,1.32,1.32,0,0,0-.75-1.08,1.66,1.66,0,0,0-1.53-.23,3.28,3.28,0,0,0-1.38,1.42l-2.38,3.94-2.06-1.25,4.54-7.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            d="M1169,970a3,3,0,0,1-1.19.77,3.8,3.8,0,0,1-1.69.14,6,6,0,0,1-2.28-.9,6.14,6.14,0,0,1-1-.82,4.23,4.23,0,0,1-.79-1.07,2.89,2.89,0,0,1-.33-1.22,2.44,2.44,0,0,1,.3-1.31l2.06,1.24a1.34,1.34,0,0,0,.08,1.15,2.31,2.31,0,0,0,.83.81,1.79,1.79,0,0,0,1.59.25,2.39,2.39,0,0,0,1.19-1.09l.6-1,0,0a2.43,2.43,0,0,1-1.49.3,3.52,3.52,0,0,1-1.47-.5,3.87,3.87,0,0,1-1.28-1.18,3.38,3.38,0,0,1-.51-1.42,4,4,0,0,1,.16-1.55,6.47,6.47,0,0,1,.7-1.58,5.43,5.43,0,0,1,1.06-1.27,4.18,4.18,0,0,1,1.34-.82,3.46,3.46,0,0,1,3.08.32,3.41,3.41,0,0,1,1.13,1.06,2.58,2.58,0,0,1,.38,1.5l0,0,.61-1,2,1.18-4.25,7A5.49,5.49,0,0,1,1169,970Zm-1.32-4.21a1.79,1.79,0,0,0,.83-.13,2.43,2.43,0,0,0,.73-.46,3.39,3.39,0,0,0,.58-.72,5,5,0,0,0,.45-.93,2.71,2.71,0,0,0,.15-.91,1.92,1.92,0,0,0-.23-.84,2,2,0,0,0-.73-.69,1.75,1.75,0,0,0-.86-.28,1.88,1.88,0,0,0-.81.15,2.29,2.29,0,0,0-.71.5,4,4,0,0,0-.59.75,5.22,5.22,0,0,0-.41.86,2.52,2.52,0,0,0-.15.89,1.85,1.85,0,0,0,.2.83,1.64,1.64,0,0,0,.67.69A1.91,1.91,0,0,0,1167.64,965.79Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
        <g>
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1296.56,908.9a8,8,0,0,1,3,.54,6.91,6.91,0,0,1,2.31,1.49,6.41,6.41,0,0,1,1.47,2.18,7,7,0,0,1,.51,2.68,7.53,7.53,0,0,1-.93,3.82,5.83,5.83,0,0,1-3,2.45v.07a4.3,4.3,0,0,1,1.68.85,4.82,4.82,0,0,1,1.08,1.37,6,6,0,0,1,.61,1.73,15.36,15.36,0,0,1,.27,1.89c0,.4,0,.86.07,1.4s.06,1.09.12,1.65a11,11,0,0,0,.28,1.59,3.68,3.68,0,0,0,.58,1.28h-5.5a9.86,9.86,0,0,1-.56-2.84c-.07-1.09-.17-2.15-.31-3.15a5,5,0,0,0-1.19-2.87,4.09,4.09,0,0,0-3-.91h-5.49v9.77h-5.5v-25Zm-2,11.3a4.14,4.14,0,0,0,2.83-.84,3.49,3.49,0,0,0,1-2.73,3.33,3.33,0,0,0-1-2.64,4.19,4.19,0,0,0-2.83-.82h-6v7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1308.11,916.3a12.45,12.45,0,0,1,2.41-4.18,11,11,0,0,1,3.87-2.8,13.61,13.61,0,0,1,10.34,0,11,11,0,0,1,3.85,2.8,12.47,12.47,0,0,1,2.42,4.18,15.62,15.62,0,0,1,.83,5.2,15.1,15.1,0,0,1-.83,5.09,12.15,12.15,0,0,1-2.42,4.11,11.1,11.1,0,0,1-3.85,2.75,13.86,13.86,0,0,1-10.34,0,11.08,11.08,0,0,1-3.87-2.75,12.12,12.12,0,0,1-2.41-4.11,14.86,14.86,0,0,1-.84-5.09A15.37,15.37,0,0,1,1308.11,916.3Zm5,8.26a8.19,8.19,0,0,0,1.17,2.68,6,6,0,0,0,2.1,1.9,7.25,7.25,0,0,0,6.3,0,6,6,0,0,0,2.1-1.9,8.19,8.19,0,0,0,1.17-2.68,12.45,12.45,0,0,0,.37-3.06,13.45,13.45,0,0,0-.37-3.19,8.37,8.37,0,0,0-1.17-2.75,6.05,6.05,0,0,0-2.1-1.92,7.25,7.25,0,0,0-6.3,0,6.05,6.05,0,0,0-2.1,1.92,8.37,8.37,0,0,0-1.17,2.75,13.45,13.45,0,0,0-.37,3.19A12.45,12.45,0,0,0,1313.13,924.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1333.62,913.52V908.9h20.48v4.62h-7.49v20.37h-5.5V913.52Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1362.46,908.9v9.59h10.12V908.9h5.49v25h-5.49V923.11h-10.12v10.78H1357v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1398.93,915.56a5.71,5.71,0,0,0-1.22-1.38,6.18,6.18,0,0,0-1.67-.93,5.73,5.73,0,0,0-1.94-.33,6.38,6.38,0,0,0-3.15.72,6.05,6.05,0,0,0-2.1,1.92,8.37,8.37,0,0,0-1.17,2.75,13.45,13.45,0,0,0-.37,3.19,12.45,12.45,0,0,0,.37,3.06,8.19,8.19,0,0,0,1.17,2.68,6,6,0,0,0,2.1,1.9,6.38,6.38,0,0,0,3.15.72,5.09,5.09,0,0,0,3.94-1.54,7.16,7.16,0,0,0,1.73-4.06h5.32a12.6,12.6,0,0,1-1.09,4.24,10.21,10.21,0,0,1-2.3,3.22,10,10,0,0,1-3.37,2,12.43,12.43,0,0,1-4.23.7,12.87,12.87,0,0,1-5.16-1,11.08,11.08,0,0,1-3.87-2.75,12.15,12.15,0,0,1-2.42-4.11,15.1,15.1,0,0,1-.84-5.09,15.62,15.62,0,0,1,.84-5.2,12.47,12.47,0,0,1,2.42-4.18,11,11,0,0,1,3.87-2.8,13.31,13.31,0,0,1,9.06-.42,10.35,10.35,0,0,1,3.29,1.73,9.38,9.38,0,0,1,2.4,2.82,10,10,0,0,1,1.19,3.85h-5.32A4.44,4.44,0,0,0,1398.93,915.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1408.59,916.3a12.62,12.62,0,0,1,2.41-4.18,11,11,0,0,1,3.87-2.8,13.61,13.61,0,0,1,10.34,0,11,11,0,0,1,3.85,2.8,12.3,12.3,0,0,1,2.42,4.18,15.62,15.62,0,0,1,.84,5.2,15.1,15.1,0,0,1-.84,5.09,12,12,0,0,1-2.42,4.11,11.1,11.1,0,0,1-3.85,2.75,13.86,13.86,0,0,1-10.34,0,11.08,11.08,0,0,1-3.87-2.75,12.29,12.29,0,0,1-2.41-4.11,15.1,15.1,0,0,1-.84-5.09A15.62,15.62,0,0,1,1408.59,916.3Zm5,8.26a8.42,8.42,0,0,0,1.18,2.68,5.88,5.88,0,0,0,2.09,1.9,6.45,6.45,0,0,0,3.16.72,6.37,6.37,0,0,0,3.14-.72,6,6,0,0,0,2.11-1.9,8.63,8.63,0,0,0,1.17-2.68,12.94,12.94,0,0,0,.36-3.06,14,14,0,0,0-.36-3.19,8.82,8.82,0,0,0-1.17-2.75,6,6,0,0,0-2.11-1.92,6.37,6.37,0,0,0-3.14-.72,6.45,6.45,0,0,0-3.16.72,5.94,5.94,0,0,0-2.09,1.92,8.61,8.61,0,0,0-1.18,2.75,14,14,0,0,0-.37,3.19A13,13,0,0,0,1413.61,924.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1448.53,908.9l9.34,25h-5.7l-1.89-5.57h-9.35l-2,5.57h-5.53l9.45-25Zm.31,15.33-3.15-9.17h-.07l-3.25,9.17Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1476.07,915.56a5.75,5.75,0,0,0-1.23-1.38,6,6,0,0,0-1.66-.93,5.73,5.73,0,0,0-1.94-.33,6.38,6.38,0,0,0-3.15.72,6.05,6.05,0,0,0-2.1,1.92,8.37,8.37,0,0,0-1.17,2.75,13.45,13.45,0,0,0-.37,3.19,12.45,12.45,0,0,0,.37,3.06,8.19,8.19,0,0,0,1.17,2.68,6,6,0,0,0,2.1,1.9,6.38,6.38,0,0,0,3.15.72,5.09,5.09,0,0,0,3.94-1.54,7.16,7.16,0,0,0,1.73-4.06h5.32a12.6,12.6,0,0,1-1.09,4.24,10.08,10.08,0,0,1-2.31,3.22,9.85,9.85,0,0,1-3.36,2,12.43,12.43,0,0,1-4.23.7,12.9,12.9,0,0,1-5.16-1,11.08,11.08,0,0,1-3.87-2.75,12.15,12.15,0,0,1-2.42-4.11,15.1,15.1,0,0,1-.83-5.09,15.62,15.62,0,0,1,.83-5.2,12.47,12.47,0,0,1,2.42-4.18,11,11,0,0,1,3.87-2.8,12.57,12.57,0,0,1,5.16-1,12.44,12.44,0,0,1,3.9.6,10.35,10.35,0,0,1,3.29,1.73,9.38,9.38,0,0,1,2.4,2.82,10,10,0,0,1,1.19,3.85h-5.32A4.44,4.44,0,0,0,1476.07,915.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1491.47,908.9v9.59h10.11V908.9h5.5v25h-5.5V923.11h-10.11v10.78H1486v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1517.4,908.9v25h-5.49v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1527.69,908.9l10.43,16.76h.07V908.9h5.15v25h-5.5l-10.39-16.73h-.07v16.73h-5.15v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1563,933.69a9.06,9.06,0,0,1-3.6.76,12.87,12.87,0,0,1-5.16-1,11.08,11.08,0,0,1-3.87-2.75,12.15,12.15,0,0,1-2.42-4.11,15.1,15.1,0,0,1-.84-5.09,15.62,15.62,0,0,1,.84-5.2,12.47,12.47,0,0,1,2.42-4.18,11,11,0,0,1,3.87-2.8,12.54,12.54,0,0,1,5.16-1,12.06,12.06,0,0,1,3.73.58,10.4,10.4,0,0,1,3.25,1.7,9.14,9.14,0,0,1,2.4,2.76,9.36,9.36,0,0,1,1.15,3.78h-5.25a5.2,5.2,0,0,0-1.89-3.15,5.48,5.48,0,0,0-3.39-1.05,6.38,6.38,0,0,0-3.15.72,6.05,6.05,0,0,0-2.1,1.92,8.37,8.37,0,0,0-1.17,2.75,13.45,13.45,0,0,0-.37,3.19,12.45,12.45,0,0,0,.37,3.06,8.19,8.19,0,0,0,1.17,2.68,6,6,0,0,0,2.1,1.9,6.38,6.38,0,0,0,3.15.72,5.36,5.36,0,0,0,6-5.39h-5.53v-4.09h10.5v13.51h-3.5l-.56-2.84A8.23,8.23,0,0,1,1563,933.69Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1588.66,927.73a4.06,4.06,0,0,0,1.21,1.41,5.14,5.14,0,0,0,1.77.81,8.63,8.63,0,0,0,2.1.26,11.1,11.1,0,0,0,1.57-.12,5.56,5.56,0,0,0,1.58-.47,3.43,3.43,0,0,0,1.22-1,2.36,2.36,0,0,0,.49-1.55,2.2,2.2,0,0,0-.64-1.65,5.24,5.24,0,0,0-1.7-1.05,16.83,16.83,0,0,0-2.38-.73c-.89-.21-1.79-.45-2.7-.7a25.45,25.45,0,0,1-2.73-.86,9.76,9.76,0,0,1-2.38-1.31,6.15,6.15,0,0,1-1.69-2,6,6,0,0,1-.65-2.92,6.51,6.51,0,0,1,.82-3.34,7.48,7.48,0,0,1,2.15-2.36,9.34,9.34,0,0,1,3-1.4,12.54,12.54,0,0,1,3.36-.46,16.08,16.08,0,0,1,3.77.44,9.21,9.21,0,0,1,3.2,1.42,7.13,7.13,0,0,1,2.22,2.5,7.62,7.62,0,0,1,.82,3.69h-5.32a4.3,4.3,0,0,0-.47-1.85,3.07,3.07,0,0,0-1.07-1.16,4.35,4.35,0,0,0-1.52-.59,9.06,9.06,0,0,0-1.87-.18,6.6,6.6,0,0,0-1.33.14,3.58,3.58,0,0,0-1.21.49,3,3,0,0,0-.89.88,2.37,2.37,0,0,0-.35,1.33,2.23,2.23,0,0,0,.28,1.19,2.55,2.55,0,0,0,1.1.84,15.24,15.24,0,0,0,2.28.77l3.79,1c.47.09,1.12.26,2,.5a9.38,9.38,0,0,1,2.46,1.18A7.25,7.25,0,0,1,1603,923a6.1,6.1,0,0,1,.89,3.45,7.67,7.67,0,0,1-.66,3.18,6.83,6.83,0,0,1-2,2.54,9.4,9.4,0,0,1-3.25,1.66,15.32,15.32,0,0,1-4.5.6,15.74,15.74,0,0,1-4-.51,10.2,10.2,0,0,1-3.43-1.59,7.92,7.92,0,0,1-2.36-2.77,8.33,8.33,0,0,1-.84-4h5.32A4.67,4.67,0,0,0,1588.66,927.73Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1606.93,916.3a12.47,12.47,0,0,1,2.42-4.18,11,11,0,0,1,3.87-2.8,13.61,13.61,0,0,1,10.34,0,11,11,0,0,1,3.85,2.8,12.45,12.45,0,0,1,2.41,4.18,15.62,15.62,0,0,1,.84,5.2,15.1,15.1,0,0,1-.84,5.09,12.12,12.12,0,0,1-2.41,4.11,11.1,11.1,0,0,1-3.85,2.75,13.86,13.86,0,0,1-10.34,0,11.08,11.08,0,0,1-3.87-2.75,12.15,12.15,0,0,1-2.42-4.11,15.1,15.1,0,0,1-.84-5.09A15.62,15.62,0,0,1,1606.93,916.3Zm5,8.26a8.19,8.19,0,0,0,1.17,2.68,6,6,0,0,0,2.1,1.9,7.25,7.25,0,0,0,6.3,0,6,6,0,0,0,2.1-1.9,8.19,8.19,0,0,0,1.17-2.68,12.45,12.45,0,0,0,.37-3.06,13.45,13.45,0,0,0-.37-3.19,8.37,8.37,0,0,0-1.17-2.75,6.05,6.05,0,0,0-2.1-1.92,7.25,7.25,0,0,0-6.3,0,6.05,6.05,0,0,0-2.1,1.92,8.37,8.37,0,0,0-1.17,2.75,13.45,13.45,0,0,0-.37,3.19A12.45,12.45,0,0,0,1612,924.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1652.78,932c-1.89,1.64-4.5,2.47-7.84,2.47s-6-.82-7.85-2.45-2.79-4.16-2.79-7.56V908.9h5.5v15.54a12,12,0,0,0,.17,2,3.92,3.92,0,0,0,2.3,3,6.36,6.36,0,0,0,2.67.47c2,0,3.32-.44,4.06-1.31a6.25,6.25,0,0,0,1.12-4.11V908.9h5.5v15.54Q1655.62,929.51,1652.78,932Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1673.82,908.9a8.1,8.1,0,0,1,3,.54,7.12,7.12,0,0,1,2.31,1.49,6.56,6.56,0,0,1,1.47,2.18,7.18,7.18,0,0,1,.5,2.68,7.63,7.63,0,0,1-.92,3.82,5.86,5.86,0,0,1-3,2.45v.07a4.23,4.23,0,0,1,1.68.85,4.82,4.82,0,0,1,1.08,1.37,6.34,6.34,0,0,1,.62,1.73,17.51,17.51,0,0,1,.26,1.89c0,.4,0,.86.07,1.4s.06,1.09.12,1.65a11,11,0,0,0,.28,1.59,3.68,3.68,0,0,0,.58,1.28h-5.5a9.86,9.86,0,0,1-.56-2.84c-.07-1.09-.17-2.15-.31-3.15a5,5,0,0,0-1.19-2.87,4.09,4.09,0,0,0-3-.91h-5.49v9.77h-5.5v-25Zm-2,11.3a4.14,4.14,0,0,0,2.83-.84,3.49,3.49,0,0,0,1-2.73,3.33,3.33,0,0,0-1-2.64,4.19,4.19,0,0,0-2.83-.82h-6v7Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1701.64,915.56a5.51,5.51,0,0,0-1.22-1.38,6.12,6.12,0,0,0-1.66-.93,5.79,5.79,0,0,0-2-.33,6.43,6.43,0,0,0-3.15.72,6.05,6.05,0,0,0-2.1,1.92,8.59,8.59,0,0,0-1.17,2.75,14,14,0,0,0-.37,3.19,13,13,0,0,0,.37,3.06,8.4,8.4,0,0,0,1.17,2.68,6,6,0,0,0,2.1,1.9,6.43,6.43,0,0,0,3.15.72,5.09,5.09,0,0,0,3.94-1.54,7.1,7.1,0,0,0,1.73-4.06h5.32a12.83,12.83,0,0,1-1.08,4.24,10.39,10.39,0,0,1-2.31,3.22,10,10,0,0,1-3.36,2,12.53,12.53,0,0,1-4.24.7,12.9,12.9,0,0,1-5.16-1,11.08,11.08,0,0,1-3.87-2.75,12.12,12.12,0,0,1-2.41-4.11,15.1,15.1,0,0,1-.84-5.09,15.62,15.62,0,0,1,.84-5.2,12.45,12.45,0,0,1,2.41-4.18,11,11,0,0,1,3.87-2.8,12.57,12.57,0,0,1,5.16-1,12.44,12.44,0,0,1,3.9.6,10.4,10.4,0,0,1,3.3,1.73,9.49,9.49,0,0,1,2.39,2.82,10,10,0,0,1,1.19,3.85h-5.32A4.44,4.44,0,0,0,1701.64,915.56Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1730.24,908.9v4.62H1717v5.35h12.11v4.27H1717v6.13h13.48v4.62h-19v-25Z"
            transform="translate(-62.75 -75.53)"
          />
          <path
            class="a48ef0c9-8d37-44ab-a633-0b3c2900d72e"
            d="M1738.43,927.73a3.93,3.93,0,0,0,1.2,1.41,5.32,5.32,0,0,0,1.77.81,8.63,8.63,0,0,0,2.1.26,11.24,11.24,0,0,0,1.58-.12,5.5,5.5,0,0,0,1.57-.47,3.56,3.56,0,0,0,1.23-1,2.42,2.42,0,0,0,.49-1.55,2.21,2.21,0,0,0-.65-1.65,5.13,5.13,0,0,0-1.7-1.05,16.83,16.83,0,0,0-2.38-.73c-.88-.21-1.78-.45-2.69-.7a24.1,24.1,0,0,1-2.73-.86,9.58,9.58,0,0,1-2.38-1.31,6.18,6.18,0,0,1-1.7-2,6.11,6.11,0,0,1-.65-2.92,6.61,6.61,0,0,1,.82-3.34,7.4,7.4,0,0,1,2.16-2.36,9.25,9.25,0,0,1,3-1.4,12.54,12.54,0,0,1,3.36-.46,16,16,0,0,1,3.76.44,9.21,9.21,0,0,1,3.2,1.42,7.06,7.06,0,0,1,2.23,2.5,7.74,7.74,0,0,1,.82,3.69h-5.32a4.44,4.44,0,0,0-.48-1.85,3,3,0,0,0-1.06-1.16,4.4,4.4,0,0,0-1.53-.59,9.06,9.06,0,0,0-1.87-.18,6.76,6.76,0,0,0-1.33.14,3.67,3.67,0,0,0-1.21.49,3.11,3.11,0,0,0-.89.88,2.37,2.37,0,0,0-.35,1.33,2.33,2.33,0,0,0,.28,1.19,2.59,2.59,0,0,0,1.11.84,14.67,14.67,0,0,0,2.27.77l3.8,1c.46.09,1.11.26,1.94.5a9.43,9.43,0,0,1,2.47,1.18,7.34,7.34,0,0,1,2.11,2.15,6.1,6.1,0,0,1,.9,3.45,7.52,7.52,0,0,1-.67,3.18,6.83,6.83,0,0,1-2,2.54,9.4,9.4,0,0,1-3.25,1.66,15.23,15.23,0,0,1-4.5.6,15.67,15.67,0,0,1-4-.51,10.11,10.11,0,0,1-3.43-1.59,8,8,0,0,1-2.37-2.77,8.33,8.33,0,0,1-.84-4H1738A4.54,4.54,0,0,0,1738.43,927.73Z"
            transform="translate(-62.75 -75.53)"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="827.13"
            y1="100.76"
            x2="827.13"
            y2="136.69"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="827.13 155.76 816.15 128.89 827.13 135.27 838.1 128.89 827.13 155.76"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="606.04"
            y1="204.97"
            x2="541.6"
            y2="204.2"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="522.54 203.97 549.53 193.31 543.03 204.21 549.27 215.26 522.54 203.97"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="285.54"
            y1="254.97"
            x2="259.36"
            y2="279.84"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="245.54 292.97 257.46 266.51 260.39 278.86 272.57 282.42 245.54 292.97"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="213.54"
            y1="443.97"
            x2="202.36"
            y2="409.12"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="196.54 390.97 215.2 413.19 202.8 410.48 194.3 419.9 196.54 390.97"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="1037"
            y1="197.2"
            x2="1228.45"
            y2="200.59"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="1247.51 200.93 1220.46 211.43 1227.02 200.57 1220.84 189.48 1247.51 200.93"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="1033.27"
            y1="245.64"
            x2="1218.54"
            y2="317.03"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="1236.33 323.88 1207.32 324.47 1217.21 316.52 1215.21 303.99 1236.33 323.88"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="823.21"
            y1="257.24"
            x2="746.36"
            y2="394.8"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="737.07 411.44 740.58 382.64 747.06 393.56 759.75 393.34 737.07 411.44"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="983.84"
            y1="535.06"
            x2="1166.47"
            y2="504.22"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="1185.27 501.04 1160.61 516.34 1165.06 504.45 1156.95 494.69 1185.27 501.04"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="901.86"
            y1="747.3"
            x2="1166.87"
            y2="907.58"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="1183.19 917.44 1154.52 912.93 1165.65 906.84 1165.88 894.15 1183.19 917.44"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="777.48"
            y1="825.19"
            x2="819.37"
            y2="917.52"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="827.24 934.88 806.15 914.95 818.78 916.22 826.14 905.88 827.24 934.88"
          />
        </g>
        <g>
          <line
            class="b2be1c92-1e94-435a-af47-91f31698e216"
            x1="434.64"
            y1="874.12"
            x2="426.78"
            y2="915.17"
          />
          <polygon
            class="ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb"
            points="423.19 933.89 417.46 905.45 427.05 913.77 439.02 909.58 423.19 933.89"
          />
        </g>
        <ellipse
          class="a3061281-913c-4864-aec6-ea2ac0e181fc"
          cx="460.12"
          cy="638.56"
          rx="431.54"
          ry="217.84"
        />
      </g>
    </g>
  </svg>
)

export default SvgMindMapDesktop
