import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { ParallaxBanner, Parallax } from 'react-scroll-parallax';
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'

import Settings from '../../Settings'
import formatDate from '../../utils/formatDate'
import urlFormat from '../../utils/urlFormat'

const NewsDisplay = (props) => {
    // console.log(props)
    const { apiElement, url, match } = props
    // console.log(match.params)
    const sortedPosts = _.reverse(_.sortBy(apiElement.posts, 'date'));
    // const sortedPosts = apiElement.posts
    return (

        <div className="news_display">
        {
            // --------------------- //
            // DISPLAY HEADER IMAGE IF OVERVIEW //
            // --------------------- //

        match.params.subpage === undefined  && 

        <div className="header_image hidden_on_mobile">
            <div className="image_wrapper">
            <Parallax y={[Settings.parallaxStart, Settings.parallaxEnd]} className={`header_image_parallax ${apiElement.hidden_on_mobile ? 'hidden_on_mobile' : ''}`}>
                <div className="image_holder" style={{backgroundImage: `url(${apiElement.hasOwnProperty(
                                'title_image'
                            ) &&
                                apiElement.title_image.hasOwnProperty('sizes') &&
                                apiElement.title_image.sizes.large})`, backgroundPosition: apiElement.image_position || 'center'}}/>
            </Parallax>
            </div>
            <div className="heading_holder">
            <h1>{apiElement.page_title}</h1>
            </div>
        </div>
    }

        {
            // --------------------- //
            // DISPLAY POST OVERVIEW //
            // --------------------- //
        match.params.subpage === undefined && 
        sortedPosts.map(post=>{
            const postUrl = `${url}${urlFormat(post.title)}`
            return (
                <Reveal
                                    effect="custom_fade_in"
                                     key={`overview_${postUrl}`}
                                >
                <div className="single_news_overview">
                <div className="left">
                    <div className="image_holder">
                    <ParallaxBanner className="news_image" layers={[
                    {
                        image: post.hasOwnProperty(
                                            'image'
                                        ) &&
                                            post.image.hasOwnProperty('sizes') &&
                                            post.image.sizes.large,
                        amount: Settings.parallaxFactor,
                    },]} style = { { height: '0px' } } >
                    
                            <div className="title_holder">
                                <span className="date">{formatDate(post.date)}</span>
                    <Link to={postUrl}><h1>{post.title}</h1></Link>
                            </div>
            
                    </ParallaxBanner>
                    </div>
                </div>
                <div className="right">
                            <div className="hidden_on_mobile">              
                                    <Link to={postUrl}><h1>{post.title}</h1></Link>
                                  <span className="date hidden_on_mobile">{formatDate(post.date)}</span>
                            </div>
                            
                    <div className="text_block">
                        <div className="" dangerouslySetInnerHTML={{
                            __html: post.excerpt || null
                        }}/>
                    </div>
                    <div className="text_block button_holder">
                        <Link className="button" to={postUrl}>{apiElement.read_more_text}</Link>
                    </div>
                </div>

                </div> </Reveal>)})
    }







    {
        // ------------------- //
        // DISPLAY SINGLE POST //
        // ------------------- //
        match.params.subpage !== undefined && _.filter(apiElement.posts, o => (urlFormat(o.title) === match.params.subpage)).map(post => {
            const postUrl = `${url}/${urlFormat(post.title)}`
            return (
                <div key={`overview_${postUrl}`} className="single_news">

                <Reveal
                                    effect="custom_fade_in"
                                     key={`landing_banner_image_${postUrl}`}
                                >
                        <ParallaxBanner className="parallax_holder" layers={[
                        {
                            image: post.hasOwnProperty(
                                                'image'
                                            ) &&
                                                post.image.hasOwnProperty('sizes') &&
                                                post.image.sizes.large,
                            amount: Settings.parallaxFactor,
                        },]} style = { { height: '0px' } } >
                
                        <div className="title_holder">
                            <span className="date">{formatDate(post.date)}</span>
                            <h1>{post.title}</h1>
                        </div>
                        </ParallaxBanner>
</Reveal>
                    
                <Reveal
                                    effect="custom_fade_in"
                                     key={`text_block_${postUrl}`}
                                >
                                <div className="text_block">
                                <div className="title_holder">
                                  <span className="date hidden_on_mobile">{formatDate(post.date)}</span>
                                <h1>{post.title}</h1>
                                </div>
                        <div className="" dangerouslySetInnerHTML={{
                __html: post.content || null
            }}></div></div>

                <div className="text_block align_center" style={{marginTop: -20, marginBottom: 20}}>
                    <Link className="button" to={url}>{apiElement.back_to_news_text}</Link>
                </div>
                </Reveal>

                        
        
                        </div>)
        })


    }

    { Settings.debugBlocks && <pre>{JSON.stringify(apiElement, null, 2) }</pre> } </div>

    )
}

NewsDisplay.defaultProps = {
    apiElement: {}
}
NewsDisplay.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default withRouter(NewsDisplay)