import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { withController } from 'react-scroll-parallax'
// import $ from 'jquery'

import { setLanguage, showNewsletterPopup } from '../actions/uiActions'
import getElementOffset from '../utils/getElementOffset'

const TryToLogPage = () => {
  // ReactGA.set({ userId })
  if (document.title !== 'loading...') {
    ReactGA.pageview(window.location.pathname)
    // console.log(
    //  'Page Load: ' + window.location.pathname + '\n' + document.title
    // )
  } else {
    // console.log('waiting...')
    window.setTimeout(TryToLogPage, 500)
  }
}

const scrollToHash = (originalHash) => {
  // console.log(`${hash}_anchor`)
  // console.log($(`${hash}_anchor`))

  // window.setTimeout(() => {
  //     if ($(`${hash}_anchor`).offset() !== undefined) {
  //         // console.log('found element')
  //         // console.log(`offset: ${$(`${hash}_anchor`).offset().top}`)
  //         window.scrollTo({ top: $(`${hash}_anchor`).offset().top - 100, behavior: 'smooth' })
  //     }
  // }, 500)
  const hash = originalHash.split('#')[1] // remove the #
  var target = document.getElementById(`${hash}_anchor`)
  console.log('_________SCROLL TO: _________')
  console.log(hash)
  console.log(target)
  let offset
  if (target) {
    offset = getElementOffset(target).top
    console.log(offset)
    window.setTimeout(() => {
      window.scrollTo({
        top: offset - 140,
        left: 0,
        behavior: 'smooth',
      })
    }, 250)
  }
}

class PageLoad extends Component {
  constructor(props) {
    super(props)
    this.setLanguage = this.setLanguage.bind(this)
  }

  componentDidMount() {
    const { hash } = this.props.location
    // window.setTimeout(TryToLogPage, 500)
    this.setLanguage()
    if (hash === '#newsletter') {
      showNewsletterPopup()
    }
  }

  componentDidUpdate(prevProps) {
    // if (this.props.location.pathname !== prevProps.location.pathname) {
    const { showNewsletterPopup, pagesLoading } = this.props

    // const previousLanguage = this.props.language
    // let language = this.props.location.pathname.split('/')[1]

    // if (previousLanguage !== language) {
    this.setLanguage()
    // }
    const { hash } = this.props.location
    if (this.props.location.hash === '#newsletter') {
      showNewsletterPopup()

      // console.log(this.props.location.hash)
    }

    // ########################### SCROLL TO HASH ###########################

    if (hash !== prevProps.location.hash) {
      if (hash !== '#newsletter') {
        scrollToHash(hash)
      }
    }

    // ########################### SCROLL TO HASH ON PAGE LOAD ###########################
    if (hash !== '#newsletter' && prevProps.pagesLoading && !pagesLoading) {
      scrollToHash(hash)
    }

    if (
      this.props.location.pathname.split('/~')[0] !==
      prevProps.location.pathname.split('/~')[0]
    ) {
      window.setTimeout(() => {
        window.scrollTo({ top: 0 })
      }, 50)

      // console.log(this.props.location.pathname)
      // window.setTimeout(() => {
      //     window.scrollTo(window.scrollX, window.scrollY + 1);
      //     window.scrollTo(window.scrollX, window.scrollY + -1);
      // }, 500)

      window.setTimeout(() => TryToLogPage(this.props.login), 500)
      // this.props.parallaxController.update()
    }
  }

  setLanguage() {
    const previousLanguage = this.props.language
    let language = this.props.location.pathname.split('/')[1]
    if (language === '') language = 'de'

    console.log(`Previous Language: ${previousLanguage}`)
    console.log(`Language of current page: ${language}`)

    if (previousLanguage !== language) {
      // console.log(`change Language to: ${language}`)
      this.props.setLanguage(language)
    }
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = (state) => ({
  language: state.ui.language,
  pagesLoading: state.pages.loading,
})

export default withRouter(
  withController(
    connect(mapStateToProps, { setLanguage, showNewsletterPopup })(PageLoad)
  )
)
