import React from 'react'
import PropTypes from 'prop-types'
import { Parallax } from 'react-scroll-parallax'

import Settings from '../../Settings'

const HeaderImage = ({ apiElement }) => {
  //   console.log(apiElement)

  const imageUrl = apiElement?.image

  return (
    <div className="header_image">
      {apiElement.anchor_name !== '' && (
        <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />
      )}
      <div className="image_wrapper">
        <Parallax
          y={[Settings.parallaxStart, Settings.parallaxEnd]}
          className={`header_image_parallax ${
            apiElement.hidden_on_mobile ? 'hidden_on_mobile' : ''
          }`}
        >
          <div
            className="image_holder"
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundPosition: apiElement.image_position || 'center',
            }}
          />
        </Parallax>
      </div>
      <div className="heading_holder">
        <h1>{apiElement.text}</h1>
      </div>
    </div>
  )
}

HeaderImage.defaultProps = {
  apiElement: {},
}
HeaderImage.propTypes = {
  apiElement: PropTypes.object, // eslint-disable-line
}

export default HeaderImage
