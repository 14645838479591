import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax'
import Reveal from 'react-reveal/Reveal'
import { connect } from 'react-redux'

import formatDate from '../../utils/formatDate'
import Settings from '../../Settings'

import WorkshopForm from './WorkshopForm'
import WorkshopBookingForm from './WorkshopBookingForm'

import {
  submitWorkshopRequest,
  submitWorkshopBooking,
} from '../../actions/userActions'
import urlFormat from '../../utils/urlFormat'

function addZero(i) {
  let p = parseInt(i)
  if (p < 10) {
    p = '0' + i
  }
  return p
}

class SingleEventDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
  }

  submit = (data) => {
    console.log(data)
    const { event, language } = this.props

    const dateStr =
      event.zeitangabe_manuell !== ''
        ? event.zeitangabe_manuell
        : `${formatDate(event.start_date)} - ${formatDate(event.end_date)}`

    return this.props.submitWorkshopRequest({
      ...data,
      event: `${event.title} am ${dateStr}`,
      workshop: event.title,
      start_date: event.start_date,
      language: language,
    })
  }

  submitBook = (data) => {
    console.log(data)
    const { event, language } = this.props

    const dateStr =
      event.zeitangabe_manuell !== ''
        ? event.zeitangabe_manuell
        : `${formatDate(event.start_date)} - ${formatDate(event.end_date)}`

    return this.props.submitWorkshopBooking({
      ...data,
      event: `${event.title} am ${dateStr}`,
      workshop_id: event.workshop_id,
      workshop: event.title,
      start_date: event.start_date,
      language: language,
    })
  }

  render() {
    const date = new Date()
    const dateString = `${date.getFullYear()}${addZero(
      date.getMonth() + 1
    )}${addZero(date.getDate())}${addZero(date.getHours())}${addZero(
      date.getMinutes()
    )}`

    const { event, apiElement, options, language, url } = this.props

    const imageUrl =
      event.hasOwnProperty('image') &&
      event.image.hasOwnProperty('sizes') &&
      event.image.sizes.large

    const dateStr =
      event.zeitangabe_manuell !== ''
        ? event.zeitangabe_manuell
        : `${formatDate(event.start_date)} - ${formatDate(event.end_date)}`
    console.log(event)

    if (options.loading) return null

    const wsOptions = options.data[language].workshop_booking

    // redirect to workshop page if event is in the past
    if (parseInt(dateString) > parseInt(event.start_date) + 10)
      return <Redirect to={`${url}${urlFormat(event.title)}`} />

    return (
      <div className="single_event">
        <div className="header_image">
          <div
            className="image_holder"
            data-position={event.image_position}
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundPosition: event.image_position || 'center',
              backgroundSize: 'cover',
            }}
          />
        </div>

        <div className="heading_holder">
          <h1>{event.title}</h1>
        </div>

        <Reveal effect="custom_fade_in">
          <div className="text_block force_left">
            <h3 className="subtitle">{event.subtitle}</h3>
            <h3 className="time">{dateStr}</h3>
            <h3 className="location">{event.location}</h3>

            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: event.description || null,
              }}
            />
          </div>
        </Reveal>

        {event.show_contact_form && (
          <Reveal effect="custom_fade_in">
            <div className="sign_up_wrapper">
              <div
                className="sign_up"
                style={{ marginTop: -20, marginBottom: 20 }}
              >
                <div className="text">
                  <h1>
                    {event.bookable_online
                      ? wsOptions.workshop_booking_online_title
                      : wsOptions.workshop_booking_title}
                  </h1>
                  <p>
                    {event.bookable_online
                      ? wsOptions.workshop_booking_online_text
                      : wsOptions.workshop_booking_text}
                  </p>
                </div>
                <div className="contact">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url('${
                        wsOptions.hasOwnProperty('image_contact') &&
                        wsOptions.image_contact.hasOwnProperty('sizes') &&
                        wsOptions.image_contact.sizes.large
                      }')`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                  <h2 className="name">{wsOptions.name_contact}</h2>
                  <a
                    href={`mailto:${wsOptions.email_address_contact}?subject=Anfrage für ${event.title} am ${dateStr}`}
                    className="email"
                  >
                    {wsOptions.email_address_contact}
                  </a>
                </div>
                <div className="form">
                  {event.bookable_online ? (
                    <WorkshopBookingForm
                      wsOptions={wsOptions}
                      event={event}
                      submit={this.submitBook}
                    />
                  ) : (
                    <WorkshopForm wsOptions={wsOptions} submit={this.submit} />
                  )}
                  {/*      <WorkshopForm
                                    wsOptions={wsOptions}
                                    submit={this.submit}
                                />*/}
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </Reveal>
        )}

        <Reveal effect="custom_fade_in">
          <div
            className="text_block align_center"
            style={{ marginTop: 0, marginBottom: 20 }}
          >
            <Link className="button" to={'../'}>
              {apiElement.text_back}
            </Link>
          </div>
        </Reveal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.ui.language,
  options: state.options,
})

export default connect(mapStateToProps, {
  submitWorkshopRequest,
  submitWorkshopBooking,
})(SingleEventDisplay)
