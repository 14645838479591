import {
    SHOW_MESSAGE,
    SET_LANGUAGE,
    HIDE_MESSAGE,
    SHOW_MENU,
    HIDE_MENU,
    SET_ERROR,
    SHOW_NEWSLETTER_POPUP,
    HIDE_NEWSLETTER_POPUP
} from '../actions/types'

const initialState = {
    overlayVisible: false,
    messageVisible: false,
    messageText: '',
    messageType: '',
    language: 'de',
    menuVisible: false,
    error: false,
    errorMessage: '',
    newsletterVisible: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                messageVisible: true,
                messageText: action.payload.text,
                messageType: action.payload.type,
                messageDuration: action.payload.duration
            }
        case HIDE_MESSAGE:
            return {
                ...state,
                messageVisible: false
            }
        case SET_LANGUAGE:
            return { ...state, language: action.payload }
        case SHOW_MENU:
            return { ...state, menuVisible: true }
        case HIDE_MENU:
            return { ...state, menuVisible: false }
        case SET_ERROR:
            return { ...state, error: true, errorMessage: action.payload }
        case SHOW_NEWSLETTER_POPUP:
            return { ...state, newsletterVisible: true, overlayVisible: false }
        case HIDE_NEWSLETTER_POPUP:
            return { ...state, newsletterVisible: false, overlayVisible: false }
        default:
            return state
    }
}
