import React from 'react'
import { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from 'react-router-dom'
import _ from 'lodash'
import { Parallax } from 'react-scroll-parallax'

import Settings from '../../Settings'
import _t from '../../utils/translate'
import urlFormat from '../../utils/urlFormat'
import parseDate from '../../utils/parseDate'
import formatDate from '../../utils/formatDate'
import ParallaxCache from '../../utils/ParallaxCache'
import SingleEventDisplay from './SingleEventDisplay'
import SingleWorkshopDisplay from './SingleWorkshopDisplay'

const SingleEvent = ({ apiElement, workshops, language, url, match }) => {
  const matchedEvent = _.find(workshops.calendar[language], {
    calUrl: match.params.subpage,
  })
  const matchedWorkshop = _.find(workshops.data[language], {
    url: match.params.subpage,
  })

  console.log('url:')
  console.log(url)
  // console.log(workshops.data[language])

  // console.log('matchedWorkshop')
  // console.log(matchedEvent)

  const eventFound = matchedEvent !== undefined && !workshops.loading
  const workshopFound = matchedWorkshop !== undefined && !workshops.loading

  if (eventFound) {
    return (
      <SingleEventDisplay
        apiElement={apiElement}
        event={matchedEvent}
        url={url}
      />
    )
  }

  if (workshopFound) {
    return (
      <SingleWorkshopDisplay
        apiElement={apiElement}
        workshop={matchedWorkshop}
        workshops={workshops}
        language={language}
        url={url}
      />
    )
  }

  return <Redirect to={url.split('single/').join('')} />
}

SingleEvent.defaultProps = {
  apiElement: {},
}
SingleEvent.propTypes = {
  apiElement: PropTypes.object, // eslint-disable-line
}

const mapStateToProps = (state) => ({
  workshops: state.workshops,
  language: state.ui.language,
})

export default withRouter(connect(mapStateToProps)(SingleEvent))
