import { store } from '../store/store'
import { WORKSHOPS_LOADED, SET_ERROR } from './types'
import { api } from '../api'
import Settings from '../Settings'


export const workshopsLoaded = pages => ({ type: WORKSHOPS_LOADED, payload: pages })


function handleErrors(responses) {
    // console.log(response)

    responses.map(response => {
        if (response.status !== 200) {
            throw Error(JSON.stringify(response))
        } else return response
    })
    return responses
}

export const loadWorkshops = () => {
    // console.log(Settings.supportedLanguages)
    let promises = []
    Settings.supportedLanguages.map(lang => {
        promises.push(api.workshops.getWorkshopsLang(lang))
    })
    Promise.all(promises)
        .then(res => handleErrors(res))
        .then(res => {
            let allLanguagesPages = {}
            res.map((singleRes, x) => {
                allLanguagesPages[Settings.supportedLanguages[x]] = singleRes.data
                return null
            })
            // console.log(allLanguagesPages)
            store.dispatch(workshopsLoaded({ data: { ...allLanguagesPages } }))

        })
        .catch(function(err) {
            // console.error(`Failed to load pages: ${err.message}`); // some coding error in handling happened
            store.dispatch({ type: SET_ERROR, payload: err.message })

            window.setTimeout(() => {

                loadWorkshops();
                // console.log('do it again');

            }, 3000)
        });
}