import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadLanguage } from '../actions/pagesActions'
import Settings from '../Settings'
// import _t from '../utils/translate'

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = { style: '' }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  handleScroll() {
    const scrollPosition = window.pageYOffset
    if (scrollPosition > 50 && this.state.style === '') {
      this.setState({ style: 'solid' })
    }
    if (scrollPosition < 50 && this.state.style === 'solid') {
      this.setState({ style: '' })
    }
  }

  toggleMenu() {
    const { menuVisible, showMenu, hideMenu } = this.props

    if (!menuVisible) {
      showMenu()
    } else {
      hideMenu()
    }
  }

  render() {
    const { language } = this.props
    const { style } = this.state
    // const { changeLanguage } = this
    // const targetLanguage = language === 'de' ? 'en' : 'de'

    // const target = alternateVersions?.filter(
    //   (version) => version.hrefLang.substr(0, 2) === targetLanguage
    // )

    // let languageUrl = `/${targetLanguage}`
    // let languageName = language === 'de' ? 'EN' : 'DE'

    // if (target?.length > 0)
    //   languageUrl = target[0].href.replace(/^.*\/\/[^\/]+/, '')

    return (
      <div className={`menu ${style}`}>
        {Settings.menu[language].map((menuItem) => (
          <NavLink className="" key={`menu_${menuItem.url}`} to={menuItem.url}>
            <span className="hover hover-1">{menuItem.title}</span>
          </NavLink>
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.ui.language,
  menuVisible: state.ui.menuVisible,
})

export default withRouter(connect(mapStateToProps)(Menu))
