import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ParallaxBanner } from 'react-scroll-parallax';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import shortid from 'shortid'


import Settings from '../../Settings'


//   <CarouselProvider
//   naturalSlideWidth={100}
//   naturalSlideHeight={80}
//   totalSlides={apiElement.references.length}
//   // totalSlides={apiElement.references.length}
//   isPlaying={true}
//   interval={Settings.slideShowSpeed}
//   lockOnWindowScroll={true}
//   touchEnabled={false}
//   dragEnabled={false}
//   style={{height: '100%'}}
// >
// </CarouselProvider>

class ReferenceSlider extends React.Component {


    render() {
        const { apiElement, activeSlide } = this.props;
        // console.log(activeSlide)
        const id = shortid.generate()
        return (
            <div className="slides_holder">
        {apiElement.references.length > 0 && apiElement.references.map((reference, i) => {
            // console.log(reference)
            return (
                  <div className={`slide ${activeSlide === i ? 'active' : ''}`} key={`slide_${reference.image.id}`} index={i} style={{background: `url('${reference.image.sizes.large}') center / cover no-repeat`}} ></div>
                    )})}


        </div>
        )
    }
}

class TextWithReference extends React.Component {

    constructor(props) {
        super(props)
        this.state = { activeSlide: 0 }
        this.nextSlide = this.nextSlide.bind(this)
        this.lastSlide = this.lastSlide.bind(this)
        this.handleClickNext = this.handleClickNext.bind(this)
        this.handleClickLast = this.handleClickLast.bind(this)
    }
    componentDidMount() {
        this.timerNextSlide = setTimeout(() => {
            this.autoNextSlide()
        }, Settings.slideShowSpeed)
    }

    componentWillUnmount() {
        clearTimeout(this.timerNextSlide)
    }

    autoNextSlide() {
        this.nextSlide();
        this.timerNextSlide = setTimeout(() => {
            this.autoNextSlide()
        }, Settings.slideShowSpeed)
    }

    nextSlide() {
        const { apiElement } = this.props
        this.setState(oldState => ({ activeSlide: oldState.activeSlide + 1 < apiElement.references.length ? oldState.activeSlide + 1 : 0 }))
    }

    lastSlide() {
        const { apiElement } = this.props
        this.setState(oldState => ({ activeSlide: oldState.activeSlide > 1 ? oldState.activeSlide - 1 : apiElement.references.length - 1 }))
    }

    handleClickNext() {

        clearTimeout(this.timerNextSlide)
        this.nextSlide();
        this.timerNextSlide = setTimeout(() => {
            this.autoNextSlide()
        }, Settings.slideShowSpeed * 3)
    }

    handleClickLast() {

        clearTimeout(this.timerNextSlide)
        this.nextSlide();
        this.timerNextSlide = setTimeout(() => {
            this.autoNextSlide()
        }, Settings.slideShowSpeed * 3)

    }

    render() {
        const { apiElement } = this.props
        const { handleClickLast, handleClickNext } = this
        const { activeSlide } = this.state
        let imageUrl = false

        if (apiElement.no_parallax) {
            imageUrl = apiElement.hasOwnProperty('image') &&
                apiElement.image.hasOwnProperty('sizes') &&
                apiElement.image.sizes.large
        }
        // console.log(apiElement)
        return (
            <div className={`text_with_reference text_${apiElement.layout}`}>
            {apiElement.anchor_name !== '' && <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />}
        <div className={`left ${apiElement.no_parallax ? 'no_parallax' : ''}`}>
        {!apiElement.no_parallax &&
        <div className="slideshow_holder">
        <ParallaxBanner className="reference_slideshow" layers={[
        {
            children: <ReferenceSlider apiElement={apiElement} activeSlide={activeSlide}/>,
            amount: Settings.parallaxFactor,
        }]} style={ { background: '', height: '0px', paddingBottom: '61%' }} >


        </ParallaxBanner>
                { apiElement.references.length > 1 && 
            <React.Fragment>
                <div className="manual right" onClick={handleClickNext}><div className="icon"/></div>
                <div className="manual left" onClick={handleClickLast}><div className="icon"/></div>
            </React.Fragment>
    }
        {apiElement.button_text !== '' && <Link className="button solid" to={apiElement.button_link}>{apiElement.button_text}</Link>}
        </div>
    }
    {
        apiElement.no_parallax &&
        <div className="no_parallax_image">
            <img alt='' className='' src={imageUrl} />
            {apiElement.button_text !== '' && apiElement.link_internal && <Link className="button solid" to={apiElement.button_link}>{apiElement.button_text}</Link>}
            {apiElement.button_text !== '' && !apiElement.link_internal && <a className="button solid" href={apiElement.button_link}>{apiElement.button_text}</a>}
        </div>
    }
        </div>
        <div className="text_block">
        <h2>{apiElement.title}</h2>
        <div className=""
            dangerouslySetInnerHTML={{
                __html: apiElement.text || null
            }}/>
            



                                
                                {Settings.debugBlocks && <pre>{JSON.stringify(apiElement, null, 2) }</pre>}
        </div>
        <div className="clearfix"/>
        </div>

        )
    }
}

/*


*/
TextWithReference.defaultProps = {
    apiElement: {}
}
TextWithReference.propTypes = {
    apiElement: PropTypes.object // eslint-disable-line
}

export default TextWithReference