import React from 'react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax'
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'

import Settings from '../../Settings'
import _t from '../../utils/translate'
import parseDate from '../../utils/parseDate'

import SingleCalendarEntry from './SingleCalendarEntry'

const SingleWorkshopDisplay = ({
    workshop,
    workshops,
    apiElement,
    language,
    url
}) => {
    console.log(workshop)

    const imageUrl =
        workshop.hasOwnProperty('image') &&
        workshop.image.hasOwnProperty('sizes') &&
        workshop.image.sizes.large

    const date = new Date()
    const dateString = `${date.getFullYear()}${
        date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1
    }${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}0000`

    const filteredEvents =
        !workshops.loading &&
        _.filter(workshops.calendar[language], { workshop_id: workshop.id })
    let lastMonth = 0

    const filteredFutureEvents = _.filter(
        filteredEvents,
        event => parseInt(event.start_date) > parseInt(dateString)
    )

    return (
        <div className="single_workshop">
            <div className="header_image">
                <div
                    className="image_holder"
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundPosition: workshop.image_position,
                        backgroundSize: 'cover'
                    }}
                />
            </div>

            <div className="heading_holder">
                <h1>{workshop.acf.title}</h1>
            </div>
            <Reveal effect="custom_fade_in">
                <div className="text_block force_left">
                    <h3 className="subtitle">{workshop.acf.subtitle}</h3>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: workshop.acf.description || null
                        }}
                    />
                </div>
            </Reveal>

            {console.log(filteredEvents.length)}
            {console.log(filteredEvents)}

            {!workshops.loading && filteredFutureEvents.length > 0 && (
                <Reveal effect="custom_fade_in">
                    <div className="workshop_calendar">
                        <h2>{apiElement.text_next_workshops}</h2>

                        {!workshops.loading &&
                            filteredFutureEvents.map(event => {
                                const lastMonthTmp = lastMonth
                                lastMonth = parseDate(event.start_date).month
                                return (
                                    <React.Fragment key={event.start_date}>
                                        {parseDate(event.start_date).month !==
                                            lastMonthTmp && (
                                            <h2 className="month">
                                                {_t(
                                                    `${
                                                        parseDate(
                                                            event.start_date
                                                        ).month
                                                    }`,
                                                    'months'
                                                )}
                                            </h2>
                                        )}
                                        <SingleCalendarEntry
                                            event={event}
                                            urlbase={`${url}`}
                                        />
                                    </React.Fragment>
                                )
                            })}
                    </div>
                </Reveal>
            )}

            <div
                className="text_block align_center"
                style={{ marginTop: -20, marginBottom: 20 }}
            >
                <Link className="button" to={'../'}>
                    {apiElement.text_back}
                </Link>
            </div>
        </div>
    )
}

export default SingleWorkshopDisplay

//                         <Parallax y={[-20, Settings.parallaxEnd]} className={`header_image_parallax`}>
//                             <div className="image_holder" style={{backgroundImage: `url(${imageUrl})`, backgroundPosition: 'top', backgroundSize: 'cover'}}/>
//                         </Parallax>

//   { false &&
//      <div className="text_block align_center" style={{marginTop: -20, marginBottom: 20}}>
//         <Link className="button solid" to={'#'}>{apiElement.text_check_availability}</Link>
//     </div>
// }
