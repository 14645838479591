import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'

import Settings from '../../Settings'
import _t from '../../utils/translate'
import urlFormat from '../../utils/urlFormat'
import parseDate from '../../utils/parseDate'

import SingleCalendarEntry from './SingleCalendarEntry'

function addZero(i) {
  let p = parseInt(i)
  if (p < 10) {
    p = '0' + i
  }
  return p
}

const WorkshopCalendar = ({ apiElement, workshops, language, url, match }) => {
  if (workshops.loading) return null
  // console.log(match)
  let lastMonth = ''

  const workshopsFromOtherLanguages = []
  Settings.supportedLanguages.map((lang) => {
    if (lang !== language) {
      workshops.calendar?.[lang]?.map((event) => {
        if (event?.showInAllLanguages) {
          workshopsFromOtherLanguages.push({ ...event, language: lang })
        }
      })
    }
  })

  // const workshopsToDisplay = _.sortBy(
  //   [...workshopsFromOtherLanguages],
  //   'start_date'
  // )

  const workshopsToDisplay = _.sortBy(
    [...workshops.calendar[language], ...workshopsFromOtherLanguages],
    'start_date'
  )

  return (
    <div className="workshop_calendar">
      {apiElement.anchor_name !== '' && (
        <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />
      )}
      <h2>{apiElement.title}</h2>
      <div className="legende">
        <p className="magenta">Rothcoaching Schauspiel Workshops</p>
        <p className="turquoise">Rothcoaching Sources Workshops</p>
        {/* <p className="blue">Rothcoaching Englisch Workshops</p> */}
      </div>
      {!workshops.loading &&
        workshopsToDisplay.map((event) => {
          // console.log(event)
          const date = new Date()
          const dateString = `${date.getFullYear()}${addZero(
            date.getMonth() + 1
          )}${addZero(date.getDate())}${addZero(date.getHours())}${addZero(
            date.getMinutes()
          )}`

          if (parseInt(dateString) > parseInt(event.start_date) + 10) {
            return null
          }

          const lastMonthTmp = lastMonth
          lastMonth = parseDate(event.start_date).month
          // return null
          return (
            <React.Fragment
              key={`calendar_event_${url}single/${urlFormat(event.title)}_${
                event.start_date
              }`}
            >
              {parseDate(event.start_date).month !== lastMonthTmp && (
                <h2 className="month">
                  {_t(`${parseDate(event.start_date).month}`, 'months')}
                </h2>
              )}
              <SingleCalendarEntry
                event={event}
                urlbase={`${url}single/`}
                language={language}
              />
            </React.Fragment>
          )
        })}
      {Settings.debugBlocks && <pre>{JSON.stringify(apiElement, null, 2)}</pre>}
      {Settings.debugBlocks && (
        <pre>{JSON.stringify(workshops.calendar[language], null, 2)}</pre>
      )}
    </div>
  )
}

WorkshopCalendar.defaultProps = {
  apiElement: {},
}
WorkshopCalendar.propTypes = {
  apiElement: PropTypes.object, // eslint-disable-line
}

const mapStateToProps = (state) => ({
  workshops: state.workshops,
  language: state.ui.language,
})

export default withRouter(connect(mapStateToProps)(WorkshopCalendar))
