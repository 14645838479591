import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ParallaxBanner } from 'react-scroll-parallax'
import Settings from '../../Settings'

const Banner = ({ apiElement }) => {
  console.log({ banner: apiElement })
  const imageUrl = apiElement?.image
  return (
    <div className="banner">
      {apiElement.anchor_name !== '' && (
        <div className="anchor" id={`${apiElement.anchor_name}_anchor`} />
      )}
      {imageUrl && (
        <ParallaxBanner
          className="landing_banner_image"
          layers={[
            {
              image: imageUrl,
              amount: Settings.parallaxFactor,
            },
          ]}
          style={{ height: '0px' }}
        >
          <div className="button_holder">
            <h1>{apiElement.text}</h1>

            {apiElement.button_1_text !== '' && (
              <div>
                <Link to={apiElement.button_1_link}>
                  {apiElement.button_1_text}
                </Link>
              </div>
            )}
            {apiElement.button_2_text !== '' && (
              <div>
                <Link to={apiElement.button_2_link}>
                  {apiElement.button_2_text}
                </Link>
              </div>
            )}
          </div>
        </ParallaxBanner>
      )}

      {!imageUrl && (
        <div className="landing_banner_image no_image">
          <div className="button_holder">
            <h1>{apiElement.text}</h1>

            {apiElement.button_1_text !== '' && (
              <div>
                <Link to={apiElement.button_1_link}>
                  {apiElement.button_1_text}
                </Link>
              </div>
            )}
            {apiElement.button_2_text !== '' && (
              <div>
                <Link to={apiElement.button_2_link}>
                  {apiElement.button_2_text}
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

Banner.defaultProps = {
  apiElement: {},
}
Banner.propTypes = {
  apiElement: PropTypes.object, // eslint-disable-line
}

export default Banner
