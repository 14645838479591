import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const LandingPage = ({ pages, history }) => {
    console.log(pages)
    history.push('/de/')
    // return (
    // 	<div>
    // 		<h1>Hello World</h1>

    // 		{!pages.loading &&
    // 			Object.keys(pages.data).map(lang => (
    // 				<div key={`lang_${lang}`}>
    // 					<h3>{lang}</h3>
    // 					<ul>
    // 						{Object.keys(pages.data[lang]).map(page => (
    // 							<li
    // 								key={`pages_list_${
    // 									pages.data[lang][page].id
    // 								}`}
    // 							>
    // 								<Link to={pages.data[lang][page].acf.url}>
    // 									{pages.data[lang][page].title.rendered}
    // 								</Link>
    // 							</li>
    // 						))}
    // 					</ul>
    // 				</div>
    // 			))}
    // 	</div>
    // )
}

// {

//

// 					}

const mapStateToProps = state => ({
    pages: state.pages
})

export default connect(mapStateToProps)(LandingPage)