import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// import $ from 'jquery'
import LogRocket from 'logrocket'

import { loadPages } from './actions/pagesActions'
import { loadWorkshops } from './actions/workshopActions'
import { loadOptions } from './actions/optionsActions'

import { loadBrowserLanguages } from './actions/browserLanguagesActions'

// import setAuthorizationHeader from './utils/setAuthorizationHeader'

// import { SET_LOADING } from './actions/types'
// import { store } from './store/store'

class AppWrapper extends Component {
    // constructor(props) {
    //  super(props)
    // }

    componentDidMount() {
        /* eslint-disable */
        // this.props.loadBrowserLanguages()
        // if (this.props.pagesLoading) {
        // }

        // const alwaysRefresh = true
        // if (alwaysRefresh) {
        //  store.dispatch({ type: SET_LOADING })
        // }

        const { redux, user } = this.props

        // LogRocket.identify(user.email, {
        //     name: user.name,
        //     email: user.email,

        //     // Add your own custom user variables here, ie:
        //     // subscriptionType: 'pro'
        // });
        // if (redux.pages.loading) {
        //     loadPages()
        // }

        // if (redux.workshops.loading) {
        //     loadWorkshops()
        // }

        // if (redux.options.loading) {
        //     loadOptions()
        // }
        loadWorkshops()
        loadOptions()
        loadPages()

        /* eslint-enable */
        // console.log(redusx)
    }

    componentDidUpdate(prevProps) {
        const html = document.querySelector('html')
        // console.log(html)
        const { overlayVisible, messageVisible, newsletterVisible } = this.props
        if (
            prevProps.overlayVisible !== overlayVisible ||
            prevProps.messageVisible !== messageVisible ||
            prevProps.newsletterVisible !== newsletterVisible
        ) {
            if (overlayVisible || messageVisible || newsletterVisible) {
                // $('html').css('overflow', 'hidden')
                html.style.cssText = 'overflow: hidden'
            } else {
                // $('html').removeAttr('style')
                html.style.cssText = ''
            }
        }
    }

    render() {
        const {
            children,
            pagesLoading,
            overlayVisible,
            messageVisible,
            language
        } = this.props
        return (
            <>
                <Helmet htmlAttributes={{ lang: language }} />
                <div
                    className={
                        pagesLoading || overlayVisible || messageVisible
                            ? 'app_wrapper loading'
                            : 'app_wrapper'
                    }
                >
                    {children}
                </div>
            </>
        )
    }
}

const mapStatetoProps = state => ({
    pagesLoading: state.pages.loading,
    overlayVisible: state.ui.overlayVisible,
    messageVisible: state.ui.messageVisible,
    newsletterVisible: state.ui.newsletterVisible,
    language: state.ui.language,
    redux: state,
    user: state.user
})

AppWrapper.defaultProps = {
    children: ''
}

AppWrapper.propTypes = {
    children: PropTypes.array, // eslint-disable-line
    pagesLoading: PropTypes.bool.isRequired,
    overlayVisible: PropTypes.bool.isRequired,
    messageVisible: PropTypes.bool.isRequired,
    newsletterVisible: PropTypes.bool.isRequired
}

export default connect(mapStatetoProps, {
    loadBrowserLanguages
})(AppWrapper)
