import { SHOW_MESSAGE, HIDE_MESSAGE, SHOW_MENU, HIDE_MENU, SET_LANGUAGE, SET_ERROR, SHOW_NEWSLETTER_POPUP, HIDE_NEWSLETTER_POPUP } from './types'
import api from '../api'

export const hideMessage = () => dispatch => {
    dispatch({
        type: HIDE_MESSAGE
    })
}

export const showMessage = (text, type, duration = 3000) => dispatch => {
    dispatch({
        type: SHOW_MESSAGE,
        payload: { text, type, duration }
    })
}


export const showMenu = () => dispatch => {
    dispatch({
        type: SHOW_MENU
    })
}


export const hideMenu = () => dispatch => {
    dispatch({
        type: HIDE_MENU
    })
}

export const setLanguage = (language = 'de') => dispatch => {
    console.log('SET_LANGUAGE')
    console.log(language)
    dispatch({
        type: SET_LANGUAGE,
        payload: language
    })
}

export const setError = (message) => dispatch => {
    dispatch({
        type: SET_ERROR,
        payload: message
    })
}

export const showNewsletterPopup = () => dispatch => {
    dispatch({
        type: SHOW_NEWSLETTER_POPUP
    })
}

export const hideNewsletterPopup = () => dispatch => {
    dispatch({
        type: HIDE_NEWSLETTER_POPUP
    })
}

export const subscribeToNewsletter = (data) => dispatch =>
// console.log('subscribeToNewsletter')
    api.newsletter.subscribeToNewsletter(data)
    .then(res => {
        console.log(res)
        return res
    })