import axios from 'axios'

import Settings from './Settings'

const { apiBase, cachedApiBase, useCache } = Settings

const domain = 'rothcoaching'

export const api = {
  pages: {
    getPages: () =>
      axios.get(`${apiBase}/wp/v2/pages?per_page=100`).then((res) => res),
    getPagesLang: (language) => {
      // Very weird hack to avoid cors problem with lang=en
      // Could have to do with redirection maybe?
      // Bla
      let langString = ''
      // if (language !== Settings.defaultLanguage) {
      langString = `&lang=${language}`

      // }
      // console.log('Fetching Pages:')
      // console.log(`${apiBase}/wp/v2/pages?per_page=100${langString}`)

      const url = useCache
        ? `${cachedApiBase}?domain=${domain}&path=pages_${language}`
        : `${apiBase}/wp/v2/pages?per_page=100${langString}`

      return axios.get(url).then((res) => res)
    },
    // getPostsLang: language => {
    //     // Very weird hack to avoid cors problem with lang=en
    //     // Could have to do with redirection maybe?
    //     // Bla
    //     let langString = ''
    //     // if (language !== Settings.defaultLanguage) {
    //     langString = `&lang=${language}`
    //     // }
    //     // console.log('Fetching Pages:')
    //     // console.log(`${apiBase}/wp/v2/posts?per_page=100${langString}`)
    //     return axios
    //         .get(`${apiBase}/wp/v2/posts?per_page=100${langString}`, {
    //             maxRedirects: 5
    //         })
    //         .then(res => res)
    // }
  },

  browserLanguages: `${apiBase}/browser_lang/browser_lang`,

  workshops: {
    getWorkshopsLang: (language) => {
      const url = useCache
        ? `${cachedApiBase}?domain=${domain}&path=workshops_${language}`
        : `${apiBase}/acf/v3/workshops?per_page=100&lang=${language}`

      return axios.get(url).then((res) => res)
      //     axios
      //         // .get(`${apiBase}/acf/v3/workshops?per_page=100${langString}`)
      //         .get(
      //             `${cachedApiBase}?domain=rothcoaching&path=workshops_${language}`
      //         )
      //         .then(res => res)
      // )
    },
  },

  options: {
    getOptionsLang: (language) => {
      const url = useCache
        ? `${cachedApiBase}?domain=${domain}&path=options_${language}`
        : `${apiBase}/acf/v3/options/options?lang=${language}`

      return axios.get(url).then((res) => res)
      // return (
      //     axios
      //         // .get(`${apiBase}/acf/v3/options/options?lang=${language}`)
      //         .get(
      //             `${cachedApiBase}?domain=rothcoaching&path=options_${language}`
      //         )
      //         .then(res => res)
      // )
    },
  },

  newsletter: {
    subscribeToNewsletter: (data) => {
      return axios
        .post(`${apiBase}/rothcoaching/newsletter`, data)
        .then((res) => {
          return res
        })
    },
  },

  user: {
    signUpForWorkshop: (data) => {
      return axios
        .post(`${apiBase}/rothcoaching/workshop`, data)
        .then((res) => {
          // console.log(res)
          return res
        })
    },
    bookWorkshop: (data) => {
      return axios
        .post(`${apiBase}/rothcoaching/workshop_book`, data)
        .then((res) => {
          console.log(res)
          return res
        })
    },
    contactForm: (data) => {
      return axios
        .post(`${apiBase}/rothcoaching/contact_form`, data)
        .then((res) => {
          // console.log(res)
          return res
        })
    },
  },
}

export default api
