import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadLanguage } from '../actions/pagesActions'
import Settings from '../Settings'
// import _t from '../utils/translate'

import {
  hideNewsletterPopup,
  subscribeToNewsletter,
} from '../actions/uiActions'

class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.sendRequest = this.sendRequest.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.onChange = this.onChange.bind(this)
    this.reset = this.reset.bind(this)
    this.state = {
      loading: false,
      message: '',
      error: false,
      data: { first_name: '', last_name: '', email: '', city: '' },
    }
  }

  reset = () => {
    this.setState({ loading: false, error: false, message: '' })
  }

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: { ...this.state.errors, [e.target.name]: false },
    })

  sendRequest(e) {
    e.preventDefault()

    const { subscribeToNewsletter, options, language } = this.props
    const { data } = this.state
    const { closePopup } = this

    this.setState({ loading: true })
    subscribeToNewsletter({ ...data, language: language })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            message: !options.loading
              ? options.data[language].newsletter.success_text
              : '',
            loading: false,
          })
          setTimeout(() => {
            closePopup()
          }, 3000)
        } else {
          console.log(res.status)
        }
      })
      .catch((err) => {
        console.log(err.response)

        if (err.response.data.message === 'subscribed') {
          this.setState({
            message: options.data[language].newsletter.already_subscribed,
            error: true,
            loading: false,
          })
        }

        if (
          err.response !== undefined &&
          err.response.data.message !== 'subscribed'
        ) {
          this.setState({
            message: err.response.data.message,
            error: true,
            loading: false,
          })
        }

        if (err.response === undefined) {
          this.setState({
            message: 'Error',
            error: true,
            loading: false,
          })
        }
      })
    console.log('sendRequest')
  }

  closePopup() {
    const { hideNewsletterPopup } = this.props
    setTimeout(() => {
      this.setState({ loading: false, message: '' })
    }, 2000)
    hideNewsletterPopup()
  }

  render() {
    const { language, visible, options } = this.props
    const { loading, message, data, error } = this.state
    const { sendRequest, onChange, reset } = this

    // console.log(this.props)

    // const visible = true
    // const { changeLanguage } = this
    const loadingText = !options.loading
      ? options.data[language].newsletter.loading_text
      : ''

    return (
      <div className={`newsletter_overlay ${visible ? 'visible' : ''}`}>
        <div className="popup">
          <h3>
            {!options.loading
              ? options.data[language].newsletter.heading_newsletter
              : ''}
          </h3>
          <p>
            {!options.loading
              ? options.data[language].newsletter.newsletter_text
              : ''}
          </p>
          <form onSubmit={sendRequest}>
            <input
              type="text"
              id="first_name"
              name="first_name"
              style={{
                display: 'inline-block',
                width: '47.5%',
                margin: 0,
              }}
              placeholder={
                !options.loading
                  ? options.data[language].newsletter.first_name_text
                  : ''
              }
              value={data.first_name}
              onChange={onChange}
            />
            <input
              type="text"
              id="last_name"
              name="last_name"
              style={{
                display: 'inline-block',
                width: '47.5%',
                margin: '0 0 0 5%',
              }}
              placeholder={
                !options.loading
                  ? options.data[language].newsletter.last_name_text
                  : ''
              }
              value={data.last_name}
              onChange={onChange}
            />
            <input
              type="text"
              id="city"
              name="city"
              placeholder={
                !options.loading
                  ? options.data[language].newsletter.city_text
                  : ''
              }
              value={data.city}
              onChange={onChange}
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder={
                !options.loading
                  ? options.data[language].newsletter.email_text
                  : ''
              }
              autoComplete="email"
              value={data.email}
              onChange={onChange}
            />
            <button className="white" type="submit" onClick={sendRequest}>
              {!options.loading
                ? options.data[language].newsletter.submit_text
                : ''}
            </button>
          </form>
          <div
            className={`loading_overlay ${
              loading || message !== '' ? 'visible' : ''
            }`}
          ></div>
          <p
            className={`loading_text ${
              loading || message !== '' ? 'visible' : ''
            }`}
          >
            {message !== '' ? message : loadingText}
            {error && (
              <React.Fragment>
                <br />
                <button onClick={reset} className="white">
                  {!options.loading
                    ? options.data[language].newsletter.try_again_text
                    : ''}
                </button>
              </React.Fragment>
            )}
          </p>
        </div>

        <button className="newsletter_button" onClick={this.closePopup}>
          <div className="bar_holder">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.ui.language,
  visible: state.ui.newsletterVisible,
  options: state.options,
})

export default withRouter(
  connect(mapStateToProps, { hideNewsletterPopup, subscribeToNewsletter })(
    Newsletter
  )
)
