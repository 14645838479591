import { UPDATE_USER_DATA, WORKSHOP_SIGNUP } from './types'
import api from '../api'

export const setUserData = data => dispatch => {
	dispatch({
		type: UPDATE_USER_DATA,
		payload: data
	})
}

export const submitWorkshopRequest = data => dispatch =>
	api.user.signUpForWorkshop(data).then(res => {
		console.log(res)
		return res
	})

export const submitWorkshopBooking = data => dispatch =>
	api.user.bookWorkshop(data).then(res => {
		console.log(res)
		return res
	})

export const submitContactFormRequest = data => dispatch =>
	api.user.contactForm(data).then(res => {
		console.log(res)
		return res
	})

// export const subscribeToNewsletter = (data) => dispatch =>
//     api.newsletter.subscribeToNewsletter(data)
//     .then(res => {
//         console.log(res)
//         return res
//     })
